import React, { useContext, useEffect, useState, useRef, useLayoutEffect, useMemo } from 'react'
import { useSnack } from 'provider/SnackbarProvider'
import DataTableWithMeta from 'components/DataTableWithMeta/DataTableWithMeta'
import { useCampaign } from 'provider/CampaignProvider'
import { DataRow, DataSession } from 'components/DataSession'
import { useHistory, useLocation, useParams, withRouter } from 'react-router-dom'
import DetailsView from './components/DetailsView'
import useApi from 'api/useApi'
import {
    Box, Button, Container, Divider, FormControl, FormControlLabel, FormLabel,
    Grid, GridList, GridListTile, IconButton, isWidthDown, makeStyles, Paper,
    Radio, RadioGroup, TextField, Typography, useTheme, withWidth
} from '@material-ui/core'
import { useLocale } from 'provider/LocaleProvider'
const moment = require('moment-timezone')

const useStyles = makeStyles(theme => ({
    body: {
        // flex: 1,
        padding: theme.spacing(3),
        // background: '#eaeff1',
        boxSizing: 'border-box',
        // overflowY: 'scroll'
    },
}))

const TokenDetailConetext = React.createContext()

const _ = require('lodash')
export const useTokenDetail = () => useContext(TokenDetailConetext)

const generalField = ['campaign_id', 'channel', 'id', 'redeem_token_created_at']

const TokenDetail = (props) => {
    const userRequest = useApi()
    const { t } = useLocale()
    const theme = useTheme()
    const classes = useStyles()
    const snack = useSnack()
    const { campaignId, tid } = useParams()
    const { campaign, getRecordMetaField } = useCampaign()
    const history = useHistory()
    const [tokenDetails, setTokenDetails] = useState()
    const [records, setRecords] = useState([])
    const [metaField, setMetaField] = useState([])
    const location = useLocation()

    useEffect(() => {
        getTokenDetails()
        getRedeemRecords()
    }, [location.pathname])



    function refresh() {
        getTokenDetails()
        getRedeemRecords()
    }

    useEffect(() => {
        setMetaField(getRecordMetaField())
    }, [campaign])

    function getTokenDetails() {
        userRequest.getTokens({ redeem_token: tid })
            .then(res => {
                const result = res.data
                if (result && result.result != 'fail' && _.size(result.redeem_tokens) > 0) {
                    setTokenDetails(result.redeem_tokens[0])
                }
                else {
                    console.log('getTokenDetails -> `/campaigns/${campaignId}`', `/campaigns/${campaignId}`)
                    history.push(`/campaigns/${campaignId}/redeem_tokens`)
                }
            })
    };

    function getRedeemRecords() {
        userRequest.getRedeemRecords({ redeem_token: tid })
            .then(res => {
                const result = res.data
                if (result && result.result != 'fail') {
                    setRecords(result.redeem_records)
                }
                else {
                    setRecords([])
                }
            })
    };

    if (!tokenDetails) return null

    return (
        <Container>
            <Typography variant='h4' className='bold' paragraph>
                {t('redeem_token')}
            </Typography>
            <DetailsView details={tokenDetails} records={records} metaField={metaField} refresh={refresh} />
        </Container>
    )

}


TokenDetail.propTypes = {
    // t: PropTypes.func.isRequired,
}

export default TokenDetail