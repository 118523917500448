import React, { useContext, useEffect, useRef, useState } from 'react'
import { Grid, makeStyles, TextField, Typography, useTheme } from '@material-ui/core'
import EditDialog, { DialogTextField } from '../EditDialog'
import PropTypes, { object } from 'prop-types'
import clsx from 'clsx'
import { useSnack } from '../../provider/SnackbarProvider'
import { useLocale } from '../../provider/LocaleProvider'
const useStyles = makeStyles(theme => ({

}))
const EditMetaDialog = props => {

    const theme = useTheme()
    const classes = useStyles()

    const { editMetaDialog, metadata, setMetadata, update } = props

    const [key, setKey] = useState()
    const [value, setValue] = useState()
    const { t } = useLocale()
    const snack = useSnack()

    useEffect(() => {
        setKey(editMetaDialog.data.key)
        setValue(editMetaDialog.data.value)
    }, [editMetaDialog.data])

    const handleClose = () => {
        editMetaDialog.close()
    }

    const handleSuccess = () => {
        editMetaDialog.close()
        snack.open(editMetaDialog.mode === 'add' ? t('add_meta') : editMetaDialog.mode === 'edit' ? t('edit_meta') : t('delete_meta'))
        // document.getElementById("app").scroll(0, 0)

    }

    const handleFail = () => {
        snack.error(t('failed'))
    }


    const handleSave = () => {
        var meta = { ...metadata }
        console.log('handleSave -> meta', meta)
        if (key in meta) {
            meta[key] = value
        }
        else {
            meta[key] = value
        }
        if (update) {
            update({ metadata: meta }, handleSuccess, handleFail)
        }
        else {
            console.log('handleSave -> setMetadata', setMetadata)
            setMetadata(meta)
            handleClose()
        }
    }

    const handleDelete = () => {
        var meta = { ...metadata }
        delete meta[editMetaDialog.data.key]
        if (update) {
            update({ metadata: meta }, handleSuccess, handleFail)
        }
        else {
            setMetadata(meta)
            handleClose()
        }

    }

    const handleChange = (name, value) => {
        if (name == 'key') {
            setKey(value)
        }
        else if (name == 'value') {
            setValue(value)
        }
    }



    const title = editMetaDialog.mode === 'add' ? t('add_meta') : editMetaDialog.mode === 'edit' ? t('edit_meta') : t('delete_meta')

    if (editMetaDialog.mode === 'delete') {
        return (
            <EditDialog open={editMetaDialog.open} title={title} handleSave={handleDelete} handleClose={handleClose} confirmText={t('confirm')}>
                {t('meta_confirm_msg')}
            </EditDialog>
        )
    }

    return (
        <EditDialog open={editMetaDialog.open} title={title} handleSave={handleSave} handleClose={handleClose} >
            <Grid className={classes.grid_container} container spacing={2} >
                <Grid item xs={12} sm={6} className='flexRow' >
                    <DialogTextField disabled={editMetaDialog.mode == 'edit'} label={t('key_name')} onValueChange={handleChange} name='key' value={key} required placeholder='' maxLength={20} style={{ margin: 0 }} />
                </Grid>
                <Grid item xs={12} sm className={'flexRow'} >
                    <DialogTextField label={t('key_value')} onValueChange={handleChange} placeholder='' name='value' value={value} maxLength={20} style={{ margin: 0 }} />
                </Grid>
            </Grid >
        </EditDialog>
    )
}

export default EditMetaDialog