import { FormColorTextField, FormSwitch, FormTextField } from 'components/DataForm'

import { Drawer, Box, Grid, useTheme, Typography, Toolbar, IconButton, withWidth } from '@material-ui/core'
import { DataSession } from 'components/DataSession'
import { useLocale } from 'provider/LocaleProvider'
import { Fragment, useEffect, useState } from 'react'
import '../theme.css'
import CloseIcon from '@material-ui/icons/Close'
import ThemeEditDrawer from '../ThemeEditDrawer'
import useRect from 'hooks/useRect'
import useApi from 'api/useApi'
import SlideShow from '../slideshow/SlideshowPreview'
import { Switch } from 'react-router-dom'
import QrcodePreview from './QrcodePreview'
var QRCode = require('qrcode.react')




const _ = require('lodash')
function createQRCode({ display_text, background_color, text_color, code, is_active = true, scale = 1 }) {
    return (
        {
            "background_color": background_color || '#000000',
            "text_color": text_color || "#ffffff",
            "code": code || 'Scan Me',
            "display_text": display_text || 'Scan Me',
            "is_active": is_active,
            "scale": scale,
        }
    )
}


const QRCodeDrawer = ({ open = true, setOpen, detail, setDetail }) => {

    const qr_code = detail.qr_code
    const [qrcode, setQrcode] = useState(createQRCode(qr_code))
    const [ref, rect, vw] = useRect(open)



    useEffect(() => {
        if (open) setQrcode(createQRCode(qr_code))
    }, [open])

    const theme = useTheme()
    const { t } = useLocale()


    const api = useApi()


    const handleSetValue = (name, format = (v) => v) => v => {
        var value = format(v)
        if (value !== null) setQrcode({ ...qrcode, [name]: value })
    }

    function handleSave() {
        api.updateThemeDetails({ qr_code: { ...qrcode, scale: _.toNumber(qrcode.scale) } }, setDetail)
    }
    function handleClear() {
        setQrcode(createQRCode({}))
    }



    const qrcodeForm = () => (
        <Fragment>
            <FormSwitch label={t('status')} checked={qrcode.is_active} handleChange={handleSetValue('is_active', e => e.target.checked)} />
            <FormColorTextField name={t('background_color')} value={qrcode.background_color} setValue={handleSetValue('background_color')} />
            <FormColorTextField name={t('text_color')} value={qrcode.text_color} setValue={handleSetValue('text_color')} />
            <FormTextField name={t('code')} value={qrcode.code} setValue={handleSetValue('code')} />
            <FormTextField name={t('scale')} value={_.toString(qrcode.scale)} setValue={handleSetValue('scale')} />
            <FormTextField name={t('display_text')} value={qrcode.display_text} setValue={handleSetValue('display_text')} />
        </Fragment>
    )

    const preview = () => (
        <div ref={ref} className='flexCol fullSize' style={{ position: 'relative' }}>
            <SlideShow idlePage={detail.idle_page} slides={detail.idle_page.contents} />
            <QrcodePreview qrcode={qrcode} vw={vw} />
        </div>
    )

    return (
        <Fragment>
            <ThemeEditDrawer open={open} setOpen={setOpen} detail={detail} title={t('edit_qr_code')} preview={preview} form={qrcodeForm} handleSave={handleSave} handleClear={handleClear} />
        </Fragment>
    )




}

export default QRCodeDrawer