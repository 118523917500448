import { DomainDisabled } from '@material-ui/icons'
import Axios from 'axios'
import { useBackDrop } from 'provider/BackdropProvider'
import { useHistory, useParams } from 'react-router-dom'
import { useSnack } from 'provider/SnackbarProvider'
import { useLocale } from 'provider/LocaleProvider'
import { useAuthData } from 'provider/AuthDataProvider'
import { useEffect } from 'react'
import apiLink from 'api/apiLink'

const moment = require('moment-timezone')
const baseURL = apiLink.gacha_device_url
const redeemBaseURL = apiLink.server_url + '/v2'

Axios.defaults.baseURL = baseURL
const axios = Axios.create({
    baseURL: baseURL,
    headers: { 'Content-Type': 'application/json' },
})

const redeemAxios = Axios.create({
    baseURL: redeemBaseURL,
    headers: { 'Content-Type': 'application/json' },
})

const useGachaDeviceApi = () => {
    const backdrop = useBackDrop()
    const { token, headers, organization } = useAuthData()
    const config = { headers: headers }

    axios.interceptors.request.use(
        (config) => {
            return config
        },
        (error) => {
            console.log(error)
            return error
        }
    )

    axios.interceptors.response.use(
        (response) => {
            const result = response && response.data
            if (result && result.result !== 'fail') {
                return response
            }
            else throw result
        },
        (error) => {
            backdrop.close()
            return Promise.reject(error)
        }
    )

    //? get device status
    function getGachaDeviceStatus(mac, setDeviceDetails = new Function()) {
        return axios.get(`/gacha/status/${mac}`).then(res => {
            const result = res.data
            setDeviceDetails(result || null)
            return Promise.resolve(res)
        })
            .catch(err => {
                setDeviceDetails(null)
            })
    }

    //? start remote redeem
    function remoteRedeem(mac, track) {
        return axios.get(`/gacha/start/${mac}/${track}`).then(res => {
            const result = res.data
            console.log('result: ', result)
            return Promise.resolve(res)
        })
            .catch(err => {
                console.log('err: ', err)
            })
    }

    //? one time redeem, add redeem record to campaign
    function oneTimeRedeem(body) {
        return redeemAxios.post(`/one-time-redeem`, body, config).then(res => {
            const result = res.data
            console.log('result: ', result)
            return Promise.resolve(res)
        })
            .catch(err => {
                console.log('err: ', err)
            })
    }


    return {
        getGachaDeviceStatus,
        remoteRedeem,
        oneTimeRedeem
    }
}


export default useGachaDeviceApi