import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
    Avatar,
    Box,
    Card,
    Checkbox,
    colors,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography
} from '@material-ui/core'
import { useLocale } from 'provider/LocaleProvider'
import { borderColor } from '@material-ui/system'

const _ = require('lodash')
const useStyles = makeStyles(theme => ({
    table: {
        overflow: "auto",
        '& .table-spacer': {
            borderLeft: '0 !important',
            flex: '1 1 auto', width: '100%', padding: '0 !important'
        },
        "& .MuiTableCell-root": {
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            padding: '6px 12px',
            borderLeft: '1px solid',
            borderColor: theme.palette.divider,
            '& a': {
                display: 'inline'
            },
            '& .MuiButtonBase-root': {
                padding: 0
            },
            '& .MuiSvgIcon-root': {
                height: '24px'
            }
        },
        "& .MuiTableRow-root": {
            height: '40px'
        },
        "& .MuiTableHead-root": {
            backgroundColor: theme.palette.table.main,
            "& .MuiTableCell-paddingCheckbox": {
                textAlign: 'center',
                width: '48px',
            },
        },
        "& .MuiTableSortLabel": {
            "&-icon": {
                opacity: 0.5
            },
            "&-active": {
                opacity: 1,
                "& .MuiTableSortLabel-icon": {
                    color: 'limegreen !important'
                },
                // "& .MuiTableSortLabel-iconDirectionDesc": {
                //     color: 'red !important'
                // }
            }

        },
    }
}))


const IndexHeadCell = () => {

}


const IndexBodyCell = ({ page = 0, rowsPerPage = 0, index = 0 }) => {
    return (
        <TableCell padding='checkbox' style={{ textOverflow: 'unset' }}>
            <Typography variant='body1' noWrap >{page * rowsPerPage + index + 1}</Typography>
        </TableCell>
    )
}


const CheckboxHeadCell = ({ selected, setSelected, rowsInPage }) => {
    const pageSelected = _.filter(rowsInPage, row => _.findIndex(selected, row) >= 0)
    const numSelected = _.size(pageSelected)
    const rowsPerPage = _.size(rowsInPage)

    function selectAll(event) {
        var newSelected = _.toArray(_.cloneDeep(selected))
        if (event.target.indeterminate) {
            newSelected = _.pullAllBy(newSelected, rowsInPage, 'id')
        }
        else if (event.target.checked) {
            newSelected = _.unionBy(newSelected, rowsInPage, 'id')
        }
        else {
            newSelected = _.pullAllBy(newSelected, rowsInPage, 'id')
        }
        setSelected(newSelected)
    }

    return (
        <TableCell padding="checkbox">
            <Checkbox
                // indeterminate={numSelected > 0 && numSelected < rowsPerPage}
                checked={numSelected > 0 && numSelected === rowsPerPage}
                onChange={selectAll}
            />
        </TableCell>
    )
}

const CheckboxBodyCell = ({ selected, row }) => {
    return (
        <TableCell padding="checkbox" style={{ textOverflow: 'unset' }}>
            <Checkbox
                checked={_.findIndex(selected, { id: row.id }) >= 0}
            />
        </TableCell>
    )
}


const DataTable = ({ rows = [], row_id = 'id', rowRef, heads = [], _orderBy = 'created_at', _order = 'desc', loading = true, rowsPerPage = 10, rowsPerPageOptions = [5, 10, 25], Container = Card, Toolbar, checkbox, showIndex, ...rest }) => {
    const classes = useStyles()

    const { t } = useLocale()
    const [page, setPage] = useState(0)

    const [order, setOrder] = useState(_order)
    const [orderBy, setOrderBy] = useState(_orderBy)

    const [limit, setLimit] = useState(rowsPerPage)



    const sorted = _.orderBy(rows, [orderBy], [order])
    const rowLength = _.size(sorted)
    const chunks = _.chunk(sorted, limit)

    const emptyRows = limit - Math.min(limit, rowLength - page * limit)

    const headCells = heads
    const bodyCells = _.size(chunks) > 0 && chunks[page] ? chunks[page] : []


    const [highlighted, setHighlighted] = useState()
    const [selected, setSelected] = useState([])

    useEffect(() => {
        if (rowRef) {
            rowRef.current = sorted
        }
    }, [rowRef, sorted])

    function handleClickRow(row) {
        if (checkbox) {
            var newSelected = _.toArray(_.cloneDeep(selected))
            var index = _.findIndex(newSelected, { [row_id]: row[row_id] })
            if (index < 0) {
                newSelected.push(row)
            }
            else {
                _.pullAt(newSelected, index)
            }
            setSelected(newSelected)
        }
        else {
            setHighlighted(row)
        }

    }


    const handleLimitChange = (event) => {
        setLimit(event.target.value)
    }

    const handlePageChange = (event, newPage) => {
        setPage(newPage)
    }

    const handleSort = (cell) => {
        if (orderBy == cell.value) {
            setOrder(order === 'asc' ? 'desc' : 'asc')
        }
        else {
            setOrderBy(cell.value)
        }
    }

    return (
        <Container {...rest}>
            {Toolbar && Toolbar({ selected, setSelected })}
            <PerfectScrollbar>
                <Box style={{ overflow: 'auto' }}>
                    <Table size='small' className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {checkbox && <CheckboxHeadCell selected={selected} setSelected={setSelected} rowsInPage={bodyCells} />}
                                {showIndex && <TableCell padding='checkbox'>#</TableCell>}
                                {_.map(headCells, cell => (
                                    <TableCell key={cell.id} style={{ textAlign: cell.align, width: cell.width, minWidth: cell.width }}>
                                        {cell.sort === true
                                            ? <TableSortLabel
                                                active={orderBy === cell.value}
                                                direction={orderBy === cell.value ? order : 'asc'}
                                                onClick={(event) => handleSort(cell)}
                                                hideSortIcon={false}
                                            >
                                                {cell.label}
                                            </TableSortLabel>
                                            : _.get(cell, 'label')
                                        }
                                    </TableCell>
                                ))
                                }
                                <TableCell className='table-spacer' />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.map(bodyCells, (row, index) => (
                                <TableRow
                                    hover
                                    selected={_.isMatch(highlighted, { id: row.id })}
                                    onClick={() => handleClickRow(row)}
                                    key={index}
                                >
                                    {checkbox && <CheckboxBodyCell selected={selected} row={row} />}
                                    {showIndex && <IndexBodyCell page={page} rowsPerPage={rowsPerPage} index={index} />}
                                    {_.map(headCells, headCell => (
                                        <TableCell key={headCell.id + index} style={{ textAlign: headCell.align }}>
                                            {headCell.content ? headCell.content(row, index) : _.get(row, headCell.value)}
                                        </TableCell>
                                    ))}
                                    <TableCell className='table-spacer' />
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: emptyRows * 40 }}>
                                    <TableCell colSpan={99} style={{ textAlign: 'center' }} >
                                        {rowLength === 0 && !loading && <Typography variant='h5'>{t('no_result')}</Typography>}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Box>
            </PerfectScrollbar>
            <TablePagination
                component="div"
                labelRowsPerPage={t('rows_per_page')}
                count={rowLength}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={rowsPerPageOptions}
                nextIconButtonText={t('next_page')}
                backIconButtonText={t('previous_page')}

            />
        </Container >
    )
}


export default DataTable
