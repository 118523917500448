import { Box, Button, makeStyles, useTheme } from '@material-ui/core'
import React, { Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { DataRow, DataSession, DataSessionSubtitle, DataTypography, TextDataRow } from 'components/DataSession'
import MetaTable from 'components/MetaTable/MetaTable'
import DataTable from 'components/DataTable/DataTable'
import { useLocale } from 'provider/LocaleProvider'
import { useFormikContext } from 'formik'
import { Alert } from '@material-ui/lab'
const moment = require('moment-timezone')



const useStyles = makeStyles(theme => ({

}))

const error_message = "Amount must be a number from 0-999"

function time(str) {
    return moment(str).format('DD/MM/YYYY, hh:mm:ss')
}

const _ = require('lodash')


const GenerateConfirm = ({ productList = [] }) => {
    const { t } = useLocale()
    const theme = useTheme()
    const { campaignId } = useParams()
    const formik = useFormikContext()
    const { values, errors, handleSubmit, setFieldValue } = formik
    console.log('errors', errors)

    const handleReset = () => {
        setFieldValue('step', 0)
        setFieldValue('mode', null)
    }

    const handleBack = () => {
        setFieldValue('step', values.step - 1)
    }

    const rule = values.redeem_token_rule
    const condition = rule.redeem_date_conditions || {}
    const quota = rule.redeem_quota || {}

    function array2String(arr) {
        if (typeof arr == 'object' && arr.length > 0) {
            return arr.reduce((a, c) => `${t(a)}, ${t(c)}`)
        }
        return null
    }
    function time_date(str) {
        return moment(str).format('DD/MM/YYYY')
    }

    return (
        <Fragment>
            <div style={{ flexGrow: 1 }}>
                {errors.response &&
                    <Alert variant="filled" severity="error" style={{ marginBottom: '24px' }}>
                        {t(errors.response)}
                    </Alert>
                }
                {values.mode == 'auto'
                    ? <DataSession title={t('token_amount')}> <TextDataRow name={t('amount')} value={values.tokens_count} /></DataSession>
                    : <DataTable
                        row_id='redeem_token'
                        rows={_.map(values.redeem_tokens, i => ({ value: i }))}
                        heads={[
                            {
                                id: 'redeem_token',
                                label: 'Redeem Token',
                                value: 'value',
                                width: '300px',
                                align: 'left',
                            }
                        ]}
                        dense
                        _order='asc'
                        rowsPerPageOptions={[10, 20, 50]}
                    />
                }
                <br></br>
                <DataSession title={t('product')}>
                    <TextDataRow name={t('product_id')} value={values.product_id} />

                    <TextDataRow name={t('product_name')} value={_.find(productList, { id: values.product_id })?.name} />
                </DataSession>
                <DataSession title={t('redeem_token_rule')} style={{ marginTop: '24px' }}>
                    <DataSessionSubtitle title={t('validity_period')} />
                    <TextDataRow name={t('start_at')} value={rule.started_at ? time_date(rule.started_at) : t('start_immediately')} />
                    <TextDataRow name={t('expired_date')} value={rule.expired_at ? time_date(rule.expired_at) : t('n/a')} />
                    <TextDataRow name={t('expired_after')} value={rule.expiry_seconds ? rule.expiry_seconds + ' ' + t('seconds') : t('n/a')} />
                    <DataSessionSubtitle title={t('redeem_period')} />
                    <TextDataRow name={t('condition_type')} value={t(condition.type)} />
                    <TextDataRow name={t('weekdays')} value={array2String(condition.weekdays)} />
                    <TextDataRow name={t('months')} value={array2String(condition.months)} />
                    <TextDataRow name={t('dates')} value={array2String(condition.dates)} />
                    <DataSessionSubtitle title={t('redeem_token_config')} />
                    <TextDataRow name={t('has_reward')} value={rule.has_reward != null ? t(rule.has_reward.toString()) : null} />
                    <TextDataRow name={t('is_reusable')} value={rule.is_reusable != null ? t(rule.is_reusable.toString()) : null} />
                    {rule.is_reusable && (quota.overall || quota.yearly || quota.monthly || quota.weekly || quota.daily) &&
                        <DataRow name={t('redeem_quota')} alignSelf='baseline'  >
                            <div>
                                {quota.overall && <DataTypography value={`${t('overall')}: ${quota.overall}`} style={{ marginBottom: '4px' }} />}
                                {quota.yearly && <DataTypography value={`${t('yearly')}: ${quota.yearly}`} style={{ marginBottom: '4px' }} />}
                                {quota.monthly && <DataTypography value={`${t('monthly')}: ${quota.monthly}`} style={{ marginBottom: '4px' }} />}
                                {quota.weekly && <DataTypography value={`${t('weekly')}: ${quota.weekly}`} style={{ marginBottom: '4px' }} />}
                                {quota.daily && <DataTypography value={`${t('daily')}: ${quota.daily}`} />}
                            </div>
                        </DataRow>
                    }
                    <TextDataRow name={t('token_identifier')} value={rule.token_identifier} />
                    <TextDataRow name={t('redeem_pin')} value={rule.redeem_pin} />
                </DataSession>
                <MetaTable metadata={values.redeem_token_rule?.metadata} />
            </div>
            <Box display='flex' style={{ marginTop: '24px' }}>
                <Button onClick={handleReset} variant="contained" style={{ color: 'white', backgroundColor: theme.palette.error.main }}>
                    {t('cancel')}
                </Button>
                <Box flexGrow={1} />
                <Button variant="outlined" onClick={handleBack}  >
                    {t('back')}
                </Button>
                <Button
                    type='submit'
                    variant="contained"
                    color="primary"
                    disabled={formik.isSubmitting}
                    style={{ marginLeft: '8px' }}
                    onClick={handleSubmit}
                >
                    {t('confirm')}
                </Button>
            </Box>
        </Fragment>
    )
}

export default GenerateConfirm