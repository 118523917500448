
import React, { Fragment, useMemo, useCallback, useEffect, useState, useRef, useLayoutEffect } from 'react'
import useRect from './useRect'
const _ = require('lodash')
const useZoomResizer = () => {

    const [zoom, setZoom] = useState(1)

    const ref = useCallback(node => {
        if (node) {
            resize(node)
            window.addEventListener('resize', handleResize)
        }
        ref.current = node
    }, [])

    function resize(node) {
        var ratio = window.innerWidth / window.innerHeight
        var x = 1600 * ratio
        var y = 1600
        var xr = window.innerWidth / x
        var xy = window.innerHeight / y
        var min = _.min([xr, xy])
        node.style.width = x + 'px'
        node.style.height = y + 'px'
        node.style.zoom = min
    }

    function handleResize() {
        if (ref.current) {
            resize(ref.current)
        }
    }



    return [ref]
}

export default useZoomResizer