import { createMuiTheme, colors, ThemeProvider } from "@material-ui/core"
import { CallMissedOutgoingOutlined } from '@material-ui/icons'

import GlobalStyles from './GlobalStyles'
import typography from './typography'




const MuiThemeProvider = props => {

    const { children } = props

    const theme = createMuiTheme({
        palette: {
            background: {
                default: '#eaeff1',
                light: '#63ccff',
                main: '#009be5',
                dark: '#006db3',
                paper: colors.common.white,
            },
            primary: {
                main: '#009be5',
                light: '#63ccff',
                dark: '#006db3',
            },
            secondary: {
                main: colors.indigo[500]
            },
            selected: {
                main: colors.lightBlue[50],
                hover: colors.lightBlue[50],
            },
            table: {
                main: colors.grey[300],
            },
            text: {
                primary: colors.blueGrey[900],
                secondary: colors.blueGrey[300]
            },
        },
        // typography: typography,
        // spacing: 4,
        shape: {
            // borderRadius: 8,
        },
        mixins: {
            toolbar: {
                minHeight: 48,
            },
        },
        props: {
            MuiTextField: {
                variant: 'outlined',
                InputLabelProps: {
                    shrink: true
                }
            },
            MuiContainer: {
                maxWidth: null,
            },
            MuiInputBase: {
                autoComplete: 'off'
            },
            MuiToolbar: {
                variant: 'dense'
            },
            MuiTab: {
                disableRipple: true,
            },
            MuiRadio: {
                color: 'primary'
            },
            MuiCheckbox: {
                color: 'primary'
            }


        },
        overrides: {
            MuiTextField: {


            },
            MuiContainer: {
                root: {
                    minHeight: '100%',
                    paddingTop: 24,
                    paddingBottom: 24
                }
            },
            MuiTypography: {
                root: {
                    overflowWrap: 'break-word'
                }
            },
            MuiInputBase: {
                input: {
                    minWidth: '100px'
                }
            },
            MuiButton: {
                root: {
                    minWidth: '80px',
                },
                label: {
                    textTransform: 'none',
                },
                contained: {
                    boxShadow: 'none',
                    '&:active': {
                        boxShadow: 'none',
                    },
                },
            },
            MuiOutlinedInput: {
                root: {
                    paddingTop: '0',
                    paddingBottom: 0
                },
                input: { padding: '14px 12px', }
            },
            MuiListItemIcon: {
                root: {
                    marginRight: 0,
                    '& svg': {
                        // fontSize: 20,
                    },
                },
            },
            MuiInputBase: {
                root: {
                    backgroundColor: colors.common.white,
                    paddingTop: 0,
                    paddingBottom: 0
                }
            },
            MuiToolbar: {
                root: {
                    padding: '8px 0',
                    boxSizing: 'border-box',
                    minHeight: '0',
                },
                regular: {
                    minHeight: '0',
                },
                gutters: {
                    paddingLeft: 0,
                    paddingRight: 0,
                    '@media (min-width: 600px)': {
                        paddingLeft: 0,
                        paddingRight: 0
                    }
                },

            },
            MuiAutocomplete: {
                option: {
                    '&[aria-selected="true"]': {
                        backgroundColor: 'rgba(0,0,0,0.02)',
                    },
                    '&[data-focus="true"]': {
                        backgroundColor: 'rgba(0,0,0,0.08)',
                    },
                },
            },
            MuiCssBaseline: GlobalStyles()
        }


    })

    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )
}

export default MuiThemeProvider
