import { Box, Button, Container, Grid, IconButton, makeStyles, Toolbar, Typography, useTheme } from '@material-ui/core'
import React, { useContext, useEffect, useState, useRef, useLayoutEffect, forwardRef } from 'react'
import { NavLink, useLocation, useParams, withRouter } from 'react-router-dom'
import { useSnack } from 'provider/SnackbarProvider'
import VisibilityIcon from '@material-ui/icons/Visibility'


import clsx from 'clsx'
import DataTableWithMeta from 'components/DataTableWithMeta/DataTableWithMeta'
import TokenTable from 'components/DataTableWithMeta/TokenTable'
import useApi from 'api/useApi'
import { useLocale } from 'provider/LocaleProvider'
const moment = require('moment-timezone')
const useStyles = makeStyles(theme => ({
    body: {
        // flex: 1,
        padding: theme.spacing(3),
        // background: '#eaeff1',
        boxSizing: 'border-box',
        // overflowY: 'scroll'
    },
}))

const TokensContext = React.createContext()



const generalField = ['is_active', 'pin', 'times_redeemed']

export const useTokens = () => useContext(TokensContext)

const RedeemTokens = (props) => {

    const theme = useTheme()
    const classes = useStyles()
    const userRequest = useApi()
    const snack = useSnack()
    const [tokens, setTokens] = useState()
    const [total, setTotal] = useState(0)

    const { t } = useLocale()
    const location = useLocation()

    useEffect(() => {
        getTokenList()
    }, [location.pathname])



    function getTokenList(data) {
        userRequest.getTokens()
            .then(res => {
                const result = res.data
                if (result && result.result != 'fail') {
                    setTokens(result.redeem_tokens.map((token, index) => ({ ...token, index: result.total - index })))
                    setTotal(result.total)
                }
                else {
                    setTokens([])
                    setTotal(0)
                }
            })
    };

    const state = {
        tokens,
        total,
    }
    return (
        <Container>
            <Typography variant='h4' className='bold' paragraph>
                {t('redeem_tokens')}
            </Typography>
            <TokensContext.Provider value={state} >
                <TokenTable showIndex linkToDetail checkBox table_name='tokens' data={tokens} handleRefresh={getTokenList} />
            </TokensContext.Provider >
        </Container>
    )
}

RedeemTokens.propTypes = {
    // t: PropTypes.func.isRequired,
}

export default withRouter(RedeemTokens)