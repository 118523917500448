import { FormColorTextField, FormTextField } from 'components/DataForm'

import { Drawer, Box, Grid, useTheme, Typography, Toolbar, IconButton, withWidth, Card, CardContent, Button, Divider } from '@material-ui/core'
import { DataSession } from 'components/DataSession'
import { useLocale } from 'provider/LocaleProvider'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import ThemeEditDrawer from '../ThemeEditDrawer'
import useRect from 'hooks/useRect'
import useApi from 'api/useApi'
import Sortable from 'sortablejs'
import { ReactSortable } from 'react-sortablejs'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import SwapVertIcon from '@material-ui/icons/SwapVert'
import '../theme.css'
import { UploadButton } from 'components/FileUploader'
import { Swiper, SwiperSlide, Autoplay } from 'swiper/react'
// Import Swiper styles
import 'swiper/swiper.scss'
import SlideForm from './SlideshowForm'
import SlideShow from './SlideshowPreview'
import QrcodePreview from '../qr_code/QrcodePreview'





function createIdlePage(data = {}) {
    const {
        image_duration = 1,
        contents = [],
    } = data



    return (
        {
            image_duration: image_duration,
            contents: contents.filter(slide => slide.url !== undefined || slide.url !== null),
        }
    )
}


const SlideshowDrawer = ({ open = true, setOpen, detail, setDetail }) => {


    const idle_page = detail.idle_page

    const { t } = useLocale()

    const [idlePage, setIdlePage] = useState(createIdlePage(idle_page))
    const api = useApi()

    const slides = idlePage.contents
    function setSlides(value) {
        setIdlePage({ ...idlePage, contents: value })
    }

    useEffect(() => {
        setIdlePage(createIdlePage(idle_page))
    }, [open])


    const handleSetValue = (name, format = (v) => v) => v => {
        var value = format(v)
        if (value !== null) setIdlePage({ ...idlePage, [name]: value })
    }

    async function handleSave() {
        const imageData = new FormData()
        const videoData = new FormData()
        var img_flag = false
        var vid_flag = false
        slides.map((slide, index) => {
            var blob = slide.blob
            if (blob && blob.type) {
                if (blob.type.match(/^image/g)) {
                    img_flag = true
                    imageData.append(index, blob)
                }
                else if (blob.type.match(/^video/g)) {
                    vid_flag = true
                    videoData.append(index, blob)
                }
            }
        })
        var _slides = [...idlePage.contents]
        const img_result = img_flag ? await api.uploadMedia('images', imageData) : []
        for (var upload of img_result) {
            var slide = _slides[upload.fieldname]
            if (slide) {
                if (slide.blob && slide.blob.name) slide.file_name = slide.blob.name
                slide.url = upload.url
                slide.type = 'image'
                slide.blob = undefined
            }
        }
        const vid_result = vid_flag ? await api.uploadMedia('videos', videoData) : []
        for (var upload of vid_result) {
            var slide = _slides[upload.fieldname]
            if (slide) {
                if (slide.blob && slide.blob.name) slide.file_name = slide.blob.name
                slide.url = upload.url
                slide.type = 'video'
                slide.blob = undefined
            }
        }
        api.updateThemeDetails({ idle_page: { ...idlePage, contents: _slides } }, setDetail)
    }


    function handleClear() {
        setIdlePage(createIdlePage({}))
    }


    const form = () => <SlideForm slides={slides} setSlides={setSlides} idlePage={idlePage} handleSetValue={handleSetValue} />

    const preview = () => (
        <div className='flexCol fullSize' style={{ position: 'relative' }}>
            <SlideShow idlePage={idlePage} slides={slides} />
            <QrcodePreview qrcode={detail.qr_code} />
        </div >
    )

    return (
        <Fragment>
            <ThemeEditDrawer open={open} setOpen={setOpen} detail={detail} title={t('edit_idle_page')} preview={preview} form={form} handleSave={handleSave} handleClear={handleClear} />
        </Fragment>
    )




}

export default SlideshowDrawer