import { Container, makeStyles, Typography, useTheme } from '@material-ui/core'
import useApi from 'api/useApi'
import RecordTable from 'components/DataTableWithMeta/RecordTable'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useParams, withRouter } from 'react-router-dom'
import { useCampaign } from 'provider/CampaignProvider'
import { useLocale } from 'provider/LocaleProvider'

const moment = require('moment-timezone')
const useStyles = makeStyles(theme => ({
    body: {
        // flex: 1,
        padding: theme.spacing(3),
        // background: '#eaeff1',
        boxSizing: 'border-box',
        // overflowY: 'scroll'
    },
}))

const RecordContext = React.createContext()


const _ = require('lodash')
const generalField = ['campaign_id', 'channel', 'id', 'redeem_token_created_at']


export const useRecords = () => useContext(RecordContext)

const RedeemRecords = (props) => {

    const theme = useTheme()
    const classes = useStyles()

    const { history } = props

    const { campaignId } = useParams()
    const [records, setRecords] = useState()

    const userRequest = useApi()
    const location = useLocation()

    const { campaign } = useCampaign()

    const [total, setTotal] = useState(0)

    const [metaField, setMetaField] = useState([])
    const { t } = useLocale()

    useEffect(() => {
        getRedeemRecords()
    }, [location.pathname])

    useEffect(() => {
        if (campaign && campaign.metadata_key_lists) {
            var fields = campaign.metadata_key_lists.redeem_records
            if (fields) fields = fields.sort()
            setMetaField(fields || [])
        }
    }, [campaign])

    function getRedeemRecords(data) {
        userRequest.getRedeemRecords()
            .then(res => {
                const result = res.data
                if (result && result.result != 'fail') {
                    setRecords(_.get(result, 'redeem_records', []))
                }
                else {
                    setRecords([])
                }
            })
    };


    const state = {
        records,
        total,
        getRedeemRecords

    }
    return (
        <Container>
            <Typography variant='h4' className='bold' paragraph>
                {t('redeem_records')}
            </Typography>
            <RecordTable linkToDetail table_name='records' data={records} handleRefresh={getRedeemRecords} />
        </Container>
    )
}

RedeemRecords.propTypes = {
    // t: PropTypes.func.isRequired,
}

export default withRouter(RedeemRecords)