import React, { Fragment, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { createMuiTheme, makeStyles, ThemeProvider, useTheme, withStyles } from '@material-ui/core/styles'
import CampaignList from './components/CampaignList'
import AddCompaign from './AddCampaign/AddCompaign'
import useApi from 'api/useApi'
import { useAuthData } from 'provider/AuthDataProvider'
import { Box, Typography } from '@material-ui/core'
import { useLocale } from 'provider/LocaleProvider'

const useStyles = makeStyles(theme => ({
    body: {
        flex: 1,
        margin: 'auto',
        padding: theme.spacing(3),
        boxSizing: 'border-box',
    },
}))





const CampaignsContext = React.createContext()

export const useCampaigns = () => useContext(CampaignsContext)

const Campaigns = (props) => {

    const userRequest = useApi()
    const classes = useStyles()
    const { t } = useLocale()

    const [campaignList, setCampaignList] = useState()
    const [open, setOpen] = useState(false)
    const { organization } = useAuthData()

    useEffect(() => {
        getCampaignList()
    }, [organization])



    function getCampaignList() {
        userRequest.getCampaigns(setCampaignList)
    };


    const state = {
        campaignList: campaignList,
        drawer: {
            open: open,
            setOpen: setOpen,
        }
    }

    return (

        <CampaignsContext.Provider value={state} >
            <Box pb={3}>
                <Typography color="inherit" variant="h4" className='bold'>
                    {t('campaigns')}
                </Typography>
            </Box>
            <CampaignList />
            <AddCompaign />
        </CampaignsContext.Provider>
    )
}

Campaigns.propTypes = {
    // t: PropTypes.func.isRequired,
}

export default Campaigns