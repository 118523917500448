import 'chartjs-plugin-annotation'
import { useLocale } from 'provider/LocaleProvider'
import React, { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'





const moment = require('moment-timezone')




const _ = require('lodash')


const DashBoardChart = ({ title, data = {}, data2 = {}, mean = false, format = v => v }) => {
    const avg = _.floor(_.mean(data.y)) || null
    const avg2 = _.floor(_.mean(data2.y)) || null
    const { t } = useLocale
    if (_.size(data.x) === 0 && _.size(data2.x) === 0) return <div style={{ height: '400px' }}></div>

    const Chart = () =>
        <Bar data={{
            labels: _.map(data.x, v => format(v)),
            datasets: [
                {
                    label: 'Redeem Tokens',
                    backgroundColor: 'rgba(255,0,0,0.3)',
                    data: data.y,
                    borderWidth: 1,
                },
                {
                    label: 'Redeem Records',
                    backgroundColor: 'rgba(0,0,255,0.3)',
                    data: data2.y,
                    borderWidth: 1,
                },
            ],
        }}
            width={null}
            height={400}
            options={{
                animation: {
                    duration: 500,
                    easing: 'easeInOutQuad'
                },
                title: {
                    display: false,
                    text: title
                },
                tooltips: {
                    mode: 'index',
                    intersect: true
                },

                responsive: true,
                defaultColor: 'red',
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                    ],
                },
                maintainAspectRatio: false,
                aspectRatio: 1,
                annotation: {
                    events: ['click'],
                    annotations: t('mean') ?
                        [
                            {
                                id: 'a-line-1', // optional
                                type: 'line',
                                mode: 'horizontal',
                                scaleID: 'y-axis-0',
                                value: avg,
                                borderColor: 'rgba(255,0,0,0.8)',
                                borderWidth: 2,
                                borderDash: [5, 5],
                                label: {
                                    xAdjust: 10,
                                    enabled: true,
                                    content: t('mean'),
                                    backgroundColor: 'rgba(255,0,0,0.8)',
                                    position: "left",
                                },
                                onClick: function (e) {
                                    console.log(e)
                                }

                            },
                            {
                                id: 'a-line-2', // optional
                                type: 'line',
                                mode: 'horizontal',
                                scaleID: 'y-axis-0',
                                value: avg2,
                                borderColor: 'rgba(0,0,255,0.8)',
                                borderWidth: 2,
                                borderDash: [5, 5],
                                label: {
                                    xAdjust: 10,
                                    enabled: true,
                                    content: 'Mean',
                                    backgroundColor: 'rgba(0,0,255,0.8)',
                                    position: 'right',
                                }

                            }
                        ]
                        : []
                },
            }}
        />

    return <div>
        <Chart />
    </div>
}
export default DashBoardChart