import React, { Fragment, useCallback, useContext, useLayoutEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Divider, FormControl, FormControlLabel, FormLabel, Grid, GridList, GridListTile, IconButton, isWidthDown, makeStyles, Paper, Radio, RadioGroup, TextField, Typography, useTheme, withWidth } from '@material-ui/core'
import clsx from 'clsx'
import EditIcon from '@material-ui/icons/Edit'

import CheckboxList from '../../CheckboxLIst'
import RadioList from '../../RadioList'
import { DataRow } from '../../DataSession'
import { useLocale } from '../../../provider/LocaleProvider'
const moment = require('moment-timezone')
const useStyles = makeStyles(theme => ({
    root: {
        boxSizing: 'border-box',

    },
    spacing: {
        padding: theme.spacing(1, 0)
    },
    action: {
        // width: '100%',
        marginTop: theme.spacing(2),
        // marginBottom: theme.spacing(2),
        flexWrap: 'wrap',
        marginLeft: '-12px',
        marginRight: '-12px',
    },
    button: {
        minWidth: 'min(100px,100%)',
        marginBottom: theme.spacing(2),
        marginLeft: '12px',
        marginRight: '12px'
    },
    divider: {
        marginBottom: theme.spacing(2)
    }
}))


const _ = require('lodash')
const IncludePeriod = ({ updates, handleSetValue, handleResetValue }) => {
    const classes = useStyles()
    const conditions = updates.redeem_date_conditions || {}


    const { t } = useLocale()
    const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const condition_type_options = [{ label: t('exclude'), value: "exclude" }, { label: t('include'), value: 'include' }]

    const setMonths = (value) => {
        handleSetValue('redeem_date_conditions.months')(value)
    }

    const setWeekdays = (value) => {
        handleSetValue('redeem_date_conditions.weekdays')(value)
    }

    const handleChange = (event) => {
        handleSetValue('redeem_date_conditions.type')(event.target.value)
    }

    const resetMonths = () => handleResetValue('redeem_date_conditions.months')
    const checkAllMonths = () => setMonths(months)
    const clearMonths = () => setMonths([])


    const resetWeekdays = () => handleResetValue('redeem_date_conditions.weekdays')
    const checkAllWeekdays = () => setWeekdays(weekdays)
    const clearWeekdays = () => setWeekdays([])


    const conditionMemo = useMemo(() => (
        <Fragment>
            <DataRow name={t('conditions')} >
                <RadioList row name='condition_type' list={condition_type_options} value={_.get(updates, 'redeem_date_conditions.type')} onChange={handleChange} />
            </DataRow>
            <DataRow name={t('weekdays')}>
                <div>
                    <CheckboxList list={weekdays} checked={_.get(updates, 'redeem_date_conditions.weekdays', [])} setChecked={setWeekdays} />
                    <div className={clsx("flexRow", classes.action)}>
                        <Button className={classes.button} variant='outlined' color='primary' onClick={checkAllWeekdays}> {t('select_all')} </Button>
                        <Button className={classes.button} variant='outlined' color='primary' onClick={clearWeekdays}> {t('clear')} </Button>
                        <Button className={classes.button} variant='contained' color='primary' onClick={resetWeekdays}> {t('reset')} </Button>
                    </div>
                </div>

            </DataRow>
            <DataRow name={t('months')} >
                <div>
                    <CheckboxList list={months} checked={_.get(updates, 'redeem_date_conditions.months', [])} setChecked={setMonths} />
                    <div className={clsx("flexRow", classes.action)}>
                        <Button className={classes.button} variant='outlined' color='primary' onClick={(checkAllMonths)}> {t('select_all')} </Button>
                        <Button className={classes.button} variant='outlined' color='primary' onClick={clearMonths}> {t('clear')} </Button>
                        <Button className={classes.button} variant='contained' color='primary' onClick={resetMonths}> {t('reset')} </Button>
                    </div>
                </div>
            </DataRow>
        </Fragment>
    ), [conditions.weekdays, conditions.months, conditions.type])


    return conditionMemo
}

export default IncludePeriod
