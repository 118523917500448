import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import { NavLink, useParams } from 'react-router-dom'
import DataTable from 'components/DataTable/DataTable'
import useApi from 'api/useApi'
import { useLocale } from 'provider/LocaleProvider'
import MetaSelecter from 'components/DataTableWithMeta/components/MetaSelecter'
import RefreshIcon from '@material-ui/icons/Refresh'
import EditIcon from '@material-ui/icons/Edit'
import CropFreeIcon from '@material-ui/icons/CropFree'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import ClearIcon from '@material-ui/icons/Clear'
import DeviceTable from 'components/DataTableWithMeta/DeviceTable'
import { useAuthData } from 'provider/AuthDataProvider'
import { FormAutocomplete, FormMultiAutocomplete, FormSelector } from 'components/DataForm'
import {
    Box, Button, Divider, FormControl, FormControlLabel, FormLabel,
    Grid, GridList, GridListTile, IconButton, isWidthDown, makeStyles, Paper,
    Radio, RadioGroup, TextField, Typography, useTheme, withWidth, AppBar,
    Tab, Tabs, InputAdornment, withStyles, Switch, MenuItem, Chip, Checkbox
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import { TitleActionContainer } from 'layout/Container'
import { TextDataRow } from 'components/DataSession'
const moment = require('moment-timezone')
var lodash = require('lodash')

const useStyles = makeStyles(theme => ({

}))

const _ = require('lodash')
const naturalCompare = require('string-natural-compare')


const Track = ({ index = 0, identifiers = [], onClick, ...rest }) => {
    const { t } = useLocale()
    // var color = colors[index % colors.length]
    var color = 'rgba(0,0,0,0.2)'
    var radius = '8px'
    return (
        <Paper className='flexCol' style={{ height: '100%', borderRadius: radius }} {...rest} >
            <Typography varient='body1' align='center'
                onClick={onClick}
                style={{ backgroundColor: color, padding: '12px', borderTopLeftRadius: radius, borderTopRightRadius: radius, cursor: 'pointer' }}
            >
                {t('track') + ' ' + (index + 1)}
            </Typography>
            <div style={{ padding: '12px 12px 0 12px', minHeight: '40px', flex: '1' }}>
                {identifiers.map(item => {
                    return <Typography varient='body2' align='center' noWrap>
                        {item.token_identifier}
                    </Typography>
                })}
            </div>
            <Typography color='textSecondary' varient='body2' align='center' noWrap style={{ margin: '12px 0' }}>
                {identifiers.length + ' ' + t(`identifier${identifiers.length > 0 ? 's' : ''}`)}
            </Typography>
        </Paper>
    )
}
const IdentifierContainer = ({ identifier = '', tracks = [], onClick, ...rest }) => {
    const { t } = useLocale()
    // var color = colors[index % colors.length]
    var color = 'rgba(0,0,0,0.2)'
    var radius = '8px'
    const theme = useTheme()
    return (
        <Paper style={{ height: '100%', padding: '12px', display: 'flex', overflow: 'hidden' }} {...rest} >
            <div style={{ minWidth: '50px', cursor: 'pointer' }} onClick={onClick}>
                <Typography noWrap> {t('identifier') + ' '} </Typography>
                <Divider style={{ margin: '4px -8px' }} />
                <Typography noWrap> {t('track') + ' '} </Typography>

            </div>
            <Divider orientation='vertical' flexItem style={{ margin: '0 8px' }} />
            <div style={{ flexGrow: 1, flexBasis: 0 }}>
                <Typography> {identifier} </Typography>
                <Divider style={{ margin: '4px -8px' }} />
                <Typography>
                    {_.reduce(tracks, (a, o, i) => {
                        if (i > 0) {
                            return a + ', ' + (o.track_id + 1)
                        }
                        return a + (o.track_id + 1)
                    }, '')}
                    {' '}
                    <span style={{ display: 'inline-block', color: theme.palette.text.secondary }}>
                        {`( ${tracks.length} ${t(`track${tracks.length > 0 ? 's' : ''}`)} )`}
                    </span>
                </Typography>
            </div>


        </Paper>
    )
}

const compare = (path) => (a, b) => {
    var getValue = (v) => {
        return _.get(v, path, '').toString()
    }
    return naturalCompare(getValue(a), getValue(b))
}



const TabPanel = ({ value, index, children }) => {
    if (value === index) {
        return children || null
    }
    return null

}


const DeviceTrack = props => {
    const { } = props

    const classes = useStyles()

    const { t } = useLocale()



    const api = useApi()
    const [tab, setTab] = useState(0)
    const [device, setDevice] = useState({})
    const [redeems, setRedeems] = useState([])

    const [campaignList, setCampaignList] = useState([])





    const [selectedCampaign, setSelectedCampaign] = useState(null)
    const [selected, setSelected] = useState(0)


    const indentifiers = _.get(selectedCampaign, 'token_identifier_lists', [])
    const [selectedIdentifier, setSelectedIdentifier] = useState(indentifiers[0])

    const trackNumber = useCallback(_.get(device, 'device_spec.total_tracks') || 0, [device])


    const redeemsByTrack = useMemo(() => {
        try {
            return _.groupBy(redeems.sort(compare('token_identifier')), 'track_id')
        }
        catch (e) {
            return []
        }
    }, [redeems])

    const redeemsByIdentifier = useMemo(() => {
        try {
            return _.groupBy(redeems.sort(compare('track_id')), 'token_identifier')
        }
        catch (e) {
            return []
        }
    }, [redeems])


    useEffect(async () => {
        try {
            await api.getCampaigns(setCampaignList)
            await api.getDeviceDetails(setDevice).then(({ data }) => {
                data.campaign = _.find(campaignList, { id: data.campaign.id })
                setSelectedCampaign(data.campaign)
                setRedeems(data.identifiers_in_tracks)
            })
        } catch (error) {

        }
    }, [])


    const handleClear = () => {
        setRedeems([])
    }

    const handleReset = () => {
        setSelectedCampaign(_.get(device, 'campaign'))
        setRedeems(_.get(device, 'identifiers_in_tracks'))
    }

    const handleSave = () => {
        api.updateDeviceDetails({ identifiers_in_tracks: redeems, campaign_id: selectedCampaign.id }, setDevice)
    }

    const handleSetTracks = (index, format = (v) => v) => (value) => {
        var other = _.reject(redeems, ['track_id', index])
        var newValue = format(value) || []
        newValue = newValue.map(v => ({ track_id: index, campaign_id: selectedCampaign.id, ...v }))
        var newRedeems = _.concat(other, newValue)
        setRedeems(newRedeems)
    }

    const handleSetIdentifiers = (identifier, format = (v) => v) => (value) => {
        var other = _.reject(redeems, ['token_identifier', identifier])
        var newValue = format(value) || []
        newValue = newValue.map(v => ({ token_identifier: identifier, campaign_id: selectedCampaign.id, ...v }))
        var newRedeems = _.concat(other, newValue)
        setRedeems(newRedeems)
    }

    const handleSetSelected = (index) => {
        setSelected(index)
        setTab(0)
        // var element = document.getElementById('indentifier_multi_select')
        // element.click()
    }

    const handleSetSelectedId = (value) => {
        setSelectedIdentifier(value)
        setTab(1)
        // var element = document.getElementById('track_multi_select')
        // element.click()

    }




    return (
        <Fragment>
            <TitleActionContainer title={t('device_tracks')}>
                <div style={{ margin: '-4px' }}>
                    <Button variant='outlined' onClick={handleClear} style={{ margin: '4px' }}>
                        {t('clear')}
                    </Button>
                    <Button variant='outlined' onClick={handleReset} style={{ margin: '4px' }}>
                        {t('reset')}
                    </Button>
                    <Button variant='contained' color='primary' onClick={handleSave} style={{ margin: '4px' }}>
                        {t('save')}
                    </Button>
                </div>
            </TitleActionContainer>
            <Paper style={{ marginBottom: '24px' }}>
                <FormAutocomplete options={campaignList} getOptionLabel={op => _.toString(op.name)}
                    label={t('campaigns')}
                    id={'campaigns'}
                    getOptionSelected={(op, value) => op.id === value.id}
                    value={selectedCampaign} setValue={(v) => {
                        setSelectedCampaign(v)
                        handleClear()
                    }}

                />
            </Paper>
            <Grid container spacing={3} style={{ flexWrap: 'wrap-reverse' }}>
                <Grid item xs={12} sm={8}  >
                    {/* <img style={{ aspectRatio: '1', width: '100%', height: 'auto', marginBottom: '12px', backgroundColor: 'white' }} /> */}
                    <TabPanel value={tab} index={0}>
                        <Grid container spacing={1} style={{ margin: '-8px -4px' }} >
                            {_.times(trackNumber, ((index) => {
                                const trackRedeems = redeemsByTrack[index] || []
                                const gridSize = (size) => trackNumber > 4 ? size : 12 / trackNumber
                                return (
                                    <Grid item xs={gridSize(6)} sm={gridSize(6)} md={gridSize(3)} style={{ padding: '8px 4px' }} >
                                        <Track index={index} identifiers={trackRedeems} onClick={() => handleSetSelected(index)} />
                                    </Grid>
                                )
                            }))}
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <Grid container spacing={2}>
                            {Object.keys(redeemsByIdentifier).map(key => {
                                const idRedeems = redeemsByIdentifier[key] || []
                                return <Grid item xs={12} sm={12}>
                                    <IdentifierContainer identifier={key} tracks={idRedeems} onClick={() => handleSetSelectedId(key.token_identifier)} />
                                </Grid>
                            })}
                        </Grid>
                    </TabPanel>

                </Grid>
                <Grid item item xs={12} sm={4}>
                    {_.isNil(selectedCampaign)
                        ? <Paper className='flexCol-center' style={{ position: 'sticky', top: '60px', height: '200px' }}>
                            <Typography variant='body2' align='center'> {'Please select a campaign first'}</Typography>
                        </Paper>
                        : <Paper style={{ position: 'sticky', top: '60px' }}>
                            <Paper elevation={1} style={{ marginBottom: '8px' }}>
                                <Typography variant='body2' style={{ padding: '12px' }}> {'Add and Edit By:'}</Typography>
                                <Divider />
                                <Tabs value={tab} onChange={(e, v) => setTab(v)} >
                                    <Tab label={t('track')} style={{ minWidth: '0', width: '50%' }} />
                                    <Tab label={t('identifier')} style={{ minWidth: '0', width: '50%' }} />
                                </Tabs>
                            </Paper>
                            <div style={{ padding: '12px' }}>
                                <TabPanel value={tab} index={0}>
                                    <FormSelector options={_.times(trackNumber, null)} value={selected}
                                        disabled={_.isNil(selectedCampaign)}
                                        label={t('track')}
                                        setValue={handleSetSelected}
                                        getOptionLabel={(op, index) => t('track') + ' ' + (index + 1)}
                                        getOptionValue={(op, index) => index}
                                        style={{ marginBottom: '16px' }}
                                    />
                                    <FormMultiAutocomplete options={_.map(indentifiers, id => ({ token_identifier: id }))} getOptionLabel={op => _.toString(op.token_identifier)}
                                        disabled={_.isNil(selectedCampaign)}
                                        label={t('identifier')}
                                        id={'indentifier_multi_select'}
                                        getOptionSelected={(op, value) => op.token_identifier === value.token_identifier}
                                        value={redeemsByTrack[selected]} setValue={handleSetTracks(selected)}
                                        renderTags={(value) => null}
                                        placeholder={(redeemsByTrack[selected] ? redeemsByTrack[selected].length : 0) + ' ' + t('selected')}
                                        style={{ marginBottom: '16px' }}
                                    />
                                </TabPanel>


                                <TabPanel value={tab} index={1}>
                                    <FormAutocomplete
                                        disabled={_.isNil(selectedCampaign)}
                                        options={indentifiers}
                                        disableClearable
                                        label={t('identifier')}
                                        value={selectedIdentifier}
                                        setValue={handleSetSelectedId}
                                        getOptionLabel={(op, index) => op}
                                        style={{ marginBottom: '16px' }}

                                    />
                                    <FormMultiAutocomplete options={_.times(trackNumber, (number) => ({ track_id: number }))} getOptionLabel={op => t('track') + ' ' + (op.track_id + 1)}
                                        disabled={_.isNil(selectedIdentifier)}
                                        label={t('track')}
                                        id='track_multi_select'
                                        getOptionSelected={(op, value) => op.track_id === value.track_id}
                                        value={redeemsByIdentifier[selectedIdentifier]} setValue={handleSetIdentifiers(selectedIdentifier)}
                                        renderTags={(value) => null}
                                        placeholder={(redeemsByIdentifier[selectedIdentifier] ? redeemsByIdentifier[selectedIdentifier].length : 0) + ' ' + t('selected')}
                                        style={{ marginBottom: '16px' }}
                                    />

                                </TabPanel>
                                <img src={_.get(device, 'device_spec.device_guide_image')} style={{ aspectRatio: '2', width: '100%', height: 'auto', backgroundColor: 'rgba(0,0,0,0.05)' }} />
                            </div>
                        </Paper>
                    }
                </Grid>
            </Grid>
        </Fragment >
    )
}

export default DeviceTrack