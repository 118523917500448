
var _ = require('lodash')

const Utils = {}

Utils.diff = (origin, update) => {
    console.log('update', update)
    console.log('origin', origin)
    function changes(object, base) {
        return _.transform(object, function (result, value, key) {
            if (!_.isEqual(value, base[key])) {
                result[key] = (_.isObject(value) && _.isObject(base[key]) && !_.isArray(value)) ? changes(value, base[key]) : value
            }
        })
    }
    return changes(update, origin)
}




Utils.obj2csv = (data, fields, name, download = false) => {
    const json2csv = require('json2csv').parse
    const csv = json2csv(data, { fields: fields })
    if (download || (name && data.length > 0)) {
        Utils.downloadCSv(name, csv)
    }
    return csv
}

Utils.downloadCSv = (name, data) => {
    var hiddenElement = document.createElement('a')
    hiddenElement.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(data)
    hiddenElement.target = '_blank'
    hiddenElement.download = `${name}.csv`
    hiddenElement.click()
}

module.exports = Utils