const { useEffect, useState, useRef, useLayoutEffect, useCallback } = require('react')

const useRect = (flag = 1) => {

    const ref = useRef(null)
    const [rect, setReact] = useState({})

    const setRef = useCallback(node => {
        if (node) {
            const _rect = node.getBoundingClientRect()
            setReact(_rect)
            window.addEventListener('resize', handleResize)
        }
        ref.current = node
    }, [])

    function handleResize() {
        if (ref.current) {
            const _rect = ref.current.getBoundingClientRect()
            setReact(_rect)
        }
    }

    function vw(w) {
        return w * rect.width / 100 + 'px'
    }

    function vh(h) {
        return h * rect.height / 100 + 'px'
    }


    return [setRef, rect, vw, vh]
}

export default useRect


