import { Box, IconButton, makeStyles, Tooltip, useTheme } from '@material-ui/core'
import React, { useContext, useEffect, useState, useRef, useLayoutEffect, forwardRef, useMemo } from 'react'
import { NavLink, useParams, withRouter } from 'react-router-dom'

import clsx from 'clsx'
import DataTableWithMeta from './DataTableWithMeta'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { useCampaign } from '../../provider/CampaignProvider'
import { useLocale } from '../../provider/LocaleProvider'
import useApi from 'api/useApi'
import DataTable from 'components/DataTable/DataTable'
import { Fragment } from 'react'
const moment = require('moment-timezone')
const useStyles = makeStyles(theme => ({
    body: {
        // flex: 1,
        padding: theme.spacing(3),
        // background: '#eaeff1',
        boxSizing: 'border-box',
        // overflowY: 'scroll'
    },
}))



const _ = require('lodash')

const RecordTable = ({ table_name = 'records', data = [], linkToDetail = false, handleRefresh, ...rest }) => {


    const { t } = useLocale()
    const { campaignId } = useParams()
    const { campaign } = useCampaign()
    const userRequest = useApi()




    const CustomRouterLink = forwardRef((props, ref) => {
        return (
            <div
                ref={ref}
                style={{ display: 'inline', flexGrow: 1 }}
            >
                <NavLink {...props} />
            </div>
        )
    })


    const generalFields = [
        // {

        //     id: 'campaign_id',
        //     label: t('campaign_id'),
        //     value: row => row.campaign_id,
        //     width: 200,
        //     fixed: true,
        //     align: 'left',
        // },
    ]



    return <DataTableWithMeta
        rows={data}
        heads={[
            {
                id: 'redeem_token', label: t('redeem_token'), value: 'redeem_token', content: (row) => <Fragment> {row.redeem_token} <NavLink to={`/campaigns/${campaignId}/redeem_tokens/${row.redeem_token}`} >

                    <Tooltip title={t('see_token_detail')}>
                        <IconButton style={{ marginLeft: '8px' }}>
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                </NavLink> </Fragment>, sort: true, width: '300px'
            },
            { id: 'identifier', label: t('identifier'), value: 'identifier', sort: true },
            { id: 'created_at', label: t('created_at'), value: 'created_at', content: (row) => row.created_at ? moment(row.created_at).format('DD/MM/YYYY, hh:mm:ss') : null, sort: true },
        ]}
        optionals={generalFields}
        handleRefresh={handleRefresh}
        {...rest}
    />

}

RecordTable.propTypes = {
    // t: PropTypes.func.isRequired,
}

export default RecordTable