import React, { useEffect } from 'react'
import { Switch, Redirect, Route, BrowserRouter, useParams } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import Campaigns from 'views/CampaignViews/Campaigns/Campaigns'
import CampaignDetails from 'views/CampaignViews/CampaignDetails/CampaignDetails'
import TokenRule from 'views/CampaignViews/RedeemTokenRule/TokenRule'
import RedeemRecords from 'views/CampaignViews/RedeemRecords/RedeemRecords'
import RedeemTokens from 'views/CampaignViews/RedeemTokens/RedeemTokens'
import MainLayout, { DeviceLayout, HomeLayout } from './layout/MainLayout'
import CampaignLayout from './layout/campaign/CampaignLayout'
import Header, { CampaignHeader, DashHeader, HomeHeader, LoginHeader, DeviceHeader } from './layout/Header'
import CampaignProvider from './provider/CampaignProvider'
import TokenDetails from 'views/CampaignViews/TokenDetails/TokenDetails'
import TokenSearch from 'views/CampaignViews/TokenSearch/TokenSearch'
import GenerateToken from 'views/CampaignViews/GenerateRedeemToken/GenerateToken'
import { useLocale } from './provider/LocaleProvider'
import HomePage from 'views/MiscView/HomePage/HomePage'
import DeviceTrack from 'views/DeviceViews/DeviceTrack/DeviceTrack'
import DeviceList from 'views/DeviceViews/DeviceList/DeviceList'
import DeviceDetails from 'views/DeviceViews/DeviceDetails/DeviceDetails'
import DevicePanel from 'views/DeviceViews/DevicePanel/DevicePanel'
import ThemeList from 'views/ThemeView/ThemeList/ThemeList'
import ThemeDetails from 'views/ThemeView/ThemeDetails/ThemeDetails'
import SignIn from 'views/MiscView/SignIn/SignIn'
import AuthDataProvider from 'provider/AuthDataProvider'
import useAuthApi, { Login } from 'api/useAuthApi'
import CampaignDashboard from 'views/CampaignViews/CampaignDashboard/CampaignDashboard'
import DeviceOperation from 'views/OperationViews/DeviceOperation/DeviceOperation'
import ProductList from 'views/CampaignViews/ProductList/ProductList'
import ProductDeviceConfigure from 'views/CampaignViews/ProductDeviceConfigure/ProductDeviceConfigure'
import DeviceProvider from 'provider/DeviceProvider'

var flatten = require('flat')
const _ = require('lodash')

const RouteWithLayout = (props) => {
    const { type, title, header, layout: Layout, component: Component, ...rest } = props
    var render
    switch (type) {
        case 'auth':
            render = (props) => (
                <AuthDataProvider>
                    <Layout title={title} header={header}>
                        <Component {...props} />
                    </Layout>
                </AuthDataProvider>
            )
            break
        case 'campaign':
            render = (props) => (
                <AuthDataProvider>
                    <CampaignProvider>
                        <Layout title={title} header={header}>
                            <Component {...props} />
                        </Layout>
                    </CampaignProvider>
                </AuthDataProvider>
            )
            break
        default:
            render = (props) => (
                <Layout title={title} header={header}>
                    <Component {...props} />
                </Layout>
            )
    }
    return <Route {...rest} render={render} />
}


const RouteWithProps = (props) => {
    const { component: Component, ...rest } = props
    return <Route {...rest} render={props => <Component {...props} />} />
}


const Routes = () => {
    const { t, getLanguage } = useLocale()
    const browserHistory = createBrowserHistory()


    return (
        <BrowserRouter history={browserHistory} >
            <Switch>
                <Route exact path='/server-error' component={props => 'Server Error'} />
                <Route exact path='/login' component={SignIn} />
                <Route exact path='/login/:ssoID' component={Login} />
                <Route exact path='/*'>
                    <AuthDataProvider>
                        <Switch>
                            <Route exact path='/home'>
                                <HomeLayout header={HomeHeader}>
                                    <HomePage />
                                </HomeLayout>
                            </Route>
                            <Route exact path={['/campaigns', '/devices', '/themes', '/themes/*']}>
                                <HomeLayout header={DashHeader}>
                                    <Switch>
                                        <Route exact path='/home' component={HomePage} />
                                        <Route exact path='/campaigns' component={Campaigns} />
                                        <Route exact path='/devices' component={DeviceList} />
                                        <Route exact path='/themes' component={ThemeList} />
                                        <Route exact path='/themes/:themeId' component={ThemeDetails} />
                                        <Redirect to='/home' />
                                    </Switch>
                                </HomeLayout>
                            </Route>
                            <Route exact path='/devices/:deviceId/*'>
                                <DeviceProvider>
                                    <DeviceLayout header={DeviceHeader} >
                                        <Switch>
                                            <Route exact path='/devices/:deviceId/info' component={DeviceDetails} />
                                            <Route exact path='/devices/:deviceId/panel' component={DevicePanel} />
                                            {/* <Route exact path='/devices/:deviceId/api_key' component={DeviceDetails} /> */}
                                            {/* <Route exact path='/devices/:deviceId/track' component={DeviceTrack} /> */}
                                            <Redirect to="/home" />
                                        </Switch>
                                    </DeviceLayout>
                                </DeviceProvider>

                            </Route>
                            <Route exact path={'/campaigns/:campaignId/*'}>
                                <CampaignProvider>
                                    <CampaignLayout>
                                        <Switch >
                                            <Route exact path='/campaigns/:campaignId/generate_tokens' component={GenerateToken} />
                                            <Route exact path='/campaigns/:campaignId/product_list' component={ProductList} />
                                            <Route exact path='/campaigns/:campaignId/product_device_configure' component={ProductDeviceConfigure} />
                                            <Route exact path='/campaigns/:campaignId/dashboard' component={CampaignDashboard} />
                                            <Route exact path='/campaigns/:campaignId/info' component={CampaignDetails} />
                                            <Route exact path='/campaigns/:campaignId/info/redeem_token_rule' component={TokenRule} />
                                            <Route exact path='/campaigns/:campaignId/redeem_tokens/edit_tokens' component={TokenRule} />
                                            <Route exact path='/campaigns/:campaignId/redeem_tokens' component={RedeemTokens} />
                                            <Route exact path='/campaigns/:campaignId/redeem_tokens/:tid' component={TokenDetails} />
                                            <Route exact path='/campaigns/:campaignId/redeem_records' component={RedeemRecords} />
                                            <Route exact path='/campaigns/:campaignId/token_search' component={TokenSearch} />
                                            <Redirect to="/home" />
                                        </Switch>
                                    </CampaignLayout>
                                </CampaignProvider>
                            </Route>
                            <Redirect to="/home" />
                        </Switch>
                    </AuthDataProvider>
                </Route>
                <Redirect to="/login" />
            </Switch>
        </BrowserRouter>
    )

}




export default Routes
