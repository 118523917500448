import { Box, Button, FormControl, FormControlLabel, makeStyles, Radio, RadioGroup, TextField, Toolbar, useTheme } from '@material-ui/core'
import React, { } from 'react'
import { UploadButton } from '../../../../components/FileUploader'
import DataTable from '../../../../components/DataTable/DataTable'
import { Alert, Autocomplete } from '@material-ui/lab'
import { useLocale } from '../../../../provider/LocaleProvider'
import { Form, useFormikContext } from 'formik'
import _ from 'lodash'
import { DataRow, DataSession } from 'components/DataSession'
import ImagePopover from 'components/ImagePopover'
const csv = require('csvtojson')

const moment = require('moment-timezone')

const useStyles = makeStyles(theme => ({

}))

const error_message = "Must be a number between 0-999"


const ChooseProduct = ({ productList }) => {



    const { values, touched, errors, validateForm, handleChange, setFieldValue, setFieldTouched, isValid } = useFormikContext()

    const classes = useStyles()

    const theme = useTheme()

    const { t } = useLocale()

    const handleNext = async (e) => {
        e.preventDefault()
        setFieldTouched('product_id', true, true)
        var invalid = await validateForm()
        document.getElementById("app").scroll(0, 0)
        setFieldValue('step', values.step + 1)
    }

    const handleBack = () => {
        setFieldValue('step', values.step - 1)
    }

    const handleReset = () => {
        setFieldValue('step', 0)
        setFieldValue('mode', null)
    }

    return (
        <Form onSubmit={handleNext} style={{ height: '100%', display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
            <DataSession title={t('select_product')} >
                <DataRow name={t('redeem_specify_product')}>
                    <Autocomplete
                        autoHighlight
                        label="Redeem Product"
                        variant='outlined'
                        fullWidth
                        value={_.find(productList, { id: values.product_id }) || {}}
                        onChange={(e, v) => setFieldValue('product_id', v?.id)}
                        options={_.toArray(productList)}
                        disabled={_.size(productList) === 0}
                        getOptionLabel={op => op.name}
                        renderInput={(params) => <TextField {...params} variant="outlined" InputLabelProps={{ shrink: true }} />}
                        renderOption={(op) => {
                            console.log('ChooseProduct => op', op)
                            return <Box display='flex' width={'100%'} >
                                {op.name}
                                {op.image_url &&
                                    <Box height='20px' width='20px' ml={2} >
                                        <img src={op.image_url} />
                                    </Box>
                                }
                            </Box>
                        }}
                    />
                </DataRow>
            </DataSession>
            <Box flexGrow={1} />
            <Box display='flex' style={{ marginTop: '24px' }}>
                <Button onClick={handleReset} variant="contained" className={classes.button} style={{ color: 'white', backgroundColor: theme.palette.error.main }}>
                    {t('cancel')}
                </Button>
                <Box flexGrow={1} />
                <Button variant="outlined" onClick={handleBack} className={classes.button} >
                    {t('back')}
                </Button>
                <Button
                    type='submit'
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: '8px' }}
                >
                    {t('next')}
                </Button>
            </Box>
        </Form>
    )
}

export default ChooseProduct