import { FormColorTextField, FormSwitch, FormTextField } from 'components/DataForm'

import { Drawer, Box, Grid, useTheme, Typography, Toolbar, IconButton, withWidth, Card, CardContent, Button, Divider } from '@material-ui/core'
import { DataSession } from 'components/DataSession'
import { useLocale } from 'provider/LocaleProvider'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import ThemeEditDrawer from '../ThemeEditDrawer'
import useRect from 'hooks/useRect'
import useApi from 'api/useApi'
import Sortable from 'sortablejs'
import { ReactSortable } from 'react-sortablejs'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import SwapVertIcon from '@material-ui/icons/SwapVert'
import '../theme.css'
import { UploadButton } from 'components/FileUploader'
import { Swiper, SwiperSlide, Autoplay } from 'swiper/react'
// Import Swiper styles
import 'swiper/swiper.scss'
import SlideForm from '../slideshow/SlideshowForm'
import SlideShow from '../slideshow/SlideshowPreview'
import MessageForm from './MessageForm'
import MessagePreview from './MessagePreview'




function createMessage(data = {}) {
    const {
        display_text = '',
        image_url = null,
        text_color = '#ffffff',
        background_color = '#000000',
        is_active = true,
        scale_type = 'contain',
    } = data
    return (
        {
            display_text: display_text,
            image_url: image_url,
            background_color: background_color,
            text_color: text_color,
            is_active: is_active,
            scale_type,
        }
    )
}


const MessageDrawer = ({ message_type = '', open = true, setOpen, detail, setDetail }) => {

    const { t } = useLocale()
    const api = useApi()
    const messages = detail.messages
    const [message, setMessage] = useState({})

    useEffect(() => {
        const _message = messages[message_type]
        setMessage(createMessage(_message))
    }, [open, message_type])



    async function handleSave() {
        var _message = { ...message }
        if (_message.blob && _message.blob.name) {
            const imageData = new FormData()
            imageData.append(message_type, _message.blob)
            const img_result = await api.uploadMedia('images', imageData)
            if (img_result.length > 0) {
                if (_message.blob.name) {
                    _message.image_name = _message.blob.name
                    _message.image_size = _message.blob.size
                }
                _message.image_url = img_result['0'].url
                _message.blob = undefined
            }
        }
        api.updateThemeDetails({ messages: { [message_type]: _message } }, setDetail)
    }


    function handleClear() {
        const _message = messages[message_type]
        setMessage(createMessage(_message))
    }


    // const form = <SlideForm slides={slides} setSlides={setSlides} idlePage={idlePage} handleSetValue={handleSetValue} />

    const [ref, rect, vw, vh] = useRect(open)
    const preview = () => (
        <div ref={ref} className='flexCol fullSize' style={{ position: 'relative' }}>
            <MessagePreview message={message} vw={vw} vh={vh} />
        </div >
    )



    const form = () => <MessageForm message_type={message_type} message={message} setMessage={setMessage} />

    return (
        <Fragment>
            <ThemeEditDrawer open={open} setOpen={setOpen} detail={detail} title={t('edit_messages')} preview={preview} form={form} handleSave={handleSave} handleReset={handleClear} />
        </Fragment>
    )




}

export default MessageDrawer