import React, { useState, useEffect, useContext } from 'react'
import { Alert } from '@material-ui/lab'
import { CircularProgress, Grow, makeStyles, Snackbar, useTheme } from '@material-ui/core'
import { useHistory, useLocation, useParams, withRouter } from 'react-router-dom'
import useApi from 'api/useApi'
import { useAuthData } from './AuthDataProvider'
import { DataBackdrop } from './BackdropProvider'


const { createContext } = require('react')

const DeviceContext = createContext()

export function useDevice() {
    return useContext(DeviceContext) || {}
}

const DeviceProvider = (props) => {

    const { children } = props
    const location = useLocation()
    const api = useApi()
    const [device, setDevice] = useState()


    useEffect(() => {
        api.getDeviceDetails(setDevice)
    }, [location.pathname])

    const state = {
        device: device,
        setDevice
    }

    return (
        <DeviceContext.Provider value={state} >
            <DataBackdrop open={device === undefined || device === null} />
            {children}
        </DeviceContext.Provider>
    )
}

export default DeviceProvider