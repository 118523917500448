import React, { useEffect, useMemo, useState } from 'react'

import 'chartjs-plugin-annotation'

import {
    IconButton, Paper, Tooltip,
    Typography, Table, TableHead, TableCell, TableRow, makeStyles, Grid
} from '@material-ui/core'
import DashBoardSingleChart, { DashBoardRecordChart } from './DashBoardSingleChart'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useLocale } from 'provider/LocaleProvider'
import Utils from 'misc/Utils'
import { FormSelector } from 'components/DataForm'
import { t } from 'react-switch-lang'

const _ = require('lodash')
const moment = require('moment-timezone')
const useStyles = makeStyles(theme => ({
    table: {
        overflow: "auto",
        "& .MuiTableCell-root": {
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            padding: '6px 12px',
            borderLeft: '1px solid',
            borderColor: theme.palette.divider,
            '& a': {
                display: 'inline'
            },
            '& .MuiButtonBase-root': {
                padding: 0
            },
            '& .MuiSvgIcon-root': {
                height: '24px'
            }
        },
        "& .MuiTableRow-root": {
            height: '40px'
        },
        "& .MuiTableHead-root": {
            backgroundColor: theme.palette.table.main,
        },
    }
}))

const timeFormat = {
    'year': 'YYYY',
    'month': 'YYYY-MM',
    'day': 'YYYY-MM-DD',
}

const getBetweenDate = function (groupBy, start, end) {
    var arr = []
    var startOf = groupBy === 'hour' ? 'day' : groupBy
    var currentDate = moment(start).startOf(startOf)
    var endDate = moment(end).endOf(startOf)
    while (currentDate.isSameOrBefore(endDate, groupBy)) {
        arr.push(currentDate.format(timeFormat[groupBy]))
        currentDate = moment(currentDate).add(1, groupBy)
    }
    return arr
}

const PeriodChart = ({ data, titleId = 'title', start, end, dataSetLabel = t('label'), color = '#FF0000' }) => {
    const classes = useStyles()
    const [array, setArray] = useState([])
    const [groupBy, setGroupBy] = useState('day')

    const { t } = useLocale()

    useEffect(() => {
        var grouped = _.groupBy(data, v => moment(v).format(timeFormat[groupBy]))
        grouped = _.mapValues(grouped, o => o.length)
        var array = getBetweenDate(groupBy, start, end)
        array = _.map(array, k => ({
            key: k,
            value: grouped[k] || 0
        }))
        setArray(array)
    }, [data, start, end, groupBy])

    function exportCSV() {
        Utils.obj2csv(array, [{ label: groupBy, value: (i) => i.key }, 'value'], 'record_hourly_report', true)
    }

    var row = _.map(array, (v) => v.value)
    var column = _.map(array, (v) => v.key)

    return (
        <Paper>
            <div style={{ padding: '24px' }}>
                <Grid container spacing={1} style={{ marginBottom: '8px' }}>
                    <Grid item xs={12} md={8} lg={8} >
                        <Typography variant='body1' color='textPrimary' className='bold' style={{ display: 'inline-flex' }}>
                            {t(titleId, { a: t(groupBy) })}
                        </Typography>
                        <Tooltip title={t('export_data')}>
                            <IconButton onClick={() => exportCSV()}>
                                <GetAppIcon xs={1} />
                            </IconButton>
                        </Tooltip>

                    </Grid>
                    <Grid item xs={12} md={4} lg={4} >
                        <FormSelector label={t('show_by')} value={groupBy} setValue={setGroupBy}
                            options={[
                                { label: t('years'), value: 'year' },
                                { label: t('months'), value: 'month' },
                                { label: t('days'), value: 'day' },
                            ]}

                            style={{ backgroundColor: 'white', flexGrow: 0, flexBasic: 0 }}
                        />
                    </Grid>
                </Grid>
                <DashBoardSingleChart row={row} column={column} dataSetLabel={dataSetLabel} color={color} mean />
            </div>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell padding='checkbox'>{t('rank')}</TableCell>
                        <TableCell>{t('day')}</TableCell>
                        <TableCell>{t('count')}</TableCell>
                    </TableRow>
                </TableHead>
                {_.map(_.take(_.orderBy(array, 'value', 'desc'), 5), ({ key, value }, index) => {
                    return (
                        <TableRow>
                            <TableCell padding='checkbox'>{index + 1}</TableCell>
                            <TableCell>{key}</TableCell>
                            <TableCell>{value}</TableCell>
                        </TableRow>
                    )
                })}
            </Table>
        </Paper>
    )
}

export default PeriodChart