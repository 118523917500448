import { makeStyles } from "@material-ui/core"


const useStyles = makeStyles({
  '@global': {
    '.flexRow': {
      display: 'flex',
      flexDirection: 'row',
      boxSizing: 'border-box'
    },
    '.flexCol': {
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box'
    },
    '.flexRow-center': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box'
    },
    '.flexCol-center': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box'
    },
    '.fullWidth': {
      width: '100% !important',
      boxSizing: 'border-box !important'
    },
    '.fullHeight': {
      height: '100% !important',
      boxSizing: 'border-box !important'
    },
    '.fullSize': {
      width: '100% !important',
      height: '100% !important',
      boxSizing: 'border-box !important'
    },
    '.bold': {
      fontWeight: 'bold !important'
    },
    '.drawer_title': {
      fontSize: '15px !important',
      fontWeight: 'bold !important'
    },
    '.drawer_body': {
      fontSize: '10px !important',
    },
    '.spacer': {
      flexGrow: 1,
    }
  }
})


const GlobalStyles = () => {
  return useStyles()
}

export default GlobalStyles