import { FormColorTextField, FormTextField } from 'components/DataForm'
import React, { Fragment, useMemo, useCallback, useEffect, useState, useRef, useLayoutEffect } from 'react'
import { Drawer, Box, Grid, useTheme, Typography, Toolbar, IconButton, Button } from '@material-ui/core'
import { DataSession } from 'components/DataSession'
import { useLocale } from 'provider/LocaleProvider'
import './theme.css'
import CloseIcon from '@material-ui/icons/Close'

import ScreenRotationIcon from '@material-ui/icons/ScreenRotation'
import useZoomResizer from 'hooks/useZoomResizer'
import useRect from 'hooks/useRect'
import ThemePreviewLayout from './preview/ThemePreviewLayout'
const _ = require('lodash')
const ThemeEditDrawer = ({ open = true, setOpen, detail, title, preview, form, handleSave = new Function(), handleClear, handleReset }) => {

    const theme = useTheme()
    const { t } = useLocale()
    const [ratio, setRatio] = useState(9 / 16)
    const [landscape, setLandscape] = useState(true)

    const [flag, setFlag] = useState(false)
    const [flag2, setFlag2] = useState(true)
    const [ref, rect] = useRect()


    const contentRef = useCallback(node => {
        contentRef.current = node
    }, [])

    useEffect(() => {
        handleResize()
    }, [ratio, landscape, rect, contentRef.current])

    function resize(node) {
        var x = landscape ? 1600 * ratio : 1600 / ratio
        var y = 1600
        var xr = rect.width / x
        var xy = rect.height / y
        var min = _.min([xr, xy])
        node.style.width = x + 'px'
        node.style.height = y + 'px'
        node.style.zoom = min
    }

    function handleResize() {
        if (rect && contentRef.current) {
            resize(contentRef.current)
            setFlag2(!flag2)
        }
    }


    const handleClick = () => {
        if (flag === false) {
            handleSave()
            setFlag(true)
            setTimeout(() => setFlag(false), 1000)
        }
    }

    function handleClose() {
        setOpen(false)
    }

    const handleKeyPress = (e) => {
        if (e.keyCode == 13 || e.charCode == 13) {
            handleSave()
        }
    }


    const handleChange = (r) => () => {
        if (ratio !== 1 / r && ratio !== r) {
            setRatio(null)
            setTimeout(() => setRatio(r), 10)
        }
    }

    const handleRotate = () => {
        var temp = !landscape
        setLandscape(null)
        setTimeout(() => setLandscape(temp), 10)
    }




    if (!open) return null


    return (
        <Drawer onKeyPress={handleKeyPress} anchor={'right'} open={open} PaperProps={{ className: 'fullSize', style: { backgroundColor: 'transparent' } }} >

            <Grid container spacing={0} style={{ height: '100%', }}>
                <Grid item xs={12} sm={6} className='flexCol-center' >
                    <Toolbar>
                        <button style={{ width: '100px', height: '30px' }} onClick={handleChange(9 / 16)}>16:9</button>
                        <button style={{ width: '100px', height: '30px' }} onClick={handleChange(10 / 16)}>16:10</button>
                        <button style={{ width: '100px', height: '30px' }} onClick={handleRotate}><ScreenRotationIcon style={{ width: '16px' }} /></button>
                    </Toolbar>
                    <div style={{ flexGrow: 1 }} />
                    {ratio !== null && landscape !== null &&
                        <Box ref={ref} className='preview-box' >
                            <div ref={contentRef} style={{ backgroundColor: _.get(detail, 'background_color'), margin: 'auto' }}>
                                <ThemePreviewLayout theme={detail}>
                                    {preview && preview()}
                                </ThemePreviewLayout>
                            </div>
                        </Box>

                    }
                    <div style={{ flexGrow: 2 }} />

                </Grid>
                <Grid className='flexCol' item xs={12} sm={6} style={{ padding: '12px', backgroundColor: theme.palette.background.default, maxHeight: '100%', overflow: 'auto' }} >
                    <Toolbar variant='dense' style={{ backgroundColor: theme.palette.background.default }}>
                        <Typography variant='h5'>
                            {title}
                        </Typography>
                        <div style={{ flexGrow: 1 }} />
                        <IconButton onClick={handleClose} style={{ padding: 0 }} >
                            <CloseIcon style={{ width: '32px', height: '32px' }} />
                        </IconButton>
                    </Toolbar >
                    {form && form()}
                    <div style={{ flexGrow: 1 }} />
                    <Toolbar variant='dense' >
                        <div style={{ flexGrow: 1 }} />
                        {handleClear &&
                            <Button variant='outlined' color='primary' onClick={handleClear} style={{ marginRight: '8px' }} >
                                {t('clear')}
                            </Button>
                        }
                        {handleReset &&
                            <Button variant='outlined' color='primary' onClick={handleReset} style={{ marginRight: '8px' }} >
                                {t('reset')}
                            </Button>
                        }
                        <Button variant='contained' color='primary' onClick={handleClick} disabled={flag}>
                            {t('save')}
                        </Button>
                    </Toolbar >

                </Grid>
            </Grid>
        </Drawer >
    )



}

export default ThemeEditDrawer