import { Box, Button, Drawer, IconButton, isWidthDown, makeStyles, TextField, Typography, useTheme, withWidth } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { useCampaigns } from '../Campaigns'
import CloseIcon from '@material-ui/icons/Close'
import { useHistory, withRouter } from 'react-router-dom'
import HelpPopHover from '../../../../components/HelpPopHover'
import StatusRadio from './components/StatusRadio'
import useApi from 'api/useApi'
import { useLocale } from '../../../../provider/LocaleProvider'

const useStyles = makeStyles((theme) => ({
    fullList: {
        width: '50vw',
        height: '100vh',
    },
    closeIcon: {
        position: 'absolute',
        right: '0'
    },
    textField: {
        width: 'min(500px, 100%)',
        // paddingBottom: theme.spacing(1),
        height: '68px',
        "& > * > *": {
            padding: '12px 14px',
        },
    },
    spacing: {
        height: theme.spacing(1)
    },
    field: {
        marginBottom: theme.spacing(2)
    }
}))

// const StyledTextField = withStyles({
//     root: {
//         '&& .MuiInputBase-input': {
//             color: 'green',
//             backgroundColor: 'red'
//         },
//     },
// })(TextField)


const DataTextField = (props) => {
    const { className, onChange, error, helperText, name, ...rest } = props
    const { drawer } = useCampaigns()
    const inputRef = useRef()
    useEffect(() => {
        if (inputRef.current)
            inputRef.current.value = null
    }, [drawer.open])
    return (
        <TextField id={name}
            className={className}
            name={name}
            error={error}
            inputRef={inputRef}
            defaultValue={null}
            helperText={error ? helperText : ''}
            onChange={onChange}
            type='text'
            required
            autoComplete='off'
            InputLabelProps={{
                shrink: true,
            }}
            variant='outlined'
            {...rest}
        />
    )
}

const AddCompaign = (props) => {
    const { width } = props
    const history = useHistory()
    const theme = useTheme()
    const classes = useStyles()
    const { drawer } = useCampaigns()
    const [newCampaign, setNewCampaign] = useState({ is_active: true })
    const userRequest = useApi()
    const [idField, setIdField] = useState({
        error: false,
        helperText: ''
    })

    const { t } = useLocale()

    const clearData = () => {
        drawer.setOpen(false)
        setNewCampaign({})
        setIdField({ error: false, helperText: '' })
    }

    const handleChange = (event) => {
        var temp = { ...newCampaign }
        if (event.target.name == 'name') {
            temp.name = event.target.value
        }
        else if (event.target.name == 'identifier') {
            if (event.target.value.length > 20) {
                setIdField({ error: true, helperText: 'Too long' })
                temp.identifier = null
            }
            else {
                temp.identifier = event.target.value
                if (event.target.value == "") {
                    setIdField({ error: true, helperText: 'Required' })
                }
                else {
                    setIdField({ error: false, helperText: '' })
                }
            }
        }
        setNewCampaign(temp)
    }

    const cantSubmit = newCampaign.name == null || newCampaign.identifier == null || idField.error || newCampaign.name == ""

    function handleSubmit() {
        if (cantSubmit) return
        userRequest.addCampaign(newCampaign)
            .then(res => {
                const result = res.data
                console.log('handleSubmit -> result', result)
                if (result && result.result != 'fail') {
                    history.push(`/campaigns/${result.id}/info`)
                }
            })
    }

    return (
        <React.Fragment key={'right'} >
            <Drawer anchor={'right'} open={drawer.open} onClose={clearData} >
                <Box className='fullSize' style={{ backgroundColor: 'white' }} >
                    <div className={classes.fullList} style={{ width: isWidthDown('sm', width) ? '100vw' : '50vw' }} >
                        <IconButton aria-label='delete' className={classes.closeIcon} onClick={() => drawer.setOpen(false)} >
                            <CloseIcon fontSize='large' style={{ width: '50px', height: '50px' }} />
                        </IconButton>
                        <div className='flexCol' style={{ padding: 'max(5vw, 70px) max(5vw, 20px)' }} >
                            <div>
                                <Typography variant='h6' noWrap paragraph className='bold' >
                                    {t('enter_campaign_name')}
                                </Typography>
                                <DataTextField
                                    className={classes.textField}
                                    name='name'
                                    placeholder={t('enter_campaign_name_placeholder')}
                                    onChange={handleChange}
                                    error={newCampaign.name == ""}
                                    helperText='Required'
                                />
                            </div>
                            <div >
                                <Typography variant='h6' noWrap paragraph className={clsx('bold', 'flexRow')} >
                                    {t('enter_campaign_identifier')}
                                    <HelpPopHover text={t('unique_ID_to_identify_your_campaign')} style={{ color: theme.palette.text.secondary, marginLeft: '4px', height: '16px' }} />
                                </Typography>
                                <DataTextField
                                    className={classes.textField}
                                    name='identifier'
                                    placeholder={t('enter_campaign_identifier_placeholder')}
                                    onChange={handleChange}
                                    error={idField.error}
                                    helperText={idField.helperText}
                                />
                            </div>
                            {/* <div className={classes.field}>
                                <Typography variant='h6' noWrap className={clsx('bold', 'flexRow')} >
                                    {' Please enter campaign period'}
                                </Typography>
                                <ValidityPeriod newCampaign={newCampaign} setNewCampaign={setNewCampaign} />
                            </div> */}
                            <div className={classes.field}>
                                <Typography variant='h6' noWrap className={clsx('bold', 'flexRow')} >
                                    {t('status')}
                                </Typography>
                                <StatusRadio newCampaign={newCampaign} setNewCampaign={setNewCampaign} />
                            </div>
                            <Button variant='contained'
                                color='primary'
                                disabled={cantSubmit}
                                onClick={handleSubmit}
                                style={{ width: 'min(100px, 100%)' }}
                            >
                                {t('submit')}
                            </Button>
                        </div>
                    </div>
                </Box>
            </Drawer>
        </React.Fragment>
    )
}

export default withWidth()(withRouter(AddCompaign))