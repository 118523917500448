const { useEffect, useState, useRef, useLayoutEffect, useCallback } = require('react')

const useDelay = (setValue, delay = 40) => {

    const [flag, setFlag] = useState(true)

    function updateValue(value) {
        var timer
        if (flag) {
            setFlag(false)
            setValue(value)
           timer = setTimeout(() => {
                setFlag(true)
            }, delay)
        }
    }

    return updateValue
}

export default useDelay


