import { Box, Button, makeStyles, useTheme } from '@material-ui/core'
import MetaTable from 'components/MetaTable/MetaTable'
import { Form, useFormikContext } from 'formik'
import _ from 'lodash'
import { useCampaign } from 'provider/CampaignProvider'
import { useLocale } from 'provider/LocaleProvider'
import React, { Fragment, useEffect, useState } from 'react'
import { useGenerateToken } from '../GenerateToken'
const moment = require('moment-timezone')



const useStyles = makeStyles(theme => ({

}))


const GenerateMetaData = props => {
    const classes = useStyles()

    const { t } = useLocale()
    const theme = useTheme()
    const formik = useFormikContext()
    const { values, touched, errors, handleChange, handleBlur, setFieldValue, setFieldTouched, isValid } = formik


    const handleReset = () => {
        setFieldValue('step', 0)
        setFieldValue('mode', null)
    }

    const handleBack = () => {
        setFieldValue('step', values.step - 1)
    }

    const handleNext = (e) => {
        e.preventDefault()
        if (errors !== {}) {
            setFieldValue('step', values.step + 1)
        }
    }

    return (
        <Form onSubmit={handleNext} style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <div style={{ flexGrow: 1 }}>
                <MetaTable metadata={_.get(values, 'redeem_token_rule.metadata')} setMetadata={(v) => setFieldValue('redeem_token_rule.metadata', v)} action />
            </div>
            <Box display='flex' style={{ marginTop: '24px' }}>
                <Button onClick={handleReset} variant="contained" style={{ color: 'white', backgroundColor: theme.palette.error.main }}>
                    {t('cancel')}
                </Button>
                <Box flexGrow={1} />
                <Button variant="outlined" onClick={handleBack}  >
                    {t('back')}
                </Button>
                <Button
                    type='submit'
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: '8px' }}
                    onClick={handleNext}
                >
                    {t('next')}
                </Button>
            </Box>
        </Form>
    )
}

export default GenerateMetaData