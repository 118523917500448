
import { Box, Button, Tooltip, Divider, FormControl, FormControlLabel, FormLabel, Grid, GridList, GridListTile, IconButton, isWidthDown, makeStyles, Paper, Radio, RadioGroup, Toolbar, Typography, useTheme, withWidth } from '@material-ui/core'
import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'

import EditIcon from '@material-ui/icons/Edit'
import { withRouter } from 'react-router-dom'
import DataTable from '../DataTable/DataTable'
import DeleteIcon from '@material-ui/icons/Delete'
import EditMetaDialog from './EditMetaDialog'
import { useLocale } from '../../provider/LocaleProvider'

const moment = require('moment-timezone')
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        // maxWidth: '1000px',
        boxSizing: 'border-box'
    },
    grid_container: {
        padding: '12px 24px',
        overflow: 'hidden'
    },
    text_name: {
        alignItems: 'start',
        minWidth: 'min(150px,100%)',
        maxWidth: '200px',
        paddingRight: '16px'
    },
    text_body: {
        // minWidth: 'min(200px,calc(100% - 32px))',
        alignItems: 'center',
        lineHeight: '24px',
        maxHeight: '96px',
        overflow: 'hidden',
        overflowWrap: 'break-word'
    },
    text_body_2: {
        // minWidth: 'min(200px,calc(100% - 32px))',
        // alignItems: 'center',
        lineHeight: '40px',
        maxHeight: '120px',
        overflow: 'hidden',
        overflowWrap: 'break-word'
    },

    divider: {
        marginTop: '8px',
        marginBottom: '16px'
    },
    formControlLabel: {
        margin: 0
    },
    button: {
        width: '80px',
    },
    statusButton: {
        margin: '24px 0 0 0',
        width: '100%',
    },
    title: {
        padding: '4px 24px 12px 24px'
    },
    paper: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        overflow: 'hidden'
    }
}))

const MetaTable = ({ metadata, setMetadata, action = false, update }) => {

    const classes = useStyles()
    const { t } = useLocale()

    const [editMeta, setEditMeta] = useState({
        open: false,
        mode: null,
        data: {},
    })


    const editMetaDialog = ({
        open: editMeta.open,
        mode: editMeta.mode,
        data: editMeta.data,
        add: () => setEditMeta({ open: true, mode: 'add', data: {} }),
        edit: (data) => setEditMeta({ open: true, mode: 'edit', data: data }),
        delete: (data) => setEditMeta({ open: true, mode: 'delete', data: data }),
        close: () => setEditMeta({ ...editMeta, open: false })

    })


    const handleAddMeta = () => {
        editMetaDialog.add()
    }

    const handleEditMeta = row => {
        editMetaDialog.edit({ key: row[0], value: row[1] })
    }

    const handleDeleteMeta = row => {
        editMetaDialog.delete({ key: row[0], value: row[1] })
    }


    const metadataArray = useMemo(() => {
        if (metadata == null) return []
        return Object.entries(metadata)
    }, [metadata])



    var metadata_head = [
        {
            id: 'key',
            label: t('key_name'),
            width: 310,
            value: '0',
            align: 'left',
            fixed: true,
        },
        {
            id: 'value',
            label: t('key_value'),
            value: '1',
            width: '100%',
        }
    ]

    if (action) metadata_head.push(
        {
            id: 'action',
            label: t('actions'),
            value: 'action',
            content: row =>
                <div style={{ marginTop: '-8px', marginBottom: '-8px' }}>
                    <Tooltip title={t('edit')}>
                        <IconButton onClick={() => handleEditMeta(row)} style={{ padding: 0, margin: '8px' }}>
                            <EditIcon style={{ height: '24px' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={t('delete')}>
                        <IconButton onClick={() => handleDeleteMeta(row)} style={{ padding: 0, margin: '8px' }}>
                            <DeleteIcon style={{ height: '24px' }} />
                        </IconButton>
                    </Tooltip>
                </div>,
            width: 100,
            sort: false,
        }
    )


    return (
        <Fragment>
            {action && <EditMetaDialog editMetaDialog={editMetaDialog} metadata={metadata} setMetadata={setMetadata} update={update} />}
            <Box className='flexCol' width='100%' mt={3}>
                <Toolbar variant='dense'>
                    <Typography noWrap variant='h5' color='primary' >{t('metadata')}</Typography>
                    <Box flexGrow={1} />
                    {action &&
                        <Button
                            className={classes.button}
                            variant="contained"
                            color='secondary'
                            size="medium"
                            onClick={handleAddMeta}
                        >
                            {t('add')}
                        </Button>
                    }
                </Toolbar>
                <DataTable row_id='key' rows={metadataArray} heads={metadata_head} />
            </Box>
        </Fragment>

    )
}


export default MetaTable