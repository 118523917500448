import React, { useContext, useEffect, useRef, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles, TextField, Typography, useTheme } from '@material-ui/core'
import EditDialog, { DialogTextField } from 'components/EditDialog'
import PropTypes, { object } from 'prop-types'
import { useSnack } from 'provider/SnackbarProvider'
import useApi from 'api/useApi'
import { useLocale } from 'provider/LocaleProvider'

const RedeemDialog = props => {
    const userRequest = useApi()
    const { open, setOpen, token, refresh } = props
    const snack = useSnack()
    const { t } = useLocale()

    const handleClose = () => {
        setOpen(false)
    }

    const handleSuccess = () => {
        setOpen(false)
        snack.open(t('token_redeemed'))
        document.getElementById("app").scroll(0, 0)
        refresh()
    }

    const handleSave = () => {
        userRequest.redeemToken(token).then(res => {
            const result = res.data
            handleSuccess()
        }).catch(e => {
            snack.error(e.msg)
        })
    }


    return (
        <EditDialog open={open} title={t('redeem_dialog_title')} handleSave={handleSave} handleClose={handleClose} confirmText={t('confirm')}>
            {t('redeem_dialog_msg')}
        </EditDialog>
    )
}

export default RedeemDialog