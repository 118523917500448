import { CssBaseline } from '@material-ui/core'
import LocaleProvider from './provider/LocaleProvider'
import GlobalStyles from './theme/GlobalStyles'
import GlobalThemeProvider from './theme/GlobalThemeProvider'
import Routes from './Routes'
import SnackbarProvider from 'provider/SnackbarProvider'
import BackdropProvider from 'provider/BackdropProvider'
import { useEffect } from 'react'

import SwiperCore, { Autoplay } from 'swiper'
var _ = require('lodash')

function App() {

  SwiperCore.use([Autoplay])

  function appHeight() {
    const doc = document.documentElement
    doc.style.setProperty('--vh', (window.innerHeight * 0.01) + 'px')
  }

  useEffect(() => {
    window.addEventListener('resize', appHeight)
    appHeight()
  }, [])

  return (
    <GlobalThemeProvider>
      <SnackbarProvider>
        <BackdropProvider>
          <LocaleProvider>
            <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' />
            <CssBaseline />
            <Routes />
          </LocaleProvider>
        </BackdropProvider>
      </SnackbarProvider>
    </GlobalThemeProvider>
  )
}

export default App
