import { FormColorTextField, FormSelector, FormSwitch, FormTextField } from 'components/DataForm'

import { Drawer, Box, Grid, useTheme, Typography, Toolbar, IconButton, withWidth, Card, CardContent, Button, Divider, Icon } from '@material-ui/core'
import { DataSession } from 'components/DataSession'
import { useLocale } from 'provider/LocaleProvider'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import ThemeEditDrawer from '../ThemeEditDrawer'
import useRect from 'hooks/useRect'
import useApi from 'api/useApi'
import Sortable from 'sortablejs'
import { ReactSortable } from 'react-sortablejs'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import SwapVertIcon from '@material-ui/icons/SwapVert'
import '../theme.css'
import { UploadButton } from 'components/FileUploader'
import { Swiper, SwiperSlide, Autoplay } from 'swiper/react'
import DeleteIcon from '@material-ui/icons/Delete'



const MessageForm = ({ message, setMessage, message_type = '' }) => {
    const { t } = useLocale()
    const handleSetValue = (name, format = (v) => v) => v => {
        var value = format(v)
        if (value !== null) setMessage({ ...message, [name]: value })
    }


    const getImage = () => {
        var url = message.image_url
        const blob = message.blob
        if (blob && blob.name !== undefined) {
            url = URL.createObjectURL(blob)
        }
        if (url) return <img src={url} alt={t('no_image')} style={{ maxWidth: '100%', maxHeight: '150px', objectFit: 'contain', objectPosition: 'left' }} />
        return t('no_image')
    }

    function handleDeleteImage() {
        setMessage({
            ...message,
            image_size: null,
            image_url: null,
            image_name: null,
            blob: undefined,
        })
    }
    return (
        <Fragment>
            {/* <Typography variant='body1'>
            {t(message_type)}
        </Typography> */}
            <FormSwitch label={t(message_type)} checked={message.is_active} handleChange={handleSetValue('is_active', e => e.target.checked)} />
            {/* <FormColorTextField name={t('background_color')} value={message.background_color} setValue={handleSetValue('background_color')} /> */}
            {/* <FormColorTextField name={t('text_color')} value={message.text_color} setValue={handleSetValue('text_color')} /> */}
            {/* <FormTextField name={t('display_text')} value={message.display_text} setValue={handleSetValue('display_text')} /> */}


            < FormSelector options={[{ value: 'contain', label: t('contain') }, { value: 'fill', label: t('fill') }, { value: 'cover', label: t('cover') }]}
                value={message.scale_type}
                label={t('scale_type')}
                setValue={handleSetValue('scale_type')}
                getOptionLabel={(op, index) => op.label}
                getOptionValue={(op, index) => op.value}
                style={{ margin: '16px 0', backgroundColor: 'white' }}
            />
            <Toolbar>
                <Typography variant='h6'>
                    {t('image')}
                </Typography>
                <UploadButton size='small' variant='contained' icon
                    setFile={handleSetValue('blob')}
                    reset
                    accept="video/*,image/*"
                />
                <IconButton onClick={handleDeleteImage} style={{ padding: '4px' }}>
                    <DeleteIcon />
                </IconButton>
            </Toolbar >
            { getImage()}
        </Fragment >
    )
}

export default MessageForm