import { Box, Button, Divider, FormControl, FormControlLabel, FormLabel, Grid, GridList, GridListTile, IconButton, isWidthDown, makeStyles, Paper, Radio, RadioGroup, TextField, Tooltip, Typography, useTheme, withWidth } from '@material-ui/core'
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { NavLink, useParams } from 'react-router-dom'
import DataTable from 'components/DataTable/DataTable'
import useApi from 'api/useApi'
import { useLocale } from 'provider/LocaleProvider'
import MetaSelecter from 'components/DataTableWithMeta/components/MetaSelecter'
import RefreshIcon from '@material-ui/icons/Refresh'
import EditIcon from '@material-ui/icons/Edit'
import CropFreeIcon from '@material-ui/icons/CropFree'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import ClearIcon from '@material-ui/icons/Clear'
import { DataRow, DataSession, TextDataRow } from 'components/DataSession'
import { useSnack } from 'provider/SnackbarProvider'
import EditDialog, { DialogAutocomplete, DialogTextField } from 'components/EditDialog'

import DeleteIcon from '@material-ui/icons/Delete'
import { Alert } from '@material-ui/lab'
const ThemeDeleteDialog = ({ details, setDetails = new Function(), open, setOpen = new Function() }) => {

    const snack = useSnack()
    const { t } = useLocale()
    const api = useApi()

    const { themeId } = useParams()

    const [attached, setAttached] = useState()
    const [disabled, setDisabled] = useState(true)

    function device(name) {
        return (
            {
                name: name
            }
        )
    }

    useEffect(() => {
        setDisabled(true)
        setAttached(undefined)
        if (open) {
            api.getDevices(setAttached, { theme_id: themeId })
        }
    }, [open])



    useEffect(() => {
        var timeout
        if (attached !== undefined) {
            timeout = setTimeout(() => setDisabled(false), 1500)
        }
        else {
            clearTimeout(timeout)
        }
    }, [attached])


    const handleConfirm = () => {
        api.deleteTheme(themeId)
    }

    const handleClose = () => {
        setOpen(false)
    }

    function array2String(arr) {
        if (typeof arr === 'object' && arr.length > 0) {
            if (arr.length > 1) {
                const str = arr.reduce((a, c, i) => {
                    return i > 1 ? `${a}, ${c.name}` : `${a.name}, ${c.name}`
                })
                return str
            }
            return arr[0].name
        }
        return 'null'
    }

    const loading = attached === undefined || disabled
    if (details === undefined) return null
    return (
        <EditDialog open={open} title={t('delete_theme')} handleSave={handleConfirm} handleClose={handleClose} confirmDisabled={loading} loading={loading}>
            {attached && attached.length > 0 && <Alert severity="warning">
                <div> {`${t('theme_is_used_by')} ${attached.length} ${t('devices')}:`}</div>
                <div>
                    {array2String(attached)}
                </div>

            </Alert>
            }
            <div style={{ marginTop: '8px' }}> {t('are_you_sure')}</div>

        </EditDialog>
    )
}

export default ThemeDeleteDialog


