import React, { useState, useEffect, useContext } from 'react'
import { Alert } from '@material-ui/lab'
import { CircularProgress, Grow, makeStyles, Snackbar, useTheme } from '@material-ui/core'
import { useHistory, useLocation, useParams, withRouter } from 'react-router-dom'
import useApi from 'api/useApi'
import { useAuthData } from './AuthDataProvider'
import { DataBackdrop } from './BackdropProvider'


const { createContext } = require('react')

const CampaignContext = createContext()

export function useCampaign() {
    return useContext(CampaignContext) || {}
}

const CampaignProvider = (props) => {

    const { children } = props
    const location = useLocation()
    const history = useHistory()
    const api = useApi()
    const [campaign, setCampaign] = useState()
    const [campaignList, setCampaignList] = useState()
    const { campaignId } = useParams()


    const { organization } = useAuthData()



    useEffect(() => {
        getCampaignDetails()
        getCampaignList()
    }, [location.pathname])


    function getCampaignList() {
        api.getCampaigns(setCampaignList, { limit: 10 })
    };

    function getCampaignDetails() {
        api.getCampaignDetails(setCampaign)
    };


    function getRecordMetaField() {
        if (campaign && campaign.metadata_key_lists) {
            var fields = campaign.metadata_key_lists.redeem_records
            if (fields) fields = fields.sort()
            return fields
        }
        return []
    }

    function getTokenMetaField() {
        if (campaign && campaign.metadata_key_lists) {
            var fields = campaign.metadata_key_lists.redeem_tokens
            if (fields) fields = fields.sort()
            return fields
        }
        return []
    }

    function updateCampaign(updates, callback, failCallback) {
        api.updateCampaign(updates, setCampaign).then(callback).catch(failCallback)
    }

    const state = {
        campaign_id: campaignId,
        campaign: campaign || {},
        setCampaign,
        campaignList: campaignList || [],
        getRecordMetaField,
        getTokenMetaField,
        updateCampaign

    }
    return (
        <CampaignContext.Provider value={state} >
            <DataBackdrop open={campaign === undefined || campaignList === undefined} />
            {children}
        </CampaignContext.Provider>
    )
}

export default withRouter(CampaignProvider)