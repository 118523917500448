import { Box, Typography } from '@material-ui/core'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/swiper.scss'
import '../theme.css'

const MessagePreview = ({ message = {}, vw, vh }) => {

    const display_text = message.display_text || ''
    const display = message.is_active || false
    const objectFit = message.scale_type
    const color = message.objectFit

    const getImageUrl = () => {
        var url = message.image_url
        const blob = message.blob
        if (blob && blob.name !== undefined) {
            url = URL.createObjectURL(blob)

        }
        return url
    }
    const src = getImageUrl()
    const description = message.display_text
    if (!display) return null
    return <div style={{ width: '100%', height: '100%', color: 'white', fontSize: '5rem' }}>
        {src && <img src={src} style={{ width: '100%', height: '100%', objectFit: objectFit }} />}
        {/* <div className={'message-text'}>
            <div style={{ textAlign: 'left', fontSize: '0.2em', margin: '0 0.1em 0.3em 0.1em', lineHeight: '0.3em', overflow: 'hidden', overflowWrap: 'anywhere' }}>
                {description}
            </div>
        </div> */}
    </div>
}


export default MessagePreview

