import React, { Fragment, useMemo, useCallback, useEffect, useState, useRef, useLayoutEffect } from 'react'
import { Field, Form, Formik, useFormikContext } from 'formik'
import {
    Box, Button, Divider, FormControl, FormControlLabel, FormLabel,
    Grid, GridList, GridListTile, IconButton, isWidthDown, makeStyles, Paper,
    Radio, RadioGroup, TextField, Typography, useTheme, withWidth, AppBar,
    Tab, Tabs, InputAdornment, withStyles, Switch, MenuItem, Chip, Checkbox, Toolbar, Table, TableBody, TableRow, TableCell, Card, FormGroup
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useLocale } from 'provider/LocaleProvider'
import DatePicker from './DatePicker'
import _ from 'lodash'
import DataChipInput from './DataChipInput'
import ChipInput from 'material-ui-chip-input'
const moment = require('moment-timezone')


const StyledFormGroup = withStyles({
    root: {
        "& .fb-20": {
            flexBasis: '20%'
        },
    }
})(FormGroup)




const ValidityPeriod = () => {
    const { t } = useLocale()
    const { values, handleChange, setFieldValue } = useFormikContext()
    return (
        <Fragment>
            <TableRow>
                <TableCell colSpan={2}>  <Typography noWrap variant='h6'>{t('validity_period')} </Typography> </TableCell>
            </TableRow>
            <TableRow>
                <TableCell width='20%'> {t('start_at')} </TableCell>
                <TableCell>
                    <FormControl component="fieldset">
                        <RadioGroup name="start_at_type" value={values.start_at_type} onChange={handleChange}>
                            <FormControlLabel value='null' control={<Radio color='primary' />} label={t('start_immediately')} />
                            <FormControlLabel value="date" control={<Radio color='primary' />} label={<DatePicker handleChange={v => setFieldValue('rule.started_at', v)} />} />
                        </RadioGroup>
                    </FormControl>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell > {t('expiry')} </TableCell>
                <TableCell>
                    <FormControl component="fieldset">
                        <RadioGroup name="expiry_type" value={values.expiry_type} onChange={handleChange}>
                            <FormControlLabel value='null' control={<Radio color='primary' />} label={t('never_expired')} />
                            <FormControlLabel value='seconds' control={<Radio color='primary' />}
                                label={
                                    <TextField variant='outlined' name='rule.expiry_seconds' value={_.get(values, 'rule.expiry_seconds')} onChange={handleChange} InputProps={{
                                        endAdornment: <InputAdornment position="end">{t('seconds')}</InputAdornment>
                                    }} />
                                } />
                            <FormControlLabel value="date" control={<Radio color='primary' />} label={<DatePicker handleChange={v => setFieldValue('rule.expired_at', v)} />} />
                        </RadioGroup>
                    </FormControl>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}

const RedeemPeriod = () => {
    const { t } = useLocale()
    const { values, handleChange, setFieldValue } = useFormikContext()
    const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    console.log(_.get(values, 'rule.redeem_date_conditions.weekdays'))
    console.log(_.indexOf(_.get(values, 'rule.redeem_date_conditions.weekdays'), 'Sun'))
    return (
        <Fragment>
            <TableRow>
                <TableCell colSpan={2}>
                    <Typography noWrap variant='h6' style={{ display: 'inline-block' }}>{t('redeem_period')} </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell width='20%'> {t('enable')} </TableCell>
                <TableCell>
                    <Switch
                        checked={_.get(values, 'condition')}
                        onChange={handleChange}
                        name="condition"
                        color="primary"
                    />
                </TableCell>
            </TableRow>
            {_.get(values, 'condition') == true &&
                <Fragment>
                    <TableRow>
                        <TableCell width='20%'> {t('conditions')} </TableCell>
                        <TableCell  >
                            <RadioGroup name="rule.redeem_date_conditions.type" value={_.get(values, 'rule.redeem_date_conditions.type', null)} onChange={handleChange} row>
                                <FormControlLabel value="exclude" control={<Radio color='primary' />} label={t('exclude')} />
                                <FormControlLabel value='include' control={<Radio color='primary' />} label={t('include')} />
                            </RadioGroup>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width='20%'> {t('weekdays')} </TableCell>
                        <TableCell >
                            <StyledFormGroup row>
                                {weekdays.map(day =>
                                    <FormControlLabel style={{ flexBasis: '24%' }}
                                        control={
                                            <Checkbox
                                                checked={_.indexOf(_.get(values, 'rule.redeem_date_conditions.weekdays'), day) >= 0}
                                                color='primary'
                                                onChange={handleChange}
                                                name="rule.redeem_date_conditions.weekdays"
                                                value={day}

                                            />
                                        }

                                        label={t('day')}
                                    />
                                )}
                            </StyledFormGroup>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width='20%'> {t('months')} </TableCell>
                        <TableCell >
                            <StyledFormGroup row>
                                {months.map(month =>
                                    <FormControlLabel style={{ flexBasis: '24%' }}
                                        control={
                                            <Checkbox
                                                checked={_.indexOf(_.get(values, 'rule.redeem_date_conditions.months'), month) >= 0}
                                                color='primary'
                                                onChange={handleChange}
                                                name="rule.redeem_date_conditions.months"
                                                value={month}
                                            />
                                        }
                                        label={t('months')}
                                    />
                                )}
                            </StyledFormGroup>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width='20%'> {t('days')} </TableCell>
                        <TableCell >
                            <DataChipInput
                                tags={_.get(values, 'rule.redeem_date_conditions.dates')}
                                setTags={chips => setFieldValue('rule.redeem_date_conditions.dates', chips)}
                                isValid={(date) => {
                                    return moment(date, 'YYYY-MM-DD', true).isValid()
                                }}
                                regex={/[^0-9,-]/g}
                                helperText={t('dates_chip_helper')}
                            />
                        </TableCell>
                    </TableRow>
                </Fragment>
            }
        </Fragment>
    )
}


const RedeemConfig = ({ isEdit }) => {
    const { t } = useLocale()
    const { values, handleChange, setFieldValue } = useFormikContext()

    return (
        <Fragment>
            <TableRow>
                <TableCell colSpan={2}>  <Typography noWrap variant='h6'>{t('redeem_token_config')} </Typography> </TableCell>
            </TableRow>
            <TableRow>
                <TableCell width='20%'> {t('has_reward')} </TableCell>
                <TableCell>
                    <Switch
                        name="rule.has_reward"
                        checked={_.get(values, 'rule.has_reward') === true}
                        onChange={handleChange}
                        color="primary"
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell width='20%'> {t('is_reusable')} </TableCell>
                <TableCell>
                    <Switch
                        name="rule.is_reusable"
                        checked={_.get(values, 'rule.is_reusable') === true}
                        onChange={handleChange}
                        color="primary"
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell width='20%'> {t('redeem_quota')} </TableCell>
                <TableCell >
                    <div style={{ margin: '-16px 0' }}>
                        {['overall', 'yearly', 'monthly', 'weekly', 'daily'].map(type => {
                            var name = `rule.redeem_quota.${type}`
                            var value = _.get(values, name, null)
                            return (
                                <div style={{ margin: '16px 0' }}>
                                    <TextField
                                        label={t(type)}
                                        value={value}
                                        onChange={handleChange}
                                        variant='outlined'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell width='20%'> {t('token_identifier')} </TableCell>
                <TableCell >
                    <TextField
                        label={t('token_identifier')}
                        name='token_identifier'
                        disabled={isEdit}
                        value={_.get(values, 'rule.token_identifier')}
                        onChange={handleChange}
                        variant='outlined'
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell width='20%'> {t('redeem_pin')} </TableCell>
                <TableCell >
                    <TextField
                        label={t('redeem_pin')}
                        name='rule.redeem_pin'
                        value={_.get(values, 'rule.redeem_pin')}
                        onChange={handleChange}
                        variant='outlined'
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </TableCell>
            </TableRow>
        </Fragment>
    )
}





const RedeemTokenRuleForm = ({ rule, handleSubmit = new Function(), isEdit = false }) => {
    const { t } = useLocale()


    return (
        <Formik
            enableReinitialize
            initialValues={{
                rule: rule,
                condition: _.get(rule, 'redeem_date_conditions.type') !== null,
                start_at_type: _.isNil(_.get(rule, 'started_at')) ? 'null' : 'date',
                expiry_type: _.isNil(_.get(rule, 'expired_at')) ? 'null' : 'date'
            }}
            onSubmit={handleSubmit}
            render={({ values, handleChange, touched, setFieldValue }) => {
                return (
                    <Form>
                        <Card style={{ marginBottom: '16px' }}>
                            <Table>
                                <ValidityPeriod />
                            </Table>
                        </Card>
                        <Card style={{ marginBottom: '16px' }}>
                            <Table>
                                <RedeemPeriod />
                            </Table>
                        </Card>
                        <Card >
                            <Table>
                                <RedeemConfig isEdit={isEdit} />
                            </Table>
                        </Card>
                        <Toolbar style={{ justifyContent: 'flex-end' }}>
                            <Button
                                color='primary'
                                variant="contained"
                                size="large"
                                type='summit'
                            >
                                {t('save')}
                            </Button>
                        </Toolbar>
                    </Form>
                )
            }}
        />
    )
}

export default RedeemTokenRuleForm