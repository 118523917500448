import { Box, Button, Chip, Divider, FormControl, FormControlLabel, FormLabel, Grid, GridList, GridListTile, IconButton, Input, InputAdornment, isWidthDown, makeStyles, Paper, Radio, RadioGroup, TextField, Toolbar, Typography, useTheme, withWidth } from '@material-ui/core'
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'

import ChipInput from 'material-ui-chip-input'
import { useSnack } from '../provider/SnackbarProvider'
import { useLocale } from 'provider/LocaleProvider'

const moment = require('moment-timezone')



const useStyles = makeStyles(theme => ({
    input: {
        backgroundColor: '#fff',
        boxShadow: ' 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        alignItems: 'baseline'
        // flex: 1,
        // font: 'inherit',
        // border: '0',
        // minHeight: '200px',
        // height: '100%',
        // outline: 'none',
        // "&:hover": {
        //     border: 0,
        //     outline: 'none'
        // }
        // '&input': {
        //     minHeight: '200px',
        // }
    }
}))

const error_message = "Must be a number between 0-999"




const InputComponent = props => {
    return <textarea rows={5} {...props} style={{ minHeight: '150px', minWidth: '200px', whiteSpace: 'normal' }} />
}

const DataChipInput = props => {

    const { t } = useLocale()
    const classes = useStyles()
    const snack = useSnack()
    const { tags, setTags, isValid, regex, helperText } = props
    const [error, setError] = useState(false)
    const inputRegex = regex || /(^,)|[^A-Za-z0-9,]/g

    const [inputValue, setInputValue] = useState('')

    const handleClearAll = () => {
        setTags([])
        setInputValue('')
    }

    const handleHashtagDelete = (chip, index) => {
        var temp = [...tags]
        temp.splice(index, 1)
        setTags(temp)
    }

    function checkDuplicate(array) {
        for (let index = 0; index < array.length; index++) {
            var item = array[index]
            if (tags.includes(item) || array.indexOf(item) != index) {
                snack.error(t('more_than_one') + item)
                return true
            }
        }
        return false
    }

    function checkValid(array, index) {
        var item = array[index]
        if (tags.includes(item) || array.indexOf(item) != index) {
            return false
        }
        else {
            var valid = isValid != null ? isValid(item) : true
            return valid
        }

    }

    function handleAddChip(chip) {
        var input = chip.replace(/(^, )/g, '').replace(/,,*/g, ',')
        var array = input.split(/[,]/)
        var temp = [...tags]
        var error = []
        for (let index = 0; index < array.length; index++) {
            var item = array[index]
            if (checkValid(array, index) && item != '') {
                temp.push(item)
            }
            else if (item != '') {
                error.push(item)
            }
        }
        var errString = error.length > 0 ? error.reduce((a, c) => a + ',' + c) + ',' : ''
        setTags(temp)
        setInputValue(errString)
    }


    const handleHashtagChange = e => {
        var input = e.target.value.replace(inputRegex, '').replace(/,,*/g, ',')
        setInputValue(input)
    }


    return (

        <ChipInput
            classes={{
                inputRoot: classes.input
            }}
            fullWidth
            margin="dense"
            variant="outlined"
            // disabled={!checkPermission("editBrandInfo")}
            // label="Hashtag"
            value={tags}
            inputValue={inputValue}
            name="hashtag"
            // newChipKeyCodes={[]}
            // newChipKeys={[]}
            // onBeforeAdd={(chip) => {
            //     var value = chip.replace(/[ ]/g, '')
            //     if (tags.includes(value)) {
            //         snack.error('more than one 2 ' + value)
            //         return false
            //     }
            //     if (value.includes(',')) {
            //         return false
            //     }
            //     return true
            // }}
            clearInputValueOnChange={true}
            onAdd={(chip) => handleAddChip(chip)}
            onUpdateInput={handleHashtagChange}
            helperText={helperText}
            onDelete={(chip, index) => handleHashtagDelete(chip, index)}
            InputProps={{
                inputComponent: InputComponent,
                // endAdornment: (
                //     <InputAdornment position="end">
                //         <CloseIcon />
                //     </InputAdornment>

                // )
            }}


        />


    )
}

export default DataChipInput