import Hidden from '@material-ui/core/Hidden'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DnsRoundedIcon from '@material-ui/icons/DnsRounded'
import PeopleIcon from '@material-ui/icons/People'
import PermMediaOutlinedIcon from '@material-ui/icons/PhotoSizeSelectActual'
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent'
import { useLocale } from 'provider/LocaleProvider'
import React, { } from 'react'
import { useParams } from 'react-router-dom'
import Navigator from '../Navigator'
import { Fragment } from 'react'


const drawerWidth = 256

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            {new Date().getFullYear()}
            {' '}
            <Link color="inherit" href="https://www.animaetech.com/">
                <span style={{ display: 'inline-block' }}> {'Animae Technologies Limited.'}</span>
            </Link>
            <span style={{ display: 'inline-block' }}>All Rights Reserved.</span>
        </Typography>
    )
}

const useStyles = makeStyles(theme => ({

}))

function CampaignSidebar({ mobileOpen, handleDrawerToggle }) {
    const classes = useStyles()

    const { campaignId } = useParams()
    const { t } = useLocale()
    const categories = [
        {
            id: t('campaign'),
            children: [
                { id: t('campaign_overview'), icon: <PeopleIcon />, active: true, href: `/campaigns/${campaignId}/info` },
            ],
        },
        {
            id: t('tokens'),
            children: [
                { id: t('redeem_tokens'), icon: <PeopleIcon />, active: true, href: `/campaigns/${campaignId}/redeem_tokens` },
                { id: t('search_token'), icon: <SettingsInputComponentIcon />, href: `/campaigns/${campaignId}/token_search` },
                { id: t('generate_token'), icon: <PermMediaOutlinedIcon />, href: `/campaigns/${campaignId}/generate_tokens` },
            ],
        },
        {
            id: t('records'),
            children: [
                { id: t('redeem_records'), icon: <DnsRoundedIcon />, href: `/campaigns/${campaignId}/redeem_records` },
            ],
        },
        {
            id: t('usage'),
            children: [
                { id: t('dashboard'), icon: <DnsRoundedIcon />, href: `/campaigns/${campaignId}/dashboard` },
            ],
        },
        {
            id: t('product'),
            children: [
                { id: t('product_list'), icon: <DnsRoundedIcon />, href: `/campaigns/${campaignId}/product_list` },
                { id: t('product_device_configure'), icon: <DnsRoundedIcon />, href: `/campaigns/${campaignId}/product_device_configure` },
            ],
        },

    ]
    return (
        <Fragment>
            <Hidden mdUp implementation="css">
                <Navigator
                    categories={categories}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                />
            </Hidden>
            <Hidden smDown implementation="css">
                <Navigator categories={categories} />
            </Hidden>
        </Fragment>
    )
}

export default CampaignSidebar
