import { Button, Grid, makeStyles, Toolbar } from '@material-ui/core'
import React, { Fragment } from 'react'
import TokenTable from '../../../../components/DataTableWithMeta/TokenTable'
import useApi from 'api/useApi'
import { Alert } from '@material-ui/lab'
import { useFormikContext } from 'formik'
import { useLocale } from 'provider/LocaleProvider'
const moment = require('moment-timezone')
const _ = require('lodash')


const useStyles = makeStyles(theme => ({

}))

const error_message = "Must be a number between 0-999"

const GenerateSuccess = props => {
    const { t } = useLocale()
    const api = useApi()
    const { values, touched, errors, validateForm, handleChange, setFieldValue, setFieldTouched, isValid } = useFormikContext()


    function handleExportCSV() {
        api.exportSelecedTokensToCSV(_.map(values.tokens, item => item.redeem_token))
    }

    function handleExportPDF() {
        api.exportSelecedTokensToPDF(_.map(values.tokens, item => item.redeem_token))
    }

    function handleDone() {
        setFieldValue('step', 0)
    }

    return (
        <Fragment>
            <Alert variant="filled" severity="success" style={{ marginBottom: '24px' }}>
                {t('generate_success_msg1') + ' ' + _.size(values.tokens) + ' ' + t('generate_success_msg2')}
            </Alert>
            <div style={{ flexGrow: 1 }}>
                <TokenTable data={values.tokens} />
            </div>
            <Toolbar style={{ marginTop: '24px' }} >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Button onClick={handleExportCSV} variant='outlined' color='primary' style={{ height: '100px', width: '100%' }}> {t('export_all')} </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Button onClick={handleExportPDF} variant='outlined' color='primary' style={{ height: '100px', width: '100%' }}> {t('download')} </Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Button onClick={handleDone} variant='contained' color='primary' style={{ height: '100px', width: '100%' }}> {t('done')} </Button>
                    </Grid>
                </Grid>
            </Toolbar>
        </Fragment>
    )
}

export default GenerateSuccess