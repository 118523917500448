import Link from '@material-ui/core/Link'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DnsRoundedIcon from '@material-ui/icons/DnsRounded'
import PeopleIcon from '@material-ui/icons/People'
import PermMediaOutlinedIcon from '@material-ui/icons/PhotoSizeSelectActual'
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent'
import { useLocale } from 'provider/LocaleProvider'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Fragment } from 'react'
import CampaignSidebar from './CampaignSidebar'
import { CampaignHeader } from '../Header'


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            {new Date().getFullYear()}
            {' '}
            <Link color="inherit" href="https://www.animaetech.com/">
                <span style={{ display: 'inline-block' }}> {'Animae Technologies Limited.'}</span>
            </Link>
            <span style={{ display: 'inline-block' }}>All Rights Reserved.</span>
        </Typography>
    )
}

const drawerWidth = 256

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        background: '#eaeff1',
        paddingTop: 50,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        overflow: 'auto'
    },
    content: {
        flex: '1 1 auto',
    },
    footer: {
        padding: theme.spacing(1),
        background: '#eaeff1',
        // paddingBottom: '64px'
    },

}))




function CampaignLayout({ children }) {
    // const { children, title, header: Header, sidebar = true } = props
    const theme = useTheme()
    const classes = useStyles(theme)
    const [mobileOpen, setMobileOpen] = React.useState(false)
    const { campaignId } = useParams()
    const { t } = useLocale()

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    useEffect(() => {
        document.getElementById("app").scroll(0, 0)
    }, [children])

    return (
        <Fragment>
            <div className={classes.root}>
                <CampaignSidebar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
                <CampaignHeader onDrawerToggle={handleDrawerToggle} />
                <div className={classes.wrapper}>
                    <div id='app' className={classes.container}>
                        <div className={classes.content}>
                            {children}
                        </div>
                        <div className={classes.footer}>
                            <Copyright />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CampaignLayout
