import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import CommentIcon from '@material-ui/icons/Comment'
import { Grid } from '@material-ui/core'
import clsx from 'clsx'
import { useLocale } from '../provider/LocaleProvider'
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 200,
        boxSizing: 'border-box',
        backgroundColor: theme.palette.background.paper,
    },
    icon_root: {
        minWidth: 20
    },
    checkbox: {
        paddingTop: 0,
        paddingBottom: 0
    },
    grid_container: {
        width: '100%',
        // marginLeft: 0,
        // marginRight: 0
    },
    grid_item: {
        minWidth: '120px',
        marginLeft: -8,
        marginRight: -8
    },
    list_item: {
        paddingLeft: 8,
        paddingRight: 8
    },
    list_item2: {
        paddingLeft: 0,
        paddingRight: 0
    }

}))

const CheckboxList = props => {
    const { t } = useLocale()
    const classes = useStyles()
    const { list, checked, setChecked, type } = props
    // const [checked, setChecked] = React.useState(defaultValue || [])

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value)
        const newChecked = [...checked]

        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }
        setChecked(newChecked.sort())
    }


    // if (type == 'list') {
    //     return (
    //         <List className={clsx(classes.root, classes.grid_item)} >
    //             {list.map((value) => {
    //                 const labelId = `checkbox-list-label-${value}`
    //                 return (
    //                     <ListItem key={value} dense button onClick={handleToggle(value)} className={classes.list_item} >
    //                         <ListItemIcon className={classes.icon_root}>
    //                             <Checkbox
    //                                 className={classes.checkbox}
    //                                 edge="start"
    //                                 color='primary'
    //                                 checked={checked.indexOf(value) !== -1}
    //                                 disableRipple
    //                                 inputProps={{ 'aria-labelledby': labelId }}
    //                             />
    //                         </ListItemIcon>
    //                         <ListItemText id={labelId} primary={value} />
    //                     </ListItem>
    //                 )
    //             })}
    //         </List >
    //     )
    // }
    return (
        <Grid className={classes.grid_container} container spacing={1} >
            {list.map((value) => {
                const labelId = `checkbox-list-label-${value}`
                return (
                    <Grid Grid Grid key={value} item xs={3} sm={3} className={clsx('flexRow', classes.grid_item)}  >
                        <ListItem dense button onClick={handleToggle(value)} className={classes.list_item}>
                            <ListItemIcon className={classes.icon_root}>
                                <Checkbox
                                    className={classes.checkbox}
                                    edge="start"
                                    color='primary'
                                    checked={checked.indexOf(value) !== -1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={t(value)} />
                        </ListItem>
                    </Grid >
                )
            })}
        </Grid>
    )


}

export default CheckboxList