import { Box, Button, Divider, FormControl, FormControlLabel, FormLabel, Grid, GridList, GridListTile, IconButton, isWidthDown, makeStyles, Paper, Radio, RadioGroup, TextField, Tooltip, Typography, useTheme, withWidth } from '@material-ui/core'
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { NavLink, useParams } from 'react-router-dom'
import DataTable from 'components/DataTable/DataTable'
import useApi from 'api/useApi'
import { useLocale } from 'provider/LocaleProvider'
import MetaSelecter from 'components/DataTableWithMeta/components/MetaSelecter'
import RefreshIcon from '@material-ui/icons/Refresh'
import EditIcon from '@material-ui/icons/Edit'
import CropFreeIcon from '@material-ui/icons/CropFree'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import ClearIcon from '@material-ui/icons/Clear'
import { DataRow, DataSession, TextDataRow } from 'components/DataSession'
import { useSnack } from 'provider/SnackbarProvider'
import EditDialog, { DialogAutocomplete, DialogTextField } from 'components/EditDialog'

import DeleteIcon from '@material-ui/icons/Delete'
import { Alert } from '@material-ui/lab'
import { SignalCellularNullTwoTone } from '@material-ui/icons'
const AddThemeDialog = ({ open, setOpen = new Function(), setThemes }) => {

    const snack = useSnack()
    const { t } = useLocale()
    const api = useApi()

    const [name, setName] = useState()


    useEffect(() => {
        setName(null)
    }, [open])


    const handleConfirm = () => {
        api.addTheme({ name: name }, setThemes).then(handleClose)
    }


    const handleClose = () => {
        setOpen(false)
    }

    const handleChange = (name, value) => {
        if (name == 'name') {
            setName(value)
        }
    }

    return (
        <EditDialog open={open} title={t('add_theme')} handleSave={handleConfirm} handleClose={handleClose}>
            <DialogTextField label={t('theme_name')} onValueChange={handleChange} name='name' value={name} maxLength={20} style={{ margin: 0 }} />
        </EditDialog>
    )
}

export default AddThemeDialog


