import clsx from 'clsx'
import React, { useCallback, useMemo, useRef, useLayoutEffect } from 'react'
import { useLocale } from '../../../provider/LocaleProvider'
import DataTextField from '../../DataTextField'

const _ = require('lodash')


const RedeemQuota = props => {
    const { classes, updates, setUpdates, updateRef, handleSetValue } = props

    const quota = _.get(updates, 'redeem_quota', {})
    const { t } = useLocale()

    const handleQuotaUpdate = (name, value) => {
        if (name == 'overall' || name == 'daily' || name == 'weekly' || name == 'monthly' || name == 'yearly') {
            handleSetValue(`redeem_quota.${name}`)(parseInt(value))
        }
    }



    return useMemo(() => (
        <div className={clsx("flexCol", "fullWidth")} >
            <DataTextField className={classes.textField} disabled={!updates.is_reusable} label={t('overall')} name={'overall'} onValueChange={handleQuotaUpdate} value={quota.overall} max={999} min={0} type='number' />
            <DataTextField className={classes.textField} disabled={!updates.is_reusable} label={t('yearly')} name={'yearly'} onValueChange={handleQuotaUpdate} value={quota.yearly} max={999} min={0} type='number' />
            <DataTextField className={classes.textField} disabled={!updates.is_reusable} label={t('monthly')} name={'monthly'} onValueChange={handleQuotaUpdate} value={quota.monthly} max={999} min={0} type='number' />
            <DataTextField className={classes.textField} disabled={!updates.is_reusable} label={t('weekly')} name={'weekly'} onValueChange={handleQuotaUpdate} value={quota.weekly} max={999} min={0} type='number' />
            <DataTextField className={classes.textField} disabled={!updates.is_reusable} label={t('daily')} name={'daily'} onValueChange={handleQuotaUpdate} value={quota.daily} max={999} min={0} type='number' />
        </div>
    ), [updates.redeem_quota, updates.is_reusable])
}

export default RedeemQuota


