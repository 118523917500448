import { DomainDisabled } from '@material-ui/icons'
import Axios from 'axios'
import apiLink from 'api/apiLink'
import { useBackDrop } from 'provider/BackdropProvider'
import { useHistory, useParams } from 'react-router-dom'
import { useSnack } from 'provider/SnackbarProvider'
import { useLocale } from 'provider/LocaleProvider'
import { useEffect } from 'react'

const moment = require('moment-timezone')
const baseURL = apiLink.server_url + '/api/v2'

const axios = Axios.create({
    baseURL: apiLink.auth_url,
    headers: { 'Content-Type': 'application/json' },
})
export const Login = () => {
    const auth = useAuthApi()
    const { ssoID } = useParams()
    useEffect(() => {
        if (ssoID) login()
    }, [ssoID])

    const login = () => {
        auth.userLogin(ssoID)
    }
    return null
}
const _ = require('lodash')
const useAuthApi = () => {


    const { campaignId, deviceId, themeId } = useParams()
    const backdrop = useBackDrop()
    const history = useHistory()
    const snack = useSnack()
    const { t } = useLocale()

    const authToken = localStorage.getItem('token')

    const config = {
        headers: { authorization: `Bearer ${authToken}` }
    }

    function userLogin(ssoID) {
        return axios.get(`/ext/sso/${ssoID}`)
            .then(res => {
                const result = res.data
                localStorage.setItem('token', result.token)
                window.parent.location.href = '/home'
            })
            .catch(userLogout)
    }

    function userLogout() {
        localStorage.removeItem('token')
        return axios.get(`/ext/auth/logout`, config)
            .then(res => {
                history.push('/')
            })
            .catch(() => history.push('/login'))
    }

    function checkOrganisations(setOrganization) {
        return axios.get(`/ext/check/organisations`, config)
            .then(res => {
                try {
                    const result = res.data
                    if (_.size(result.organisations) == 0) {
                        window.parent.location.href = apiLink.account_url
                    }
                    setOrganization(result.organisations)
                } catch (err) {
                    throw 'Error'
                }
            })
            .catch(userLogout)
    }


    function checkUser(setUser) {
        return axios.get(`/ext/check/me`, config)
            .then(res => {
                const result = res.data
                if (result) setUser(result)
            })
            .catch(userLogout)
    }

    return {
        baseURL,
        userLogin,
        userLogout,
        checkOrganisations,
        checkUser,
    }
}


export default useAuthApi