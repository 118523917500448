import { TextField, TextFieldProps } from '@material-ui/core'
import { useFormik } from 'formik'
import React from 'react'
const _ = require('lodash')
const FormikTextField: React.FC<{ name: string, formik: any, touchError: Boolean, ...TextFieldProps}> = ({ name, formik, touchError = true, ...rest }) => {
    try {

        const value = _.get(formik.values, name)
        const error = _.get(formik.errors, name)
        const touched = _.get(formik.touched, name)

        const isError = touchError ? Boolean(touched && error) : Boolean(error)


        return <TextField
            name={name}
            value={value}
            onChange={(e) => {
                if (rest?.type == 'number') {
                    formik.setFieldValue(name, _.toNumber(e.target.value) || null)
                }
                else {
                    formik.handleChange(e)
                }
            }}
            onBlur={formik.handleBlur}
            error={isError}
            helperText={isError && error
            }
            {...rest}
        />
    } catch (error) {
        console.log('FormikTextField => error', error)
        return null
    }

}

export default FormikTextField