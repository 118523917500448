
import { Box, Button, Divider, FormControl, FormControlLabel, FormLabel, Grid, GridList, GridListTile, IconButton, isWidthDown, makeStyles, Paper, Radio, RadioGroup, Typography, useTheme, withWidth } from '@material-ui/core'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'


import { useHistory, withRouter } from 'react-router-dom'
import { useDetails } from '../CampaignDetails'
import DeleteIcon from '@material-ui/icons/Delete'
import { DataSession, TextDataRow, DataRow, DataSessionSubtitle, DataTypography } from 'components/DataSession'
import MetaTable from 'components/MetaTable/MetaTable'
import { useLocale } from 'provider/LocaleProvider'
import tr from 'date-fns/esm/locale/tr/index.js'

const moment = require('moment-timezone')

function time_date(str) {
    return moment(str).format('DD/MM/YYYY')
}
function time(str) {
    return moment(str).format('hh:mm:ss')
}
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        // maxWidth: '1000px',
        boxSizing: 'border-box'
    },
    grid_container: {
        padding: '12px 24px',
        overflow: 'hidden'
    },
    text_name: {
        alignItems: 'start',
        minWidth: 'min(150px,100%)',
        maxWidth: '200px',
        paddingRight: '16px'
    },
    text_body: {
        // minWidth: 'min(200px,calc(100% - 32px))',
        alignItems: 'center',
        lineHeight: '24px',
        maxHeight: '96px',
        overflow: 'hidden',
        overflowWrap: 'break-word'
    },
    text_body_2: {
        // minWidth: 'min(200px,calc(100% - 32px))',
        // alignItems: 'center',
        lineHeight: '40px',
        maxHeight: '120px',
        overflow: 'hidden',
        overflowWrap: 'break-word'
    },

    divider: {
        marginTop: '8px',
        marginBottom: '16px'
    },
    formControlLabel: {
        margin: 0
    },
    button: {
        width: '80px',
    },
    statusButton: {
        margin: '24px 0 0 0',
        width: '100%',
    },
    title: {
        padding: '4px 24px 12px 24px'
    },
    paper: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        overflow: 'hidden'
    }
}))

const str2Pw = (str) => {
    if (!str) return null
    var _str = str.replace(/./g, '\u2022')
    return _str
}




const General = props => {


    const theme = useTheme()
    const classes = useStyles()
    const { t } = useLocale()

    const { campaign, editNameDialog, editPinDialog, editStatusDialog, editPeriodDialog, editMetaDialog, updateCampaign } = useDetails()

    const history = useHistory()

    const rule = campaign.redeem_token_rule || {}
    const rule_metadata = Object.entries(rule.metadata || {})

    function array2String(arr) {

        if (typeof arr == 'object' && arr.length > 0) {
            return arr.reduce((a, c) => `${t(a)}, ${t(c)}`)
        }
        return null

    }

    const handleEditName = () => {
        editNameDialog.setOpen(true)
    }

    const handleEditPin = () => {
        editPinDialog.setOpen(true)
    }

    const handleEditStatus = () => {
        editStatusDialog.setOpen(true)
    }

    const handleEditPeriod = () => {
        editPeriodDialog.setOpen(true)
    }

    const handleEditRule = () => {
        history.push({ pathname: `/campaigns/${campaign.id}/info/redeem_token_rule` })
    }


    const start_at = campaign.started_at && moment(campaign.started_at).format('YYYY-MM-DD')
    const expired_at = campaign.expired_at && moment(campaign.expired_at).format('YYYY-MM-DD')
    console.log(start_at, expired_at)
    // const period_text = (start_at && expired_at == null ? t('n/a') : `${start_at || ''} ~ ${expired_at || ''} `)
    const period_text = function () {
        if (start_at == null && expired_at == null) {
            return t('not_set')
        }
        if (start_at == null && expired_at != null) {
            return t('to') + ' ' + expired_at
        }
        if (start_at != null && expired_at == null) {
            return t('from') + ' ' + start_at


        }
        if (start_at != null && expired_at != null) {
            return `${t('from')} ${start_at} ${t('to')} ${expired_at}`
        }
    }()
    // (start_at == null && expired_at == null ? t('not_set') : `${start_at!= null && expired_at != null ? t('start_at') + '~' + t(expired_at)  :  ${start_at == null ? t('to') : start_at}) ${expired_at == null ? t('today') : expired_at}`)}




    const condition = rule.redeem_date_conditions || {}
    const quota = rule.redeem_quota || {}



    const editRule = (
        <Button
            className={classes.button}
            variant="contained"
            color='secondary'
            size="medium"
            onClick={handleEditRule}
        >
            {t('edit')}
        </Button>
    )

    return (
        <div className={clsx('flexCol-center', classes.root)}>
            <DataSession title={t('your_campaign')}>
                <TextDataRow name={t('campaign_name')} handleEdit={handleEditName} value={campaign.name} />
                <TextDataRow name={t('campaign_id')} value={campaign.id} />
                <TextDataRow name={t('identifier')} helpText={t('identifier_helper')} noWrap value={campaign.identifier} />
                <TextDataRow name={t('status')}
                    helpText={t('general_status_helper')}
                    style={{ color: campaign.is_active ? 'limegreen' : 'red' }}
                    value={campaign.is_active ? t('active') : t('inactive')}
                />
                <TextDataRow name={t('campaign_period')} handleEdit={handleEditPeriod} value={period_text} />
                {/* <TextDataRow name={t('master_redeem_pin')} handleEdit={handleEditPin} noWrap helpText={t('master_redeem_pin_helper')} value={str2Pw(campaign.master_redeem_pin) || '-'} /> */}
            </DataSession>
            <DataSession title={t('redeem_token_rule')} style={{ marginTop: '24px' }} action={editRule}>
                <DataSessionSubtitle title={t('validity_period')} />
                <TextDataRow name={t('start_at')} value={rule.started_at ? time_date(rule.started_at) : t('start_immediately')} />
                <TextDataRow name={t('expired_date')} value={rule.expired_at ? time_date(rule.expired_at) : t('n/a')} />
                <TextDataRow name={t('expired_after')} value={rule.expiry_seconds ? rule.expiry_seconds + ' ' + t('seconds') : t('n/a')} />
                <DataSessionSubtitle title={t('redeem_period')} />
                <TextDataRow name={t('condition_type')} value={t(condition.type)} />
                <TextDataRow name={t('weekdays')} value={array2String(condition.weekdays)} />
                <TextDataRow name={t('months')} value={array2String(condition.months)} />
                <TextDataRow name={t('dates')} value={array2String(condition.dates)} />
                <DataSessionSubtitle title={t('redeem_token_config')} />
                <TextDataRow name={t('has_reward')} value={rule.has_reward != false ? t('yes') : t('no')} />
                <TextDataRow name={t('is_reusable')} value={rule.is_reusable != false ? t('yes') : t('no')} />
                {rule.is_reusable && (quota.overall || quota.yearly || quota.monthly || quota.weekly || quota.daily) &&
                    <DataRow name={t('redeem_quota')} alignSelf='baseline'  >
                        <div>
                            {quota.overall && <DataTypography value={`${t('overall')}: ${quota.overall}`} style={{ marginBottom: '4px' }} />}
                            {quota.yearly && <DataTypography value={`${t('yearly')}: ${quota.yearly}`} style={{ marginBottom: '4px' }} />}
                            {quota.monthly && <DataTypography value={`${t('monthly')}: ${quota.monthly}`} style={{ marginBottom: '4px' }} />}
                            {quota.weekly && <DataTypography value={`${t('weekly')}: ${quota.weekly}`} style={{ marginBottom: '4px' }} />}
                            {quota.daily && <DataTypography value={`${t('daily')}: ${quota.daily}`} />}
                        </div>
                    </DataRow>
                }
                <TextDataRow name={t('token_identifier')} value={rule.token_identifier} />
                <TextDataRow name={t('redeem_pin')} value={rule.redeem_pin} />
            </DataSession>
            <MetaTable metadata={campaign.metadata} update={updateCampaign} action />
            <Button
                variant="contained"
                size="large"
                className={classes.statusButton}
                // startIcon={<DeleteIcon />}
                onClick={handleEditStatus}
                style={{ backgroundColor: campaign.is_active ? 'red' : 'limegreen', color: 'white' }}
            >
                {campaign.is_active ? t('deactivate_campaign') : t('activate_campaign')}
            </Button>
        </div >

    )
}


export default withWidth()(withRouter(General))