
import { Box, Divider, FormControl, FormControlLabel, FormLabel, Grid, GridList, GridListTile, IconButton, isWidthDown, makeStyles, Paper, Radio, RadioGroup, Typography, useTheme, withWidth } from '@material-ui/core'
import React, { useContext, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { useLocale } from 'provider/LocaleProvider'

import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import PropTypes, { object } from 'prop-types'
import DateFnsUtils from '@date-io/date-fns'
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers'

const moment = require('moment-timezone')

const DatePicker = ({ defaultDate, handleChange, disabled, minDate, maxDate, ...rest }) => {
    const [date, setDate] = useState(defaultDate)
    const [help, setHelp] = useState('')


    function handleDateChange(event) {
        console.log('handleDateChange -> event', event)
        setDate(event)
        handleChange(event)
    }
    const { t } = useLocale()



    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                margin="normal"
                minDate={minDate}
                maxDate={maxDate}
                id="date-picker-dialog"
                inputVariant='standard'
                disabled={disabled}
                minDateMessage={t('after_start')}
                // disablePast
                format="yyyy/MM/dd"
                value={date}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                style={{ margin: '0', maxWidth: '150px' }}
                {...rest}
            />
        </MuiPickersUtilsProvider>
    )
}


DatePicker.propTypes = {
    disabled: PropTypes.bool,
    handleChange: PropTypes.func,
}


export default DatePicker