import React, { Fragment, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'

import { Box, isWidthDown, StepButton, Toolbar, withWidth } from '@material-ui/core'
import { useLocale } from '../../../../provider/LocaleProvider'
import { useFormikContext } from 'formik'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%'
    },
    card: {
        '& .title': {
            display: 'inline-table',
            color: theme.palette.text.secondary,
            width: '200px',
            maxWidth: '100%',
            overflowWrap: 'anywhere',
            marginBottom: '8px',
        },
        '& .content': {
            display: 'inline-table',
            overflowWrap: 'anywhere',
            marginBottom: '8px',
            width: '300px',
            maxWidth: '100%'
        },
    },
}))

const _ = require('lodash')

function ModeStepper({ children, width }) {
    const classes = useStyles()
    const { t } = useLocale()
    const [skipped, setSkipped] = React.useState(new Set())
    const theme = useTheme()


    const stepLabel = {
        auto: [t('enter_token_amount'), t('select_product'), t('config_token_rule'), t('config_metadata'), t('confirm_info')],
        custom: [t('enter_custom_token'), t('select_product'), t('config_token_rule'), t('config_metadata'), t('confirm_info')],
        upload: [t('upload_token_csv'), t('select_product'), t('config_token_rule'), t('config_metadata'), t('confirm_info')]
    }


    const { values, setFieldValue } = useFormikContext()

    const steps = _.get(stepLabel, values.mode, [])

    if (!values.mode || values.step === 0 || values.step > 4) {
        return null
    }

    const handleStep = (index) => () => {
        console.log('handleStep => step', index)
        if (values.step > index + 1) {
            setFieldValue('step', index + 1)
        }
    }

    return (
        <Stepper activeStep={values.step - 1} orientation='horizontal' alternativeLabel style={{ marginBottom: '24px' }}>
            {steps.map((label, index) =>
                <Step key={label} onClick={handleStep(index)} >
                    <StepButton >{label}</StepButton>
                </Step>)}

        </Stepper>
    )
}
export default ModeStepper