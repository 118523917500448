import { Box, Button, Divider, FormControl, FormControlLabel, FormLabel, Grid, GridList, GridListTile, IconButton, isWidthDown, makeStyles, Paper, Radio, RadioGroup, TextField, Tooltip, Typography, useTheme, withWidth } from '@material-ui/core'
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { NavLink, useParams } from 'react-router-dom'
import DataTable from 'components/DataTable/DataTable'
import useApi from 'api/useApi'
import { useLocale } from 'provider/LocaleProvider'
import MetaSelecter from 'components/DataTableWithMeta/components/MetaSelecter'
import RefreshIcon from '@material-ui/icons/Refresh'
import EditIcon from '@material-ui/icons/Edit'
import CropFreeIcon from '@material-ui/icons/CropFree'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import ClearIcon from '@material-ui/icons/Clear'
import { DataRow, DataSession, TextDataRow } from 'components/DataSession'
import { useSnack } from 'provider/SnackbarProvider'
import EditDialog, { DialogAutocomplete, DialogTextField } from 'components/EditDialog'
import AsyncAutocomplete from '../../../components/AsyncAutocomplete'

import DeleteIcon from '@material-ui/icons/Delete'
const DeviceCampaignConfigDialog = ({ details, setDetails = new Function(), open, setOpen = new Function() }) => {

    const snack = useSnack()
    const { t } = useLocale()
    const api = useApi()
    const defaultValue = details?.campaign_device_config || null

    const [value, setValue] = useState(defaultValue)

    const campaign_id = details?.campaign?.id

    useEffect(() => {
        setValue(defaultValue)
    }, [open])


    const handleChange = (name, value) => {
        setValue(value)
    }

    const handleDelete = () => {
        setValue(null)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleSuccess = () => {
        document.getElementById("app").scroll(0, 0)
        setOpen(false)
    }

    const handleSave = () => {
        if (value === defaultValue) {
            handleSuccess()
        }
        else if (value) {
            api.attachDevice('attach/campaign_device_config', { campaign_device_config_id: value.id }, setDetails).then(handleSuccess)
        }
        else {
            api.attachDevice('detach/campaign_device_config', { campaign_device_config_id: defaultValue.id || '' }, setDetails).then(handleSuccess)
        }
    }

    const handleGetOption = (setOptions) => {
        api.getCampaignDeviceConfigs(campaign_id).then(res => {
            setOptions(res.data.campaign_device_configs)
        })
    }

    if (details === undefined || !campaign_id) {
        handleClose()
        return null
    }
    return (
        <EditDialog open={open} title={t('campaign_device_config')} handleSave={handleSave} handleClose={handleClose}>
            <div style={{ display: 'flex' }}>
                <AsyncAutocomplete value={value} onChange={handleChange} handleGetOption={handleGetOption} disableClearable />
                <Tooltip title={t('delete')}>
                    <IconButton onClick={handleDelete} > <ClearIcon /></IconButton>
                </Tooltip>
            </div>
        </EditDialog>
    )
}

export default DeviceCampaignConfigDialog


