import { Box, Button, Divider, FormControl, FormControlLabel, FormLabel, Grid, GridList, GridListTile, IconButton, isWidthDown, makeStyles, Paper, Radio, RadioGroup, TextField, Tooltip, Typography, useTheme, withWidth } from '@material-ui/core'
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { NavLink, useParams } from 'react-router-dom'
import DataTable from 'components/DataTable/DataTable'
import useApi from 'api/useApi'
import { useLocale } from 'provider/LocaleProvider'
import MetaSelecter from 'components/DataTableWithMeta/components/MetaSelecter'
import RefreshIcon from '@material-ui/icons/Refresh'
import EditIcon from '@material-ui/icons/Edit'
import CropFreeIcon from '@material-ui/icons/CropFree'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import ClearIcon from '@material-ui/icons/Clear'
import { DataRow, DataSession, TextDataRow } from 'components/DataSession'
import { useSnack } from 'provider/SnackbarProvider'
import EditDialog, { DialogTextField } from 'components/EditDialog'
import { useDevice } from 'provider/DeviceProvider'
const _ = require('lodash')
export const DescriptionDialog = ({ open = false, setOpen = new Function(), details, setDetails = new Function() }) => {

    const snack = useSnack()
    const defaultValue = details?.description
    const [description, setDesciption] = useState(defaultValue)
    const { t } = useLocale()
    const api = useApi()



    useEffect(() => {
        setDesciption(defaultValue)
    }, [open])

    const handleClose = () => {
        setOpen(false)
    }

    const handleSuccess = () => {
        document.getElementById("app").scroll(0, 0)
        setOpen(false)
    }

    const handleSave = () => {
        if (description != null) {
            if (description == defaultValue) {
                handleSuccess()
            }
            else {
                api.updateDeviceDetails({ description: description }, setDetails).then(handleSuccess)
            }
        }
    }
    const handleChange = (name, value) => {
        setDesciption(value)
    }
    if (details === undefined) return null
    return (
        <EditDialog open={open} title={t('description')} handleSave={handleSave} handleClose={handleClose}>
            <DialogTextField onValueChange={handleChange} value={description} />
        </EditDialog>
    )
}


const DeviceDetailDialog = ({ details, setDetails = new Function(), dialogState = {}, setDialogState }) => {

    const snack = useSnack()
    const { t } = useLocale()
    const api = useApi()
    const id = dialogState.id
    const open = dialogState.open
    const title = dialogState.title
    const confirmValue = dialogState.confirmValue
    const defaultValue = _.get(details, id)

    const [value, setValue] = useState(confirmValue || defaultValue)

    useEffect(() => {
        setValue(defaultValue)
    }, [open])


    const handleChange = (name, value) => {
        setValue(value)
    }

    const handleClose = () => {
        setDialogState({ ...dialogState, open: false })
    }

    const handleSuccess = () => {
        document.getElementById("app").scroll(0, 0)
        handleClose()
    }

    const handleSave = () => {
        if (confirmValue !== undefined) {
            api.updateDeviceDetails({ [id]: confirmValue }, setDetails).then(handleSuccess)
        }
        else if (value != null) {
            if (value === defaultValue) {
                handleSuccess()
            }
            else {
                api.updateDeviceDetails({ [id]: value }, setDetails).then(handleSuccess)
            }
        }
    }


    if (details === undefined) return null
    if (confirmValue !== undefined) return (
        <EditDialog open={open} title={title} handleSave={handleSave} handleClose={handleClose} confirmText={t('confirm')}>
            {t('are_you_sure')}
        </EditDialog>
    )
    return (
        <EditDialog open={open} title={title} handleSave={handleSave} handleClose={handleClose}>
            <DialogTextField onValueChange={handleChange} value={value} />
        </EditDialog>
    )
}

export default DeviceDetailDialog


