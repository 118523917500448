import { Box, Button, FormHelperText, Grid, IconButton, makeStyles, Paper, Toolbar, Tooltip, Typography, useTheme } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import clsx from 'clsx'
import React, { Fragment, useMemo, useEffect, useState, useRef, useLayoutEffect } from 'react'
import HelpPopHover from './HelpPopHover'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { withRouter } from 'react-router-dom'
import { useLocale } from '../provider/LocaleProvider'

const moment = require('moment-timezone')
const useStyles = makeStyles(theme => ({
    grid_container: {
        padding: '12px 24px',
        overflow: 'hidden'
    },
    grid_value: {
        // minWidth: 'min(300px,calc(100% - 32px))',
        '& > p': {
            placeSelf: 'center'
        }
    },
    text_name: {
        alignItems: 'start',
        minWidth: 'min(300px,100%)',
        maxWidth: '220px',
        paddingRight: '16px'
    },
    text_body: {
        alignItems: 'center',
        lineHeight: '24px',
        maxHeight: '96px',
        overflow: 'hidden',
        overflowWrap: 'break-word'
    },
    divider: {
        marginTop: '8px',
        marginBottom: '16px'
    },
    button: {
        width: '80px',
    },
    title: {
        padding: '12px 24px 12px 24px',
        fontWeight: 'bold'
    },

    textField: {
        maxWidth: '300px'
    },
    paper: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        overflow: 'hidden'
    }
}))


export const DataRow = ({ name, children, helpText, description, handleEdit, noWrap, alignSelf, ...rest }) => {

    const { t } = useLocale()
    const classes = useStyles()
    const theme = useTheme()

    return useMemo(() => (
        <Grid className={classes.grid_container} container spacing={2} >
            <Grid item xs={12} sm={4} style={{ alignSelf: alignSelf ? alignSelf : 'center' }}>
                <Box className={clsx(classes.text_name, 'flexRow')} >

                    {<Typography variant='body1' color='textSecondary' noWrap >{name}  </Typography>}
                    {helpText && <HelpPopHover text={helpText} style={{ color: theme.palette.text.secondary, height: '16px' }} />}
                </Box>
                {description && <FormHelperText>{description}  </FormHelperText>}
            </Grid>
            <Grid item xs={12} sm className={`flexRow ${classes.grid_value}`} {...rest}>
                {children}
                {handleEdit &&
                    <Tooltip title={t('edit')}>
                        <IconButton onClick={handleEdit} style={{ padding: 0, marginLeft: '8px' }}>
                            <EditIcon style={{ height: '24px' }} />
                        </IconButton>
                    </Tooltip>
                }
            </Grid>
        </Grid >
    ))
}

const BackButtonRouter = props => {
    const classes = useStyles()
    const { t } = useLocale()
    const { history } = props
    return (
        <Button
            variant="contained"
            color="secondary"
            size='large'
            // className={classes.button}
            onClick={() => history.goBack()}
            startIcon={<ArrowBackIcon />}
        >
            {t('back')}
        </Button>
    )
}

export const BackButton = withRouter(BackButtonRouter)

export const TextDataRow = ({ children, value, noWrap, nullText, ...rest }) => {
    const { t } = useLocale()

    const classes = useStyles()
    const theme = useTheme()
    if (value && typeof value == 'string' || typeof value == 'boolean' || typeof value == 'number')
        return (
            <DataRow {...rest}>
                <Typography variant='body1' className={classes.text_body} noWrap={noWrap} >
                    {value.toString() || children || nullText}
                </Typography>
            </DataRow>
        )
    return (
        <DataRow {...rest}>
            <Typography variant='body1' className={classes.text_body} noWrap={noWrap} >
                {nullText || t('n/a')}
            </Typography>
        </DataRow>
    )
}

export const DataTypography = ({ value, handleEdit, ...rest }) => {
    const classes = useStyles()
    const { t } = useLocale()
    if (handleEdit) {
        return (
            <div className='flexRow' style={{ alignItems: 'center' }}>
                <Typography variant='body1' className={classes.text_body} noWrap {...rest} >
                    {value && value.toString() ||  t('n/a')}
                </Typography>
                {handleEdit &&

                    <Tooltip title={t('edit')}>
                        <IconButton onClick={handleEdit} style={{ padding: 0, marginLeft: '8px', height: '24px', width: '24px' }}>
                            <EditIcon style={{ height: '20px' }} />
                        </IconButton>
                    </Tooltip>

                }
            </div>
        )
    }
    return (
        <Typography variant='body1' className={classes.text_body} noWrap {...rest} >
            {value && value.toString() || t('n/a')}
        </Typography>
    )
}

export const DataSessionSubtitle = ({ title, handleEdit, ...rest }) => {
    const classes = useStyles()
    const { t } = useLocale()

    const subtitle = title || rest.label || rest.value
    return (
        <Fragment>
            <div className='flexRow' style={{ alignItems: 'center' }}>
                <Typography noWrap variant='h6' className={classes.title}>{subtitle}</Typography>

                {handleEdit &&
                    <Tooltip title={t('edit')}>
                        <IconButton onClick={handleEdit} style={{ padding: 0, marginLeft: '8px', height: '32px', width: '32px' }}>
                            <EditIcon style={{ height: '24px' }} />
                        </IconButton>
                    </Tooltip>
                }

            </div>


        </Fragment>
    )
}

export const DataSession = ({ children, title, action, ...rest }) => {
    const classes = useStyles()
    const theme = useTheme()

    return (
        <Box className='flexCol' width='100%' {...rest}>
            {title &&
                <Toolbar variant='dense'>
                    <Typography noWrap variant='h5' color='primary' >{title}</Typography>
                    <Box flex={1} />
                    {action}
                </Toolbar>
            }
            <Paper className={clsx('flexCol', classes.paper)} >
                {children}
            </Paper>
        </Box>
    )
}


