
import { Box, isWidthDown, makeStyles, Paper, Typography, useTheme, withWidth } from '@material-ui/core'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import React, { useContext, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { useCampaigns } from '../Campaigns'
import AddIcon from '@material-ui/icons/Add'
import { withRouter } from 'react-router-dom'
import { useLocale } from '../../../../provider/LocaleProvider'


const useStyles = makeStyles(theme => ({
    gridList: {
        boxSizing: 'border-box',
        alignContent: 'baseline',
    },
    gridTile: {
        height: '200px',
        minWidth: '50px',
        minHeight: '50px',
        overflow: 'visible',
    },
    paper_add: {
        borderRadius: theme.spacing(0.5),
    },
    paper: {
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(2),
        paddingTop: theme.spacing(5),
        alignContent: 'flex-start'
    }
}))

const CampaignList = props => {

    const { history } = props
    const { t } = useLocale()
    const ref = useRef(null)
    const classes = useStyles()
    const [cols, setCols] = useState()


    const context = useCampaigns()
    const list = context.campaignList
    const drawer = context.drawer

    useEffect(() => {
        handleResize()
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener('keydown', handleResize)
        }
    }, [])


    async function handleResize() {
        if (ref.current != null) {
            const rect = ref.current.getBoundingClientRect()
            if (rect.width > 1200) {
                setCols(3)
            }
            else if (rect.width > 900) {
                setCols(3)
            }
            else if (rect.width > 600) {
                setCols(2)
            }
            else {
                setCols(1)
            }
        }
        else {
            setTimeout(handleResize, 100)
        }
    }


    function handleAdd() {
        drawer.setOpen(true)
    }

    function handleClick(camp) {
        // localStorage.setItem("campaign_id", camp.id)
        history.push(`campaigns/${camp.id}/info`)
    }

    if (!list) return null
    return (
        <div ref={ref} style={{ visibility: cols == null ? 'hidden' : 'visible' }}>
            <GridList className={classes.gridList} cols={cols} cellHeight='auto' spacing={24} >
                <GridListTile key='tile_add' cols={1} classes={{ tile: classes.gridTile }}  >
                    <Paper className={clsx('flexCol-center', 'fullSize', classes.paper_add)} elevation={1} onClick={handleAdd}  >
                        <AddIcon color='primary' style={{ width: '100px', height: '100px' }} />
                        <Typography variant='h6' color='primary'>{t('add_new')}</Typography>
                    </Paper>
                </GridListTile>
                {list.map((tile, index) => {
                    return (
                        <GridListTile key={`tile_${index}`} cols={1} classes={{ tile: classes.gridTile }}  >
                            <Paper className={clsx('flexCol', 'fullSize', classes.paper)} onClick={() => handleClick(tile)} elevation={1}>
                                <Typography variant='h6' paragraph className='bold' style={{ maxHeight: "40px", lineHeight: '22px', overflow: 'hidden' }}>
                                    {tile.name}</Typography>
                                <Typography variant='body1' color='textSecondary' noWrap>  {tile.id}</Typography>
                                <Box position='absolute' right={10} top={5} padding='2px' >
                                    <Typography variant='body1' className='bold' style={{ color: tile.is_active ? 'limegreen' : 'red' }} noWrap>
                                        {tile.is_active ? t('active') : t('inactive')}
                                    </Typography>
                                </Box>
                            </Paper>
                        </GridListTile>
                    )
                })}
            </GridList >
        </div >


    )
}


export default withWidth()(withRouter(CampaignList))