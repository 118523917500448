import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, InputAdornment, makeStyles, Radio, RadioGroup, TextField, useTheme } from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import TokenRuleConfigure from 'components/TokenRuleConfigure/TokenRuleConfigure'
import { useCampaign } from 'provider/CampaignProvider'
import RedeemTokenRuleForm from 'components/RedeemTokenRuleForm'
import { useLocale } from 'provider/LocaleProvider'
import { Form, useFormik, useFormikContext } from 'formik'
import { DataRow, DataSession, DataSessionSubtitle } from 'components/DataSession'
import DatePicker from 'components/DatePicker'
import { CheckBox } from '@material-ui/icons'
import RadioList from 'components/RadioList'
import DataChipInput from 'components/DataChipInput'
import FormikTextField from 'components/FormikMaterialUI/FormikTextField'
const moment = require('moment-timezone')


const _ = require('lodash')

const useStyles = makeStyles(theme => ({
    form: {
        '& .MuiDivider-root': {
            marginTop: '8px',
            marginBottom: '16px'
        },
        '& .MuiFormControlLabel-root': {
            minWidth: '150px'
        },
        '& .MuiTextField-root': {
            width: 'min(400px, 100%)'
        }
    },
    quota: {
        width: '100%',
        '& .MuiTextField-root': {
            display: 'block',
            marginTop: '8px',
            marginBottom: '16px'
        }

    }
}))
const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const StartPeriod = () => {
    const { t } = useLocale()

    const formik = useFormikContext()
    const { values, touched, errors, handleChange, setFieldValue, setFieldTouched, isValid } = formik
    const { campaign } = useCampaign()

    const handleDateChange = (value) => {
        var date = moment(value).toISOString()
        setFieldValue('redeem_token_rule.started_at', date)
    }
    useEffect(() => {
        if (values.has_start_date == 'false') {
            setFieldValue('redeem_token_rule.started_at', null)
        }
    }, [values.has_start_date])

    var error = _.get(errors, 'redeem_token_rule.started_at')

    return (
        <FormControl component="fieldset">
            <RadioGroup row color='primary' name='has_start_date' value={_.get(values, 'has_start_date')} onChange={handleChange}>
                <FormControlLabel value={'false'} control={<Radio />} label={t('start_immediately')} />
                <FormControlLabel value={'true'} control={<Radio />} label={
                    <DatePicker minDate={moment(campaign.created_at).toDate()} disabled={_.get(values, 'has_start_date') == 'false'} value={_.get(values, 'redeem_token_rule.started_at')} handleChange={handleDateChange} />
                } />
            </RadioGroup>
            {error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
    )
}

const EndPeriod = () => {
    const { t } = useLocale()
    const { campaign } = useCampaign()

    const formik = useFormikContext()
    const { values, touched, errors, handleChange, setFieldValue, setFieldTouched, isValid } = formik

    const handleDateChange = (value) => {
        var date = moment(value).toISOString()
        setFieldValue('redeem_token_rule.expired_at', date)
    }
    var error = _.get(errors, 'redeem_token_rule.expired_at')

    useEffect(() => {

        if (values.has_end_date == 'false') {
            setFieldValue('redeem_token_rule.expired_at', null)
        }
    }, [values.has_end_date])


    return (
        <FormControl component="fieldset">
            <RadioGroup row color='primary' name='has_end_date' value={_.get(values, 'has_end_date')} onChange={handleChange}>
                <FormControlLabel value={'false'} control={<Radio />} label={t('n/a')} />
                <FormControlLabel value={'true'} control={<Radio />} label={
                    <DatePicker minDate={moment(campaign.created_at).toDate()} disabled={_.get(values, 'has_end_date') == 'false'} value={_.get(values, 'redeem_token_rule.expired_at')} handleChange={handleDateChange} />
                } />
            </RadioGroup>
            {error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
    )
}


const ExpiredAfter = () => {
    const { t } = useLocale()

    const formik = useFormikContext()
    const { values, setFieldValue, handleChange } = formik
    const name = 'redeem_token_rule.expiry_seconds'
    const value = _.get(formik.values, name)

    useEffect(() => {
        if (values.has_expiry_seconds == 'false') {
            setFieldValue('redeem_token_rule.expiry_seconds', null)
        }
    }, [values.has_expiry_seconds])


    const error = _.get(formik.errors, name)


    return (
        <FormControl component="fieldset">
            <RadioGroup row color='primary' name='has_expiry_seconds' value={_.get(values, 'has_expiry_seconds')} onChange={handleChange}>
                <FormControlLabel value={'false'} control={<Radio />} label={t('n/a')} />
                <FormControlLabel value={'true'} control={<Radio />} label={
                    <TextField
                        disabled={values.has_expiry_seconds == 'false'}
                        name={name}
                        value={_.isNil(value) ? '' : value}
                        onChange={formik.handleChange}
                        type='number'
                        inputProps={{
                            min: 1
                        }}
                        InputProps={{
                            min: 1,
                            endAdornment: <InputAdornment position='end'>{t('seconds')}</InputAdornment>
                        }}
                    />
                } />
            </RadioGroup>
            {error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
    )
}

const GenerateTokenRule = props => {
    const classes = useStyles()

    const { t } = useLocale()
    const theme = useTheme()
    const formik = useFormikContext()
    const { values, touched, errors, handleChange, handleBlur, setFieldValue, setFieldTouched, isValid } = formik

    useEffect(() => {
        var rule = values.redeem_token_rule
        if (rule) {
            if (values.has_start_date === null) {
                setFieldValue('has_start_date', _.toString(!_.isNil(rule.started_at)))
            }
            if (values.has_end_date === null) {
                setFieldValue('has_end_date', _.toString(!_.isNil(rule.expired_at)))
            }
            if (values.has_expiry_seconds === null) {
                setFieldValue('has_expiry_seconds', _.toString(!_.isNil(rule.expiry_seconds)))
            }
        }
    }, [values.redeem_token_rule])

    useEffect(() => {
        if (values.has_start_date == 'false') {
            setFieldValue('redeem_token_rule.started_at', null)
        }
    }, [values.has_start_date])

    useEffect(() => {

        if (values.has_end_date == 'false') {
            setFieldValue('redeem_token_rule.expired_at', null)
        }
    }, [values.has_end_date])

    useEffect(() => {
        if (values.has_expiry_seconds == 'false') {
            setFieldValue('redeem_token_rule.expiry_seconds', null)
        }
    }, [values.has_expiry_seconds])

    const handleReset = () => {
        setFieldValue('step', 0)
        setFieldValue('mode', null)
    }

    const handleBack = () => {
        setFieldValue('step', values.step - 1)
    }

    const handleNext = async (e) => {
        e.preventDefault()
        var error = await formik.validateForm()
        console.log('handleNext => error', error)
        if (_.keys(error) == 0) {
            setFieldValue('step', values.step + 1)
        }
        else {
            document.getElementById('app').scrollTo(0, 0)
        }
    }

    return (
        <Form onSubmit={handleNext} className={classes.form} style={{ height: '100%', display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
            <DataSession title={t('redeem_token_rule')} flexGrow={1}>
                <DataSessionSubtitle title={t('validity_period')} />
                <DataRow name={t('start_at')}>
                    <StartPeriod formik={formik} />
                </DataRow>
                <DataRow name={t('expired_date')}>
                    <EndPeriod formik={formik} />
                </DataRow>
                <DataRow name={t('expired_after')}>
                    <ExpiredAfter formik={formik} />

                </DataRow>
                <Divider className={classes.divider} />
                <DataSessionSubtitle title={t('redeem_period')} />
                <DataRow name={t('conditions')} >
                    <FormControl component="fieldset">
                        <RadioGroup row color='primary'
                            name='redeem_token_rule.redeem_date_conditions.type'
                            value={_.get(values, 'redeem_token_rule.redeem_date_conditions.type')}
                            onChange={handleChange}
                        >
                            <FormControlLabel value={'exclude'} control={<Radio />} label={t('exclude')} />
                            <FormControlLabel value={'include'} control={<Radio />} label={t('include')} />
                        </RadioGroup>
                    </FormControl>
                </DataRow>
                <DataRow name={t('weekdays')}>
                    <FormGroup row color='primary' >
                        {weekdays.map(d => {
                            const name = 'redeem_token_rule.redeem_date_conditions.weekdays'
                            const checked = _.includes(_.get(values, name), d)
                            return <FormControlLabel label={t(d)} control={<Checkbox type='checkbox' checked={checked} name={name} value={d} onChange={handleChange} />} />
                        })}
                    </FormGroup>
                </DataRow>
                <DataRow name={t('months')}>
                    <FormGroup row color='primary'>
                        {months.map(d => {
                            const name = 'redeem_token_rule.redeem_date_conditions.months'
                            const checked = _.includes(_.get(values, name), d)
                            return <FormControlLabel label={t(d)} control={<Checkbox type='checkbox' checked={checked} name={name} value={d} onChange={handleChange} />} />
                        })}
                    </FormGroup>
                </DataRow>
                <DataRow name={t('dates')}>
                    <DataChipInput
                        tags={_.get(values, 'redeem_token_rule.redeem_date_conditions.dates', [])}
                        setTags={v => {
                            console.log('v', v)

                            setFieldValue('redeem_token_rule.redeem_date_conditions.dates', v)
                        }}
                        isValid={(date) => {
                            return moment(date, 'YYYY-MM-DD', true).isValid()
                        }}
                        regex={/[^0-9,-]/g}
                        helperText={t('dates_chip_helper')}
                    />
                </DataRow>
                <Divider className={classes.divider} />
                <DataSessionSubtitle title={t('redeem_token_config')} />
                <DataRow name={t('has_reward')} >
                    <FormControl component="fieldset">
                        <RadioGroup row color='primary'
                            name='redeem_token_rule.has_reward'
                            value={_.toString(_.get(values, 'redeem_token_rule.has_reward'))}
                            onChange={(e) => setFieldValue(e.target.name, Boolean(e.target.value == 'true'))}
                        >
                            <FormControlLabel value={'true'} control={<Radio />} label={t('yes')} />
                            <FormControlLabel value={'false'} control={<Radio />} label={t('no')} />
                        </RadioGroup>
                    </FormControl>
                </DataRow>
                <DataRow name={t('is_reusable')} >
                    <FormControl component="fieldset">
                        <RadioGroup row color='primary'
                            name='redeem_token_rule.is_reusable'
                            value={_.toString(_.get(values, 'redeem_token_rule.is_reusable'))}
                            onChange={(e) => setFieldValue(e.target.name, Boolean(e.target.value == 'true'))}
                        >
                            <FormControlLabel value={'true'} control={<Radio />} label={t('yes')} />
                            <FormControlLabel value={'false'} control={<Radio />} label={t('no')} />
                        </RadioGroup>
                    </FormControl>
                </DataRow>
                <DataRow name={t('redeem_quota')} alignSelf='baseline'>
                    <div className={classes.quota} >
                        {['overall', 'yearly', 'monthly', 'weekly', 'daily'].map(type => {
                            return (
                                <FormikTextField
                                    placeholder={t('n/a')}
                                    formik={formik}
                                    disabled={!_.get(values, 'redeem_token_rule.is_reusable')}
                                    fullWidth
                                    type='number'
                                    label={t(type)}
                                    name={`redeem_token_rule.redeem_quota.${type}`}
                                />
                            )
                        })}
                    </div>
                </DataRow>
                <DataRow name={t('token_identifier')} helpText={t('token_identifier_help')}>
                    <FormikTextField
                        placeholder={t('n/a')}
                        formik={formik}
                        name='redeem_token_rule.token_identifier'
                    />
                </DataRow>
                <DataRow name={t('redeem_pin')} helpText={t('redeem_pin_helper')}>
                    <FormikTextField
                        placeholder={t('n/a')}
                        formik={formik}
                        name='redeem_token_rule.redeem_pin'
                    />
                </DataRow>
            </DataSession>
            <Box display='flex' style={{ marginTop: '24px' }}>
                <Button onClick={handleReset} variant="contained" style={{ color: 'white', backgroundColor: theme.palette.error.main }}>
                    {t('cancel')}
                </Button>
                <Box flexGrow={1} />
                <Button variant="outlined" onClick={handleBack}  >
                    {t('back')}
                </Button>
                <Button
                    type='submit'
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: '8px' }}
                    onClick={handleNext}
                >
                    {t('next')}
                </Button>
            </Box>
        </Form>
    )
}

export default GenerateTokenRule