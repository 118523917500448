// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import useApi from 'api/useApi'
import { useLocale } from 'provider/LocaleProvider'
import { makeStyles } from '@material-ui/core'


const useStyles = makeStyles(theme => ({
    inputRoot: {
        padding: '4px !important',
        margin: '8px 0'
    }
}))


export default function Asynchronous({
    id = 'id',
    label = '',
    handleGetOption = new Function(),
    placeholder,
    getOptionLabel = (option) => option.name || '',
    ...rest
}) {
    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState([])
    console.log('options', options)
    const loading = open && options.length === 0
    const classes = useStyles()
    const api = useApi()
    const { t } = useLocale()
    useEffect(() => {
        let active = true

        if (loading) {
            handleGetOption(setOptions)

        }

        return () => {
            active = false
        }
    }, [loading])


    return (
        <Autocomplete
            id="asynchronous-demo"
            open={open}
            onOpen={() => {
                setOpen(true)
            }}
            onClose={() => {
                setOpen(false)
            }}
            fullWidth
            getOptionSelected={(option, value) => {
                if (value && option)
                    return option[id] === value[id]
                return false
            }
            }
            classes={{
                inputRoot: classes.inputRoot
            }}
            getOptionLabel={getOptionLabel}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    placeholder={placeholder || t('not_set')}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
            {...rest}
        />
    )
}
