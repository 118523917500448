import { Box, Button, Divider, FormControl, FormControlLabel, FormLabel, Grid, GridList, GridListTile, IconButton, isWidthDown, makeStyles, Paper, Radio, RadioGroup, TextField, Tooltip, Typography, useTheme, withWidth } from '@material-ui/core'
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { NavLink, useParams } from 'react-router-dom'
import DataTable from 'components/DataTable/DataTable'
import useApi from 'api/useApi'
import { useLocale } from 'provider/LocaleProvider'
import MetaSelecter from 'components/DataTableWithMeta/components/MetaSelecter'
import RefreshIcon from '@material-ui/icons/Refresh'
import EditIcon from '@material-ui/icons/Edit'
import CropFreeIcon from '@material-ui/icons/CropFree'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import ClearIcon from '@material-ui/icons/Clear'
import { DataRow, DataSession, DataSessionSubtitle, DataTypography, TextDataRow } from 'components/DataSession'

import MetaTable from 'components/MetaTable/MetaTable'
import ThemeDetailDialog from './ThemeDetailDialog'
import ThemeDeleteDialog from './ThemeDeleteDialog'
import QRCodeDrawer from './qr_code/QRCodeDrawer'
import SlideshowDrawer from './slideshow/SlideshowDrawer'
import MessageDrawer from './message/MessageDrawer'
import GeneralDrawer from './general/GeneralDrawer'
import config from 'misc/config'
const moment = require('moment-timezone')


const _ = require('lodash')
const useStyles = makeStyles(theme => ({

}))


const Upload = ({ render }) => {

    const a = 1
    const b = 2


    return render({ a, b })
}
const time = str => {
    if (!str) return null
    return moment(str).format(config.timeformat)
}
const ThemeDetails = props => {
    const { } = props
    const classes = useStyles()
    const [themeDetails, setThemeDetails] = useState()
    const details = themeDetails || {}
    const qr_code = details.qr_code || {}
    const idle_page = details.idle_page || {}
    const messages = details.messages || {}
    const { t } = useLocale()
    const userRequest = useApi()


    const [dialogState, setDialogState] = useState({
        open: false,
        id: '',
        title: '',
        confirmValue: undefined
    })

    const [deleteDialog, setDeleteDialog] = useState(false)
    const [qrcodeDrawer, setQrcodeDrawer] = useState(false)
    const [slideDrawer, setSlideDrawer] = useState(false)
    const [generalDrawer, setGeneralDrawer] = useState(false)
    const [messageDrawer, setMessageDrawer] = useState({ open: false, type: 'campaign_invalid' })

    useEffect(() => {
        userRequest.getThemeDetails(setThemeDetails)
    }, [])



    function handleEditName() {
        setDialogState({ open: true, id: 'name', title: t('theme_name') })
    }





    const DetailShowcase = () => {
        if (themeDetails === undefined) return null
        return (
            <DataSession title={t('theme_detail')}>
                <TextDataRow name={t('theme_id')} value={details.id} />
                <TextDataRow name={t('theme_name')} value={details.name} handleEdit={handleEditName} />
                <TextDataRow name={t('created_at')} value={time(details.created_at)} />
                <TextDataRow name={t('updated_at')} value={time(details.updated_at)} />
            </DataSession>
        )
    }

    function getMessage() {

    }

    const ThemeContent = () => {
        return (
            <Fragment>
                {/* <Upload render={(props) => {

                }} /> */}
                <DataSession title={t('layout_and_content')}>
                    {/* <DataSessionSubtitle label={t('status_bar')} handleEdit={() => setGeneralDrawer(true)} />
                    <DataRow name={t('background_color')} alignSelf='baseline'  >
                        <input type='color' disabled value={details.background_color} />
                        <DataTypography value={details.background_color} style={{ marginLeft: '4px' }} />
                    </DataRow>
                    <DataRow name={t('loader_color')} alignSelf='baseline'  >
                        <input type='color' disabled value={details.loader_color} />
                        <DataTypography value={details.loader_color} style={{ marginLeft: '4px' }} />
                    </DataRow> */}
                    <DataSessionSubtitle label={t('color_theme')} handleEdit={() => setGeneralDrawer(true)} />
                    <DataRow name={t('background_color')} alignSelf='baseline'  >
                        <input type='color' disabled value={details.background_color} />
                        <DataTypography value={details.background_color} style={{ marginLeft: '4px' }} />
                    </DataRow>
                    <DataRow name={t('loader_color')} alignSelf='baseline'  >
                        <input type='color' disabled value={details.loader_color} />
                        <DataTypography value={details.loader_color} style={{ marginLeft: '4px' }} />
                    </DataRow>
                    <DataSessionSubtitle label={t('qr_code')} handleEdit={() => setQrcodeDrawer(true)} />
                    <TextDataRow name={t('status')} value={qr_code.is_active ? t('active') : t('inactive')} />
                    <DataRow name={t('background_color')} alignSelf='baseline'  >
                        <input type='color' disabled value={qr_code.background_color} />
                        <DataTypography value={qr_code.background_color} style={{ marginLeft: '4px' }} />
                    </DataRow>
                    <DataRow name={t('text_color')} alignSelf='baseline'  >
                        <input type='color' disabled value={qr_code.text_color} />
                        <DataTypography value={qr_code.text_color} style={{ marginLeft: '4px' }} />
                    </DataRow>
                    <TextDataRow name={t('code')} value={qr_code.code} />
                    <TextDataRow name={t('display_text')} value={qr_code.display_text} />
                    <DataSessionSubtitle label={t('idle_page')} handleEdit={() => setSlideDrawer(true)} />
                    <TextDataRow name={t('image_duration')} value={idle_page.image_duration} />
                    <DataRow name={t('media')} alignSelf='baseline'  >
                        <div>
                            {idle_page.contents && idle_page.contents.length > 0 ?
                                idle_page.contents.map((slide, index) => {
                                    return <DataTypography value={`${index + 1}. ${slide.file_name} (${slide.type})`} style={{ marginBottom: '4px' }} />

                                })
                                : t('no_media')
                            }
                        </div>
                    </DataRow>

                </DataSession>
                <DataSession title={t('messages')}>
                    <DataSessionSubtitle label={t('messages')} />
                    <TextDataRow name={t('message_duration')} value={_.get(messages, 'message_duration')} handleEdit={() => {
                        setDialogState({ open: true, id: 'messages.message_duration', title: t('message_duration'), type: 'number' })
                    }} />
                    {
                        ['campaign_invalid',
                            'device_invalid',
                            'failed_drop',
                            'no_redeem_quota',
                            'redeem_success',
                            'server_timeout',
                            'token_expired',
                            'token_invalid',
                            'token_redeemed',
                        ].map((type, index) => {
                            function createMessage(value) {
                                var m = value || {}
                                return ({
                                    ...m,
                                    is_active: m.is_active || false,
                                    display_text: m.display_text || '-',
                                    image_name: m.image_name || t('no_image'),
                                    background_color: m.background_color || '#ffffff',
                                    text_color: m.text_color || '#000000',
                                })

                            }
                            const message = createMessage(messages[type])
                            return (
                                <Fragment key={index}>
                                    <DataSessionSubtitle label={t(type)} handleEdit={() => setMessageDrawer({ open: true, type: type })} />
                                    <TextDataRow name={t('status')} value={message.is_active ? t('active') : t('inactive')} />
                                    {/* <TextDataRow name={t('display_text')} value={message.display_text} /> */}
                                    <TextDataRow name={t('image_name')} value={message.image_name} />
                                    <TextDataRow name={t('scale_type')} value={t(message.scale_type)} />
                                    {/* <DataRow name={t('background_color')} alignSelf='baseline'  >
                                        <input type='color' disabled value={message.background_color} />
                                        <DataTypography value={message.background_color} style={{ marginLeft: '4px' }} />
                                    </DataRow> */}
                                    {/* <DataRow name={t('text_color')} alignSelf='baseline'  >
                                        <input type='color' disabled value={message.text_color} />
                                        <DataTypography value={message.text_color} style={{ marginLeft: '4px' }} />
                                    </DataRow> */}
                                </Fragment>
                            )
                        })
                    }

                </DataSession>
            </Fragment>

        )
    }


    if (!themeDetails) return <Box height='120vh' />
    return (
        <Fragment>
            <Box pb={3}>
                <Typography color="inherit" variant="h4" className='bold'>
                    {t('theme')}
                </Typography>
            </Box>
            <ThemeDeleteDialog details={themeDetails} setDetails={setThemeDetails} open={deleteDialog} setOpen={setDeleteDialog} />
            <ThemeDetailDialog details={themeDetails} setDetails={setThemeDetails} dialogState={dialogState} setDialogState={setDialogState} />
            <GeneralDrawer open={generalDrawer} setOpen={setGeneralDrawer} detail={themeDetails} setDetail={setThemeDetails} />
            <QRCodeDrawer open={qrcodeDrawer} setOpen={setQrcodeDrawer} detail={themeDetails} setDetail={setThemeDetails} />
            <MessageDrawer message_type={messageDrawer.type} open={messageDrawer.open} setOpen={(v) => setMessageDrawer({ ...messageDrawer, open: v })} detail={themeDetails} setDetail={setThemeDetails} />
            <SlideshowDrawer open={slideDrawer} setOpen={setSlideDrawer} detail={themeDetails} setDetail={setThemeDetails} />
            {DetailShowcase()}
            {ThemeContent()}
            {/* <MetaTable metadata={details.metadata} update={handleUpdateMetadata} action /> */}
            <Button
                variant="contained"
                size="large"
                className={classes.statusButton}
                // startIcon={<DeleteIcon />}
                onClick={() => setDeleteDialog(true)}
                style={{ backgroundColor: 'red', color: 'white', marginTop: '24px' }}
            >
                {t('delete_theme')}
            </Button>
        </Fragment>
    )
}

export default ThemeDetails