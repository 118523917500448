
import React, { Fragment, useEffect, useState } from 'react'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Box, Button, CardMedia } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
const useStyles = makeStyles((theme) => ({
    box: {
        display: 'flex',
        alignItems: 'center',
        width: '20px',
        boxSizing: 'border-box',
        height: '100%'
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
        color: 'white',
        backgroundColor: '#18202c'
    },
}))

const HelpPopHover = (props) => {


    const { text, ...rest } = props
    const classes = useStyles()

    const [anchorEl, setAnchorEl] = React.useState(null)

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }





    const open = Boolean(anchorEl)


    return (
        <Fragment>
            <div
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                className={classes.box}
                {...rest}
            >
                <HelpOutlineIcon className='fullSize' />
            </div>
            {text &&
                <Popover
                    id="mouse-over-popover"
                    className={classes.popover}
                    classes={{
                        paper: classes.paper,
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    {text}
                </Popover>
            }
        </Fragment>
    )
}

export default HelpPopHover