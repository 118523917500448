import React, { Fragment, useEffect, useState } from 'react'
import DataTable from 'components/DataTable/DataTable'
import useApi from 'api/useApi'
import useGachaDeviceApi from 'api/useGachaDeviceApi'
import { useLocale } from 'provider/LocaleProvider'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import { Container, Box, Table, IconButton, makeStyles, Divider, TableHead, TableBody, TableRow, TableCell, CircularProgress, Grid } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { Card } from '@material-ui/core'
import 'theme/animation.css'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { useSnack } from 'provider/SnackbarProvider'
import { DataRow, DataSession, TextDataRow } from 'components/DataSession'
import { useDevice } from 'provider/DeviceProvider'

const _ = require('lodash')



const moment = require('moment-timezone')


const useStyles = makeStyles(theme => ({

}))

const StockValue = ({ stock }) => {
    const { t } = useLocale()
    if (stock === true) {
        return <span style={{ color: 'limegreen' }}>{t('available')}</span>
    }
    else if (stock === false) {
        return <span style={{ color: 'red' }}>{t('out_of_stock')}</span>
    }
    else {
        return <span>{t('no_data')}</span>
    }
}


const DevicePanel = props => {
    const { } = props
    const snack = useSnack()
    const classes = useStyles()

    const { device } = useDevice()


    const [deviceStatus, setDeviceStatus] = useState()
    const [submitting, setSubmitting] = useState({})
    const { t } = useLocale()

    const gachaApi = useGachaDeviceApi()

    var mac = device?.machine_id
    var isOnline = deviceStatus?.status === 'idle' || deviceStatus?.status === 'running'


    useEffect(() => {
        getDeviceStatus()
    }, [device])

    const getDeviceStatus = () => {
        if (mac) {
            gachaApi.getGachaDeviceStatus(mac).then(res => {
                setDeviceStatus(res.data)
            })
        }

    }

    const handleRemoteRedeem = (row) => {
        var isSubmitting = submitting[row.id]
        if (!isSubmitting) {
            setSubmitting(s => ({ ...s, [row.id]: true }))
            gachaApi.remoteRedeem(mac, row.id).then(res => {
                if (res.data.status !== 'success') throw res.data.status
                getDeviceStatus()
                snack.open('Success')
                setSubmitting(s => ({ ...s, [row.id]: false }))
            }).catch(e => {
                var err = _.capitalize(e)
                if (err) {
                    snack.error('Failed : Device ' + err)
                }
                else {
                    snack.error('Failed')
                }
                setSubmitting(s => ({ ...s, [row.id]: false }))
            })
        }
    }

    return (
        <Fragment >
            <Typography variant='h4' className='bold' paragraph>
                {t('device_panel')}
            </Typography>
            <DataSession title={t('device_status')}>
                <TextDataRow name={t('device_id')} value={device?.id} />
                <TextDataRow name={t('device_name')} value={device?.name} />
                <TextDataRow name={t('machine_id')} value={device?.machine_id} />
                {deviceStatus?.status
                    ? <TextDataRow name={t('device_panel_track_status')} value={isOnline ? t('online') : t('offline')} style={{ color: isOnline ? 'limegreen' : 'red' }} />
                    : <TextDataRow name={t('device_panel_track_status')} value={t('connecting') + '...'} />
                }

                {deviceStatus?.data &&
                    <Fragment>
                        <TextDataRow name={t('device_panel_local_ip')} value={_.get(deviceStatus, 'data.ip.0')} />
                        <DataRow name={t('device_panel_master_code')}>
                            <Box>
                                <Typography > {deviceStatus?.data?.info?.masterCode} </Typography>
                                <div>
                                    <a target='_blank' href={'https://grm.animaetech.com/api/token_qr/' + deviceStatus?.data?.info?.masterCode} style={{ textDecoration: 'underline' }}>
                                        <Box style={{ verticalAlign: 'super', display: 'inline' }}> {t('show_qr_code')}</Box>
                                        <OpenInNewIcon style={{ height: '18px' }} />
                                    </a>
                                </div>
                            </Box>
                        </DataRow>
                    </Fragment>
                }
            </DataSession>
            {deviceStatus?.data &&
                <Box mt={2}>
                    <Typography color='primary' variant='h4' paragraph>{t('track_list')} </Typography>
                    <Grid container spacing={2} style={{ flexDirection: 'row' }} >
                        <Grid item xs={12} md={4} >
                            <Card style={{ height: '100%', minHeight: '200px', maxHeight: '500px', padding: '16px' }}>
                                {_.get(device, 'device_specification.image_url') && <img src={_.get(device, 'device_specification.image_url')} style={{ height: '100%', width: '100%', objectFit: 'contain' }} />}
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={8} >
                            <DataTable
                                heads={[
                                    { id: 'id', label: t('track_id'), value: 'id', width: '50%' },
                                    {
                                        id: 'stock', label: t('has_stock'), value: 'stock', width: '50%', content: (row) => <StockValue stock={_.get(deviceStatus, 'data.info.stockValue.' + row.id)} />
                                    },
                                    {
                                        id: 'action', label: t('action'), type: 'checkbox', width: '1%', align: 'center', content: (row) => {
                                            var Icon = submitting[row.id] ? PlayCircleOutlineIcon : PlayCircleOutlineIcon
                                            return <Box mx={-1} >
                                                <IconButton color={submitting[row.id] == true ? 'primary' : ''}
                                                    onClick={() => handleRemoteRedeem(row)} style={{ padding: '4px' }}
                                                >
                                                    {submitting[row.id] == true && <CircularProgress size={30} style={{ zIndex: 99, position: 'absolute', }} />}
                                                    <PlayCircleOutlineIcon />
                                                </IconButton>
                                            </Box>
                                        }
                                    },
                                ]}
                                rowsPerPage={5}
                                rows={_.get(deviceStatus, 'data.info.trackConfig.track')}
                                _order='asc' _orderBy='id'
                            />
                        </Grid>

                    </Grid>
                </Box>

            }
        </Fragment>
    )
}

export default DevicePanel