import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core'
import { ApiContext } from '@material-ui/data-grid'
import useApi from 'api/useApi'
import useAuthApi from 'api/useAuthApi'
import Axios from 'axios'
import { useRefresh } from 'hooks/useRefresh'
import React, { useState, useEffect, useContext, createContext, } from 'react'
import { useLocation, useHistory, useParams, matchPath } from 'react-router-dom'
const AuthDataContext = createContext()
var lodash = require('lodash')


export function useAuthData() {
    const history = useHistory()
    const context = useContext(AuthDataContext)
    if (!context) return {}
    return context
}
const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}))

export default (props) => {

    const { children } = props
    const location = useLocation()
    const history = useHistory()
    const params = useParams()
    const { campaignId, deviceId, themeId } = params

    const api = useApi()

    const auth = useAuthApi()
    const [user, setUser] = useState()
    const [organizationList, setOrganizationList] = useState()
    const [organization, setOrganization] = useState()

    const token = localStorage.getItem('token')
    const organizationId = localStorage.getItem('organization')
    const authorization = `Bearer ${token}`

    const headers = { authorization: authorization, organization_id: organizationId }

    const [orgToken, setOrgToken] = useState()

    useEffect(() => {
        auth.checkOrganisations(setOrganizationList)
        auth.checkUser(setUser)
    }, [location.pathname])

    useEffect(() => {
        if (authorization && organizationId) {
            var headers = { authorization: authorization, organization_id: organizationId }
            api.getToken(headers).then(({ data }) => {
                setOrgToken(data.token)
            })
        }
    }, [authorization, organizationId])

    useEffect(() => {

        if (organizationList && organizationList.length > 0) {
            if (organizationId) {
                const match = organizationList.find(org => org.id === organizationId)
                if (match) {
                    setOrganization(match)
                    localStorage.setItem('organization', match.id)
                    return
                }
            }
            setOrganization(organizationList[0])
            localStorage.setItem('organization', organizationList[0].id)
        }
    }, [organizationList])


    function switchOrganization(organization) {
        if (organization?.id !== organizationId) {
            if (matchPath(location.pathname, { path: '/campaigns/*', exact: true })) {
                history.push('/campaigns')
            }
            else if (matchPath(location.pathname, { path: '/devices/*', exact: true })) {
                history.push('/devices')
            }
            else if (matchPath(location.pathname, { path: '/themes/*', exact: true })) {
                history.push('/themes')
            }
        }
        setOrganization(organization)
        localStorage.setItem('organization', organization.id)
    }


    const state = {
        user: user || {},
        organizationList: organizationList || [],
        organization,
        switchOrganization,
        token: orgToken,
        headers
    }

    const flag = !(!user || !organizationList || !token || !organization)


    return (
        <AuthDataContext.Provider value={state}>
            {flag && children}
        </AuthDataContext.Provider>
    )
}