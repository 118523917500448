import { Box, Button, makeStyles, Toolbar, useTheme } from '@material-ui/core'
import React, { } from 'react'
import { UploadButton } from '../../../../components/FileUploader'
import DataTable from '../../../../components/DataTable/DataTable'
import { Alert } from '@material-ui/lab'
import { useLocale } from '../../../../provider/LocaleProvider'
import { Form, useFormikContext } from 'formik'
import _ from 'lodash'
import { downloadCSv } from 'misc/Utils'
const csv = require('csvtojson')
const json2csv = require('json2csv').parse

const obj2csv = (data, fields, name) => {
    const csv = json2csv(data, { fields: fields })
    downloadCSv(name, csv)
    return csv
}

const moment = require('moment-timezone')

const useStyles = makeStyles(theme => ({

}))



const GenerateUpload = props => {



    const { values, touched, errors, validateForm, handleChange, setFieldValue, setFieldError, setFieldTouched, isValid } = useFormikContext()

    const classes = useStyles()

    const theme = useTheme()

    const { t } = useLocale()

    const handleNext = async (e) => {
        e.preventDefault()
        setFieldTouched('redeem_tokens', true, true)
        var invalid = await validateForm()
        document.getElementById("app").scroll(0, 0)
        if (!invalid.redeem_tokens) {
            setFieldValue('step', values.step + 1)
        }
    }


    async function csv2Json(string) {
        const jsonArray = await csv().fromString(string)
        var tokens = jsonArray.map(row => row.redeem_token).filter(row => !_.isNil(row))
        setFieldValue('redeem_tokens', tokens)
        setFieldTouched('redeem_tokens', true, true)

    }

    const handleCsv = (csvFile) => {
        if (csvFile) {
            const fileReader = new FileReader()
            fileReader.readAsText(csvFile)
            fileReader.onload = (e) => {
                csv2Json(e.target.result)
            }
        }
    }

    const handleReset = () => {
        setFieldValue('step', 0)
        setFieldValue('mode', null)
    }


    const handleTemplate = () => {
        obj2csv([{ token: 'token1' }, { token: 'token2' }, { token: 'token3' }], [{ label: 'redeem_token', value: row => row.token }], `redemption_token_template`)
    }

    return (
        <Form onSubmit={handleNext} style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <div style={{ flexGrow: 1 }}>
                {

                    touched.redeem_tokens && errors.redeem_tokens &&
                    <Alert variant="filled" severity="error" style={{ marginBottom: '24px' }}>
                        {errors.redeem_tokens}
                    </Alert>
                }
                <Toolbar>
                    <Box flexGrow={1} />
                    <UploadButton reset setFile={handleCsv} accept='.csv' />
                </Toolbar>
                <DataTable
                    row_id='redeem_token'
                    rows={_.map(values.redeem_tokens, i => ({ value: i }))}
                    heads={[
                        {
                            id: 'redeem_token',
                            label: t('redeem_token'),
                            value: 'value',
                            width: '300px',
                            align: 'left',
                        },
                    ]}
                    _order='asc'
                    rowsPerPageOptions={[10, 20, 50]}
                />
            </div>

            <Box display='flex' style={{ marginTop: '24px' }}>
                <Button onClick={handleReset} variant="contained" style={{ color: 'white', backgroundColor: theme.palette.error.main }}>
                    {t('cancel')}
                </Button>
                <Box flexGrow={1} />

                <Button variant="outlined" onClick={handleReset} >
                    {t('back')}
                </Button>
                <Button
                    type='submit'
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: '8px' }}
                    onClick={handleNext}
                >
                    {t('next')}
                </Button>
            </Box>
        </Form>
    )
}

export default GenerateUpload