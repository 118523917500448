import React from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import { translate } from 'react-switch-lang'

const StatusRadio = props => {
    const { newCampaign, setNewCampaign, t } = props

    function handleChange(event) {
        if (event.target.value == 'active') {
            setNewCampaign({ ...newCampaign, is_active: true })
        }
        else {
            setNewCampaign({ ...newCampaign, is_active: false })

        }
    }
    return (
        <FormControl component="fieldset">
            <RadioGroup row aria-label="position" name="position" defaultValue={newCampaign.is_active ? 'active' : 'inactive'} onChange={handleChange}>
                <FormControlLabel
                    value='active'
                    control={<Radio color="primary" />}
                    label={t('active')}
                />
                <FormControlLabel
                    value='inactive'
                    control={<Radio color="primary" />}
                    label={t('inactive')}
                />
            </RadioGroup>
        </FormControl>
    )
}

export default translate(StatusRadio)