import React, { forwardRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import HomeIcon from '@material-ui/icons/Home'
import PeopleIcon from '@material-ui/icons/People'
import DnsRoundedIcon from '@material-ui/icons/DnsRounded'
import PermMediaOutlinedIcon from '@material-ui/icons/PhotoSizeSelectActual'
import PublicIcon from '@material-ui/icons/Public'
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet'
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent'
import TimerIcon from '@material-ui/icons/Timer'
import SettingsIcon from '@material-ui/icons/Settings'
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup'
import { NavLink, useParams } from 'react-router-dom'
import { useLocale } from '../provider/LocaleProvider'


const drawerWidth = 256



const styles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    "& .MuiDrawer-paper": {
      backgroundColor: '#18202c',
      width: drawerWidth
    },
    "& .MuiTabs-root": {
      marginLeft: theme.spacing(0.5),
    },
    "& .MuiTabs-indicator": {
      height: 3,
      borderTopLeftRadius: 3,
      borderTopRightRadius: 3,
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiTab-root": {
      textTransform: 'none',
      margin: '0 16px',
      minWidth: 0,
      padding: 0,
      [theme.breakpoints.down('md')]: {
        padding: 0,
        minWidth: 0,
      },
    },
    "& .MuiIconButton-root": {
      padding: theme.spacing(0.5),
    },
    "& .MuiTooltip-tooltip": {
      borderRadius: 4,
    },
    "& .MuiDivider-root": {
      backgroundColor: '#404854',
    },
    "& .MuiListItemText-primary": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& .MuiListItemIcon-root": {
      color: 'inherit',
      // marginRight: 0,
      '& svg': {
        fontSize: 20,
      },
    },
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
    },
    "& .MuiInput-underline": {
      '&:not($disabled):not($focused):not($error):before': {
        border: 0
      },
      '&:not($disabled):not($focused):not($error):after': {
        border: 0
      },
      '&:hover:not($disabled):not($focused):not($error):after': {
        border: 0
      },
      '&:hover:not($disabled):not($focused):not($error):before': {
        border: 0
      }
    },
    "& .MuiInputBase-root": {
      color: 'red'
    },
    "& .MuiInputBase-input": {
      "&:focus": {
        backgroundColor: 'rgba(0,0,0,0)',
      }
    },

  },
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  header: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: '#4fc3f7',
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    backgroundColor: '#404854',
  },



}))






function Navigator({ onClose = new Function(), categories = [], ...rest }) {
  const classes = styles()
  const { t } = useLocale()


  return (
    <Drawer className={classes.drawer} variant="permanent" onClose={onClose} {...rest}>
      <List disablePadding>
        <ListItem
          className={clsx(classes.firebase, classes.item, classes.header)}
          component={NavLink}
          exact
          to='/home'
          style={{ minHeight: '48px' }}

        >
          <HomeIcon />
          <div style={{ fontSize: '0.875rem', marginLeft: '8px' }}>
            {t('gift_redemption_service')}
          </div>
        </ListItem>


        {categories.map(({ id, children, icon, href }) => {
          if (children) return (
            <React.Fragment key={id}>
              <ListItem className={classes.categoryHeader}>
                <ListItemText
                  classes={{
                    primary: classes.categoryHeaderPrimary,
                  }}
                >
                  {id}
                </ListItemText>
              </ListItem>
              {children.map(({ id: childId, icon, active, href }) => (
                <ListItem
                  key={childId}
                  className={classes.item}
                  component={NavLink}
                  exact
                  onClick={onClose}
                  activeClassName={clsx(classes.item, classes.itemActiveItem)}
                  to={href}
                >
                  <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: classes.itemPrimary,
                    }}
                  >
                    {childId}
                  </ListItemText>
                </ListItem>
              ))}

              <Divider className={classes.divider} />
            </React.Fragment>
          )
          else return (
            <ListItem
              className={clsx(classes.item, classes.itemCategory)}
              component={NavLink}
              exact
              onClose={onClose}
              to={href}
            >
              <ListItemIcon className={classes.itemIcon}>
                {icon}
              </ListItemIcon>
              <ListItemText
                classes={{
                  primary: classes.itemPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>

          )
        })}
      </List>
    </Drawer>
  )
}

Navigator.propTypes = {
  classes: PropTypes.object,
}

export default Navigator
