import { useEffect } from 'react'
import { useParams, withRouter } from 'react-router-dom'
import useApi from 'api/useApi'
import { Fragment } from 'react'
import { LoginHeader } from 'layout/Header'
import { Box } from '@material-ui/core'
import apiLink from 'api/apiLink'



const SignIn = (props) => {
    const { history } = props

    return (
        <div className='flexCol' style={{ width: '100%', height: '100vh' }}>
            <LoginHeader />
            <Box width='100%' height='100%'>
                <iframe src={apiLink.login_url} width="100%" height="100%"  >
                    <p>Your browser does not support iframes.</p>
                </iframe>
            </Box>
        </div >
    )
}


export default withRouter(SignIn)