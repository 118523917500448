import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import HelpIcon from '@material-ui/icons/Help'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import MenuIcon from '@material-ui/icons/Menu'
import NotificationsIcon from '@material-ui/icons/Notifications'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useCampaign } from '../provider/CampaignProvider'
import { Box, FormControl, InputBase, ListSubheader, MenuItem, Select } from '@material-ui/core'
import HeaderDrawer from './HeaderDrawer'
import SettingsIcon from '@material-ui/icons/Settings'
import { matchPath, useHistory, useLocation, useParams, withRouter } from 'react-router-dom'
import { useLocale } from '../provider/LocaleProvider'
import DashTabs from './HeaderTabs'
import useAuthApi from 'api/useAuthApi'
import { useAuthData } from 'provider/AuthDataProvider'


const lightColor = 'rgba(255, 255, 255, 0.7)'

const useStyles = makeStyles(theme => ({
  header: {
    flex: 1,
    "& .MuiTabs-root": {
      marginLeft: theme.spacing(0.5),
    },
    "& .MuiTabs-indicator": {
      height: 3,
      borderTopLeftRadius: 3,
      borderTopRightRadius: 3,
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiTab-root": {
      textTransform: 'none',
      margin: '0 16px',
      minWidth: 0,
      padding: 0,
      [theme.breakpoints.down('md')]: {
        padding: 0,
        minWidth: 0,
      },
    },
    "& .MuiIconButton-root": {
      padding: '8px',
    },
    "& .MuiTooltip-tooltip": {
      borderRadius: 4,
    },
    "& .MuiDivider-root": {
      backgroundColor: '#404854',
    },
    "& .MuiListItemText-primary": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& .MuiListItemIcon-root": {
      color: 'inherit',
      '& svg': {
        fontSize: 20,
      },
    },
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
    },
    "& .MuiInput-underline": {
      '&:not($disabled):not($focused):not($error):before': {
        border: 0
      },
      '&:not($disabled):not($focused):not($error):after': {
        border: 0
      },
      '&:hover:not($disabled):not($focused):not($error):after': {
        border: 0
      },
      '&:hover:not($disabled):not($focused):not($error):before': {
        border: 0
      }
    },
    "& .MuiInputBase-root": {
      color: 'inherit'
    },
    "& .MuiInputBase-input": {
      "&:focus": {
        backgroundColor: 'rgba(0,0,0,0)',
      }
    },
    "& .MuiToolbar-gutters": {
      padding: '0 12px',
    }
  },
  primaryBar: ({ sidebar }) => ({
    zIndex: 10,
    minWidth: 300,
    position: 'fixed',
    // paddingBottom: theme.spacing(1.5)
    [theme.breakpoints.up('md')]: {
      width: sidebar ? 'calc(100% - 255px)' : '100%',
    },
  }),
  secondaryBar: {
    zIndex: 20,
    paddingBottom: theme.spacing(1.5)
  },
  menuButton: {
    // marginLeft: -theme.spacing(1),
  },
  link: {
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
  select: {
    "&-select:focus": {
      backgroundColor: 'rgba(0,0,0,0)',
    }
  }
}))

export const PageHeader = props => {
  const { title, children } = props

  return (
    <Toolbar Toolbar style={{ marginBottom: '24px' }}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs>
          <Typography color="inherit" variant="h5" component="h1">
            {title}
          </Typography>
        </Grid>
        {children}
      </Grid>
    </Toolbar>
  )
}





const StyledTypography = withStyles({
  root: {
    '& .MuiInputBase-root': {
      color: 'red', // Text color
    },
    '& .MuiInputBase-root': {
      color: 'red', // Text color
    },
    '& .MuiInput-underline:before': {
      border: 0
    },
    '& .MuiInput-underline:hover:before': {
      border: 0
    },
    '& .MuiInput-underline:after': {
      border: 0
    },
  },
})(Typography)


const CampaignSelector = ({ onDrawerToggle = new Function() }) => {
  const campaignContext = useCampaign()
  const campaign_id = campaignContext && campaignContext.campaign_id
  const campaignList = campaignContext && campaignContext.campaignList
  const classes = useStyles()
  const location = useLocation()
  const { t } = useLocale()
  const history = useHistory()
  const params = useParams()

  function handleClick(e) {
    const value = e.target.value
    if (value == 0) {
      history.push('/campaigns')
    }
    else {
      var path = location.pathname
      var id = params.campaignId
      if (!id) return history.push('/campaigns')
      var regex = new RegExp(`(${id})`, 'g')
      path = path.replace(regex, value)
      // window.location.href = path
      history.push(`/campaigns/${value}/info`)
    }
  }

  if (campaignList && campaign_id)
    return (
      <Fragment>

        <Grid item xs>
          <Select className={classes.select} variant='standard' value={campaign_id} id="campaign-select" onChange={handleClick} style={{ backgroundColor: "inherit" }}  >
            <MenuItem MenuItem value={0} divider >
              <Typography variant="body2" >
                {t('see_all_campaigns')}
              </Typography>
            </MenuItem>
            <MenuItem disabled>
              <Typography variant="body2" >
                {t('campaigns')}
              </Typography>
            </MenuItem>
            {campaignList.map((item, index) => (
              <MenuItem key={`campaign_${index}`} value={item.id} >
                <Typography variant="body2" >
                  {item.name}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Fragment>
    )
  else return null
}






const AccountInfo = ({ drawerMode, handleDrawer }) => {
  const authData = useAuthData()
  const classes = useStyles()
  const { t } = useLocale()
  if (!authData) return null
  const { user, organization } = authData
  const backgroundColor = 'rgba(255,255,255,0.2)'

  return (
    <Grid item xs style={{ textAlign: 'right' }}>
      <Tooltip title={t('setting')} >
        <IconButton color="inherit" onClick={() => handleDrawer('setting')} style={{ backgroundColor: drawerMode === 'setting' && backgroundColor }}>
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('help')} >
        <IconButton color="inherit" onClick={() => handleDrawer('help')} style={{ backgroundColor: drawerMode === 'help' && backgroundColor }}>
          <HelpIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('account')} >
        <IconButton color="inherit" onClick={() => handleDrawer('account')} style={{ backgroundColor: drawerMode === 'account' && backgroundColor }}>
          <Avatar alt="My Avatar" style={{ width: '24px', height: '24px', backgroundColor: user.name && 'red' }}>
            {user.name && user.name.charAt(0)}
          </Avatar>
        </IconButton>
      </Tooltip>
    </Grid>
  )
}


function Header(props) {
  const { title, onDrawerToggle, sidebar, tabs, campaign, logined, drawerWidth } = props
  const classes = useStyles(props)
  const { t } = useLocale()
  const auth = useAuthApi()
  const location = useLocation()
  const [drawerMode, setDrawerMode] = useState()
  function handleDrawer(mode) {
    if (drawerMode == mode) {
      setDrawerMode(null)
    }
    else {
      setDrawerMode(mode)

    }
  }



  return (
    <React.Fragment>
      <AppBar className={`${classes.primaryBar} ${classes.header}`} color="primary" position="sticky" elevation={0} style={{ backgroundColor: '#3f4755' }}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            {sidebar && <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={onDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            }
            {campaign && <CampaignSelector onDrawerToggle={onDrawerToggle} />}
            {tabs && <DashTabs />}
            {logined && <AccountInfo drawerMode={drawerMode} handleDrawer={handleDrawer} />}
          </Grid>
        </Toolbar>
        <HeaderDrawer mode={drawerMode} setMode={setDrawerMode} />
      </AppBar>

    </React.Fragment >
  )
}
export const LoginHeader = (props) => {
  return <Header {...props} />
}

export const HomeHeader = (props) => {
  return <Header {...props} logined />
}

export const CampaignHeader = (props) => {
  return <Header   {...props} sidebar campaign logined />
}

export const DashHeader = (props) => {
  return <Header  {...props} tabs logined />
}

export const DeviceHeader = (props) => {
  return <Header  {...props} sidebar logined />
}

export default withRouter(Header)
