import { Box, Button, Divider, Drawer, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Toolbar, Typography } from '@material-ui/core'
import { Fragment, useRef, useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { min } from 'moment-timezone'
import { useLocale } from '../provider/LocaleProvider'
import { Link } from 'react-router-dom'
import useAuthApi from 'api/useAuthApi'
import { useAuthData } from 'provider/AuthDataProvider'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import apiLink from 'api/apiLink'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
    },
    drawer: {
        width: 0,
        flexShrink: 0,
    },
    drawerPaper: {
        overflow: 'hidden',
        marginBottom: '-4px',
        position: 'absolute',
        height: 'auto',
        maxHeight: 'calc(100vh - 49px)',
        top: 'auto',
        backgroundColor: 'white',
        width: 'min(240px,100%)',
        border: '1px solid rgba(0, 0, 0, 0.12)'
    },
    // drawerBox: {
    //     position: 'absolute',
    //     top: 'auto',
    //     height: '100%',
    //     backgroundColor: 'white',
    //     width: drawerWidth,
    // },
    drawerRoot: {
        // flex: 1,
        paddingTop: 12,
        paddingLeft: 12,
        paddingRight: 12,
        paddingBottom: 12,
        height: '100vh',
    }
}))



const SettingDrawer = props => {

    const classes = useStyles()
    const { t, ...local } = useLocale()
    const languages = local.getLanguages()
    const [lang, setLang] = useState(local.getLanguage())


    function handleDiscard() {
        setLang(local.getLanguage())
    }

    function handleApply() {
        local.setLanguage(lang)
    }

    return (
        <div className={classes.drawerRoot}>
            <Typography varient='body2' className='drawer_title' style={{ fontSize: '15px' }} >
                {t('setting.language')}
            </Typography>
            <Typography varient='body2' style={{ fontSize: '10px' }} >
                {t('setting.choose_lang')}
            </Typography>
            <select id='language' value={lang} onChange={(e) => setLang(e.target.value)} style={{ display: 'block', width: '100%', margin: '12px 0' }}>
                {languages.map(op =>
                    <option value={op}>{local.getLanguageLabel(op)}</option>
                )}
            </select>
            <button type="button" disabled={lang == local.getLanguage()} onClick={handleApply} style={{ marginRight: '8px' }}>
                {t('apply')}
            </button>
            <button type="button" disabled={lang == local.getLanguage()} onClick={handleDiscard}>
                {t('cancel')}
            </button>
        </div>
    )
}


const HelpDrawer = props => {

    const classes = useStyles()
    const { t, ...local } = useLocale()

    return (
        <div className={classes.drawerRoot}>
            <Typography varient='body2' className='drawer_title' style={{ fontSize: '15px' }} >
                {t('help.support_resourse')}
            </Typography>
            <a href='https://www.animaetech.com/grm/grm_manual.html' target="_blank">
                <Typography varient='body2' style={{ fontSize: '15px' }}  >
                    {t('help.user_manual')}
                </Typography>
            </a>
            <Link to='/#' target="_blank">
                <Typography varient='body2' style={{ fontSize: '15px' }} >
                    {t('help.api_doc')}
                </Typography>
            </Link>

        </div>
    )
}



const AccountDrawer = props => {
    const classes = useStyles()
    const { t, ...local } = useLocale()
    const auth = useAuthApi()
    const { user, organization, switchOrganization, organizationList } = useAuthData()





    return (
        <div className={classes.drawerRoot} style={{ height: 'auto', overflow: 'hidden' }}>
            <Typography varient='body2' className='drawer_title' style={{ fontSize: '15px' }} >
                {t('user_name')}
            </Typography>
            <Typography varient='body2' noWrap paragraph style={{ fontSize: '15px' }}  >
                {user.name}
            </Typography>
            <Typography varient='body2' className='drawer_title' style={{ fontSize: '15px' }} >
                {t('email')}
            </Typography>
            <Typography varient='body2' noWrap paragraph style={{ fontSize: '15px' }}  >
                {user.email}
            </Typography>
            <Typography varient='body2' className='drawer_title' style={{ fontSize: '15px' }} >
                {t('organizations')}
            </Typography>
            {/* <Typography varient='body2' noWrap paragraph style={{ fontSize: '15px' }}  >
                {organization.name}
            </Typography> */}

            <List component="nav" style={{ margin: '0 -12px' }}>
                {/* <Divider light /> */}
                {organizationList.map(org => {
                    return (
                        <ListItem divider button onClick={() => switchOrganization(org)} component="a" href={org.href} style={{ padding: '12px 40px 12px 12px' }}>
                            {/* <ListItemText primary={org.name} /> */}
                            <Typography varient='body2' noWrap style={{ overflow: 'hidden', textOverflow: "ellipsis" }} >
                                {org.name}
                            </Typography>

                            <ListItemSecondaryAction className='flexCol-center'>
                                {org.id === organization.id && <CheckCircleOutlineIcon style={{ width: '20px', height: '20px', color: 'limegreen' }} />}
                            </ListItemSecondaryAction>
                        </ListItem>
                    )

                })}
            </List>
            <div className="flexCol" style={{ marginBottom: '8px' }} >
                <Button variant='outlined' component='a' href={apiLink.account_url} target="_blank">
                    {t('account_setting')}
                </Button>
            </div>
            <Divider style={{ margin: '8px -12px', backgroundColor: 'rgba(0, 0, 0, 0.12)' }} />
            <div className="flexCol" >
                <Button variant='outlined' onClick={auth.userLogout}>
                    {t('logout')}
                </Button>
            </div>
        </div >
    )
}

const HeaderDrawer = props => {
    const { mode, setMode } = props
    const classes = useStyles()
    const drawerRef = useRef()
    const { t, ...local } = useLocale()
    const DrawerTitle = (title) => (
        <Toolbar Toolbar >
            <Typography noWrap variant='h6' className='bold' >{title}</Typography>
            <div style={{ flexGrow: 1 }} />
            {/* <IconButton aria-label='delete' className={classes.closeIcon} onClick={() => setMode(null)} >
                <CloseIcon fontSize='large' style={{ width: '32px', height: '32px' }} />
            </IconButton> */}
        </Toolbar >
    )



    const DrawerSelector = () => {
        switch (mode) {
            case 'setting':
                return (
                    <Fragment>
                        {DrawerTitle(t('setting.setting'))}
                        <SettingDrawer />
                    </Fragment>
                )
            case 'help':
                return (
                    <Fragment>
                        {DrawerTitle(t('help.help'))}
                        <HelpDrawer />
                    </Fragment>
                )
            case 'account':
                return (
                    <Fragment>
                        {DrawerTitle(t('account'))}
                        <AccountDrawer />
                    </Fragment>
                )
        }
    }


    if (mode == null) return null
    return (
        <Drawer
            ref={drawerRef}
            className={classes.drawer}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
            anchor="right"
            elevation={3}
        >
            {DrawerSelector()}
        </Drawer>
    )
}



export default HeaderDrawer