import useRect from 'hooks/useRect'
import { useEffect, useMemo, useRef, useState } from 'react'
import SwiperCore, { Autoplay, Lazy } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/swiper.scss'
import '../theme.css'
const _ = require('lodash')
function getBlobType(blob) {
    if (blob && blob.type) {
        if (blob.type.match(/^image/g)) {
            return 'image'
        }
        else if (blob.type.match(/^video/g)) {
            return 'video'
        }
    }
    return 'image'
}
SwiperCore.use([Autoplay, Lazy])

const MediaSlide = ({ index, slide, state, swiperRef }) => {

    const isActive = state.isActive
    const isVisible = state.isVisible

    const isDuplicate = state.isDuplicate
    const id = `media_slide_${index}_${isDuplicate ? '2' : ''}`
    var url = slide.url
    var media_type = slide.type
    const blob = slide.blob

    if (blob && blob.name !== undefined) {
        media_type = getBlobType(blob)
        url = URL.createObjectURL(blob)
    }

    useEffect(() => {
        if (isVisible) {
            if (media_type === 'video') {
                stopSlideShow()
                var video = document.getElementById(id)
                if (video) {
                    video.pause()
                    video.currentTime = 0
                    video.play()
                }
            }
            else {
                playSlideShow()
            }
        }
        else {
            var video = document.getElementById(id)
            if (video) {
                video.pause()
                video.currentTime = 0
            }
        }
    }, [isVisible])

    function playSlideShow() {
        if (swiperRef.current && swiperRef.current.autoplay) {
            const autoplay = swiperRef.current.autoplay
            autoplay.start()

        }
    }

    function stopSlideShow() {
        if (swiperRef.current && swiperRef.current.autoplay)
            swiperRef.current.autoplay.stop()
    }


    function nextSlide() {
        if (swiperRef.current) {
            swiperRef.current.slideNext()
            playSlideShow()
        }
    }
    if (media_type === 'image') {
        return <img src={url} className='slide-img' style={{ objectFit: slide.scale_type }} />
    }
    else if (media_type === 'video') {
        return (
            <video
                muted
                width="100%" height='100%' onEnded={nextSlide} id={id} src={url}
                onCanPlay={e => {
                    if (isVisible || isActive) e.target.play()
                }}
                style={{ objectFit: slide.scale_type }}
            />
        )

    }
    return null
}

const SlideShow = ({ idlePage = {} }) => {

    const slides = _.get(idlePage, 'contents', [])
    const swiperRef = useRef(null)

    const [ref, rect] = useRect()

    // useEffect(() => {
    //     if (swiperRef.current) {
    //         console.log('2', idlePage)
    //         swiperRef.current.update()
    //     }
    // }, [rect, idlePage])

    const MySwiper = () => {
        return (
            <div style={{ width: '100%', height: '100%' }}>
                <Swiper
                    className='swiper'
                    onSwiper={sw => {
                        swiperRef.current = sw
                    }}
                    spaceBetween={200}
                    slidesPerView={1}
                    autoplay={{
                        delay: idlePage.image_duration * 1000,
                        disableOnInteraction: false
                    }}
                    lazy
                    loop={slides.length > 1}
                    // noSwiping
                    allowTouchMove={false}
                    watchSlidesVisibility
                >
                    {slides.map((slide, index) => {
                        return <SwiperSlide key={index}>
                            {(state) => <MediaSlide index={index} slide={slide} state={state} swiperRef={swiperRef} />}
                        </SwiperSlide>
                    })}
                </Swiper>
            </div>
        )
    }
    return <MySwiper />

    // return useMemo(() => {
    //     console.log('SlideShow -> idlePage', idlePage)
    //     return (
    //         <div ref={ref} style={{ width: '100%', height: '100%' }}>
    //             <Swiper
    //                 className='swiper'
    //                 onSwiper={sw => {
    //                     swiperRef.current = sw
    //                 }}
    //                 spaceBetween={200}
    //                 slidesPerView={1}
    //                 autoplay={{
    //                     delay: idlePage.image_duration * 1000,
    //                     disableOnInteraction: false
    //                 }}
    //                 lazy
    //                 loop={slides.length > 1}
    //                 // noSwiping
    //                 allowTouchMove={false}
    //                 watchSlidesVisibility
    //                 style={{ backgroundColor: 'black' }}
    //             >
    //                 {slides.map((slide, index) => {
    //                     return <SwiperSlide key={index}>
    //                         {(state) => <MediaSlide index={index} slide={slide} state={state} swiperRef={swiperRef} />}
    //                     </SwiperSlide>
    //                 })}
    //             </Swiper>
    //         </div>
    //     )
    // }, [idlePage, rect])

}


export default SlideShow