import { Box, Button, Checkbox, Container, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, InputAdornment, makeStyles, Radio, RadioGroup, TextField, Typography, useTheme } from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import TokenRuleConfigure from 'components/TokenRuleConfigure/TokenRuleConfigure'
import { useCampaign } from 'provider/CampaignProvider'
import RedeemTokenRuleForm from 'components/RedeemTokenRuleForm'
import { useLocale } from 'provider/LocaleProvider'
import { Form, useFormik, useFormikContext } from 'formik'
import { BackButton, DataRow, DataSession, DataSessionSubtitle } from 'components/DataSession'
import DatePicker from 'components/DatePicker'
import { CheckBox } from '@material-ui/icons'
import RadioList from 'components/RadioList'
import DataChipInput from 'components/DataChipInput'
import * as Yup from 'yup'
import useApi from 'api/useApi'
import { useSnack } from 'provider/SnackbarProvider'
import { useLocation } from 'react-router'
import FormikTextField from 'components/FormikMaterialUI/FormikTextField'
import Constants from 'misc/Constants'
const moment = require('moment-timezone')

const _ = require('lodash')

const useStyles = makeStyles(theme => ({
    form: {
        '& .MuiDivider-root': {
            marginTop: '8px',
            marginBottom: '16px'
        },
        '& .MuiFormControlLabel-root': {
            minWidth: '150px'
        },
        '& .MuiTextField-root': {
            width: 'min(400px, 100%)'
        }
    },
    quota: {
        width: '100%',
        '& .MuiTextField-root': {
            display: 'block',
            marginTop: '8px',
            marginBottom: '16px'
        }

    }
}))
const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const StartPeriod = ({ formik }) => {
    const { t } = useLocale()
    const { values, touched, errors, handleChange, setFieldValue, setFieldTouched, isValid } = formik
    const { campaign } = useCampaign()

    const handleDateChange = (value) => {
        var date = moment(value).toISOString()
        setFieldValue('redeem_token_rule.started_at', date)
    }
    useEffect(() => {
        if (values.has_start_date == 'false') {
            setFieldValue('redeem_token_rule.started_at', null)
        }
    }, [values.has_start_date])

    var error = _.get(errors, 'redeem_token_rule.started_at')

    return (
        <FormControl component="fieldset">
            <RadioGroup row color='primary' name='has_start_date' value={_.get(values, 'has_start_date')} onChange={handleChange}>
                <FormControlLabel value={'false'} control={<Radio />} label={t('start_immediately')} />
                <FormControlLabel value={'true'} control={<Radio />} label={
                    <DatePicker minDate={moment(campaign.created_at).toDate()} disabled={_.get(values, 'has_start_date') == 'false'} value={_.get(values, 'redeem_token_rule.started_at')} handleChange={handleDateChange} />
                } />
            </RadioGroup>
            {error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
    )
}

const EndPeriod = ({ formik }) => {
    const { t } = useLocale()
    const { campaign } = useCampaign()
    const { values, touched, errors, handleChange, setFieldValue, setFieldTouched, isValid } = formik

    const handleDateChange = (value) => {
        var date = moment(value).toISOString()
        setFieldValue('redeem_token_rule.expired_at', date)
    }
    var error = _.get(errors, 'redeem_token_rule.expired_at')

    useEffect(() => {

        if (values.has_end_date == 'false') {
            setFieldValue('redeem_token_rule.expired_at', null)
        }
    }, [values.has_end_date])


    return (
        <FormControl component="fieldset">
            <RadioGroup row color='primary' name='has_end_date' value={_.get(values, 'has_end_date')} onChange={handleChange}>
                <FormControlLabel value={'false'} control={<Radio />} label={t('n/a')} />
                <FormControlLabel value={'true'} control={<Radio />} label={
                    <DatePicker minDate={moment(campaign.created_at).toDate()} disabled={_.get(values, 'has_end_date') == 'false'} value={_.get(values, 'redeem_token_rule.expired_at')} handleChange={handleDateChange} />
                } />
            </RadioGroup>
            {error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
    )
}


const ExpiredAfter = ({ formik }) => {
    const { t } = useLocale()


    const { values, setFieldValue, handleChange } = formik
    const name = 'redeem_token_rule.expiry_seconds'
    const value = _.get(formik.values, name)

    useEffect(() => {
        if (values.has_expiry_seconds == 'false') {
            setFieldValue('redeem_token_rule.expiry_seconds', null)
        }
    }, [values.has_expiry_seconds])


    const error = _.get(formik.errors, name)


    return (
        <FormControl component="fieldset">
            <RadioGroup row color='primary' name='has_expiry_seconds' value={_.get(values, 'has_expiry_seconds')} onChange={handleChange}>
                <FormControlLabel value={'false'} control={<Radio />} label={t('n/a')} />
                <FormControlLabel value={'true'} control={<Radio />} label={
                    <TextField
                        disabled={values.has_expiry_seconds == 'false'}
                        name={name}
                        value={_.isNil(value) ? '' : value}
                        onChange={formik.handleChange}
                        type='number'
                        inputProps={{
                            min: 1
                        }}
                        InputProps={{
                            min: 1,
                            endAdornment: <InputAdornment position='end'>{t('seconds')}</InputAdornment>
                        }}
                    />
                } />
            </RadioGroup>
            {error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
    )
}

const TokenRule = props => {
    const classes = useStyles()

    const { t } = useLocale()
    const location = useLocation()
    const theme = useTheme()
    const { campaign, setCampaign } = useCampaign()
    const snack = useSnack()
    const api = useApi()


    const selected = location.state?.selected
    const isSelected = _.size(selected) > 0
    const fy = Constants.yupErrorMessage

    const [tokenRule, setTokenRule] = useState(campaign?.redeem_token_rule)

    useEffect(() => {

        if (campaign?.redeem_token_rule) {
            if (isSelected) {
                api.getTokens({ redeem_token: selected[0] }).then(res => {
                    setTokenRule(_.get(res, 'data.redeem_tokens[0]', campaign?.redeem_token_rule))
                })
            }
            else {
                setTokenRule(campaign?.redeem_token_rule)
            }
        }
    }, [campaign, selected])

    const schema = Yup.object({
        selected: Yup.array(),
        redeem_token_rule: Yup.object({
            started_at: Yup.string().test('format', 'format error', v => !v || moment(v, moment.ISO_8601, true).isValid()).nullable(),
            expired_at: Yup.string().label(t('expired_at')).test('format', 'format error', v => !v || moment(v, moment.ISO_8601, true).isValid()).nullable().test('after', t('after_start'), function (value) {
                return !value || !this.parent.started_at || moment(value).isSameOrAfter(moment(this.parent.started_at), 'date')
            }),
            expiry_seconds: Yup.number().min(1).nullable().default(null),
            has_reward: Yup.boolean().required().default(false),
            is_reusable: Yup.boolean().required().default(false),
            redeem_date_conditions: Yup.object({
                type: Yup.string().oneOf(['exclude', 'include']).default('exclude'),
                months: Yup.array().of(
                    Yup.string().oneOf(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']).nullable()
                ),
                weekdays: Yup.array().of(
                    Yup.string().oneOf(['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']).nullable()
                ),
                dates: Yup.array().of(
                    Yup.string().test('foramt', 'format error', v => moment(v, 'YYYY-MM-DD', true).isValid()).nullable()
                ),
            }),
            redeem_quota: Yup.object({
                overall: Yup.number().min(0).max(999).nullable(),
                yearly: Yup.number().min(0).max(999).nullable(),
                monthly: Yup.number().min(0).max(999).nullable(),
                weekly: Yup.number().min(0).max(999).nullable(),
                daily: Yup.number().min(0).max(999).nullable()
            }),
            metadata: Yup.object(),
            token_identifier: Yup.string().nullable(),
            redeem_pin: Yup.string().nullable(),
        }).when('has_start_date', {
            is: v => v === 'true',
            then: Yup.object({
                started_at: Yup.string().required().nullable()
            })
        }).when('has_end_date', {
            is: v => v === 'true',
            then: Yup.object({
                expired_at: Yup.string().required().nullable()
            })
        }).when('has_expiry_seconds', {
            is: v => v === 'true',
            then: Yup.object({
                expiry_seconds: Yup.number().required().nullable()
            })
        }),
        has_start_date: Yup.string().default(null),
        has_end_date: Yup.string().default(null),
        has_expiry_seconds: Yup.string().default(null),
    })
    const formik = useFormik({
        enableReinitialize: true,
        validationSchema: schema,
        initialValues: (schema.cast({
            selected: location.state?.selected,
            redeem_token_rule: tokenRule
        })),
        onSubmit: (values, helper) => {
            if (isSelected) {
                var temp = {}
                _.keys(values.redeem_token_rule).map(key => {
                    var newValue = _.get(values.redeem_token_rule, key)
                    if (newValue !== _.get(tokenRule, key)) {
                        _.set(temp, key, newValue)
                    }
                })
                api.editTokenRules(values.selected, temp).then(res => {
                    helper.setSubmitting(false)
                    snack.open(t('saved'))
                }).catch(e => {
                    helper.setSubmitting(false)
                    snack.error(t('failed'))
                })
            }
            else {
                api.updateCampaign({ redeem_token_rule: values.redeem_token_rule, }, setCampaign)
                    .then(res => {
                        helper.setSubmitting(false)
                        snack.open(t('saved'))
                    }).catch(e => {
                        helper.setSubmitting(false)
                        snack.error(t('failed'))
                    })
            }
        }
    })
    const { values, touched, errors, handleChange, handleReset, handleBlur, setFieldValue, setFieldTouched, isValid } = formik


    useEffect(() => {
        var rule = values.redeem_token_rule
        if (rule) {
            if (values.has_start_date === null) {
                setFieldValue('has_start_date', _.toString(!_.isNil(rule.started_at)))
            }
            if (values.has_end_date === null) {
                setFieldValue('has_end_date', _.toString(!_.isNil(rule.expired_at)))
            }
            if (values.has_expiry_seconds === null) {
                setFieldValue('has_expiry_seconds', _.toString(!_.isNil(rule.expiry_seconds)))
            }
        }
    }, [values.redeem_token_rule])




    return (
        <Container>
            <Typography variant='h4' className='bold' paragraph>
                {t('redeem_token_rule')}
            </Typography>
            <Box display='flex' flexDirection='row'>
                <BackButton />
            </Box>
            <form onSubmit={formik.handleSubmit} className={classes.form} style={{ height: '100%', display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
                <DataSession title={t('redeem_token_rule')} flexGrow={1}>
                    <DataSessionSubtitle title={t('validity_period')} />
                    <DataRow name={t('start_at')}>
                        <StartPeriod formik={formik} />
                    </DataRow>
                    <DataRow name={t('expired_date')}>
                        <EndPeriod formik={formik} />
                    </DataRow>
                    <DataRow name={t('expired_after')}>
                        <ExpiredAfter formik={formik} />

                    </DataRow>
                    <Divider className={classes.divider} />
                    <DataRow name={t('conditions')} >
                        <FormControl component="fieldset">
                            <RadioGroup row color='primary'
                                name='redeem_token_rule.redeem_date_conditions.type'
                                value={_.get(values, 'redeem_token_rule.redeem_date_conditions.type')}
                                onChange={handleChange}
                            >
                                <FormControlLabel value={'exclude'} control={<Radio />} label={t('exclude')} />
                                <FormControlLabel value={'include'} control={<Radio />} label={t('include')} />
                            </RadioGroup>
                        </FormControl>
                    </DataRow>
                    <DataRow name={t('weekdays')}>
                        <FormGroup row color='primary' >
                            {weekdays.map(d => {
                                const name = 'redeem_token_rule.redeem_date_conditions.weekdays'
                                const checked = _.includes(_.get(values, name), d)
                                return <FormControlLabel label={t(d)} control={<Checkbox type='checkbox' checked={checked} name={name} value={d} onChange={handleChange} />} />
                            })}
                        </FormGroup>
                    </DataRow>
                    <DataRow name={t('months')}>
                        <FormGroup row color='primary'>
                            {months.map(d => {
                                const name = 'redeem_token_rule.redeem_date_conditions.months'
                                const checked = _.includes(_.get(values, name), d)
                                return <FormControlLabel label={t(d)} control={<Checkbox type='checkbox' checked={checked} name={name} value={d} onChange={handleChange} />} />
                            })}
                        </FormGroup>
                    </DataRow>
                    <DataRow name={t('dates')}>
                        <DataChipInput
                            tags={_.get(values, 'redeem_token_rule.redeem_date_conditions.dates', [])}
                            setTags={v => {
                                console.log('v', v)

                                setFieldValue('redeem_token_rule.redeem_date_conditions.dates', v)
                            }}
                            isValid={(date) => {
                                return moment(date, 'YYYY-MM-DD', true).isValid()
                            }}
                            regex={/[^0-9,-]/g}
                            helperText={t('dates_chip_helper')}
                        />
                    </DataRow>
                    <DataRow name={t('has_reward')} >
                        <FormControl component="fieldset">
                            <RadioGroup row color='primary'
                                name='redeem_token_rule.has_reward'
                                value={_.toString(_.get(values, 'redeem_token_rule.has_reward'))}
                                onChange={(e) => setFieldValue(e.target.name, Boolean(e.target.value == 'true'))}
                            >
                                <FormControlLabel value={'true'} control={<Radio />} label={t('yes')} />
                                <FormControlLabel value={'false'} control={<Radio />} label={t('no')} />
                            </RadioGroup>
                        </FormControl>
                    </DataRow>
                    <DataRow name={t('is_reusable')} >
                        <FormControl component="fieldset">
                            <RadioGroup row color='primary'
                                name='redeem_token_rule.is_reusable'
                                value={_.toString(_.get(values, 'redeem_token_rule.is_reusable'))}
                                onChange={(e) => setFieldValue(e.target.name, Boolean(e.target.value == 'true'))}
                            >
                                <FormControlLabel value={'true'} control={<Radio />} label={t('yes')} />
                                <FormControlLabel value={'false'} control={<Radio />} label={t('no')} />
                            </RadioGroup>
                        </FormControl>
                    </DataRow>
                    <DataRow name={t('redeem_quota')} alignSelf='baseline'>
                        <div className={classes.quota} >
                            {['overall', 'yearly', 'monthly', 'weekly', 'daily'].map(type => {
                                return (
                                    <FormikTextField
                                        placeholder={t('n/a')}
                                        formik={formik}
                                        disabled={!_.get(values, 'redeem_token_rule.is_reusable')}
                                        fullWidth
                                        type='number'
                                        label={t(type)}
                                        name={`redeem_token_rule.redeem_quota.${type}`}
                                    />
                                )
                            })}
                        </div>
                    </DataRow>
                    <DataRow name={t('token_identifier')} helpText={t('token_identifier_help')}>
                        <FormikTextField
                            placeholder={t('n/a')}
                            formik={formik}
                            name='redeem_token_rule.token_identifier'
                        />
                    </DataRow>
                    <DataRow name={t('redeem_pin')} helpText={t('redeem_pin_helper')}>
                        <FormikTextField
                            placeholder={t('n/a')}
                            formik={formik}
                            name='redeem_token_rule.redeem_pin'
                        />
                    </DataRow>
                </DataSession>
                <Box display='flex' style={{ marginTop: '24px' }}>
                    <Box flexGrow={1} />
                    <Button onClick={handleReset} variant="outlined" >
                        {t('reset')}
                    </Button>
                    <Button
                        type='submit'
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: '8px' }}
                        onClick={formik.handleSubmit}
                    >
                        {t('save')}
                    </Button>
                </Box>
            </form>
        </Container >

    )
}

export default TokenRule