import React, { useContext, useEffect, useLayoutEffect } from 'react'
import PropTypes, { object } from 'prop-types'

// Translation Higher Order Component
import {
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage,
    translate,
    getLanguage,
    getLanguages,
    getDefaultLanguage,
} from 'react-switch-lang'
import en from '../lang/en.json'
import zh from '../lang/zh.json'
import { setLocale } from 'yup'

const _ = require('lodash')

// Do this two lines only when setting up the application
setTranslations({ en, zh })
setDefaultLanguage('en')

const yup = require('yup')

const LocaleContext = React.createContext()

export function useLocale() {
    return useContext(LocaleContext)
}

const LocaleProvider = (props) => {

    const { children, t } = props

    const _t = (path, params) => {
        if (_.isString(path))
            return t(path, params)
        return ''
    }

    yup.setLocale({
        // use constant translation keys for messages without values
        mixed: {
            required: () => _t('required_field'),
        },
        string: {
            // min: ({ min }) => _t('min', { max: min }),
            max: ({ max }) => _t('str_maxchar', { max: max }),
        },
        array: {
            min: ({ min }) => _t('min_array', { min: min }),
            max: ({ max }) => _t('max_array', { max: max })

        },
        // use functions to generate an error object that includes the value from the schema
        number: {
            min: ({ min }) => _t('min_value', { min: min }),
            max: ({ max }) => _t('max_value', { max: max }),
        }
    })


    useEffect(() => {
        var lang = localStorage.getItem('language')
        switchLanguage(lang)
        // setLocale({
        //     // use constant translation keys for messages without values
        //     mixed: {
        //         default: 'field_invalid',
        //     },
        //     string: {
        //         default: 'required_field',
        //     },
        //     // use functions to generate an error object that includes the value from the schema
        //     number: {
        //         required: () => 'required_field',
        //         min: ({ min }) => 'min_value',
        //         max: ({ max }) => ['max_value', { max: max }],
        //     },

        // })

    }, [])

    const switchLanguage = (key) => {
        if (getLanguages().includes(key)) {
            setLanguage(key)
            localStorage.setItem('language', key)
        }
        else {
            setLanguage(getDefaultLanguage())
            localStorage.setItem('language', getDefaultLanguage())
        }
    }

    const translate = (obj) => {
        if (obj) {
            var value = obj[getLanguage()] || obj[getDefaultLanguage()]
            if (value) return value
            for (var lang of getLanguages()) {
                if (obj[lang]) return obj[lang]
            }
            for (var key of Object.keys(obj)) {
                if (obj[key]) return obj[key]
            }
            return null
        }
        return null
    }
    const getLanguageLabel = (lang) => {
        switch (lang) {
            case 'en': return 'English'
            case 'zh': return '中文(繁體)'
            default: return 'unknown'
        }
    }

    const state = {
        t: _t,
        translate,
        setLanguage: switchLanguage,
        getLanguage,
        getLanguages,
        getLanguageLabel
    }
    if (!t) return null

    return (
        <LocaleContext.Provider value={state} >
            {children}
        </LocaleContext.Provider>
    )
}

LocaleProvider.propTypes = {
    t: PropTypes.func.isRequired,
}

export default translate(LocaleProvider)