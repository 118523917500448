import React from 'react'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import { Children } from 'react'
import { Fragment } from 'react'

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}))

export default function ImagePopover({ children, image_url }) {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)

    return (
        <Fragment>
            <Box onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
                {children}
            </Box>
            {image_url &&
                <Popover
                    id="mouse-over-popover"
                    className={classes.popover}
                    classes={{
                        paper: classes.paper,
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <img src={image_url} style={{ height: '300px', width: "300px" }} />
                </Popover>
            }
        </Fragment>
    )
}
