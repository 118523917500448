import { Box, Button, makeStyles, Toolbar, Typography, useTheme } from '@material-ui/core'
import React, { Fragment } from 'react'
import { Alert } from '@material-ui/lab'
import { useLocale } from '../../../../provider/LocaleProvider'
import DataChipInput from '../../../../components/DataChipInput'
import { Form, useFormikContext } from 'formik'

const moment = require('moment-timezone')



const useStyles = makeStyles(theme => ({
    input: {
        backgroundColor: '#fff',
        boxShadow: ' 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        alignItems: 'baseline'
        // flex: 1,
        // font: 'inherit',
        // border: '0',
        // minHeight: '200px',
        // height: '100%',
        // outline: 'none',
        // "&:hover": {
        //     border: 0,
        //     outline: 'none'
        // }
        // '&input': {
        //     minHeight: '200px',
        // }
    }
}))


const _ = require('lodash')
const GenerateCustom = props => {
    const { values, touched, errors, validateForm, handleChange, setFieldValue, setFieldTouched, isValid } = useFormikContext()

    const classes = useStyles()

    const theme = useTheme()

    const { t } = useLocale()

    const handleNext = async (e) => {
        e.preventDefault()
        setFieldTouched('redeem_tokens', true, true)
        var invalid = await validateForm()
        document.getElementById("app").scroll(0, 0)
        if (!invalid.redeem_tokens) {
            setFieldValue('step', values.step + 1)
        }
    }

    const handleReset = () => {
        setFieldValue('step', 0)
        setFieldValue('mode', null)
    }

    return (
        <Form onSubmit={handleNext} style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            {touched.redeem_tokens && errors.redeem_tokens && <Alert variant="filled" severity="error" style={{ marginBottom: '24px' }}>
                {errors.redeem_tokens}
            </Alert>
            }
            <div style={{ flexGrow: 1 }}>
                <Toolbar>
                    {/* <Typography variant='h6'>
                        Please enter custom tokens seperate with ','
                </Typography> */}
                    <Box flexGrow={1} />
                    <Button variant='outlined' color='primary' onClick={() => setFieldValue('redeem_tokens', [])}>
                        {t('clear')}
                    </Button>
                </Toolbar>
                <DataChipInput
                    tags={values.redeem_tokens || []}
                    setTags={v => setFieldValue('redeem_tokens', v)}
                    regex={/(^,)|[^A-Za-z0-9,]/g}
                />
                <Typography variant='body1' style={{ width: '100%', textAlign: 'right', lineHeight: '40px' }}>
                    {_.size(values.redeem_tokens) + ' ' + t('token(s)')}
                </Typography>
            </div>
            <Box display='flex' style={{ marginTop: '24px' }}>
                <Button onClick={handleReset} variant="contained" style={{ color: 'white', backgroundColor: theme.palette.error.main }}>
                    {t('cancel')}
                </Button>
                <Box flexGrow={1} />
                <Button variant="outlined" onClick={handleReset} >
                    {t('back')}
                </Button>
                <Button
                    type='submit'
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: '8px' }}
                    onClick={handleNext}
                >
                    {t('next')}
                </Button>
            </Box>
        </ Form>
    )
}

export default GenerateCustom