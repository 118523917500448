import React, { useState, useEffect, useContext, useMemo, Fragment } from 'react'
import './statusbar.css'
import { useHistory, useLocation } from 'react-router'
import { LinearProgress } from '@material-ui/core'
import useZoomResizer from 'hooks/useZoomResizer'
import { CSSTransition } from 'react-transition-group'
import CallIcon from '@material-ui/icons/Call'
import CallEndIcon from '@material-ui/icons/CallEnd'
import CloseIcon from '@material-ui/icons/Close'
const _ = require('lodash')
const moment = require('moment-timezone')


const CallDrawer = ({ open, setOpen }) => {
    const location = useLocation()
    useEffect(() => {
        var timer
        clearTimeout(timer)
        if (open) {
            timer = setTimeout(() => setOpen(false), 10000)
        }
        return () => {
            clearTimeout(timer)
        }
    }, [open])

    return (
        <CSSTransition classNames='item' timeout={500} in={open} mountOnEnter unmountOnExit >
            <div className='call-drawer'>
                <div>
                    Live Chat
                </div>
                <div>
                    Mon-Fri 11:00-18:00
                </div>
                <div style={{ display: 'flex', paddingTop: '20px', justifyContent: 'space-around' }}>

                    <button className='endcall-btn' disabled={true}>
                        <CloseIcon className='call-btn-icon' />
                    </button>
                    <button className='call-btn'>
                        <CallIcon className='call-btn-icon' />
                    </button>
                </div>
                <div className={`call-status`}>
                    {'Ready'}
                </div>
            </div>
        </CSSTransition >
    )
}


const HelpButton = ({ handleHelp }) => {
    const location = useLocation()
    return (
        <div className='help-btn' onClick={handleHelp}>
            <CallIcon />
        </div>
    )
}


const ThemePreviewLayout = ({ children, theme = {} }) => {
    const history = useHistory()
    const location = useLocation()



    const background_color = _.get(theme, 'background_color', '#222')


    const [time, setTime] = useState()

    const [open, setOpen] = useState(false)

    useEffect(() => {
        update()
        var timer = setInterval(update, 1000)
        return () => {
            clearInterval(timer)
        }
    }, [])


    function update() {
        var now = moment().format('YYYY-MM-DD HH:mm:ss').toString()
        setTime(now)
    }


    const handleHelp = () => {
        setOpen(b => true)
    }


    const status_bar = _.get(theme, 'status_bar.is_active', true)
    const device_name = _.get(theme, 'status_bar.device_name', true)
    const online_status = _.get(theme, 'status_bar.online_status', true)
    const timestamp = _.get(theme, 'status_bar.timestamp', true)
    const stock_status = _.get(theme, 'status_bar.stock_status', true)
    const video_call = _.get(theme, 'status_bar.video_call', true)

    return (
        <div className='Content' style={{ height: '100%', width: '100%' }}  >
            <CallDrawer open={open} setOpen={setOpen} />
            <div style={{ width: '100%', height: '100%', paddingBottom: status_bar ? '100px' : '0px' }}>
                {children}
            </div>
            {status_bar &&
                <div className='status-bar'>
                    {video_call && <HelpButton handleHelp={handleHelp} />}
                    <div style={{ marginLeft: '24px' }}></div>
                    {device_name &&
                        <span>
                            {'Your Device Name'}
                        </span>
                    }
                    {online_status && <span className={true ? 'online' : 'offline'}>
                        {true ? 'Online ' : 'Offline '}
                    </span>
                    }
                    {timestamp && <span >
                        {time}
                    </span>
                    }
                    <div style={{ flexGrow: 1 }}></div>
                    {stock_status && <>
                        <span >
                            Stock:
                    </span>
                        <LinearProgress variant="determinate" value={80} className='stock-progress' classes={{ bar: `stock-${80 > 0.5 ? 'high' : 80 > 0.3 ? 'mid' : 'low'}` }}
                            style={{ backgroundColor: 'rgba(182,188,226,0.5)', width: '100px' }}
                        />
                    </>
                    }
                </div>
            }
        </div>
    )

}

export default ThemePreviewLayout