import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React, { useContext, useEffect, useState } from 'react'
import { useParams, withRouter } from 'react-router-dom'
import useApi from 'api/useApi'

import { useCampaign } from 'provider/CampaignProvider'
import { useSnack } from 'provider/SnackbarProvider'
import EditNameDiolog from './components/EditNameDialog'
import EditPeriodDialog from './components/EditPeriodDialog'
import EditPinDialog from './components/EditPinDialog'
import EditStatusDialog from './components/EditStatusDialog'
import General from './components/General'
import { Container, Typography } from '@material-ui/core'
import { useLocale } from 'provider/LocaleProvider'
const useStyles = makeStyles(theme => ({
    body: {
        flex: 1,
        margin: 'auto',
        padding: theme.spacing(3),
        boxSizing: 'border-box',
    },
}))

const DetailsContext = React.createContext()


export const useDetails = () => useContext(DetailsContext)


const CampaignDetails = (props) => {


    const classes = useStyles()
    const userRequest = useApi()
    const { t } = useLocale()

    const { history } = props
    const snack = useSnack()
    const { campaign, updateCampaign } = useCampaign()
    const [editNameOpen, setEditNameOpen] = useState(false)
    const [editPinOpen, setEditPinOpen] = useState(false)
    const [editStatusOpen, setEditStatusOpen] = useState(false)
    const [editPeriodOpen, setEditPeriodOpen] = useState(false)
    const [editMeta, setEditMeta] = useState({
        open: false,
        mode: null,
        data: {},
    })


    const state = {
        campaign: campaign,
        editNameDialog: {
            open: editNameOpen,
            setOpen: setEditNameOpen,
        },
        editPinDialog: {
            open: editPinOpen,
            setOpen: setEditPinOpen
        },
        editStatusDialog: {
            open: editStatusOpen,
            setOpen: setEditStatusOpen
        },
        editPeriodDialog: {
            open: editPeriodOpen,
            setOpen: setEditPeriodOpen
        },
        snack: snack,
        updateCampaign: updateCampaign
    }
    if (!campaign) return null
    return (
        <Container>
            <Typography variant='h4' className='bold' paragraph>
                {t('campaign_overview')}
            </Typography>
            <DetailsContext.Provider value={state} >
                <General />
                <EditNameDiolog />
                <EditPinDialog />
                <EditStatusDialog />
                <EditPeriodDialog />
            </DetailsContext.Provider>
        </Container>
    )
}

CampaignDetails.propTypes = {
    // t: PropTypes.func.isRequired,
}

export default withRouter(CampaignDetails)
