
import { Box, Container, GridList, GridListTile, isWidthDown, makeStyles, Paper, TextField, Typography, useTheme, withWidth } from '@material-ui/core'
import React, { useContext, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import AddIcon from '@material-ui/icons/Add'

import { useHistory, useLocation, useParams, withRouter } from 'react-router-dom'
import ChooseGenerateMode from './components/ChooseGenerateMode'
import { useCampaign } from 'provider/CampaignProvider'
import { useLocale } from 'provider/LocaleProvider'
import { Formik, useFormik, useFormikContext } from 'formik'
import * as Yup from 'yup'
import useApi from 'api/useApi'
import ModeStepper from './components/ModeStepper'
import GenerateAuto from './components/GenerateAuto'
import GenerateTokenRule from './components/GenerateTokenRule'
import GenerateMetaData from './components/GenerateMetaData'
import { ca } from 'date-fns/locale'
import GenerateConfirm from './components/GenerateConfirm'
import GenerateSuccess from './components/GenerateSuccess'
import GenerateUpload from './components/GenerateUpload'
import GenerateCustom from './components/GenerateCustom'
import ChooseProduct from './components/ChooseProduct'
import { Fragment } from 'react'
import Constants from 'misc/Constants'
const useStyles = makeStyles(theme => ({
    body: {
        flex: 1,
        margin: 'auto',
        padding: theme.spacing(3),
        boxSizing: 'border-box',
    },
}))

const moment = require('moment-timezone')


const _ = require('lodash')








const FormHandler = () => {

    const { values, touched, errors, resetForm } = useFormikContext()
    console.log('FormHandler => values', values)
    console.log('FormHandler => errors', errors)
    const [productList, setProductList] = useState([])

    const step = values.step

    const api = useApi()

    useEffect(() => {
        if (values.step === 0) {
            resetForm()
        }
    }, [step, values.mode])

    useEffect(() => {
        document.getElementById("app").scroll(0, 0)
    }, [step])


    useEffect(() => {
        api.getProducts({ is_active: true }).then(res => {
            setProductList(res.data.products)
        }).catch(e => {
            console.log('api.getProducts => e', e)
        })
    }, [])


    if (step === 0) {
        return <ChooseGenerateMode />
    }
    if (step === 1) {
        if (values.mode == 'auto') return <GenerateAuto />
        if (values.mode == 'upload') return <GenerateUpload />
        if (values.mode == 'custom') return <GenerateCustom />
    }
    if (step === 2) {
        return <ChooseProduct productList={productList} />
    }
    if (step === 3) {
        return <GenerateTokenRule />
    }
    if (step === 4) {
        return <GenerateMetaData />
    }
    if (step === 5) {
        return <GenerateConfirm productList={productList} />
    }
    if (step === 6) {
        return <GenerateSuccess />
    }
    return null
}


const GenerateToken = (props) => {


    const classes = useStyles()
    const { campaign } = useCampaign()
    const { campaignId } = useParams()
    const api = useApi()
    const { t } = useLocale()
    const fy = Constants.yupErrorMessage

    const schema = Yup.object({
        campaign_id: Yup.string(),
        mode: Yup.string(),
        step: Yup.number().default(0),
        tokens_count: Yup.number().when('mode', {
            is: v => v === 'auto',
            then: Yup.number().required().min(1).max(100).label(t("token_amount"))
        }),
        product_id: Yup.string(),
        redeem_tokens: Yup.array().when('mode', {
            is: v => v === 'upload' || v === 'custom',
            then: Yup.array().required().min(1).max(100)
        }),
        redeem_token_rule: Yup.object({
            started_at: Yup.string().test('format', 'format error', v => !v || moment(v, moment.ISO_8601, true).isValid()).nullable(),
            expired_at: Yup.string().label(t('expired_at')).test('format', 'format error', v => !v || moment(v, moment.ISO_8601, true).isValid()).nullable().test('after', 'must after start data', function (value) {
                return !value || !this.parent.started_at || moment(value).isSameOrAfter(moment(this.parent.started_at), 'date')
            }),
            expiry_seconds: Yup.number().min(1).nullable().default(null),
            has_reward: Yup.boolean().required().default(false),
            is_reusable: Yup.boolean().required().default(false),
            redeem_date_conditions: Yup.object({
                type: Yup.string().oneOf(['exclude', 'include']).default('exclude'),
                months: Yup.array().of(
                    Yup.string().oneOf(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']).nullable()
                ),
                weekdays: Yup.array().of(
                    Yup.string().oneOf(['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']).nullable()
                ),
                dates: Yup.array().of(
                    Yup.string().test('foramt', 'format error', v => moment(v, 'YYYY-MM-DD', true).isValid()).nullable()
                ),
            }),
            redeem_quota: Yup.object({
                overall: Yup.number().min(0).max(999).nullable(),
                yearly: Yup.number().min(0).max(999).nullable(),
                monthly: Yup.number().min(0).max(999).nullable(),
                weekly: Yup.number().min(0).max(999).nullable(),
                daily: Yup.number().min(0).max(999).nullable()
            }),
            metadata: Yup.object(),
            token_identifier: Yup.string().nullable(),
            redeem_pin: Yup.string().nullable(),
        }).when('has_start_date', {
            is: v => v === 'true',
            then: Yup.object({
                started_at: Yup.string().required().nullable()
            })
        }).when('has_end_date', {
            is: v => v === 'true',
            then: Yup.object({
                expired_at: Yup.string().required().nullable()
            })
        }).when('has_expiry_seconds', {
            is: v => v === 'true',
            then: Yup.object({
                expiry_seconds: Yup.number().required().nullable()
            })
        }),
        has_start_date: Yup.string().default(null),
        has_end_date: Yup.string().default(null),
        has_expiry_seconds: Yup.string().default(null),
    })

    return (
        <Container style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant='h4' className='bold' paragraph>
                {t('generate_token')}
            </Typography>
            <Formik
                enableReinitialize
                validationSchema={schema}
                initialValues={schema.cast({
                    campaign_id: campaignId,
                    redeem_token_rule: campaign?.redeem_token_rule,
                })}
                onSubmit={(values, helper) => {
                    api.generateToken({
                        redeem_token_rule: values.redeem_token_rule,
                        product_id: values.product_id,
                        campaign_id: values.campaign_id,
                        tokens_count: values.tokens_count,
                        redeem_tokens: values.redeem_tokens,
                    }).then(res => {
                        helper.setFieldValue('tokens', res.data.redeem_tokens)
                        helper.setFieldValue('step', 6)
                        helper.setSubmitting(false)
                    }).catch(e => {
                        if (e.errorCode === 1) {
                            helper.setFieldError('response', 'token_conflict')
                        }
                        else {
                            helper.setFieldError('response', e?.msg)
                        }
                        document.getElementById('app').scrollTo(0, 0)
                        helper.setSubmitting(false)
                    })
                }}
            >
                <Fragment>
                    <ModeStepper />
                    <FormHandler />
                </Fragment>
            </Formik>
        </Container >
    )
}

GenerateToken.propTypes = {
    // t: PropTypes.func.isRequired,
}

export default GenerateToken