import React, { useContext, useEffect, useState } from 'react'
import { Box, Card, Grid, makeStyles, Typography } from '@material-ui/core'
import AirplayIcon from '@material-ui/icons/Airplay'
import { Link, NavLink } from 'react-router-dom'
import { useLocale } from 'provider/LocaleProvider'
import { Container } from 'layout/Container'

import DevicesIcon from '@material-ui/icons/Devices'
import EventIcon from '@material-ui/icons/Event'
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications'
import StyleIcon from '@material-ui/icons/Style'
import CallToActionIcon from '@material-ui/icons/CallToAction'
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun'
import { Fragment } from 'react'

const useStyles = makeStyles(theme => ({




    grid: {
        justifyContent: 'baseline',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
        },
    }


}))


const Session = ({ href = '/', Icon = AirplayIcon, text = '', }) => {
    return (
        <NavLink to={href}>
            <div style={{ margin: 'auto', backgroundColor: 'white', padding: '20px', marginBottom: '12px', height: '200px' }}>
                <Icon color='primary' style={{ width: '100%', alignSelf: 'center', height: '140px' }} />
                <Typography variant='h6' color='textPrimary' align='center'>
                    {text}
                </Typography>
            </div>
        </NavLink>
    )

}


const HomePage = props => {




    const classes = useStyles()

    const { t } = useLocale()



    return (
        <Fragment>

            <Grid className={classes.grid} container spacing={5} >
                <Grid item xs={12} sm={4} md={4}>
                    <Session text={t('campaigns')} href='/campaigns' Icon={EventIcon} />
                </Grid>
                <Grid item xs={12} sm={4} md={4} >
                    <Session text={t('devices')} href='/devices' Icon={DevicesIcon} />
                </Grid>
                <Grid item xs={12} sm={4} md={4} >
                    <Session text={t('themes')} href='/themes' Icon={StyleIcon} />
                </Grid>
            </Grid>
        </Fragment>

    )


}
export default HomePage