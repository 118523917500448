import 'chartjs-plugin-annotation'
import React, { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'


import { t } from 'react-switch-lang'



const moment = require('moment-timezone')




const _ = require('lodash')



export const DashBoardTokenChart = ({ title, data = {}, row = [], column = [], mean = false }) => <DashBoardSingleChart title={title} row={row} column={column} mean={mean} dataSetLabel='Redeem Tokens' color='#FF0000' />
export const DashBoardRecordChart = ({ title, data = {}, row = [], column = [], mean = false }) => <DashBoardSingleChart title={title} row={row} column={column} mean={mean} dataSetLabel='Redeem Records' color='#0000FF' />

const DashBoardSingleChart = ({ row = [], column = [], mean = false, dataSetLabel = 'Redeem Records', color = '#0000FF' }) => {
    const avg = _.floor(_.mean(row), 1) || null

    if (_.size(row === 0)) return <div style={{ height: '400px' }}></div>


    var meanColor = color + 'CC'
    var backgroundColor = color + '4D'

    const Chart = () =>
        <Bar data={{
            labels: column,
            datasets: [
                {
                    label: dataSetLabel,
                    backgroundColor: backgroundColor,
                    data: row,
                    borderWidth: 1,
                },
            ],
        }}
            width={null}
            height={400}
            options={{
                animation: {
                    duration: 500,
                    easing: 'easeInOutQuad'
                },
                title: {
                    display: false,
                },
                tooltips: {
                    mode: 'index',
                    intersect: true
                },
                responsive: true,
                defaultColor: 'red',
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                    ],
                },
                maintainAspectRatio: false,
                aspectRatio: 1,
                annotation: {
                    events: ['click'],
                    annotations: mean ?
                        [
                            {
                                id: 'a-line-1', // optional
                                type: 'line',
                                mode: 'horizontal',
                                scaleID: 'y-axis-0',
                                value: avg,
                                borderColor: meanColor,
                                borderWidth: 2,
                                borderDash: [5, 5],
                                label: {
                                    xAdjust: 10,
                                    yAdjust: 8,
                                    enabled: true,
                                    content: t('mean'),
                                    backgroundColor: meanColor,
                                    position: "left",
                                },
                                onClick: function (e) {
                                    console.log(e)
                                }

                            },
                        ]
                        : []
                },
            }}
        />

    return <div>
        <Chart />
    </div>
}
export default DashBoardSingleChart