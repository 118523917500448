
import {
    Box, Button, Divider, FormControl, FormControlLabel, FormLabel,
    Grid, GridList, GridListTile, IconButton, isWidthDown, makeStyles, Paper,
    Radio, RadioGroup, TextField, Typography, useTheme, withWidth,
    InputAdornment, withStyles, Switch, MenuItem, Chip
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

// const maxWidth = '1240px'
const maxWidth = '100%'

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        boxSizing: 'border-box',
        margin: 'auto',
        padding: theme.spacing(3),
        maxWidth: maxWidth
    },
    title: {
        width: '100%',
        boxSizing: 'border-box',
        maxWidth: maxWidth,
        paddingBottom: theme.spacing(3),
    },
    titleAction: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        overflow: 'hidden',
        flexWrap: 'wrap',
        boxSizing: 'border-box',
        maxWidth: maxWidth,
        alignItems: 'center'
    },

}))




export const Container = (props) => {
    const classes = useStyles()
    return <div className={classes.container} {...props} />
}


export const TitleContainer = (props) => {
    const classes = useStyles()
    return <div className={classes.title} {...props} />
}

export const TitleActionContainer = ({ title, children, ...rest }) => {
    const classes = useStyles()
    const theme = useTheme()
    return (
        <div className={classes.titleAction} {...rest} >
            <Typography color="inherit" variant="h4" component="h1" className='bold' style={{ paddingBottom: theme.spacing(3), flexGrow: 1 }}>
                {title}
            </Typography>
            <div style={{ paddingBottom: theme.spacing(3) }}>
                {children}
            </div>
        </div>
    )
}








