import { Box, Button, makeStyles, TextField, useTheme } from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import { DataRow, DataSession } from 'components/DataSession'
import { useLocale } from 'provider/LocaleProvider'
import { Form, useFormikContext } from 'formik'
const moment = require('moment-timezone')



const useStyles = makeStyles(theme => ({

}))


const GenerateAuto = props => {
    const { t } = useLocale()
    const classes = useStyles()
    const theme = useTheme()
    const formik = useFormikContext()
    const { values, touched, errors, validateForm, handleChange, setFieldValue, setFieldTouched, isValid } = formik

    useEffect(() => {
        if (!values.tokens_count) {
            setFieldValue('tokens_count', 1)
        }
    }, [])

    const handleReset = () => {
        setFieldValue('step', 0)
        setFieldValue('mode', null)
    }

    const handleNext = async (e) => {
        e.preventDefault()
        setFieldTouched('tokens_count', true, true)
        var invalid = await validateForm()
        console.log('handleNext => invalid', invalid)
        document.getElementById("app").scroll(0, 0)
        if (!invalid.tokens_count) {
            setFieldValue('step', values.step + 1)
        }
    }

    return (
        <Form onSubmit={handleNext} style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }} >
            <DataSession title={t('token_amount')} flexGrow={1}>
                <DataRow name={t('how_many_token')} helpText={t('how_many_token_helper')}>
                    <TextField name='tokens_count'
                        required
                        type='number'
                        error={Boolean(touched.tokens_count && errors.tokens_count)}
                        helperText={touched.tokens_count && errors.tokens_count}
                        value={formik.values.tokens_count}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        style={{ margin: 0 }} />
                </DataRow>
            </DataSession>
            <Box display='flex' style={{ marginTop: '24px' }}>
                <Button onClick={handleReset} variant="contained" className={classes.button} style={{ color: 'white', backgroundColor: theme.palette.error.main }}>
                    {t('cancel')}
                </Button>
                <Box flexGrow={1} />
                <Button variant="outlined" onClick={handleReset} className={classes.button} >
                    {t('back')}
                </Button>
                <Button
                    type='submit'
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: '8px' }}
                    onClick={handleNext}
                >
                    {t('next')}
                </Button>
            </Box>
        </Form>
    )
}

export default GenerateAuto