import EditIcon from '@material-ui/icons/Edit'
import clsx from 'clsx'
import React, { useLayoutEffect, useRef, useState } from 'react'
import HelpPopHover from './HelpPopHover'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useLocale } from '../provider/LocaleProvider'
import DataTextField from './DataTextField'
import useRect from 'hooks/useRect'
import purple from '@material-ui/core/colors/purple'
import useDelay from 'hooks/useDelay'



import {
    Box, Button, Divider, FormControl, FormControlLabel, FormLabel,
    Grid, GridList, GridListTile, IconButton, isWidthDown, makeStyles, Paper,
    Radio, RadioGroup, TextField, Typography, useTheme, withWidth,
    InputAdornment, withStyles, Switch, MenuItem, Chip, Checkbox
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
const _ = require('lodash')
const moment = require('moment-timezone')
const useStyles = makeStyles(theme => ({
    grid_container: {
        padding: '12px 24px',
        overflow: 'hidden'
    },
    grid_name: {
        alignItems: 'start',
        minWidth: 'min(100px,100%)',
        maxWidth: '200px',
    },
    grid_value: {
        // minWidth: 'min(300px,calc(100% - 32px))',
    },

    text_body: {
        alignItems: 'center',
        lineHeight: '24px',
        maxHeight: '96px',
        overflow: 'hidden',
        overflowWrap: 'break-word'
    },
    divider: {
        marginTop: '8px',
        marginBottom: '16px'
    },
    button: {
        width: '80px',
    },
    title: {
        padding: '12px 24px 12px 24px',
        fontWeight: 'bold'
    },

    textField: {
        maxWidth: '300px'
    },
    paper: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        overflow: 'hidden'
    },
    autocompletePaper: {
        margin: 0
    },
    option: {
        // backgroundColor: 'white !important',
        // '&:hover': {
        //     backgroundColor: 'rgba(0,0,0,0.1) !important'
        // },
    }
}))


export const FormTextField = ({ id, name, value = null, setValue, ...rest }) => {

    const classes = useStyles()
    const theme = useTheme()
    function handleChange(e) {
        setValue(e.target.value)
    }

    const [ref, rect] = useRect()
    var isLabel = rect.width < 400

    const InputField = () => {
        return (
            <TextField
                ref={ref}
                label={isLabel && name}
                id={id}
                value={value}
                onChange={handleChange}
                InputProps={{
                    style: { verticalAlign: 'middle' },
                    startAdornment: !isLabel && <InputAdornment position="start">{name}</InputAdornment>,
                    // readOnly: true,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                variant='outlined'
                style={{ width: '100%', margin: '8px 0', backgroundColor: 'white' }}
                {...rest}
            />
        )
    }
    return InputField()
}



const PurpleSwitch = withStyles({
    switchBase: {
        color: purple[300],
        '&$checked': {
            color: purple[500],
        },
        '&$checked + $track': {
            backgroundColor: purple[500],
        },
    },
    checked: {},
    track: {},
})(Switch)


export const FormSwitch = ({ label = 'label', name, checked = null, handleChange }) => {
    return (
        <FormControlLabel
            labelPlacement='start'
            label={label}
            control={< Switch color="primary" checked={checked} onChange={handleChange} name={name} />}
            style={{ margin: 0, alignSelf: 'flex-start' }}
        />
    )
}

export const FormSelector = ({
    id, label, options = [], value = null, setValue = new Function(),
    getOptionLabel = (option) => option.label,
    getOptionValue = (option) => option.value,
    required,

    ...rest }) => {

    const classes = useStyles()
    return <TextField
        id={id}
        label={label}
        type="text"
        select
        fullWidth
        required={required}
        InputLabelProps={{
            shrink: true,
        }}
        variant="outlined"
        value={value}
        onChange={e => {
            setValue(e.target.value)
        }}
        {...rest}
    >
        {options.map((op, index) => {
            var value = _.isObject(op) ? getOptionValue(op, index) : op
            var label = getOptionLabel(op, index)
            return (
                <MenuItem key={value} value={value}>
                    {label}
                </MenuItem>
            )
        })}
    </TextField>
}

export function FormAutocomplete({ id, label, value = null, setValue = new Function(),
    getOptionSelected = (option, value) => option && value && option.id === value.id && option.group === value.group,
    getOptionLabel = (option) => option.label,
    getOptionValue = op => op,
    options = [],
    style,
    ...rest
}) {
    const classes = useStyles()
    return (
        <Autocomplete
            id={id}
            fullWidth
            openOnFocus
            options={options}
            classes={{
                option: classes.option,
                paper: classes.autocompletePaper
            }}
            value={value}
            onChange={(e, v) => setValue(getOptionValue(v))}
            getOptionSelected={getOptionSelected}
            getOptionLabel={getOptionLabel}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        label={label}
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}

                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off', // disable autocomplete and autofill
                        }}
                    />
                )
            }}
            style={{ backgroundColor: 'white', ...style }}
            {...rest}
        />
    )
}

export function FormMultiAutocomplete({ id, label, value = [], setValue = new Function(), placeholder,
    getOptionSelected,
    getOptionLabel = (option) => option.label,
    options = [],
    style,
    ...rest
}) {
    const classes = useStyles()

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
    const checkedIcon = <CheckBoxIcon fontSize="small" />

    return (
        <Autocomplete
            multiple
            openOnFocus
            id={id}
            fullWidth
            options={options}
            classes={{
                option: classes.option,
                paper: classes.autocompletePaper
            }}
            value={value}
            disableCloseOnSelect
            onChange={(e, v) => setValue(v)}
            getOptionSelected={getOptionSelected}
            getOptionLabel={getOptionLabel}
            renderOption={(option, { selected }) => (
                <React.Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {getOptionLabel(option)}
                </React.Fragment>
            )}
            renderInput={(params) => {
                return (
                    <TextField {...params} variant="outlined" label={label} placeholder={placeholder}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )
            }}
            style={{ backgroundColor: 'white', ...style }}
            {...rest}
        />
    )
}



export const FormColorTextField = ({ name, value = null, setValue, ...rest }) => {

    const classes = useStyles()
    const theme = useTheme()
    const timer = useRef()


    function handleChange(e) {
        var value = e.target.value
        // if (flag) {
        //     setFlag(false)
        //     setValue(value)
        //     setTimeout(() => {
        //         setFlag(true)
        //     }, 100)
        // }
        setValue(value)
    }

    const colorRef = useRef(null)


    const [ref, rect] = useRect()
    var isLabel = rect.width < 400

    function handleClick() {
        if (colorRef.current) {
            colorRef.current.click()
        }
    }
    const InputField = () => {
        return (
            <TextField
                ref={ref}
                label={isLabel && name}
                value={value}
                // onChange={handleChange}
                InputProps={{
                    onClick: handleClick,
                    style: { verticalAlign: 'middle' },
                    startAdornment: !isLabel && <InputAdornment position="start">{name}</InputAdornment>,
                    endAdornment: <InputAdornment position="end">
                        <input type='color' value={value} onChange={handleChange} ref={colorRef} />
                    </InputAdornment>,
                    readOnly: true,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
                variant='outlined'
                style={{ width: '100%', margin: '8px 0', backgroundColor: 'white' }}
            />
        )
    }
    return InputField()
}