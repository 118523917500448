import { Box, Button, Divider, FormControl, FormControlLabel, FormLabel, Grid, GridList, GridListTile, IconButton, isWidthDown, makeStyles, Paper, Radio, RadioGroup, TextField, Tooltip, Typography, useTheme, withWidth } from '@material-ui/core'
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { NavLink, useParams } from 'react-router-dom'
import DataTable from 'components/DataTable/DataTable'
import useApi from 'api/useApi'
import { useLocale } from 'provider/LocaleProvider'
import MetaSelecter from 'components/DataTableWithMeta/components/MetaSelecter'
import RefreshIcon from '@material-ui/icons/Refresh'
import EditIcon from '@material-ui/icons/Edit'
import CropFreeIcon from '@material-ui/icons/CropFree'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import ClearIcon from '@material-ui/icons/Clear'
import DeviceTable from 'components/DataTableWithMeta/DeviceTable'
import { useAuthData } from 'provider/AuthDataProvider'
const moment = require('moment-timezone')

var lodash = require('lodash')

const useStyles = makeStyles(theme => ({

}))


const DeviceList = props => {
    const { } = props

    const classes = useStyles()
    const [deviceList, setDeviceList] = useState()

    const { t } = useLocale()

    const api = useApi()
    const fieldOption = []
    const { organization } = useAuthData()

    const [metaField, setMetaField] = useState([])

    useEffect(() => {
        api.getDevices(setDeviceList)
    }, [organization])


    // useEffect(() => {
    //     if (deviceList && deviceList.length > 0)
    //         api.checkDeviceStatus(deviceList[1].id)
    // }, [deviceList])

    function handleExportCSV() {

    }

    function handleRefresh() {
        api.getDevices(setDeviceList)
    }



    return (
        <Fragment>
            <Box pb={3}>
                <Typography color="inherit" variant="h4" className='bold'>
                    {t('devices')}
                </Typography>
            </Box>
            <DeviceTable table_name='devices' data={deviceList} loading={deviceList === undefined} handleRefresh={handleRefresh} handleExportCSV={handleExportCSV} />
        </Fragment>
    )
}

export default DeviceList