import React, { useContext, useEffect, useRef, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { CircularProgress, makeStyles, TextField, Typography, useTheme } from '@material-ui/core'
import PropTypes, { object } from 'prop-types'
import DataTextField from './DataTextField'
import { useLocale } from '../provider/LocaleProvider'
import { Autocomplete } from '@material-ui/lab'
const useStyles = makeStyles((theme) => ({
    dialog: {
        width: 'min(800px, 100%)',
        overflow: 'hidden'
    },
    textField: {
        width: 'min(800px, 100%)',
        // height: '80px'
    },
    action: {
        width: '80px'
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
}))

export function DialogAutocomplete({ options }) {
    const classes = {}

    return (
        <Autocomplete
            id="dialog_autocomplete"
            fullWidth
            options={options}
            classes={{
                option: classes.option,
            }}
            // autoHighlight
            getOptionLabel={(option) => option.name}
            // renderOption={(option) => (
            //     <React.Fragment>
            //         <span>{countryToFlag(option.code)}</span>
            //         {option.label} ({option.code}) +{option.phone}
            //     </React.Fragment>
            // )}
            renderInput={(params) => {
                console.log('DialogAutocomplete -> params', params)
                return (
                    <TextField
                        {...params}
                        label="Choose a country"
                        variant="outlined"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                )
            }}
        />
    )
}

export const DialogTextField = props => {

    const classes = useStyles()

    return <DataTextField className={classes.textField} {...props} />
}


const EditDialog = ({ open, title, children, handleSave, handleClose, confirmText, cancelText, confirmDisabled = false, loading = false, ...rest }) => {

    const { t } = useLocale()

    const classes = useStyles()

    const [flag, setFlag] = useState(false)


    const handleKeyPress = (e) => {
        if (e.keyCode == 13 || e.charCode == 13) {
            handleClick()
        }
    }

    const handleClick = () => {
        if (flag === false) {
            handleSave()
            setFlag(true)
            setTimeout(() => setFlag(false), 1000)
        }
    }

    return (
        <div>
            <Dialog
                classes={{ paper: classes.dialog }}
                maxWidth='md'
                open={open}
                onClose={handleClose}
                onKeyPress={handleKeyPress}
                {...rest}
            >
                <DialogTitle id="alert-dialog-title" style={{ padding: '24px 24px 8px 24px' }}>
                    <Typography variant='h5' color='textPrimary'>{title}</Typography>
                </DialogTitle>
                <DialogContent>
                    {children}
                </DialogContent>
                <DialogActions style={{ padding: '8px 24px 24px 24px' }}>
                    {loading && <CircularProgress size={20} />}
                    <Button className={classes.action} onClick={handleClose}>
                        {cancelText || t('cancel')}
                    </Button>
                    <Button disabled={confirmDisabled || flag} className={classes.action} variant='contained' onClick={handleClick} color="primary" autoFocus>
                        {confirmText || t('save')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}

EditDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string,
    children: PropTypes.any.isRequired,
    handleClose: PropTypes.func,
    handleSave: PropTypes.func,
}


export default EditDialog