import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Popper from '@material-ui/core/Popper'
import Fade from '@material-ui/core/Fade'
import { collapseClasses, Divider, ListSubheader, Paper, Toolbar, Tooltip, Typography } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import CommentIcon from '@material-ui/icons/Comment'
import { useLocale } from '../../../provider/LocaleProvider'
import ViewColumnIcon from '@material-ui/icons/ViewColumn'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.background.paper + 'e',
    },
    root: {
        width: '100%',
        opacity: 1,
        // maxWidth: 500,
        // minWidth: 500,
        // backgroundColor: theme.palette.background.paper,
    },
}))
const _ = require('lodash')

export default function TransitionsPopper(props) {
    const classes = useStyles()
    const { displayCells } = props
    const { t } = useLocale()
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = (event) => {
        console.log('handleClick -> displayCells', displayCells)
        if (_.size(displayCells) > 0) {
            setAnchorEl(anchorEl ? null : event.currentTarget)
        }
        else {
            setAnchorEl(null)
        }
    }

    const handleClosePopper = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'transitions-popper' : undefined

    return (
        <div>
            <Tooltip title={t('extra_columns')}>
                <IconButton onClick={handleClick} style={{ padding: '4px' }}>
                    <ViewColumnIcon />
                </IconButton>
            </Tooltip>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                transition
                placement={'bottom-end'}
                modifiers={{
                    flip: {
                        enabled: false,
                    },
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: 'window',
                    },
                }}

            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper className={classes.paper} >
                            <CheckboxList handleClosePopper={handleClosePopper} {...props} />
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </div>
    )
}



function CheckboxList({ displayCells = [], setDisplayCells = [], handleClosePopper = new Function() }) {
    const { t } = useLocale()
    const classes = useStyles()

    const handleToggle = (field) => (value) => {
        var temp = _.cloneDeep(displayCells)
        var index = _.findIndex(temp, { id: field.id })
        if (field.display) {
            _.set(temp, index + '.display', false)
        }
        else {
            _.set(temp, index + '.display', true)
        }
        setDisplayCells(temp)
    }

    const grouped = _.groupBy(displayCells, 'group')

    const generalFieldOption = grouped.general || []
    const metaFieldOption = grouped.metadata || []


    return (
        <Fragment>
            <Toolbar variant='dense' style={{ paddingLeft: '12px', paddingBottom: '0' }}>
                <Typography variant='h6' color='textPrimary'>
                    {t('extra_columns')}
                </Typography>
                <div style={{ flexGrow: 1 }} />
                <IconButton aria-label='delete' className={classes.closeIcon} onClick={handleClosePopper} >
                    <CloseIcon fontSize='large' style={{ width: '32px', height: '32px' }} />
                </IconButton>
            </Toolbar >
            <Divider />
            <List className={classes.root} style={{ maxHeight: '400px', overflow: 'auto' }}>
                <ListSubheader>{t('general')}</ListSubheader>
                {generalFieldOption.map((field) => {
                    const labelId = `checkbox-list-label-${field.id}`
                    return (
                        <ListItem key={field.id} role={undefined} dense button onClick={handleToggle(field)}>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={field.display}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                    style={{ minWidth: 'auto' }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={t(`${field.label}`)} />
                        </ListItem>
                    )
                })}
                {metaFieldOption.length > 0 && <ListSubheader>{t('meta.metadata')}</ListSubheader>}
                {metaFieldOption.map((field) => {
                    const labelId = `checkbox-list-label-${field.id}`
                    return (
                        <ListItem key={field.id} role={undefined} dense button onClick={handleToggle(field)}>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={field.display}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                    style={{ minWidth: 'auto' }}

                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${field.label}`} />
                        </ListItem>

                    )
                })}
            </List>
        </Fragment>

    )
}