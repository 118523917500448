import { IconButton, InputAdornment, makeStyles, TextField, useTheme } from '@material-ui/core'
import React, { useMemo, useState } from 'react'
import clsx from 'clsx'
import PropTypes, { object } from 'prop-types'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { useLocale } from 'provider/LocaleProvider'

const useStyles = makeStyles((theme) => ({
    textField: {
        width: 'min(800px, 100%)',
        marginTop: theme.spacing(1),
        // height: '70px',
        marginBottom: theme.spacing(1),
    },
    input: {
        // paddingTop: theme.spacing(0.5),
        padding: theme.spacing(3, 2),
    },
    // input_disalbed: {
    //     backgroundColor: '#ececec'
    // },
    label: {
        // paddingTop: theme.spacing(0.5),
        color: 'black', //theme.palette.primary.main,
    },
    label_disabled: {
        textDecoration: 'line-through'
    }
}))


const DataTextField = ({
    data,
    setData,
    data_key,
    className,
    onValueChange,
    setValue,
    error,
    helperText,
    name,
    maxLength,
    minLength,
    required,
    noSpace,
    onlyWord,
    value = null,
    type,
    max,
    min,
    strikethrough,
    noSymbol,
    onInput,
    ...rest }) => {
    const { t } = useLocale()
    const classes = useStyles()
    const [helpTest, setHelpTest] = useState('')
    const [isError, setIsError] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    function handleChange(event) {
        checkError(event.target.value)
        if (onValueChange) onValueChange(event.target.name, event.target.value)
        if (setValue) setValue(event.target.value)
        // onValueChange(event.target.name, event.target.value)
        if (setData == null) return
        if (data_key && data) {
            setData({ ...data, data_key: event.target.value })
        }
        else {
            setData(event.target.value)
        }
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const checkError = (value) => {
        if (error == true) {
            setHelpTest(helperText)
            setIsError(true)
            return true
        }
        if (required && value == '') {
            setHelpTest(t('required_field'))
            setIsError(true)
            return true
        }
        if (minLength && value.length < minLength) {
            setHelpTest(`Required ${minLength} or more characters/digits`)
            setIsError(true)
            return true
        }
        if (maxLength && value.length > maxLength) {
            setHelpTest(t('str_min', { max: maxLength }))
            setIsError(true)
            return true
        }
        setIsError(false)
        return false
    }

    return (
        <TextField id={name}
            className={clsx(classes.textField, className)}
            name={name}
            error={isError}
            helperText={isError ? helpTest : ''}
            onChange={handleChange}
            type={type == 'password' ? (showPassword ? 'text' : 'password') : type || 'text'}
            required={required}
            autoComplete='off'
            InputProps={{
                classes: {
                    input: classes.input,
                    disabled: classes.input_disalbed,
                },
                endAdornment:
                    type == 'password' &&
                    <InputAdornment InputAdornment position="end" >

                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment >

            }}
            InputLabelProps={{
                classes: {
                    root: classes.label,
                    disabled: strikethrough && classes.label_disabled,
                },
                shrink: true,
            }}
            onInput={(e) => {
                if (noSpace) e.target.value = e.target.value.trim()
                if (noSymbol) e.target.value = e.target.value.replace(/[^\w-_. ]*$/, '')
                if (type == 'number') {
                    e.target.value = parseInt(e.target.value)
                    if (max && parseInt(e.target.value) > parseInt(max)) {
                        e.target.value = parseInt(max)
                    }
                    else if (min && parseInt(e.target.value) <= parseInt(min)) {
                        e.target.value = parseInt(min)
                    }
                }
                if (onInput) onInput(e)
            }}
            placeholder={ t('n/a')}   
            value={value || ''}
            variant='outlined'
        
            {...rest}
        />
    )
}

DataTextField.propTypes = {
    value: PropTypes.string,
    onValueChange: PropTypes.func,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    name: PropTypes.string,
}

export default DataTextField