import { Box, Button, Grid, makeStyles, Toolbar, Typography, useTheme } from '@material-ui/core'
import React, { useContext, useEffect, useState, useRef, useLayoutEffect, useMemo, Fragment } from 'react'
import DataTableWithMeta from 'components/DataTableWithMeta/DataTableWithMeta'
import DataTable from 'components/DataTable/DataTable'
import { DataRow, DataSession, TextDataRow } from 'components/DataSession'
import RecordTable from 'components/DataTableWithMeta/RecordTable'
import MetaTable from 'components/MetaTable/MetaTable'
import { useHistory, useParams, withRouter } from 'react-router-dom'
import useApi from 'api/useApi'
import RedeemDialog from './RedeemDialog'
import { useLocale } from 'provider/LocaleProvider'
import { valHooks } from 'jquery'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useSnack } from 'provider/SnackbarProvider'
var QRCode = require('qrcode')
// import QRCode from 'components/davidshimjs-qrcodejs-04f46c6/qrcode'
const moment = require('moment-timezone')
const useStyles = makeStyles(theme => ({
    body: {
        // flex: 1,
        padding: theme.spacing(3),
        // background: '#eaeff1',
        boxSizing: 'border-box',
        // overflowY: 'scroll'
    },
}))


const time = str => {
    if (!str) return null
    return moment(str).format('DD/MM/YYYY, hh:mm:ss')
}

const generalField = ['campaign_id', 'channel', 'id', 'redeem_token_created_at']

const DetailsView = props => {
    const { t } = useLocale()
    const { campaignId } = useParams()
    const classes = useStyles()
    const { details, records, metaField, refresh } = props

    const history = useHistory()
    const [open, setOpen] = useState(false)
    const userRequest = useApi()
    const snack = useSnack()


    function handleEdit() {
        history.push(`/campaigns/${campaignId}/redeem_tokens/edit_tokens`, { selected: [details.redeem_token] })
    }
    function handleExportPDF(selected) {
        QRCode.toDataURL(details.redeem_token, { errorCorrectionLevel: 'high' }, (err, url) => {
            const a = document.createElement('a')
            document.body.appendChild(a)
            a.href = url
            a.download = `qr_code_${moment().format('YYYY_MM_DD')}.png`
            a.click()
            window.URL.revokeObjectURL(url)
        })
    }


    return (
        <Fragment>
            <Toolbar>
                <Grid container alignItems="center" spacing={1}>
                    <Grid item xs >
                        <Typography noWrap variant='h5' color='primary' >{t('token_details')}</Typography>
                    </Grid>
                    <Grid item  >
                        <Grid item container alignItems="center" spacing={1}>
                            <Grid item >
                                <Button className={classes.button} onClick={handleEdit} variant='outlined' color='primary' > {t('edit')} </Button>
                            </Grid>
                            <Grid item >
                                <Button className={classes.button} onClick={handleExportPDF} variant='outlined' color='primary' > {t('download')} </Button>
                            </Grid>
                            <Grid item >
                                <Button className={classes.button} onClick={() => setOpen(true)} variant='outlined' color='primary' > {t('redeem')} </Button>
                            </Grid>
                            <Grid item >
                                <CopyToClipboard text={details.token_qr} onCopy={() => snack.info(t('copied_to_clipboard'))}>
                                    <Button className={classes.button} variant='outlined' color='primary' > {t('share')} </Button>
                                </CopyToClipboard>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
            <DataSession>
                <TextDataRow name={t('token_id')} value={details.id} />
                <TextDataRow name={t('redeem_token')} value={details.redeem_token} />
                <DataRow name={t('redeem_token') + ' url'}>
                    <a target='_blank' href={details.token_qr}>
                        {details.token_qr}
                    </a>
                </DataRow>
                <TextDataRow name={t('identifier')} value={details.identifier} />
                <TextDataRow name={t('status')}
                    helpText={t('status_helper')}
                    style={{ color: details.is_active ? 'limegreen' : 'red' }}
                    value={details.is_active ? t('active') : t('inactive')}
                />
                <TextDataRow name={t('times_redeemed')} value={details.times_redeemed} />
                <TextDataRow name={t('last_redeemed_at')} value={time(details.last_redeemed_at)} />
                <TextDataRow name={t('pin')} value={details.redeem_pin} />
                <TextDataRow name={t('is_reusable')} value={details.is_reusable != false ? t('yes') : t('no')} />
                <TextDataRow name={t('has_reward')} value={details.has_reward != false ? t('yes') : t('no')} />
                <TextDataRow name={t('created_at')} value={time(details.created_at)} />
                <TextDataRow name={t('started_at')} value={time(details.started_at)} />
                <TextDataRow name={t('expired_at')} value={time(details.expired_at)} />
            </DataSession>

            <MetaTable metadata={details.metadata} />
            <Box className='flexCol' width='100%' mt={3}>
                <Box className='flexRow' mb={2} alignItems='center'>
                    <Typography noWrap variant='h5' color='primary' >{t('redeem_records')}</Typography>
                </Box>
                <RecordTable table_name='token_details' data={records} />
            </Box>
            <RedeemDialog open={open} setOpen={setOpen} token={details} refresh={refresh} />
        </Fragment>

    )
}


export default withRouter(DetailsView)