import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import { NavLink, useParams } from 'react-router-dom'
import DataTable from 'components/DataTable/DataTable'
import useApi from 'api/useApi'
import { useLocale } from 'provider/LocaleProvider'
import MetaSelecter from 'components/DataTableWithMeta/components/MetaSelecter'
import RefreshIcon from '@material-ui/icons/Refresh'
import EditIcon from '@material-ui/icons/Edit'
import CropFreeIcon from '@material-ui/icons/CropFree'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import ClearIcon from '@material-ui/icons/Clear'
import DeviceTable from 'components/DataTableWithMeta/DeviceTable'
import { useAuthData } from 'provider/AuthDataProvider'
import { FormAutocomplete, FormMultiAutocomplete, FormSelector } from 'components/DataForm'
import {
    Box, Button, Divider, FormControl, FormControlLabel, FormLabel,
    Grid, GridList, GridListTile, IconButton, isWidthDown, makeStyles, Paper,
    Radio, RadioGroup, TextField, Typography, useTheme, withWidth, AppBar,
    Tab, Tabs, InputAdornment, withStyles, Switch, MenuItem, Chip, Checkbox
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import { TitleActionContainer } from 'layout/Container'
import { TextDataRow } from 'components/DataSession'
const moment = require('moment-timezone')
var lodash = require('lodash')

const useStyles = makeStyles(theme => ({

}))

const _ = require('lodash')
const naturalCompare = require('string-natural-compare')


const Track = ({ index = 0, identifiers = [], onClick, ...rest }) => {
    const { t } = useLocale()
    // var color = colors[index % colors.length]
    var color = 'rgba(0,0,0,0.2)'
    var radius = '8px'
    return (
        <Paper className='flexCol' style={{ height: '100%', borderRadius: radius }} {...rest} >
            <Typography varient='body1' align='center'
                onClick={onClick}
                style={{ backgroundColor: color, padding: '12px', borderTopLeftRadius: radius, borderTopRightRadius: radius, cursor: 'pointer' }}
            >
                {t('track') + ' ' + (index + 1)}
            </Typography>
            <div style={{ padding: '12px 12px 0 12px', minHeight: '40px', flex: '1' }}>
                {identifiers.map(item => {
                    return <Typography varient='body2' align='center' noWrap>
                        {item.token_identifier}
                    </Typography>
                })}
            </div>
            <Typography color='textSecondary' varient='body2' align='center' noWrap style={{ margin: '12px 0' }}>
                {identifiers.length + ' ' + t(`identifier${identifiers.length > 0 ? 's' : ''}`)}
            </Typography>
        </Paper>
    )
}
const IdentifierContainer = ({ identifier = '', tracks = [], onClick, ...rest }) => {
    const { t } = useLocale()
    // var color = colors[index % colors.length]
    var color = 'rgba(0,0,0,0.2)'
    var radius = '8px'
    const theme = useTheme()
    return (
        <Paper style={{ height: '100%', padding: '12px', display: 'flex', overflow: 'hidden' }} {...rest} >
            <div style={{ minWidth: '50px', cursor: 'pointer' }} onClick={onClick}>
                <Typography noWrap> {t('identifier') + ' '} </Typography>
                <Divider style={{ margin: '4px -8px' }} />
                <Typography noWrap> {t('track') + ' '} </Typography>

            </div>
            <Divider orientation='vertical' flexItem style={{ margin: '0 8px' }} />
            <div style={{ flexGrow: 1, flexBasis: 0 }}>
                <Typography> {identifier} </Typography>
                <Divider style={{ margin: '4px -8px' }} />
                <Typography>
                    {_.reduce(tracks, (a, o, i) => {
                        if (i > 0) {
                            return a + ', ' + (o.track_id + 1)
                        }
                        return a + (o.track_id + 1)
                    }, '')}
                    {' '}
                    <span style={{ display: 'inline-block', color: theme.palette.text.secondary }}>
                        {`( ${tracks.length} ${t(`track${tracks.length > 0 ? 's' : ''}`)} )`}
                    </span>
                </Typography>
            </div>


        </Paper>
    )
}

const compare = (path) => (a, b) => {
    var getValue = (v) => {
        return _.get(v, path, '').toString()
    }
    return naturalCompare(getValue(a), getValue(b))
}



const TabPanel = ({ value, index, children }) => {
    if (value === index) {
        return children || null
    }
    return null

}


const DeviceOperation = props => {
    const { } = props

    const classes = useStyles()

    const { t } = useLocale()



    const api = useApi()
    const [tab, setTab] = useState(0)
    const [device, setDevice] = useState({})
    const [redeems, setRedeems] = useState([])

    const [campaignList, setCampaignList] = useState([])

    const indentifiers = [
        'brand1',
        'brand2',
        'brand3',
        'brand4',
        'brand5',
        'brand6',
        'brand7',
        'brand8',
        'brand9',
        'brand10',
    ].sort(compare(''))


    const [deviceList, setDeviceList] = useState([])

    const [selectedDevice, setSelectedDevice] = useState(null)



    const trackNumber = useCallback(_.get(device, 'device_spec.total_tracks') || 0, [device])


    const redeemsByTrack = useMemo(() => {
        try {
            return _.groupBy(redeems.sort(compare('token_identifier')), 'track_id')
        }
        catch (e) {
            return []
        }
    }, [redeems])

    const redeemsByIdentifier = useMemo(() => {
        try {
            return _.groupBy(redeems.sort(compare('track_id')), 'token_identifier')
        }
        catch (e) {
            return []
        }
    }, [redeems])


    useEffect(() => {
        api.getDevices(setDeviceList)
    }, [])






    return (
        <Fragment>
            <Paper style={{ marginBottom: '24px' }}>
                <FormAutocomplete options={deviceList} getOptionLabel={op => _.toString(op.name)}
                    label={t('campaigns')}
                    id={'campaigns'}
                    getOptionSelected={(op, value) => op.id === value.id}
                    value={selectedDevice} setValue={(v) => {
                        setSelectedDevice(v)
                        // handleClear()
                    }}
                />
            </Paper>
            <Grid container spacing={3} style={{ flexWrap: 'wrap-reverse' }}>
                <Grid item xs={12} sm={8}  >
                    <Grid container spacing={1} style={{ margin: '-8px -4px' }} >
                        {_.times(trackNumber, ((index) => {
                            const trackRedeems = redeemsByTrack[index] || []
                            const gridSize = (size) => trackNumber > 4 ? size : 12 / trackNumber
                            return (
                                <Grid item xs={gridSize(6)} sm={gridSize(6)} md={gridSize(3)} style={{ padding: '8px 4px' }} >
                                    <Track index={index} identifiers={trackRedeems} />
                                </Grid>
                            )
                        }))}
                    </Grid>
                </Grid>
                <Grid item item xs={12} sm={4}>
                    <Paper style={{ position: 'sticky', top: '60px' }}>
                        <div style={{ padding: '12px' }}>
                            <img src={_.get(device, 'device_spec.device_guide_image')} style={{ aspectRatio: '2', width: '100%', height: 'auto', backgroundColor: 'rgba(0,0,0,0.05)' }} />
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Fragment >
    )
}

export default DeviceOperation