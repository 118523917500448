import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import CommentIcon from '@material-ui/icons/Comment'
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core'
import clsx from 'clsx'
const useStyles = makeStyles((theme) => ({
    label: {
        minHeight: '60px'
    }
}))

const RadioList = props => {
    const classes = useStyles()
    const { name, list, value, onChange, row } = props
    return (
        <FormControl component="fieldset" >
            <RadioGroup row={row} name={name} value={value != null && value.toString()} onChange={onChange}>
                {list.map((item) => {
                    return <FormControlLabel className={classes.label} value={item.value != null && item.value.toString()} control={<Radio color='primary' />} label={item.label} />
                })}
            </RadioGroup>
        </FormControl >
    )


}

export default RadioList