import React, { useState, useEffect, useContext } from 'react'
import { Alert } from '@material-ui/lab'
import { CircularProgress, Grow, makeStyles, Snackbar, useTheme } from '@material-ui/core'
import { useHistory, useLocation, useParams, withRouter } from 'react-router-dom'
import useApi from 'api/useApi'


const useCampaign = (props) => {

    const location = useLocation()
    const history = useHistory()
    const api = useApi()

    const { campaignId } = useParams()
    const [campaign, setCampaign] = useState()
    const [campaignList, setCampaignList] = useState()





    useEffect(() => {
        getCampaignDetails()
        getCampaignList()
    }, [location.pathname])


    function getCampaignList() {
        api.getCampaigns(setCampaignList, { limit: 10 })
    };

    function getCampaignDetails() {
        api.getCampaignDetails(setCampaign)
    };


    function getRecordMetaField() {
        if (campaign && campaign.metadata_key_lists) {
            var fields = campaign.metadata_key_lists.redeem_records
            if (fields) fields = fields.sort()
            return fields
        }
        return []
    }

    function getTokenMetaField() {
        if (campaign && campaign.metadata_key_lists) {
            var fields = campaign.metadata_key_lists.redeem_tokens
            if (fields) fields = fields.sort()
            return fields
        }
        return []
    }


    const state = {
        campaign_id: campaignId,
        campaign,
        campaignList,
        recordMetaField: getRecordMetaField(),
        tokenMetaField: getTokenMetaField(),

    }

    return state
}

export default useCampaign