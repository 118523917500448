import React, { useEffect, useState } from 'react'

import 'chartjs-plugin-annotation'

import {
    IconButton, Paper, Tooltip,
    Typography, Table, TableHead, TableCell, TableRow, makeStyles
} from '@material-ui/core'
import DashBoardSingleChart, { DashBoardRecordChart } from './DashBoardSingleChart'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useLocale } from 'provider/LocaleProvider'
import Utils from 'misc/Utils'
import { t } from 'react-switch-lang'

const _ = require('lodash')
const moment = require('moment-timezone')
const useStyles = makeStyles(theme => ({
    table: {
        overflow: "auto",
        "& .MuiTableCell-root": {
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            padding: '6px 12px',
            borderLeft: '1px solid',
            borderColor: theme.palette.divider,
            '& a': {
                display: 'inline'
            },
            '& .MuiButtonBase-root': {
                padding: 0
            },
            '& .MuiSvgIcon-root': {
                height: '24px'
            }
        },
        "& .MuiTableRow-root": {
            height: '40px'
        },
        "& .MuiTableHead-root": {
            backgroundColor: theme.palette.table.main,
        },
    }
}))



const HourlyChart = ({ data, title = 'title', dataSetLabel = t('label'), color = '#FF0000' }) => {
    const classes = useStyles()
    const [hourly, setHourly] = useState([])
    const { t } = useLocale()
    useEffect(() => {
        var grouped = _.groupBy(data, v => moment(v).format('HH:00'))
        grouped = _.mapValues(grouped, o => o.length)
        var array = _.times(24, n => {
            var key = moment().set({ hour: n }).format('HH:00')
            return ({
                key: key,
                value: grouped[key] || 0
            })
        })
        setHourly(array)
    }, [data])

    function exportCSV() {
        Utils.obj2csv(hourly, [{ label: 'hour', value: (i) => i.key }, 'value'], 'record_hourly_report', true)
    }

    var row = _.map(hourly, (v) => v.value)
    var column = _.map(hourly, (v) => v.key)

    return (
        <Paper>
            <div style={{ padding: '24px' }}>
                <Typography variant='body1' color='textPrimary' className='bold' paragraph style={{ display: 'inline-flex' }}>
                    {title}
                </Typography>
                <Tooltip title={t('export_data')}>
                    <IconButton onClick={() => exportCSV()}>
                        <GetAppIcon xs={1} />
                    </IconButton>
                </Tooltip>
                <DashBoardSingleChart row={row} column={column} dataSetLabel={dataSetLabel} color={color} mean />
            </div>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell padding='checkbox'>{t('rank')}</TableCell>
                        <TableCell>{t('hour')}</TableCell>
                        <TableCell>{t('count')}</TableCell>
                    </TableRow>
                </TableHead>
                {_.map(_.take(_.orderBy(hourly, 'value', 'desc'), 5), ({ key, value }, index) => {
                    return (
                        <TableRow>
                            <TableCell padding='checkbox'>{index + 1}</TableCell>
                            <TableCell>{key}</TableCell>
                            <TableCell>{value}</TableCell>
                        </TableRow>
                    )
                })}
            </Table>
        </Paper>
    )
}

export default HourlyChart