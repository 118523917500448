import { Box, Button, Card, Divider, IconButton, Toolbar, Typography } from '@material-ui/core'
import SwapVertIcon from '@material-ui/icons/SwapVert'
import { FormTextField } from 'components/DataForm'
import { UploadButton } from 'components/FileUploader'
import { useLocale } from 'provider/LocaleProvider'
import { Fragment } from 'react'
import { ReactSortable } from 'react-sortablejs'
// Import Swiper styles
import 'swiper/swiper.scss'
import '../theme.css'
import CloseIcon from '@material-ui/icons/Close'
import DragHandleTwoToneIcon from '@material-ui/icons/DragHandleTwoTone'
import { useSnack } from 'provider/SnackbarProvider'
function getBlobType(blob) {
    if (blob && blob.type) {
        if (blob.type.match(/^image/g)) {
            return 'image'
        }
        else if (blob.type.match(/^video/g)) {
            return 'video'
        }
    }
    return 'image'
}

function createSlide({ scale_type = 'contain', url, blob, file_name }) {
    var type = getBlobType(blob)
    return (
        {
            "type": type,
            "scale_type": scale_type,
            "url": url,
            "blob": blob,
            file_name: blob.name || file_name
        }
    )
}



const FormMedia = ({ slide, index, slides, setSlides }) => {
    const { t } = useLocale()
    const scale_type = slide.scale_type
    const blob = slide.blob
    const thumbnail_id = `thumbnail${index}`
    const upload_id = `slide_upload${index}`
    const scale_type_id = `scale_type${index}`


    const setSlideValue = (name, getValue = v => v) => (value) => {
        var _slides = [...slides]
        if (_slides[index]) {
            _slides[index][name] = getValue(value)
            setSlides(_slides)
        }
    }

    const handleRemove = () => {
        var _slides = [...slides]
        if (_slides[index]) {
            _slides.splice(index, 1)
            setSlides(_slides)
        }
    }



    const Media = () => {
        var url = slide.url
        var media_type = slide.type
        const blob = slide.blob
        if (blob && blob.name !== undefined) {
            media_type = getBlobType(blob)
            url = URL.createObjectURL(blob)

        }
        if (media_type === 'image') {
            return <img src={url} className='slide-img-preview' />
        }
        else if (media_type === 'video') {
            return <video className='slide-img-preview' src={url} />

        }

    }

    return (
        <Card className='flexRow' style={{ marginBottom: '12px', position: 'relative' }}>
            <Box p='8px' pb='8px' className='handle flexCol-center' style={{ backgroundColor: '#dce5f6' }}>
                <DragHandleTwoToneIcon />
            </Box>

            {/* <Box p='8px' pb='4px'  >
                {index + 1}. {blob ? blob.name : slide.file_name}
            </Box> */}
            <Box p='8px' className='flexRow' style={{ flexGrow: 1 }}>
                {Media()}
                <div className='flexCol fullWidth'>
                    <div className='flexRow' style={{ verflowWrap: 'anywhere' }}>
                        {blob ? blob.name : slide.file_name}
                        <div style={{ flexGrow: 1 }} />
                        <div className='flexRow' style={{ height: '20px' }}>
                            <UploadButton id={upload_id} size='small' variant='contained' icon
                                setFile={setSlideValue('blob')}
                                max={null}
                                style={{ marginRight: '8px', height: '20px' }}
                                accept="video/*,image/*"
                            />
                            <IconButton size='small' variant='contained' onClick={handleRemove}><CloseIcon /></IconButton>
                        </div>
                    </div>
                    <div style={{ flexGrow: 1 }} />
                    <div>
                        <label for={scale_type_id}>{t('scale_type')}: </label>
                        <select name="scale_type" id={scale_type_id} value={scale_type} onChange={setSlideValue('scale_type', e => e.target.value)}>
                            <option value="contain">{t('contain')}</option>
                            <option value="fill">{t('fill')}</option>
                            <option value="cover">{t('cover')}</option>
                        </select>
                    </div>
                </div>

            </Box>

        </Card >
    )
}



const SlideForm = ({ idlePage, handleSetValue, slides, setSlides }) => {
    const { t } = useLocale()
    const snack = useSnack()
    function addSlides(blob) {
        if (slides.length < 10) {
            var _slides = [...slides]
            _slides.push(createSlide({ blob: blob }))
            setSlides(_slides)
        }
        else {
            snack.error(t('exceeds_max_content_number'))
        }
    }
    return (
        <Fragment>
            <Toolbar>
                <Typography variant='h6'>
                    {t('image_duration')}
                </Typography>
            </Toolbar>
            <FormTextField name={''} value={idlePage.image_duration} setValue={handleSetValue('image_duration', (v) => parseInt(v))} type='number' />
            <Toolbar>
                <Typography variant='h6'>
                    {t('media_contents')}
                </Typography>
                <UploadButton size='small' variant='contained' icon
                    setFile={addSlides}
                    reset
                    accept="video/*,image/*"
                />
            </Toolbar>
            <ReactSortable list={slides} setList={setSlides}
                handle='.handle'
                animation={150}
                ghostClass='ghost'
                style={{ maxHeight: '100%', overflow: 'auto', marginRight: '-12px', paddingRight: '12px' }}
            >
                {slides.map((item, index) => (
                    <FormMedia slide={item} index={index} slides={slides} setSlides={setSlides} />
                ))}
            </ReactSortable>
            <Typography variant='h6' >
                {t('total')}: {slides.length}/10
            </Typography>
        </Fragment >
    )
}

export default SlideForm