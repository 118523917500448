import { Button, IconButton } from '@material-ui/core'
import { useLocale } from 'provider/LocaleProvider'
import { useSnack } from 'provider/SnackbarProvider'
import PublishIcon from '@material-ui/icons/Publish'
import GenerateUpload from 'views/CampaignViews/GenerateRedeemToken/components/GenerateUpload'
import _ from 'lodash'
import { downloadCSv } from 'misc/Utils'
const { Fragment } = require('react')

const csv = require('csvtojson')
const json2csv = require('json2csv').parse

const obj2csv = (data, fields, name) => {
    const csv = json2csv(data, { fields: fields })
    downloadCSv(name, csv)
    return csv
}
const handleTemplate = () => {
    obj2csv([{ token: 'token1' }, { token: 'token2' }, { token: 'token3' }], [{ label: 'redeem_token', value: row => row.token }], `redemption_token_template`)
}

export function appendFormdata(formData, data, name = '') {
    if (typeof data == 'object') {
        for (var key in data) {
            if (key == 'blob') {
                var imageName = name == '' ? 'image' : `${name}[image]`
                var image = data[key]
                if (image == null) {
                    formData.append(imageName, '')
                }
                else if (image.type != 'Buffer') {
                    formData.append(imageName, new Blob([image], { type: image.type }), image.name)
                }
            }
            else if (name == '') appendFormdata(formData, data[key], key)
            else appendFormdata(formData, data[key], `${name}[${key}]`)
        }
    }
    else if (data != null) {
        formData.append(name, data)
    }
}

// const UploadButton = ({ id = 'upload_button', setFile = new Function(), accept, reset = false, max = 10485760, render = new Function(), ...rest }) => {
//     const { t } = useLocale()
//     const snack = useSnack()

//     const handleFileChange = event => {
//         if (event.target.files && event.target.files[0]) {
//             var file = event.target.files[0]
//             if (max && file.size > max) {
//                 console.log('UploadButton -> file.size', file.size)
//                 event.target.value = null
//                 snack.error(t('file_size_too_big'))
//             }
//             else {
//                 setFile(file)
//                 if (reset) {
//                     event.target.value = null
//                 }
//             }

//         }
//     }
//     return (
//         <Fragment>
//             <input type="file" accept={accept} onChange={handleFileChange}
//                 style={{ display: 'none' }}
//                 id={id}
//             />
//             < label htmlFor={id}>
//                 {render()}
//             </label>
//         </Fragment >
//     )
// }


const UploadButton = ({ icon = false, id = 'upload_button', setFile = new Function(), accept, reset = false, max = 10485760, ...rest }) => {
    const { t } = useLocale()
    const snack = useSnack()
    const handleFileChange = event => {
        if (event.target.files && event.target.files[0]) {
            var file = event.target.files[0]
            if (max && file.size > max) {
                console.log('UploadButton -> file.size', file.size)
                event.target.value = null
                snack.error(t('file_size_too_big'))
            }
            else {
                setFile(file)
                if (reset) {
                    event.target.value = null
                }
            }

        }
    }

    return (
        <Fragment>
            <input type="file" accept={accept} onChange={handleFileChange}
                style={{ display: 'none' }}
                id={id}
            />
            {icon
                ?
                < label htmlFor={id}>
                    <IconButton component="span" htmlFor={id}  {...rest} style={{ ...rest.style, padding: '4px', height: '100%' }}  >
                        <PublishIcon />
                    </IconButton>
                </label>
                :
                < label htmlFor={id}>
                    <Button variant="contained" color="primary" onClick={handleTemplate} style={{ marginRight: '10px' }} {...rest}>
                        {t('template')}
                    </Button>
                    <Button variant="contained" color="primary" component="span" {...rest}>
                        {t('choose_file')}
                    </Button>
                </label>
            }
        </Fragment >
    )
}

const DeleteButton = () => {
    const { t } = useLocale()
    return (
        <Fragment>
            <Button variant="contained" color="primary" component="span">
                {t('delete')}
            </Button>
        </Fragment>
    )
}


async function readFile(csvFile) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.onload = (e) => {
            console.log('readFile -> e.target.result', e.target.result)
            resolve(e.target.result)
        }
        fileReader.readAsText(csvFile, 'utf-8')
    })
}


async function csv2Json(string) {
    const jsonArray = await csv().fromString(string)
    var tokens = jsonArray.map(row => row.redeem_token).filter(row => row != null)
    // setRedeemTokens(tokens)

}

export { UploadButton, DeleteButton }