import React, { useContext, useEffect, useRef, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles, TextField, Typography, useTheme } from '@material-ui/core'
import EditDialog, { DialogTextField } from 'components/EditDialog'
import { useDetails } from '../CampaignDetails'
import { useLocale } from 'provider/LocaleProvider'

const EditNameDialog = props => {

    const { editNameDialog, campaign, updateCampaign, snack } = useDetails()

    const defaultValue = campaign.name
    const [name, setName] = useState(defaultValue)


    useEffect(() => {
        setName(defaultValue)
    }, [defaultValue])

    const { t } = useLocale()
    const handleClose = () => {
        editNameDialog.setOpen(false)
        setName(defaultValue)
    }

    const handleSuccess = () => {
        editNameDialog.setOpen(false)
        document.getElementById("app").scroll(0, 0)
        snack.open(t('campaign_name_saved'))
    }

    const handleSave = () => {
        if (name != null) {
            if (name == defaultValue) {
                editNameDialog.setOpen(false)
                var app = document.getElementById("app")
                app.scroll(0, 0)
                snack.open(t('campaign_name_saved'))
            }
            else {
                updateCampaign({ name: name }, handleSuccess)
            }
        }
    }

    const handleChange = (name, value) => {
        setName(value)
    }



    if (campaign == null) return null
    return (
        <EditDialog open={editNameDialog.open} title={t('campaign_name')} handleSave={handleSave} handleClose={handleClose}>
            <DialogTextField onValueChange={handleChange} value={name} required maxLength={20} />
        </EditDialog>
    )
}


export default EditNameDialog