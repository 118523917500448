import { makeStyles } from '@material-ui/core/styles'
import useApi from 'api/useApi'
import React, { Fragment, useMemo, useCallback, useEffect, useState, useRef, useLayoutEffect } from 'react'
import { Box, Button, Tooltip, Container, FormHelperText, IconButton, InputAdornment, InputBase, InputLabel, TextField, Typography, TablePagination } from '@material-ui/core'
import DataTable from 'components/DataTable/DataTable'
import { id } from 'date-fns/locale'
import { useLocale } from 'provider/LocaleProvider'
import SearchIcon from '@material-ui/icons/Search'
import { t } from 'react-switch-lang'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Helmet } from 'react-helmet'
import EditDialog from 'components/EditDialog'
import time_format from 'misc/config'

import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { useSnack } from 'provider/SnackbarProvider'
import { useLocation } from 'react-router'
import Constants from 'misc/Constants'
import ImagePopover from 'components/ImagePopover'
import ImageDialog from 'components/ImageDialog'
import DeleteIcon from '@material-ui/icons/Delete'
import config from 'misc/config'
const fy = Constants.yupErrorMessage
const _ = require('lodash')
const moment = require('moment-timezone')
const useStyles = makeStyles(theme => ({
    body: {
        flex: 1,
        margin: 'auto',
        padding: theme.spacing(3),
        boxSizing: 'border-box',
    },
    button: {
        position: "relative",
        '&:hover': {
            // backgroundColor: 'red',
            cursor: 'pointer'
        },
        '&:active': {
            // backgroundColor: 'red',
            cursor: 'auto'
        }
    },
}))

export function time(str) {
    return moment(str).format(config.timeformat)
}

const ImageUpload = ({ name, url_name, formik }) => {
    const classes = useStyles()
    var url = formik.values.image_url
    if (!_.isNil(formik.values.image)) url = URL.createObjectURL(formik.values.image)

    var error = _.get(formik, 'errors.' + name)

    const handleDelete = () => {
        formik.setFieldValue(name, null)
        formik.setFieldValue(url_name, null)
    }

    return (
        <Box>
            <input id={name} name={name} type="file" accept='image/*' style={{ display: 'none' }} onChange={(event) => {
                var file = _.get(event, 'target.files[0]')
                event.target.value = null
                if (file) {
                    formik.setFieldValue(name, file)
                }
            }} />
            < InputLabel htmlFor={name}>
                <Box className={classes.button} p={2} height='200px' border={error ? '1px solid rgba(255,0,0,1)' : '1px solid rgba(0,0,0,0.23)'} borderRadius='4px'>
                    {url && <img src={url} />}
                </Box>
            </InputLabel>
            <Box display='flex'>
                <FormHelperText variant='contained' error={error}>
                    {error ? _.get(formik, 'errors.' + name) : t('max_file_size', { n: Constants.fileSize.PRODUCT_IMAGE / 1000000 })}
                </FormHelperText>
                <Box flexGrow={1} />
                <IconButton onClick={handleDelete} style={{ padding: '4px' }}>
                    <DeleteIcon style={{ height: '20px', width: '20px' }} />
                </IconButton>

            </Box>
        </Box>
    )
}


const ProductList = (props) => {


    const classes = useStyles()
    const [products, setProducts] = useState()
    const [search, setSearch] = useState('')

    const [open, setOpen] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const snack = useSnack()
    const api = useApi()


    useEffect(() => {
        api.getProducts({ is_active: true }).then(res => setProducts(res.data.products))
    }, [])


    const formik = useFormik({
        initialValues: {
            name: '',
            image: null,
            image_url: '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required().nullable().max(128).test('duplicate', t(fy.name_exist), function (value) {
                return _.findIndex(_.filter(products, c => c.id !== this.parent?.id), { name: value }) < 0
            }),
            image: Yup.mixed().test('fileSize', t(fy.large_file_size), value => !value || value.size <= Constants.fileSize.PRODUCT_IMAGE)
            // .test('fileType', "Unsupported File Format", value => !value || ['png'].includes(value.type)).nullable()
        }),
        onSubmit: (values, helper) => {
            if (!values) return
            if (values.id) {
                api.updateProduct(values.id, {
                    name: values.name,
                    image: values.image,
                    image_url: values.image_url,
                }).then(res => {
                    snack.open(t('saved'))
                    helper.setSubmitting(false)
                    setOpen(false)
                    setProducts(list => _.unionBy([res.data], [...list], 'id'))
                }).catch(e => {
                    helper.setErrors({ name: e.msg })
                    helper.setSubmitting(false)
                })
            }
            else {
                api.addProduct({
                    name: values.name,
                    image: values.image
                }).then(res => {
                    setOpen(false)
                    snack.open(t('saved'))
                    helper.setSubmitting(false)
                    setProducts(list => _.unionBy([res.data], [...list], 'id'))
                }).catch(e => {
                    snack.error(t('failed'))
                    helper.setErrors({ name: e.msg })
                    helper.setSubmitting(false)
                })
            }

        },
    })


    const deleteFormik = useFormik({
        initialValues: {
            id: '',
        },
        validationSchema: Yup.object().shape({
            id: Yup.string().required(),
        }),
        onSubmit: (values, helper) => {
            api.updateProduct(values.id, {
                is_active: false,
            }).then(res => {
                snack.open(t('deleted'))
                helper.setSubmitting(false)
                setOpenDelete(false)
                setProducts(list => _.pullAllBy([...list], [res.data], 'id'))
            }).catch(e => {
                snack.error(t('failed'))
                helper.setErrors({ name: e.msg })
                helper.setSubmitting(false)
            })

        },
    })

    const handleEdit = (row) => {
        formik.handleReset()
        formik.setValues(row)
        setOpen(true)
    }

    const handleDelete = (row) => {
        deleteFormik.handleReset()
        deleteFormik.setValues(row)
        setOpenDelete(true)
    }

    const handleAdd = () => {
        formik.handleReset()
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setOpenDelete(false)
    }

    return (
        <>
            <Helmet>
                <title>Product List | Redemption Token CMS</title>
            </Helmet>
            <Container>
                <Typography variant='h4' className='bold' paragraph>
                    {t('product_list')}
                </Typography>
                <Box display='flex' flexDirection='row' mb={2} >
                    <TextField variant='outlined' fullWidth InputProps={{
                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>
                    }} value={search} onChange={(e) => setSearch(e.target.value)} />
                    <Button color='primary' onClick={handleAdd} variant='contained' style={{ marginTop: 'auto', marginBottom: 'auto', marginLeft: '8px', flexShrink: 0 }}>
                        {t('add_product')}
                    </Button>
                </Box>
                <DataTable
                    loading={_.isNil(products)}
                    rows={_.filter(products, row => {
                        return _.some(['id', 'name'], field => new RegExp(search, 'i').test(row[field]))
                    })}
                    heads={[
                        {
                            id: 'image', label: t('image'), value: 'image_url', width: '1%', content: (row) =>
                                <ImageDialog image_url={row.image_url}>
                                    <Box height='28px' >
                                        {row.image_url && <img src={row.image_url} />}
                                    </Box>
                                </ImageDialog>
                        },
                        { id: 'id', label: t('product_id'), value: 'id', width: 250 },
                        { id: 'name', label: t('product_name'), value: 'name', width: 200 },
                        { id: 'created_at', label: t('created_at'), value: 'created_at', content: row => time(row.created_at), width: '100%' },
                        {
                            id: 'action', label: t('actions'), width: '20%', content: (row) => {
                                return <Box mx={-1}>
                                    <Tooltip title={t('edit')}>
                                        <IconButton onClick={() => handleEdit(row)} style={{ padding: 0, margin: '4px' }}>
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={t('delete')}>
                                        <IconButton onClick={() => handleDelete(row)} style={{ padding: 0, margin: '4px' }}>
                                            <DeleteForeverIcon />
                                        </IconButton>
                                    </Tooltip>

                                </Box>
                            }
                        },
                    ]} />
                <EditDialog maxWidth='xs' title={_.get(formik, 'values.id') ? t('edit_product') : t('add_new_product')} open={open} handleClose={handleClose} handleSave={formik.handleSubmit} confirmDisabled={formik.isSubmitting}>
                    <TextField
                        error={Boolean(formik.touched?.name && formik.errors?.name)}
                        fullWidth
                        helperText={formik.touched?.name && formik.errors?.name}
                        label={t('name')}
                        name="name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values?.name}
                        variant="outlined"
                        style={{ marginBottom: '16px' }}
                    />
                    <ImageUpload name='image' url_name='image_url' formik={formik} />
                </EditDialog>
                <EditDialog maxWidth='xs' confirmText={t('confirm')} title={t('delete_configure')} open={openDelete} handleClose={handleClose} handleSave={deleteFormik.handleSubmit} confirmDisabled={deleteFormik.isSubmitting}>
                    {t('are_you_sure')}
                </EditDialog>
            </Container >
        </>

    )
}


export default ProductList
