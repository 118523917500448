import React, { useContext, useEffect, useRef, useState } from 'react'
import { makeStyles, TextField, Typography, useTheme } from '@material-ui/core'
import EditDialog, { DialogTextField } from 'components/EditDialog'
import PropTypes, { object } from 'prop-types'
import { useDetails } from '../CampaignDetails'
import { useLocale } from 'provider/LocaleProvider'

const StatusDialog = props => {

    const theme = useTheme()
    const { editStatusDialog, campaign, updateCampaign, snack } = useDetails()
    const { t } = useLocale()

    const handleClose = () => {
        editStatusDialog.setOpen(false)
    }

    const handleSuccess = () => {
        editStatusDialog.setOpen(false)
        snack.open(campaign.is_active ? t('campaign_deactivated') : t('campaign_activated'))
        document.getElementById("app").scroll(0, 0)
    }


    const handleSave = () => {
        updateCampaign({ is_active: !campaign.is_active }, handleSuccess)
    }

    if (campaign == null) return null


    const title = campaign.is_active ? t('deactivate_campaign') : t('activate_campaign')
    return (
        <EditDialog open={editStatusDialog.open} title={title} handleSave={handleSave} handleClose={handleClose} confirmText={t('confirm')}>
            {t('are_you_sure')}
        </EditDialog>
    )
}

export default StatusDialog