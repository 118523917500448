import React, { forwardRef, Fragment, useCallback, useContext, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import EditIcon from '@material-ui/icons/Edit'



import HelpPopHover from '../HelpPopHover'
import DataTextField from '../DataTextField'
import RedeemCondition from './components/RedeemCondition'
import RedeemQuota from './components/RedeemQuota'
import RadioList from '../RadioList'
import StartPeriod from './components/StartPeriod'
import EndPeriod from './components/EndPeriod'
import { DataRow, DataSession, DataSessionSubtitle, DataTypography } from '../DataSession'
import { useSnack } from '../../provider/SnackbarProvider'
import DataChipInput from '../DataChipInput'
import { useLocale } from '../../provider/LocaleProvider'

import {
    Box, Button, Divider, FormControl, FormControlLabel, FormLabel,
    Grid, GridList, GridListTile, IconButton, isWidthDown, makeStyles, Paper,
    Radio, RadioGroup, TextField, Typography, useTheme, withWidth, AppBar,
    Tab, Tabs, InputAdornment, withStyles, Switch, MenuItem, Chip, Checkbox, Toolbar
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
const moment = require('moment-timezone')


const _ = require('lodash')
var fp = require('lodash/fp')

const create_rule = (rule) => ({
    "is_reusable": rule.is_reusable || false,
    "metadata": rule.metadata || {},
    "redeem_pin": rule.redeem_pin || null,
    "expired_at": rule.expired_at || moment().toISOString(),
    "started_at": rule.started_at || moment().toISOString(),
    "redeem_quota": rule.redeem_quota || {
        "overall": null,
        "monthly": null,
        "yearly": null,
        "daily": null,
        "weekly": null
    },
    "expiry_seconds": rule.expiry_seconds || null,
    "has_reward": rule.has_reward || false,
    "token_identifier": rule.token_identifier || null,
    "redeem_date_conditions": rule.redeem_date_conditions || {
        "months": [],
        "weekdays": [],
        "type": "include",
        "dates": []
    }
})


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        boxSizing: 'border-box'
    },
    grid_container: {
        padding: '12px 24px',
        overflow: 'hidden'
    },
    text_name: {
        alignItems: 'start',
        minWidth: 'min(150px,100%)',
        maxWidth: '200px',
        paddingRight: '16px'
    },
    text_body: {
        minWidth: 'min(200px,calc(100% - 32px))',
        alignItems: 'center',
        lineHeight: '24px',
        maxHeight: '96px',
        overflow: 'hidden',
        overflowWrap: 'break-word'
    },
    text_body_quota: {
        minWidth: 'min(200px,calc(100% - 32px))',
        alignItems: 'center',
        lineHeight: '100px',
        maxHeight: '500px',
        overflow: 'hidden',
        overflowWrap: 'break-word',
        '& > *': {
            lineHeight: '50px',
            maxHeight: '250px',
        }
    },
    divider: {
        marginTop: '8px',
        marginBottom: '16px'
    },
    formControlLabel: {
        margin: 0
    },
    button: {
        width: '80px',
    },
    statusButton: {
        margin: '24px 0 0 0',
        width: '100%',
    },
    title: {
        padding: '4px 24px 12px 24px',
        fontWeight: 'bold'
    },
    textField: {
        maxWidth: '300px'
    }
}))

const bool_options = [{ label: 'Yes', value: true }, { label: 'No', value: false }]



const TokenConfigure = (props) => {
    const { defaultRule = {}, setRule = new Function(), isEdit } = props
    const [updates, setUpdates] = useState({})
    const [startType, setStartType] = useState()
    const [endType, setEndType] = useState()




    const classes = useStyles()
    const theme = useTheme()
    const snack = useSnack()

    const { t } = useLocale()


    const updateRef = useRef()
    const startTypeRef = useRef()
    const endTypeRef = useRef()

    useEffect(() => {

        updateRef.current = updates
        startTypeRef.current = startType
        endTypeRef.current = endType

    }, [updates, startType, endType])


    useEffect(() => {
        setRule(updates)
    }, [updates])


    useEffect(() => {
        if (defaultRule) {
            setStartType(defaultRule.started_at ? 'date' : 'null')
            setEndType(defaultRule.expired_at ? 'date' : defaultRule.expiry_seconds ? 'time' : 'null')
            setUpdates(defaultRule)
        }
        else {
            setStartType('null')
            setEndType('null')
            setUpdates({})
        }
    }, [defaultRule])





    const handleSetValue = (path, format = (v) => v) => (value) => {
        var currentUpdates = _.cloneDeep(updateRef.current)
        var newValue = format(value)
        var newUpdates = _.set(currentUpdates, path, newValue)
        setUpdates(newUpdates)
    }

    const handleResetValue = (path) => {
        var currentUpdates = _.cloneDeep(updateRef.current)
        var newValue = _.get(defaultRule, path)
        var newUpdates = _.set(currentUpdates, path, newValue)
        setUpdates(newUpdates)
    }






    const hasRewardRow = useMemo(() => (
        <RadioList row name='has_reward' list={bool_options} value={updates.has_reward} onChange={handleSetValue('has_reward', e => Boolean(e.target.value == 'true'))} />
    ), [updates.has_reward])


    const isReusableRow = useMemo(() => (
        <RadioList row name='is_reusable' list={bool_options} value={updates.is_reusable} onChange={handleSetValue('is_reusable', e => Boolean(e.target.value == 'true'))} />
    ), [updates.is_reusable])

    if (updates == null) return null

    return (
        <Fragment>
            <DataSession title={t('redeem_token_rule')}>
                <DataSessionSubtitle title={t('validity_period')} />
                <DataRow name={t('start_at')}>
                    <StartPeriod updates={updates} setUpdates={setUpdates} updateRef={updateRef} startType={startType} setStartType={setStartType} />
                </DataRow>
                <DataRow name={t('expiry')}>
                    <EndPeriod updates={updates} setUpdates={setUpdates} updateRef={updateRef} endType={endType} setEndType={setEndType} />
                </DataRow>
                <Divider className={classes.divider} />
                <Typography noWrap variant='h6' className={classes.title}>{t('redeem_period')} </Typography>
                {/* <DataRow name='Condition ' alignSelf='baseline'> */}
                <RedeemCondition updates={updates} handleSetValue={handleSetValue} handleResetValue={handleResetValue} />
                {/* </DataRow> */}
                <DataRow name={t('dates')}>
                    <DataChipInput
                        tags={(updates.redeem_date_conditions && updates.redeem_date_conditions.dates) || []}
                        setTags={handleSetValue('redeem_date_conditions.dates')}
                        isValid={(date) => {
                            return moment(date, 'YYYY-MM-DD', true).isValid()
                        }}
                        regex={/[^0-9,-]/g}
                        helperText={t('dates_chip_helper')}
                    />
                </DataRow>
                <Divider className={classes.divider} />
                <Typography noWrap variant='h6' className={classes.title}>{t('redeem_token_config')}</Typography>
                <DataRow name={t('has_reward')} >
                    {hasRewardRow}
                </DataRow>
                <DataRow name={t('is_reusable')}>
                    {isReusableRow}
                </DataRow>
                <DataRow name={t('redeem_quota')} alignSelf='baseline'>
                    <RedeemQuota classes={classes} updates={updates} setUpdates={setUpdates} updateRef={updateRef} handleSetValue={handleSetValue} />
                </DataRow>
                <DataRow name={t('token_identifier')} helpText={t('token_identifier_help')}>
                    <DataTextField
                        disabled={isEdit}
                        className={classes.textField}
                        name='token_identifier'
                        setValue={handleSetValue('token_identifier')}
                        value={updates.token_identifier}
                        noSpace
                    />
                </DataRow>
                <DataRow name={t('redeem_pin')} helpText={t('redeem_pin_helper')}>
                    <DataTextField
                        className={classes.textField}
                        name='redeem_pin'
                        setValue={handleSetValue('redeem_pin')}
                        maxLength={20}
                        value={updates.redeem_pin}
                        noSpace
                    />
                </DataRow>
            </DataSession>
        </Fragment>

    )
}

export default TokenConfigure
