import {
    t,
    setTranslations,
    setDefaultLanguage,
    setLanguageCookie,
    setLanguage,
    translate,
    getLanguage,
    getLanguages,
    getDefaultLanguage,
} from 'react-switch-lang'


export var fileSize = {
    PRODUCT_IMAGE: 1000000
}

export var yupErrorMessage = {
    min: 'min_value', // 'value must be greater than or equal to ${min}',
    max: 'max_value', // 'value must be less than or equal to ${max}',
    required: 'required_field',
    str_max: 'str_maxchar', // 'input must be at most ${max} characters',
    array_max: 'max_array',// 'must have less than or equal to ${max} items',
    array_min: 'min_array',// ' field must have at least ${min} items '

    name_exist: 'nameexist', // 'name exist',
    large_file_size: 'large_size', // 'file size is too large',
}

export default {
    fileSize,
    yupErrorMessage
}