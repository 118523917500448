import { Box, Button, Divider, Grid, IconButton, isWidthUp, makeStyles, Tooltip, Typography, useTheme, withWidth } from '@material-ui/core'
import React, { useContext, useEffect, useState, useRef, useLayoutEffect, forwardRef, Fragment, useMemo } from 'react'
import { NavLink, useParams, withRouter } from 'react-router-dom'

import clsx from 'clsx'
import DataTableWithMeta from './DataTableWithMeta'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { useLocale } from '../../provider/LocaleProvider'
import RefreshIcon from '@material-ui/icons/Refresh'
import EditIcon from '@material-ui/icons/Edit'
import CropFreeIcon from '@material-ui/icons/CropFree'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import ClearIcon from '@material-ui/icons/Clear'
import { FaFileCsv } from 'react-icons/fa'
import { HiQrcode } from 'react-icons/hi'
import useApi from 'api/useApi'
const moment = require('moment-timezone')
const useStyles = makeStyles(theme => ({
    body: {
        // flex: 1,
        padding: theme.spacing(3),
        // background: '#eaeff1',
        boxSizing: 'border-box',
        // overflowY: 'scroll'
    },
}))
const _ = require('lodash')




const ThemeTable = ({ table_name = 'themes', data = [], handleRefresh, handleExportCSV, _metaFields, ...rest }) => {

    const { t } = useLocale()



    const optionalHeads = [
        { id: 'qrcode', label: t('qr_code'), value: 'qr_code.code', width: '20%' },

    ]


    return <DataTableWithMeta
        rows={data}
        heads={[
            { id: 'created_at', label: t('created_at'), width: '20%', value: 'created_at', content: (row) => row.created_at ? moment(row.created_at).format('DD/MM/YYYY, hh:mm:ss') : null, sort: true },
            { id: 'name', label: t('theme_name'), width: '40%', value: 'name', content: row => <NavLink to={`/themes/${row.id}`} >{row.name}</NavLink> },
        ]}
        optionals={optionalHeads}
        handleRefresh={handleRefresh}
        {...rest}
    />

}


export default ThemeTable