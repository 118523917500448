import { Box, Button, Divider, FormControl, FormControlLabel, FormLabel, Grid, GridList, GridListTile, IconButton, isWidthDown, makeStyles, Paper, Radio, RadioGroup, TextField, Tooltip, Typography, useTheme, withWidth } from '@material-ui/core'
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { NavLink, useParams } from 'react-router-dom'
import DataTable from 'components/DataTable/DataTable'
import useApi from 'api/useApi'
import { useLocale } from 'provider/LocaleProvider'
import MetaSelecter from 'components/DataTableWithMeta/components/MetaSelecter'
import RefreshIcon from '@material-ui/icons/Refresh'
import EditIcon from '@material-ui/icons/Edit'
import CropFreeIcon from '@material-ui/icons/CropFree'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import ClearIcon from '@material-ui/icons/Clear'
import { DataRow, DataSession, DataSessionSubtitle, TextDataRow } from 'components/DataSession'
import DeviceDetailDialog, { DescriptionDialog } from './DeviceDetailDialog'
import DeviceCampaignDialog from './DeviceCampaignDialog'
import MetaTable from 'components/MetaTable/MetaTable'
import DeviceThemeDialog from './DeviceThemeDialog'
import DeviceCampaignConfigDialog from './DeviceCampaignConfigDialog'
import { useDevice } from 'provider/DeviceProvider'
const moment = require('moment-timezone')



const useStyles = makeStyles(theme => ({

}))


const DeviceDetails = props => {
    const { } = props
    const classes = useStyles()
    const { device, setDevice } = useDevice()
    const { t } = useLocale()
    const userRequest = useApi()

    const [dialogState, setDialogState] = useState({
        open: false,
        id: '',
        title: '',
        confirmValue: undefined
    })

    const [attachCampaign, setAttachCampaign] = useState(false)
    const [attachTheme, setAttachTheme] = useState(false)
    const [attachConfig, setAttachConfig] = useState(false)


    useEffect(() => {
        userRequest.getDeviceDetails(setDevice)
    }, [])


    function handleRefresh() {
        userRequest.getDeviceDetails(setDevice)
    }


    function handleEditName() {
        setDialogState({ open: true, id: 'name', title: t('name') })
    }

    function handleEditDescription() {
        setDialogState({ open: true, id: 'description', title: t('description') })
    }

    function handleEditStatus() {
        setDialogState({ open: true, id: 'is_active', title: device?.is_active ? t('deactivate_device') : t('activate_device'), confirmValue: !device?.is_active })
    }

    const handleUpdateMetadata = (data, handleSuccess, handleFail) => {
        userRequest.updateDeviceDetails(data, setDevice).then(handleSuccess).catch(handleFail)
    }

    const handleEditCampaign = () => {
        setAttachCampaign(true)
    }

    const handleEditTheme = () => {
        setAttachTheme(true)
    }

    const DetailShowcase = () => {
        if (device === undefined) return null
        return (
            <DataSession title={t('device_detail')}>
                <TextDataRow name={t('device_id')} value={device?.id} />
                <TextDataRow name={t('device_name')} value={device?.name} handleEdit={handleEditName} />
                <TextDataRow name={t('status')}
                    helpText={t('determine_device_will_be_shown_to_users')}
                    style={{ color: device?.is_active ? 'limegreen' : 'red' }}
                    value={device?.is_active ? t('active') : t('inactive')}
                />
                <TextDataRow name={t('description')} value={device?.description} handleEdit={handleEditDescription} />
                <TextDataRow name={t('device_type')} value={device?.device_type} />
                <TextDataRow name={t('machine_id')} value={device?.machine_id} />

                {/* <DataSessionSubtitle label={t('campaign')} /> */}
                <TextDataRow name={t('campaign')} value={device?.campaign && device?.campaign.name} nullText={t('not_set')} handleEdit={handleEditCampaign} />
                <TextDataRow name={t('campaign_device_config')} value={device?.campaign_device_config?.name} nullText={t('not_set')} handleEdit={() => setAttachConfig(true)} />
                <TextDataRow name={t('theme')} value={device?.theme && device?.theme.name} nullText={t('not_set')} handleEdit={handleEditTheme} />
                <TextDataRow name={t('created_at')} value={new Date(device?.created_at).toLocaleString()} />
                <TextDataRow name={t('updated_at')} value={new Date(device?.updated_at).toLocaleString()} />
            </DataSession>
        )
    }
    if (device === undefined || device === null) return <Box height='120vh' />
    return (
        <Fragment>
            <Typography variant='h4' className='bold' paragraph>
                {t('device_info')}
            </Typography>
            <DeviceCampaignConfigDialog details={device} setDetails={setDevice} open={attachConfig} setOpen={setAttachConfig} />
            <DeviceCampaignDialog details={device} setDetails={setDevice} open={attachCampaign} setOpen={setAttachCampaign} />
            <DeviceThemeDialog details={device} setDetails={setDevice} open={attachTheme} setOpen={setAttachTheme} />
            <DeviceDetailDialog details={device} setDetails={setDevice} dialogState={dialogState} setDialogState={setDialogState} />
            <DetailShowcase />
            <MetaTable metadata={device?.metadata} update={handleUpdateMetadata} action />
            <Button
                variant="contained"
                size="large"
                className={classes.statusButton}
                // startIcon={<DeleteIcon />}
                onClick={handleEditStatus}
                style={{ backgroundColor: device?.is_active ? 'red' : 'limegreen', color: 'white', marginTop: '24px' }}
            >
                {device?.is_active ? t('deactivate_device') : t('activate_device')}
            </Button>
        </Fragment>
    )
}

export default DeviceDetails