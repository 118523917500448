import React, { useContext, useEffect, useRef, useState } from 'react'
import { FormControl, FormControlLabel, makeStyles, Radio, RadioGroup, TextField, Typography, useTheme } from '@material-ui/core'
import EditDialog, { DataTextField } from '../../../../components/EditDialog'
import PropTypes, { object } from 'prop-types'
import DatePicker from 'components/DatePicker'
import { useLocale } from 'provider/LocaleProvider'
import { useDetails } from '../CampaignDetails'


const moment = require('moment-timezone')
const StatusDialog = props => {

    const { editPeriodDialog, campaign, updateCampaign, snack } = useDetails()


    const [start, setStart] = useState(campaign.started_at ? true : false)
    const [expire, setExpire] = useState(campaign.expired_at ? true : false)
    const [startAt, setStartAt] = useState(campaign.started_at || campaign.created_at || moment().toISOString())
    const [expireAt, setExpireAt] = useState(campaign.expired_at || moment().toISOString())
    const { t } = useLocale()

    function refresh() {
        setStart(campaign.started_at ? true : false)
        setExpire(campaign.expired_at ? true : false)
        setStartAt(campaign.started_at || campaign.created_at || moment().toISOString())
        setExpireAt(campaign.expired_at || moment().toISOString())
    }

    useEffect(() => {
        refresh()
    }, [editPeriodDialog.open])

    const handleClose = () => {
        editPeriodDialog.setOpen(false)
    }

    const handleSuccess = () => {
        editPeriodDialog.setOpen(false)
        snack.open(t('campaign_period_saved'))
        document.getElementById("app").scroll(0, 0)
    }

    function handleChange(event) {
        var bool = event.target.value == 'true' ? true : false
        update(event.target.name, bool)
    }


    function update(name, value) {
        if (name == 'start') {
            setStart(value)
        }
        else if (name == 'expire') {
            setExpire(value)
        }
    }

    function handleExpireChange(value) {
        setExpireAt(moment(value).toISOString())
    }

    function handleStartChange(value) {
        setStartAt(moment(value).toISOString())
    }

    const handleSave = () => {
        var started_at = start ? moment(startAt).toISOString() : null
        var expired_at = expire ? moment(expireAt).toISOString() : null
        var updates = { started_at: started_at, expired_at: expired_at }
        updateCampaign(updates, handleSuccess)
    }


    return (
        <EditDialog open={editPeriodDialog.open} title={t('campaign_period_dialog_title')} handleSave={handleSave} handleClose={handleClose} >
            <div >
                <Typography variant='h6' noWrap  >
                    {t('from')}
                </Typography>
                <FormControl component="fieldset">
                    <RadioGroup row aria-label="position" name="start" defaultValue={start ? 'true' : 'false'} onChange={handleChange}>
                        <FormControlLabel value="true" control={<Radio color='primary' />}
                            label={
                                <DatePicker defaultDate={startAt} name='start' handleChange={handleStartChange} />
                            }
                        />
                        <FormControlLabel value="false" control={<Radio color='primary' />} label={ t('n/a')} />
                    </RadioGroup>
                </FormControl>
            </div>
            <div style={{ paddingTop: '24px' }}>
                <Typography variant='h6' noWrap  >
                    {t('to')}
                </Typography>
                <FormControl component="fieldset">
                    <RadioGroup row aria-label="position" name="expire" defaultValue={expire ? 'true' : 'false'} onChange={handleChange}>
                        <FormControlLabel value="true" control={<Radio color='primary' />}
                            label={
                                <DatePicker defaultDate={expireAt} name='expire' handleChange={handleExpireChange} minDate={startAt} />
                            }
                        />
                        <FormControlLabel value="false" control={<Radio color='primary' />} label={ t('n/a')} />
                    </RadioGroup>
                </FormControl>
            </div>
        </EditDialog >
    )
}

export default StatusDialog