const server_url = "https://asia-east2-redemption-token-8b7af.cloudfunctions.net"
const auth_url = 'https://asia-east2-animaetech-central-hub.cloudfunctions.net/api'
const web_url = process.env.NODE_ENV == 'development' ? "http://localhost:3000" : "https://redeem.animaetech.com"
const apiLink = {
    server_url: server_url,
    auth_url: auth_url,
    web_url: web_url,
    account_url: "https://account.animaetech.com",
    login_url: "https://account.animaetech.com/login?redirect_url=" + web_url + '/login',
    gacha_device_url: "https://api.gacha.animaetech.com"

}

export default apiLink