import { KeyboardDatePicker } from '@material-ui/pickers'
import useApi from 'api/useApi'
import { FormSelector } from 'components/DataForm'
import { DataRow } from 'components/DataSession'
import useCampaign from 'hooks/useCampaign'
import { useLocale } from 'provider/LocaleProvider'
import React, { Fragment, useMemo, useCallback, useEffect, useState, useRef, useLayoutEffect } from 'react'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import TodayIcon from '@material-ui/icons/Today'


import { Bar, ChartData, LinearComponentProps } from 'react-chartjs-2'
import 'chartjs-plugin-annotation'
import DashBoardChart from './components/DashBoardChart'

import {
    Box, Button, Divider, FormControl, FormControlLabel, FormLabel,
    Grid, GridList, GridListTile, IconButton, isWidthDown, makeStyles, Paper,
    Radio, RadioGroup, TextField, Typography, useTheme, withWidth, AppBar,
    Tab, Tabs, InputAdornment, Tooltip, withStyles, Switch, MenuItem, Chip, Checkbox, Toolbar, Table, TableHead, TableCell, TableRow, Container
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import DashBoardSingleChart, { DashBoardRecordChart, DashBoardTokenChart } from './components/DashBoardSingleChart'
import GetAppIcon from '@material-ui/icons/GetApp'
import Utils from 'misc/Utils'
import DataTable from 'components/DataTable/DataTable'
import { ModeComment } from '@material-ui/icons'
import HourlyChart from './components/HourlyChart'
import WeeklyChart from './components/WeeklyChart'
import PeriodChart from './components/PeriodChart'
import { useLocation } from 'react-router'
const moment = require('moment-timezone')




const _ = require('lodash')
const useStyles = makeStyles(theme => ({
    table: {
        overflow: "auto",
        '& .table-spacer': {
            borderLeft: '0 !important',
            flex: '1 1 auto', width: '100%', padding: 0
        },
        "& .MuiTableCell-root": {
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            padding: '6px 12px',
            borderLeft: '1px solid',
            borderColor: theme.palette.divider,
            '& a': {
                display: 'inline'
            },
            '& .MuiButtonBase-root': {
                padding: 0
            },
            '& .MuiSvgIcon-root': {
                height: '24px'
            }
        },
        "& .MuiTableRow-root": {
            height: '40px'
        },
        "& .MuiTableHead-root": {
            backgroundColor: theme.palette.table.main,
            "& .MuiTableCell-paddingCheckbox": {
                textAlign: 'center',
                width: '48px',
            },
        },
        "& .MuiTableSortLabel": {
            "&-icon": {
                opacity: 0.5
            },
            "&-active": {
                opacity: 1,
                "& .MuiTableSortLabel-icon": {
                    color: 'limegreen !important'
                },
                // "& .MuiTableSortLabel-iconDirectionDesc": {
                //     color: 'red !important'
                // }
            }

        },
    }
}))


const TabPanel = ({ value, index, children }) => {
    if (value === index) {
        return children || null
    }
    return null

}


const timeFormat = {
    'year': 'YYYY',
    'month': 'YYYY-MM',
    'day': 'YYYY-MM-DD',
    'week': 'E',
    'hour': 'HH:00'
}
var colors = (len) => _.times(len, n => `hsla(${(_.floor(255 * n / len))},100%,60%,0.5)`)

const getBetweenDate = function (groupBy, start, end) {
    var arr = []
    var startOf = groupBy === 'hour' ? 'day' : groupBy
    var currentDate = moment(start).startOf(startOf)
    var endDate = moment(end).endOf(startOf)
    while (currentDate.isSameOrBefore(endDate, groupBy)) {
        arr.push(currentDate.format(timeFormat[groupBy]))
        currentDate = moment(currentDate).add(1, groupBy)
    }
    return arr
}

const groupDataBy = (data, groupBy) => {
    var grouped = _.groupBy(data, v => moment(v).format(timeFormat[groupBy]))
    return _.mapValues(grouped, o => o.length)
}

const getChartArray = (grouped = [], columns = []) => {
    var values = columns.map(day => (grouped[day] || 0))
    return { x: columns, y: values }
}


const filterDataByPeriod = (data, start, end) => {
    return _.filter(data, d => moment(d).isBetween(start, end, 'day', '[]'))
}

const Card = ({ title, value, ...rest }) => {
    return (
        <Grid item xs={12} sm={4} {...rest}>
            <Paper style={{ padding: '8px' }}>
                <Typography variant='body2' color='textSecondary'>
                    {title}
                </Typography>
                <Typography variant='h6' color='textPrimary'>
                    {value}
                </Typography>
            </Paper>
        </Grid>
    )
}





const CampaignDashboard = () => {
    const { t } = useLocale()
    const { campaign } = useCampaign()
    const api = useApi()
    const [campaignData, setCampaignData] = useState({})
    const campaignStartDate = moment(_.get(campaign, 'started_at') || _.get(campaign, 'created_at') || new Date()).startOf('day').toDate()
    const campaignEndDate = moment(_.get(campaign, 'expired_at') || new Date()).toDate()

    const location = useLocation()

    const [tab, setTab] = useState(0)

    const [dateRange, setDateRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    })


    const tokenData = useMemo(() => campaignData ? _.get(campaignData, 'redeem_tokens.data') : [], [campaignData])
    const tokenStartDate = moment(_.last(tokenData)).startOf('day').toDate() || new Date()
    const tokenEndtDate = moment(_.head(tokenData)).toDate() || new Date()

    const filteredToken = useMemo(() => tab !== 1 ? tokenData : filterDataByPeriod(tokenData, dateRange.startDate, dateRange.endDate), [tab, tokenData, dateRange])

    const redeemData = useMemo(() => campaignData ? _.get(campaignData, 'redeem_records.data') : [], [campaignData])
    const redeemStartDate = moment(_.last(redeemData)).startOf('day').toDate() || new Date()
    const redeemEndtDate = moment(_.head(redeemData)).toDate() || new Date()

    const filteredRedeem = useMemo(() => tab !== 1 ? redeemData : _.filter(redeemData, d => moment(d).isBetween(dateRange.startDate, dateRange.endDate, 'day', '[]')), [tab, redeemData, dateRange])


    const [openTimePicker, setOpenTimePicker] = useState(false)


    useEffect(() => {
        setOpenTimePicker(false)
    }, [tab])

    useEffect(() => {
        api.getCampaignData(setCampaignData)
    }, [location.pathname])


    useEffect(() => {
        const startDate = moment.min([moment(campaignStartDate), moment(redeemStartDate), moment(tokenStartDate)]).toDate()
        const endDate = moment.max([moment(campaignEndDate), moment(redeemEndtDate), moment(tokenEndtDate)]).toDate()
        setDateRange({ ...dateRange, startDate: startDate, endDate: endDate })
    }, [tokenData, redeemData])

    const handleSelectDate = (item) => {
        setDateRange(item.selection)
    }

    function createStaticRanges(ranges) {
        return ranges.map(range => ({
            isSelected: () => {
                const definedRange = range.range()
                return moment(definedRange.endDate).isSame(dateRange.endDate, 'day') && moment(definedRange.startDate).isSame(dateRange.startDate, 'day')
            },
            ...range
        }))
    }

    return (
        <Container>
            <Typography variant='h4' className='bold' paragraph>
                {t('dashboard')}
            </Typography>
            <Grid container className='flexRow' spacing={1} style={{ marginBottom: '16px' }}>
                <Card title={t('total_redeem_tokens')} value={_.size(tokenData)} />
                <Card title={t('total_redeem_records')} value={_.size(redeemData)} />
                <Card title={t('total_redeem_rate')} value={_.round(_.size(redeemData) / _.size(tokenData) * 100, 2).toFixed(2) + '%'} />
            </Grid>
            <Paper elevation={1} style={{ marginBottom: '16px' }}>
                <Tabs value={tab} onChange={(e, v) => setTab(v)} >
                    <Tab label={t('life_time_report')} style={{ minWidth: '0', width: '50%' }} />
                    <Tab label={t('period_report')} style={{ minWidth: '0', width: '50%' }} />
                </Tabs>
            </Paper>
            <TabPanel value={tab} index={1}>
                <Grid container spacing={1} style={{ marginBottom: '4px' }}>
                    <Grid item xs={8} lg={4}>
                        <TextField
                            label={t('period')}
                            value={`${moment(dateRange.startDate).format('YYYY/MM/DD')} - ${moment(dateRange.endDate).format('YYYY/MM/DD')}`}
                            InputProps={{
                                style: { verticalAlign: 'middle' },
                                readOnly: true,
                                endAdornment: <InputAdornment position="end">
                                    <Tooltip title={t('choose_period')}>
                                        <IconButton
                                            onClick={() => setOpenTimePicker(!openTimePicker)}
                                            edge="end"
                                        >
                                            <TodayIcon />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>,
                            }}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant='outlined'
                            style={{ backgroundColor: 'white' }}
                        />
                    </Grid>
                </Grid>
                <div style={{ marginBottom: '12px' }}>
                    {openTimePicker &&
                        <DateRangePicker
                            ranges={[dateRange]}
                            maxDate={new Date()}
                            onChange={handleSelectDate}
                            moveRangeOnFirstSelection={false}
                            editableDateInputs={true}
                            scroll={{ enabled: true }}
                            dateDisplayFormat='yyyy/MM/dd'
                            staticRanges={createStaticRanges([
                                {
                                    label: t('lifetime'),
                                    range: () => ({
                                        startDate: campaignStartDate,
                                        endDate: campaignEndDate,
                                    }),
                                },
                                // {
                                //     label: t('token_period'),
                                //     range: () => ({
                                //         startDate: tokenStartDate,
                                //         endDate: tokenEndtDate,
                                //     }),
                                // },
                                {
                                    label: t('today'),
                                    range: () => ({
                                        startDate: new Date(),
                                        endDate: new Date()
                                    }),
                                },
                                {
                                    label: t('last_7_days'),
                                    range: () => ({
                                        startDate: moment().subtract(1, 'w').toDate(),
                                        endDate: new Date(),
                                    }),
                                },

                            ])}
                            inputRanges={[
                                {
                                    label: t('days_up_to_today'),
                                    range(value) {
                                        return {
                                            startDate: moment().subtract(value, 'day').toDate(),
                                            endDate: moment().endOf('day').toDate(),
                                        }
                                    },
                                    getCurrentValue(range) {
                                        if (!moment().isSame(range.endDate, 'day')) return '-'
                                        if (!range.startDate) return '∞'
                                        return moment().diff(range.startDate, 'days')
                                    },
                                },
                            ]}
                        />
                    }
                </div>

                <Grid container className='flexRow' spacing={1} style={{ marginBottom: '16px' }}>
                    <Card title={t('selected_redeem_tokens')} value={_.size(filteredToken)} sm={4} />
                    <Card title={t('selected_redeem_records')} value={_.size(filteredRedeem)} sm={4} />
                    <Card title={t('total_redeem_rate')} value={_.round(_.size(filteredRedeem) / _.size(filteredToken) * 100, 2).toFixed(2) + '%'} sm={4} />
                </Grid>
            </TabPanel>

            <TabPanel value={tab} index={0}>
                <Typography variant='body1' color='textPrimary' style={{ marginBottom: '8px', textAlign: 'right' }}>
                    {`${t('data_from_to', { a: moment(campaignStartDate).format('YYYY/MM/DD'), b: moment(campaignEndDate).format('YYYY/MM/DD') })} `}
                </Typography>
            </TabPanel>
            <Grid container spacing={2} >
                <Grid item xs={12} md={12} lg={6} xl={6}>
                    <PeriodChart dataSetLabel={t('tokens')} titleId={'generated_tokens_by'} data={filteredToken} color='#FF0000' start={tab == 0 ? campaignStartDate : dateRange.startDate} end={tab == 0 ? campaignEndDate : dateRange.endDate} />
                </Grid>
                <Grid item xs={12} md={12} lg={6} xl={6}>
                    <PeriodChart dataSetLabel={t('records')} titleId={'redemption_records_by'} data={filteredRedeem} color='#0000FF' start={tab == 0 ? campaignStartDate : dateRange.startDate} end={tab == 0 ? campaignEndDate : dateRange.endDate} />
                </Grid>
                <Grid item xs={12} md={12} lg={6} xl={6}>
                    <HourlyChart dataSetLabel={t('tokens')} title={t('generated_tokens_by_hour')} data={filteredToken} color='#FF0000' />
                </Grid>
                <Grid item xs={12} md={12} lg={6} xl={6}>
                    <HourlyChart dataSetLabel={t('records')} title={t('redemption_records_by_hour')} data={filteredRedeem} color='#0000FF' />
                </Grid>
                <Grid item xs={12} md={12} lg={6} xl={6}>
                    <WeeklyChart dataSetLabel={t('tokens')} title={t('generated_tokens_by_weekday')} data={filteredToken} color='#FF0000' />
                </Grid>
                <Grid item xs={12} md={12} lg={6} xl={6}>
                    <WeeklyChart dataSetLabel={t('records')} title={t('redemption_records_by_weekday')} data={filteredRedeem} color='#0000FF' />
                </Grid>
            </Grid >

        </Container >
    )
}


export default CampaignDashboard