import React, { useContext, useEffect, useRef, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles, TextField, Typography, useTheme } from '@material-ui/core'
import EditDialog, { DialogTextField } from 'components/EditDialog'
import PropTypes, { object } from 'prop-types'
import { useDetails } from '../CampaignDetails'
import { useLocale } from 'provider/LocaleProvider'

const EditPinDialog = props => {

    const { editPinDialog, campaign, updateCampaign, snack } = useDetails()
    const defaultValue = campaign.master_redeem_pin
    const [pin, setPin] = useState(defaultValue)

    const { t } = useLocale()

    const handleClose = () => {
        editPinDialog.setOpen(false)
        setPin(defaultValue)
    }

    const handleSuccess = () => {
        editPinDialog.setOpen(false)
        snack.open(t('campaign_pin_saved'))
        document.getElementById("app").scroll(0, 0)
    }

    const handleSave = () => {
        if (pin != null) {
            if (pin == defaultValue) {
                editPinDialog.setOpen(false)
                document.getElementById("app").scroll(0, 0)
                snack.open(t('campaign_pin_saved'))
            }
            else {
                updateCampaign({ master_redeem_pin: pin }, handleSuccess)
            }
        }
    }

    const handleChange = (name, value) => {
        setPin(value)
    }

    if (campaign == null) return null
    return (
        <EditDialog open={editPinDialog.open} title={t('campaign_pin_dialog_title')} handleSave={handleSave} handleClose={handleClose}>
            <DialogTextField name='master_pin' onValueChange={handleChange} value={pin} noSpace maxLength={20} type='password' />
        </EditDialog>
    )
}

export default EditPinDialog