import { makeStyles } from '@material-ui/core'
import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { useLocale } from '../../provider/LocaleProvider'
import DataTableWithMeta from './DataTableWithMeta'

const moment = require('moment-timezone')
const useStyles = makeStyles(theme => ({
    body: {
        // flex: 1,
        padding: theme.spacing(3),
        // background: '#eaeff1',
        boxSizing: 'border-box',
        // overflowY: 'scroll'
    },
}))


var _ = require('lodash')

const DeviceTable = ({ table_name = 'devices', data = [], handleRefresh, handleExportCSV, _metaFields, ...rest }) => {

    const { t } = useLocale()




    const optionalHeads = [
        { id: 'machine_id', label: t('machine_id'), value: 'machine_id', width: 200, group: 'general' },
    ]


    return <DataTableWithMeta
        rows={data}
        heads={[
            { id: 'created_at', label: t('created_at'), value: 'created_at', content: (row) => row.created_at ? moment(row.created_at).format('DD/MM/YYYY, hh:mm:ss') : null, sort: true },
            { id: 'name', label: t('device_name'), width: 200, value: 'name', content: row => <NavLink to={`/devices/${row.id}/info`} >{row.name || '-'} </NavLink> },
            { id: 'campaign_name', label: t('campaign_name'), value: 'campaign.name', width: 200 },
            { id: 'theme_name', label: t('theme_name'), value: 'theme.name', width: 200 },
            { id: 'device_type', label: t('device_type'), value: 'device_type', width: 150 },
        ]}
        optionals={optionalHeads}
        handleRefresh={handleRefresh}
        {...rest}
    />

}


export default DeviceTable