import { Box, Button, Divider, Grid, IconButton, isWidthUp, makeStyles, Tooltip, Typography, useTheme, withWidth, TablePagination } from '@material-ui/core'
import React, { useContext, useEffect, useState, useRef, useLayoutEffect, forwardRef, Fragment, useMemo } from 'react'
import { NavLink, useHistory, useParams, withRouter } from 'react-router-dom'

import clsx from 'clsx'
import DataTableWithMeta from './DataTableWithMeta'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { useCampaign } from '../../provider/CampaignProvider'
import { useLocale } from '../../provider/LocaleProvider'

import RefreshIcon from '@material-ui/icons/Refresh'
import EditIcon from '@material-ui/icons/Edit'
import CropFreeIcon from '@material-ui/icons/CropFree'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import ClearIcon from '@material-ui/icons/Clear'
import { FaFileCsv } from 'react-icons/fa'
import { HiQrcode } from 'react-icons/hi'
import useApi from 'api/useApi'

import DataTable from '../DataTable/DataTable'
const moment = require('moment-timezone')
const useStyles = makeStyles(theme => ({
    body: {
        // flex: 1,
        padding: theme.spacing(3),
        // background: '#eaeff1',
        boxSizing: 'border-box',
        // overflowY: 'scroll'
    },
}))


const TokenTable = (props) => {


    const { width, table_name = 'tokens', data = [], linkToDetail, handleRefresh, ...rest } = props
    const { campaignId } = useParams()
    const { campaign } = useCampaign()
    const userRequest = useApi()
    const { t } = useLocale()

    const history = useHistory()



    const CustomRouterLink = forwardRef((props, ref) => {
        return (
            <div
                ref={ref}
                style={{ display: 'inline', flexGrow: 1 }}
            >
                <NavLink {...props} />
            </div>
        )
    })



    const tokenCell = linkToDetail
        ? {
            id: 'redeem_token', label: t('redeem_token'),
            value: row => <div>{row.redeem_token} <NavLink to={`/campaigns/${campaignId}/redeem_tokens/${row.redeem_token}`} style={{ display: 'inline' }}>
                <Tooltip title={t('see_detail')}>
                    <IconButton style={{ padding: 0, marginLeft: '8px' }}>
                        <VisibilityIcon style={{ height: '24px' }} />
                    </IconButton>
                </Tooltip></NavLink> </div>,
            compareValue: row => row.redeem_token,
            width: 300, fixed: true, align: 'left',
        }
        : { id: 'redeem_token', label: t('redeem_token'), value: row => row.redeem_token, width: 300, fixed: true, align: 'left', }

    const defaultHeadCells = [
        tokenCell,
        {
            id: 'identifier',
            label: t('token_identifier'),
            value: row => row.token_identifier,
            width: 200,
            fixed: true,
            align: 'left',
        },
        { id: 'created_at', label: t('created_at'), value: row => row.created_at ? moment(row.created_at).format('DD/MM/YYYY, hh:mm:ss') : null, width: 250, align: 'left', fixed: true, compareValue: row => row.created_at },
    ]

    function handleExportCSV(selected) {
        userRequest.exportSelecedTokensToCSV(selected.map(item => item.redeem_token))
    }

    function handleExportPDF(selected) {
        userRequest.exportSelecedTokensToPDF(selected.map(item => item.redeem_token))
    }
    function handleEdit(selected) {
        history.push(`/campaigns/${campaign.id}/redeem_tokens/edit_tokens`, { selected: selected.map(item => item.redeem_token) || [] })
    }


    const SelectBar = ({ selected, clearSelected }) => {
        if (selected.length > 0)
            return (
                <Fragment>
                    <Grid item>
                        <Typography variant='body1' color='textPrimary' style={{ padding: '4px' }}> {selected.length + ' ' + t('selected')}</Typography>
                    </Grid>
                    <Grid item>
                        <Tooltip title={t('clear')}>
                            <IconButton onClick={clearSelected} style={{ padding: '4px' }}> <ClearIcon /></IconButton>
                        </Tooltip>
                    </Grid >
                    <Grid itme xs />
                    <Grid item>
                        <Tooltip title={t('edit')}>
                            <IconButton onClick={() => handleEdit(selected)} style={{ padding: '4px' }}> <EditIcon /></IconButton>
                        </Tooltip>
                    </Grid >
                    <Grid item>
                        <Tooltip title={t('download')}>
                            <IconButton onClick={() => handleExportPDF(selected)} style={{ padding: '4px' }}> <HiQrcode /></IconButton>
                        </Tooltip>
                    </Grid >
                    <Grid item>
                        <Tooltip title={t('export_selected')}>
                            <IconButton onClick={() => handleExportCSV(selected)} style={{ padding: '4px' }}> <CloudDownloadIcon /></IconButton>
                        </Tooltip>
                    </Grid >
                    {isWidthUp('sm', width)
                        ?
                        <Grid item style={{ height: '40px', padding: '0 4px' }} >
                            <Divider orientation='vertical' />
                        </Grid >
                        :
                        <Fragment>
                            <Grid item xs={12} />
                            <Grid item xs />
                        </Fragment>
                    }
                </Fragment >
            )
        else return <Grid item xs />
    }


    const generalFields = [
        {

            id: 'is_active',
            label: t('is_active'),
            value: 'is_active',
            content: row => row.is_active ? t('active') : t('inactive'),
        },
        {

            id: 'times_redeemed',
            label: t('times_redeemed'),
            value: 'times_redeemed',
            sort: true,
        },
        {

            id: 'product_id',
            label: t('product_id'),
            value: 'product_id',
            sort: true,
        },
    ]

    return <DataTableWithMeta
        checkbox
        SelectBar={SelectBar}
        rows={data}

        heads={[

            { id: 'redeem_token', label: t('redeem_token'), value: 'redeem_token', content: (row) => <Fragment> {row.redeem_token} <NavLink to={`/campaigns/${campaignId}/redeem_tokens/${row.redeem_token}`} >  <Tooltip title={t('see_detail')}><IconButton style={{ marginLeft: '8px' }}> <VisibilityIcon /> </IconButton></Tooltip> </NavLink> </Fragment>, sort: true, width: '300px' },
            { id: 'identifier', label: t('token_identifier'), value: 'token_identifier', sort: true },
            { id: 'created_at', label: t('created_at'), value: 'created_at', content: (row) => row.created_at ? moment(row.created_at).format('DD/MM/YYYY, hh:mm:ss') : null, sort: true },

        ]
        }



        optionals={generalFields}
        handleRefresh={handleRefresh}
        {...rest}
    />


}
TokenTable.propTypes = {


    // t: PropTypes.func.isRequired,
}

export default withWidth()(withRouter(TokenTable))