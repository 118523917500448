import React, { Fragment, useCallback, useContext, useLayoutEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Divider, FormControl, FormControlLabel, FormLabel, Grid, GridList, GridListTile, IconButton, isWidthDown, makeStyles, Paper, Radio, RadioGroup, TextField, Typography, useTheme, withWidth } from '@material-ui/core'
import clsx from 'clsx'
import EditIcon from '@material-ui/icons/Edit'


import DataTextField from '../../DataTextField'
import DatePicker from '../../DatePicker'
import RadioList from '../../RadioList'
import { useLocale } from '../../../provider/LocaleProvider'
const moment = require('moment-timezone')
const useStyles = makeStyles(theme => ({
    textField: {
        width: 'min(800px, 100%)',
        // marginTop: theme.spacing(1),
        height: '50px',
        // marginBottom: theme.spacing(1),
    },
}))

const EndPeriod = props => {
    const classes = useStyles()
    const { updates, setUpdates, endType, setEndType, updateRef } = props

    const { t } = useLocale()

    const handleChange = (event) => setEndType(event.target.value)


    const handleEndDateChange = (value) => {
        const currentUpdates = updateRef.current
        var date = moment(value).toISOString()
        setUpdates({ ...currentUpdates, expired_at: date })
    }

    const handleValueChange = (name, value) => {
        const currentUpdates = updateRef.current
        setUpdates({ ...currentUpdates, expiry_seconds: parseInt(value) })
    }

    const timeLabel = (
        <div className='flexRow-center'>
            <DataTextField className={classes.textField} value={updates.expiry_seconds} onValueChange={handleValueChange} type='number' disabled={endType != 'time'} style={{ display: 'inline' }} />
            <Typography variant='body1' style={{ paddingLeft: '12px' }}>{t('seconds')}</Typography>
        </div>
    )


    const option = [
        { label: t('never_expired'), value: 'null' },
        { label: timeLabel, value: 'time' },
        { label: <DatePicker handleChange={handleEndDateChange} disabled={endType != 'date'} />, value: 'date' }
    ]


    const endMemo = useMemo(() => (
        <RadioList list={option} value={endType} onChange={handleChange} />
    ), [updates.expired_at, updates.expiry_seconds, endType])


    return endMemo

}

export default EndPeriod
