import { Box, Button, Divider, FormControl, FormControlLabel, FormLabel, Grid, GridList, GridListTile, IconButton, isWidthDown, makeStyles, Paper, Radio, RadioGroup, TextField, Toolbar, Tooltip, Typography, useTheme, withWidth } from '@material-ui/core'
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { NavLink, useParams } from 'react-router-dom'
import DataTable from 'components/DataTable/DataTable'
import useApi from 'api/useApi'
import { useLocale } from 'provider/LocaleProvider'
import MetaSelecter from 'components/DataTableWithMeta/components/MetaSelecter'
import RefreshIcon from '@material-ui/icons/Refresh'
import EditIcon from '@material-ui/icons/Edit'
import CropFreeIcon from '@material-ui/icons/CropFree'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import ClearIcon from '@material-ui/icons/Clear'
import DeviceTable from 'components/DataTableWithMeta/DeviceTable'
import ThemeTable from 'components/DataTableWithMeta/ThemeTable'
import AddThemeDialog from './AddTheme'
import AddIcon from '@material-ui/icons/Add'
import { useAuthData } from 'provider/AuthDataProvider'
const moment = require('moment-timezone')



const useStyles = makeStyles(theme => ({

}))


const ThemeList = props => {

    const classes = useStyles()
    const [themeList, setThemeList] = useState()
    const [addDialog, setAddDialog] = useState(false)
    const { t } = useLocale()

    const userRequest = useApi()
    const { organization } = useAuthData()
    useEffect(() => {
        userRequest.getThemes(setThemeList)
    }, [organization])


    function handleExportCSV() {

    }

    function handleRefresh() {
        userRequest.getThemes(setThemeList)
    }


    return (
        <Fragment>
            <Box display='flex' pb={3}>
                <Typography color="inherit" variant="h4" className='bold'>
                    {t('themes')}
                </Typography>
                <div style={{ flex: 1 }} />
                <Button variant='contained' color='secondary' onClick={() => setAddDialog(true)}>{t('add_theme')}</Button>
            </Box>
            <ThemeTable table_name='themes' data={themeList} loading={themeList === undefined} handleRefresh={handleRefresh} handleExportCSV={handleExportCSV} />
            <AddThemeDialog open={addDialog} setOpen={setAddDialog} setThemes={setThemeList} />
        </Fragment >
    )
}

export default ThemeList