import React, { Fragment, useCallback, useContext, useLayoutEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Divider, FormControl, FormControlLabel, FormLabel, Grid, GridList, GridListTile, IconButton, isWidthDown, makeStyles, Paper, Radio, RadioGroup, TextField, Typography, useTheme, withWidth } from '@material-ui/core'
import clsx from 'clsx'
import EditIcon from '@material-ui/icons/Edit'



import HelpPopHover from '../../HelpPopHover'
import DataTextField from '../../DataTextField'
import DatePicker from '../../DatePicker'
import CheckboxList from '../../CheckboxLIst'
import RadioList from '../../RadioList'
import { useLocale } from '../../../provider/LocaleProvider'
const moment = require('moment-timezone')
const useStyles = makeStyles(theme => ({
    root: {
        boxSizing: 'border-box',

    },
    spacing: {
        padding: theme.spacing(1, 0)
    },
    action: {
        marginTop: theme.spacing(2),
        // marginBottom: theme.spacing(2),
        flexWrap: 'wrap',
        marginLeft: '-12px',
        marginRight: '-12px',
    },
    button: {
        minWidth: 'min(100px,100%)',
        marginBottom: theme.spacing(2),
        marginLeft: '12px',
        marginRight: '12px'
    },
    divider: {
        marginBottom: theme.spacing(2)
    }
}))

const StartPeriod = props => {
    const classes = useStyles()
    const { updates, setUpdates, startType, setStartType, updateRef } = props


    const { t } = useLocale()
    const handleChange = (event) => {
        setStartType(event.target.value)
    }

    const handleStartDateChange = useCallback((value) => {
        const currentUpdates = updateRef.current
        var date = moment(value).toISOString()
        setUpdates({ ...currentUpdates, started_at: date })
    }, [updates.started_at])

    const option = [
        { label: t('start_immediately'), value: 'null' },
        { label: <DatePicker handleChange={handleStartDateChange} disabled={startType != 'date'} />, value: 'date' }
    ]

    const startMemo = useMemo(() => (
        <RadioList list={option} value={startType} onChange={handleChange} />
    ), [updates.started_at, startType])

    return startMemo
}

export default StartPeriod
