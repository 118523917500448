import React, { Fragment, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Card, Divider, FormControl, FormControlLabel, FormLabel, Grid, GridList, GridListTile, IconButton, isWidthDown, lighten, makeStyles, Paper, Radio, RadioGroup, TextField, Tooltip, Typography, useTheme, withWidth } from '@material-ui/core'
import clsx from 'clsx'

import DataTable from '../DataTable/DataTable'
import TableToolbar from './components/TableToolbar'
import { useLocale } from '../../provider/LocaleProvider'
import RefreshIcon from '@material-ui/icons/Refresh'
import EditIcon from '@material-ui/icons/Edit'
import CropFreeIcon from '@material-ui/icons/CropFree'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import ClearIcon from '@material-ui/icons/Clear'
import MetaSelecter from './components/MetaSelecter'
import Utils from 'misc/Utils'
import classNames from 'classnames'

const moment = require('moment-timezone')
const useStyles = makeStyles(theme => ({
    toolbar: {
        padding: '0 8px'
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
}))


const _ = require('lodash')

const DataTableWithMeta = ({
    table_name = 'table',
    rows,
    heads = [],
    optionals,
    handleRefresh,
    SelectBar,
    // toolbar,
    // defaultHeadCells = [],
    // generalFields = [],
    // metaFields = [],
    ...rest }) => {

    const classes = useStyles()

    const [search, setSearch] = useState({})
    const { t } = useLocale()
    const rowRef = useRef(null)


    const staticHeadCells = useMemo(() => _.map(_.cloneDeep(heads), field => ({ ...field, group: 'general' })), [heads])
    const optionalGeneralCells = useMemo(() => _.map(_.cloneDeep(optionals), field => ({ ...field, group: 'general' })), [optionals])

    const metas = useMemo(() => {
        try {
            var fields = _.map(_.cloneDeep(rows), item => item.metadata ? Object.keys(item.metadata) : undefined)
            fields = _.flattenDeep(fields)
            var uniq = _.uniq(fields)
            var sort = uniq.sort()
            return _.filter(sort, item => !_.isNil(item)).map(field => ({ id: field, label: field, value: 'metadata.' + field, group: 'metadata', width: '100px' }))
        } catch (error) {
            console.log('error', error)
            return []
        }
    }, [rows])

    const [optionalCells, setOptionalCells] = useState([])



    useEffect(() => {
        try {
            if (_.isArray(optionalGeneralCells) && _.isArray(metas)) {
                var newCell = _.concat(optionalGeneralCells, metas).map(f => ({ ...f, display: _.get(f, 'display', true) }))
                setOptionalCells(newCell)
            }
        } catch (error) {
            console.log('error', error)
        }

    }, [optionalGeneralCells, metas])


    function handleExportAllCSV() {
        const fields = _.concat(staticHeadCells, optionalCells).map(cell => ({ ...cell, value: row => _.get(row, cell.value) }))
        Utils.obj2csv(rowRef.current, fields, `${table_name}_report`, true)
    }

    const SelectToolbar = ({ selected, setSelected }) => {
        return (
            <Grid className={classNames(classes.toolbar, { [classes.highlight]: selected.length > 0 })} container alignItems="center" spacing={1} style={{ minHeight: '48px' }}>
                {SelectBar ? <SelectBar selected={selected} clearSelected={() => setSelected([])} /> : <Grid item xs />}
                <Grid item>
                    <Tooltip title={t('export_all')}>
                        <IconButton onClick={() => handleExportAllCSV(selected)} style={{ padding: '4px' }}>  <CloudDownloadIcon /></IconButton>
                    </Tooltip>
                </Grid >
                <Grid item>
                    <Tooltip title={t('select_fields')}>
                        <MetaSelecter displayCells={optionalCells} setDisplayCells={setOptionalCells} />
                    </Tooltip>
                </Grid >
                <Grid item>
                    <Tooltip title={t('refresh')}>
                        <IconButton onClick={handleRefresh} style={{ padding: '4px' }}> <RefreshIcon /></IconButton>
                    </Tooltip>
                </Grid >
            </Grid >
        )
    }

    return (
        <Fragment>

            <TableToolbar
                setSearch={setSearch}
                searchOptions={_.concat(staticHeadCells, optionals, metas)}
            />
            <Card>
                <DataTable
                    Container={Box}
                    Toolbar={SelectToolbar}
                    row_id='id'
                    rows={_.filter(rows, row => !search.filter || search.filter(row))}
                    rowRef={rowRef}
                    heads={_.concat(staticHeadCells, optionalCells.filter(v => v.display))}
                    rowsPerPageOptions={[10, 20, 50, 100, 200]}
                    loading={rows === undefined}
                    {...rest}
                />
            </Card>
        </Fragment>
    )
}




export default DataTableWithMeta
