import { FormColorTextField, FormSwitch, FormTextField } from 'components/DataForm'

import { Drawer, Box, Grid, useTheme, Typography, Toolbar, IconButton, withWidth } from '@material-ui/core'
import { DataSession } from 'components/DataSession'
import { useLocale } from 'provider/LocaleProvider'
import { Fragment, useEffect, useState } from 'react'
import '../theme.css'
import CloseIcon from '@material-ui/icons/Close'
import ThemeEditDrawer from '../ThemeEditDrawer'
import useRect from 'hooks/useRect'
import useApi from 'api/useApi'
import Loader from "react-loader-spinner"
import SlideShow from '../slideshow/SlideshowPreview'
import { Switch } from 'react-router-dom'
var QRCode = require('qrcode.react')




const _ = require('lodash')
function createQRCode({ display_text, background_color, text_color, code, is_active = true, scale = 1 }) {

    const { t } = useLocale
    return (
        {
            "background_color": background_color || '#000000',
            "text_color": text_color || "#ffffff",
            "code": code || 'Scan Me',
            "display_text": display_text || 'Scan Me',
            "is_active": is_active,
            "scale": scale,
        }
    )
}


const GeneralDrawer = ({ open = true, setOpen, detail, setDetail }) => {

    const [general, setGeneral] = useState(detail)
    console.log('GeneralDrawer => general', general)

    const { t } = useLocale()


    const api = useApi()
    useEffect(() => {
        if (open)
            setGeneral(detail)
    }, [open])

    const handleSetValue = (name, format = (v) => v) => v => {
        var value = format(v)
        if (value !== null) setGeneral(d => ({ ...d, [name]: value }))
    }

    function handleSave() {
        api.updateThemeDetails({ background_color: general.background_color, loader_color: general.loader_color }, setDetail)
    }
    function handleClear() {
        setGeneral(g => ({
            ...g,
            background_color: '#000000',
            loader_color: '#FFFFFF'
        }))
    }



    const qrcodeForm = () => (
        <Fragment>
            <FormColorTextField name={t('background_color')} value={_.get(general, 'background_color', '#000000')} setValue={handleSetValue('background_color')} />
            <FormColorTextField name={t('loader_color')} value={_.get(general, 'loader_color', '#FFFFFF')} setValue={handleSetValue('loader_color')} />
        </Fragment>
    )

    const preview = () => (
        <div style={{ backgroundColor: _.get(general, 'background_color', '#000000'), display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', height: '100%', width: '100%', textAlign: 'center' }}>
            <Loader
                type="Oval"
                color={_.get(general, 'loader_color', '#FFFFFF')}
                height={'100%'}
                width={'min(80%, 400px)'}
            />
        </div>
    )

    return (
        <Fragment>
            <ThemeEditDrawer open={open} setOpen={setOpen} detail={detail} title={t('edit_color_theme')} preview={preview} form={qrcodeForm} handleSave={handleSave} handleClear={handleClear} />
        </Fragment>
    )




}

export default GeneralDrawer