import '../theme.css'

var QRCode = require('qrcode.react')




const Qrcode = ({ qrcode = {} }) => {
    if (qrcode.is_active)
        return (
            <div className={'qrcode-box'} style={{ backgroundColor: qrcode.background_color, borderRadius: '24px', zoom: qrcode.scale }}>
                {qrcode.code && qrcode.code != '' &&
                    <div width='100%' style={{ aspectRatio: '1', padding: '36px' }}>
                        <div style={{ backgroundColor: 'white', width: '100%', height: '100%', padding: '24px', borderRadius: '24px' }}>
                            <QRCode
                                value={qrcode.code || ''}
                                style={{ backgroundColor: 'black', width: '100%', height: '100%' }}
                            />
                        </div>
                    </div>
                }
                <div style={{ color: qrcode.text_color, textAlign: 'center', fontSize: '40px', margin: '0 36px 36px 36px', lineHeight: '40px', maxHeight: '160px', overflow: 'hidden' }}>
                    {qrcode.display_text}
                </div>
            </div>
        )
    else return null


}

export default Qrcode