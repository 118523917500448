import React, { forwardRef, Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import HelpIcon from '@material-ui/icons/Help'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import MenuIcon from '@material-ui/icons/Menu'
import NotificationsIcon from '@material-ui/icons/Notifications'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Box, Drawer, FormControl, InputBase, List, ListItem, ListItemIcon, ListItemText, ListSubheader, MenuItem, Select } from '@material-ui/core'
import HeaderDrawer from './HeaderDrawer'
import SettingsIcon from '@material-ui/icons/Settings'
import { matchPath, NavLink, useLocation, useParams, withRouter } from 'react-router-dom'
import { useLocale } from '../provider/LocaleProvider'
import clsx from 'clsx'
import HomeIcon from '@material-ui/icons/Home'
import PeopleIcon from '@material-ui/icons/People'
const _ = require('lodash')
const useStyles = makeStyles((theme) => ({
    categoryHeader: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    categoryHeaderPrimary: {
        color: theme.palette.common.white,
    },
    item: {
        paddingTop: 1,
        paddingBottom: 1,
        color: 'rgba(255, 255, 255, 0.7)',
        '&:hover,&:focus': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
    },
    itemCategory: {
        backgroundColor: '#232f3e',
        boxShadow: '0 -1px 0 #404854 inset',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    firebase: {
        fontSize: 24,
        color: theme.palette.common.white,
    },
    itemActiveItem: {
        color: '#4fc3f7',
    },
    itemPrimary: {
        fontSize: 'inherit',
    },
    itemIcon: {
        minWidth: 'auto',
        marginRight: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(2),
        backgroundColor: '#404854',
    },



}))

function LinkTab(props) {
    return (

        <Tab
            style={{ ...props.style, minWidth: '32px' }}
            component={NavLink}
            {...props}
        />
    )
}

const CustomRouterLink = forwardRef((props, ref) => {
    return (
        <div
            ref={ref}
        // style={{ flexGrow: 1 }}
        >
            <NavLink {...props} />
        </div>
    )
})




const TabDrawer = ({ open = true, onClose = new Function() }) => {
    const classes = useStyles()
    const { t } = useLocale()


    const DrawerItem = ({ to, text, Icon }) => {
        return (
            <ListItem
                className={clsx(classes.item, classes.itemCategory)}
                component={CustomRouterLink}
                to={to}
                onClick={onClose}

            >
                <ListItemIcon className={classes.itemIcon}>
                    <Icon />
                </ListItemIcon>
                <ListItemText
                    classes={{
                        primary: classes.itemPrimary,
                    }}
                >
                    {text}
                </ListItemText>
            </ListItem>
        )
    }
    return (
        <Drawer variant='temporary' open={open} onClose={onClose} PaperProps={{ style: { width: 256 } }}>
            <List disablePadding>
                <ListItem className={`${classes.firebase} ${classes.item} ${classes.itemCategory}`}>
                    Animae
                </ListItem>
                <DrawerItem to='/campaigns' text={t('campaigns')} Icon={HomeIcon} />
                <DrawerItem to='/devices' text={t('devices')} Icon={HomeIcon} />
                <DrawerItem to='/themes' text={t('themes')} Icon={HomeIcon} />
            </List>
        </Drawer>
    )
}


const DashTabs = () => {
    const classes = useStyles()
    const location = useLocation()
    const { t } = useLocale()
    const [open, setOpen] = useState(false)

    return (
        <Fragment>
            <TabDrawer open={open} onClose={() => setOpen(false)} />
            <Hidden smUp>
                <Grid item xs>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setOpen(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <Tooltip title="Home">
                        <IconButton color="inherit" className={classes.iconButtonAvatar} component={NavLink} to='/home'>
                            <HomeIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Hidden>
            <Hidden xsDown>
                <Grid item>
                    <Tooltip title="Home"  >
                        <IconButton color="inherit" className={classes.iconButtonAvatar} component={NavLink} to='/home'>
                            <HomeIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs>
                    <Tabs value={'/' + _.get(location.pathname.split('/'), 1)} aria-label="simple tabs example">
                        <LinkTab label={t('campaigns')} to='/campaigns' value='/campaigns' />
                        <LinkTab label={t('devices')} to='/devices' value='/devices' />
                        <LinkTab label={t('themes')} to='/themes' value='/themes' />
                    </Tabs>
                </Grid>
            </Hidden>
        </Fragment >
    )
}


export default DashTabs
