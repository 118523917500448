import { makeStyles } from '@material-ui/core/styles'
import useApi from 'api/useApi'
import React, { Fragment, useMemo, useCallback, useEffect, useState, useRef, useLayoutEffect } from 'react'
import { Box, Button, Card, Container, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import DataTable from 'components/DataTable/DataTable'
import { id } from 'date-fns/locale'
import { useLocale } from 'provider/LocaleProvider'
import SearchIcon from '@material-ui/icons/Search'
import { t } from 'react-switch-lang'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Helmet } from 'react-helmet'
import EditDialog from 'components/EditDialog'


import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { useSnack } from 'provider/SnackbarProvider'
import { useLocation } from 'react-router'
import { useBackDrop } from 'provider/BackdropProvider'
import ImagePopover from 'components/ImagePopover'
import { Autocomplete } from '@material-ui/lab'
import Constants from 'misc/Constants'
const fy = Constants.yupErrorMessage
const _ = require('lodash')
const useStyles = makeStyles(theme => ({
    body: {
        flex: 1,
        margin: 'auto',
        padding: theme.spacing(3),
        boxSizing: 'border-box',
    },
    card: {
        '& .title': {
            display: 'inline-table',
            color: theme.palette.text.secondary,
            width: '300px',
            maxWidth: '100%',
            overflowWrap: 'anywhere',
            marginBottom: '8px',
        },
        '& .content': {
            display: 'inline-table',
            overflowWrap: 'anywhere',
            marginBottom: '8px',
            width: '200px',
            maxWidth: '100%'
        },
    },
    select: {
        '& .MuiSelect-select': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: "center",
        }
    },
    track: {
        alignItems: 'center',
        marginBottom: 16,
        padding: '16px',
        '& .title': {
            alignSelf: 'center',
            // color: theme.palette.text.secondary,
            width: '100px',
            maxWidth: '100%',
            overflowWrap: 'anywhere',
        },
        '& .content': {
            overflowWrap: 'anywhere',
            minWidth: '200px',
            maxWidth: '100%'
        },
    },
}))








const AddConfigDialog = ({ open, setOpen, setSelected, setConfigs }) => {
    const snack = useSnack()
    const api = useApi()
    const location = useLocation()
    const [deviceSpec, setDeviceSpec] = useState([])

    useEffect(async () => {
        api.getDeviceSpecifications().then(res => setDeviceSpec(res.data.device_specifications))
    }, [location.pathname])


    const handleClose = () => {
        setOpen(false)
        createFormik.handleReset()
    }

    const createFormik = useFormik({
        initialValues: {
            name: '',
            device_specification_id: '',
            tracks: [],
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required().nullable().max(128),
            device_specification_id: Yup.string().required().nullable(),
            tracks: Yup.array()
        }),
        onSubmit: (values, helper) => {
            api.addCampaignDeviceConfig({
                device_specification_id: values.device_specification_id,
                name: values.name,
                tracks: values.tracks
            }).then(res => {
                snack.open(t('saved'))
                helper.setSubmitting(false)
                setOpen(false)
                var temp = { ...res.data, device_specification: _.find(deviceSpec, { id: res.data.device_specification_id }) }
                setConfigs(list => _.unionBy([temp], [...list], 'id'))
                setSelected(temp)
            }).catch(e => {
                helper.setErrors({ name: e.msg })
                helper.setSubmitting(false)
            })

        },
    })




    return (
        <EditDialog maxWidth='xs' title={t('add_new_configure')} open={open} handleClose={handleClose} handleSave={createFormik.handleSubmit} confirmDisabled={createFormik.isSubmitting}>
            <TextField
                select
                fullWidth
                label={t('device_specification')}
                name="device_specification_id"
                error={Boolean(createFormik.touched?.device_specification_id && createFormik.errors?.device_specification_id)}
                helperText={createFormik.touched?.device_specification_id && createFormik.errors?.device_specification_id}
                onBlur={createFormik.handleBlur}
                onChange={createFormik.handleChange}
                value={createFormik.values?.device_specification_id}
                variant="outlined"
                style={{ marginBottom: 16 }}
            >
                {_.map(deviceSpec, (sp, index) => {
                    return (
                        <MenuItem key={index} value={sp.id} >
                            {sp.name}
                        </MenuItem>
                    )
                })}
            </TextField>
            <TextField
                fullWidth
                label={t('name')}
                name="name"
                error={Boolean(createFormik.touched?.name && createFormik.errors?.name)}
                helperText={createFormik.touched?.name && createFormik.errors?.name}
                onBlur={createFormik.handleBlur}
                onChange={createFormik.handleChange}
                value={createFormik.values?.name}
                variant="outlined"
            />
        </EditDialog>
    )
}


const ProductDeviceConfigure = (props) => {


    const classes = useStyles()
    const [open, setOpen] = useState(false)

    const location = useLocation()
    const snack = useSnack()
    const api = useApi()

    const [selected, setSelected] = useState()
    const [configs, setConfigs] = useState()
    const [products, setProducts] = useState()
    const backdrop = useBackDrop(

    )
    useEffect(async () => {
        backdrop.open()
        api.getCampaignDeviceConfigs().then(res => {
            setConfigs(res.data.campaign_device_configs)
            if (_.size(res.data.campaign_device_configs) > 0) {
                setSelected(res.data.campaign_device_configs[0])
            }
        })
        api.getProducts().then(res => setProducts(res.data.products))
    }, [location.pathname])

    useEffect(async () => {
        if (configs && products) {
            backdrop.close()
        }
    }, [configs, products])



    const formik = useFormik({
        initialValues: {
            id: selected?.id,
            name: selected?.name || '',
            device_specification_id: selected?.device_specification_id || '',
            configs: _.times(selected?.device_specification?.total_tracks, n => ({ track_id: n + 1, product_id: _.find(selected?.configs, { track_id: n + 1 })?.product_id || null }))
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            id: Yup.string().required(),
            name: Yup.string().required().nullable().max(128).notOneOf(_.map(_.filter(configs, c => c?.id !== selected?.id), c => c.name), 'name exists'),
            configs: Yup.array().required().of(
                Yup.object({
                    track_id: Yup.number(),
                    product_id: Yup.string().nullable()
                })
            )
        }),
        onSubmit: (values, helper) => {
            api.updateCampaignDeviceConfig(values.id, {
                name: values.name,
                configs: values.configs
            }).then(res => {
                snack.open(t('saved'))
                helper.setSubmitting(false)
                setOpen(false)
                var temp = { ...selected, ...res.data }
                setConfigs(list => _.unionBy([temp], [...list], 'id'))
                setSelected(temp)
            }).catch(e => {
                helper.setErrors({ name: e.msg })
                helper.setSubmitting(false)
            })

        },
    })


    const handleAdd = () => {
        setOpen(true)
    }

    return (
        <>
            <Helmet>
                <title>Product Device Configure | Redemption Token CMS</title>
            </Helmet>
            <Container>
                <Typography variant='h4' className='bold' paragraph>
                    {t('product_device_configure')}
                </Typography>
                <Box display='flex' flexDirection='row' mb={2} >
                    {/* <TextField
                        select
                        disabled={_.size(configs) === 0}
                        label="Product Device Configure Name"
                        variant='outlined'
                        fullWidth
                        value={selected || {}}
                        onChange={(e) => setSelected(e.target.value)}
                    >
                        {_.map(configs, (sp, index) => {
                            return (
                                <MenuItem key={index} value={sp} >
                                    {sp.name}
                                </MenuItem>
                            )
                        })}
                    </TextField> */}
                    <Autocomplete
                        autoHighlight
                        label="Product Device Configure Name"
                        variant='outlined'
                        fullWidth
                        value={selected || {}}
                        groupBy={op => _.get(op, 'device_specification.name')}
                        onChange={(e, v) => setSelected(v)}
                        options={_.toArray(configs)}
                        disabled={_.size(configs) === 0}
                        getOptionLabel={op => op.name}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                        renderOption={(op) => {
                            return <Box display='flex' width={'100%'} >
                                {op.name}
                                <Box flexGrow={1} />
                                {op.device_specification && <div>
                                    {`${op.device_specification.name} (${op.device_specification.total_tracks})`}
                                </div>}
                            </Box>
                        }}
                    />
                    <Button color='primary' onClick={handleAdd} variant='contained' style={{ marginTop: 'auto', marginBottom: 'auto', marginLeft: '8px', flexShrink: 0 }}>
                        {t('add_config')}
                    </Button>
                </Box>
                <Divider style={{ margin: '24px -24px' }} />
                <Box display={selected?.id ? 'block' : 'none'}>
                    <Grid container spacing={2} style={{ flexWrap: 'wrap-reverse' }}>
                        <Grid item xs={12} md={8}>
                            <form onSubmit={formik.handleSubmit} autoComplete='off'>
                                <TextField
                                    error={Boolean(formik.touched?.name && formik.errors?.name)}
                                    fullWidth
                                    helperText={formik.touched?.name && formik.errors?.name}
                                    label={t('name')}
                                    name="name"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values?.name}
                                    variant="outlined"
                                    style={{ marginBottom: 16 }}
                                />
                                {_.map(_.orderBy(formik.values.configs, 'track_id'), (n, index) => {
                                    var name = `configs.${index}.product_id`
                                    var value = _.get(formik, `values.configs.${index}.product_id`, '')
                                    return (
                                        <Card className={classes.track} >
                                            <Grid container spacing={2}>
                                                <Grid item className='title'>
                                                    <Typography >{t('track')} {index + 1} </Typography>
                                                </Grid>
                                                <Grid item xs className='content'>
                                                    <TextField
                                                        select
                                                        className={classes.select}
                                                        fullWidth
                                                        label={t('product')}
                                                        name={name}
                                                        disabled={_.size(products) === 0}
                                                        error={Boolean(formik.touched[name] && formik.errors[name])}
                                                        helperText={formik.touched[name] && formik.errors[name]}
                                                        onBlur={formik.handleBlur}
                                                        placeholder={t('no_product')}
                                                        onChange={formik.handleChange}
                                                        value={value}
                                                        variant="outlined"
                                                    >
                                                        <MenuItem value={null} >
                                                            {t('no_product')}
                                                        </MenuItem>
                                                        {_.map(products, (sp, index) => {
                                                            return (
                                                                <MenuItem disabled={!sp.is_active} key={index} value={sp.id} style={{ display: 'flex', flexDirection: 'row' }}>
                                                                    <span> {sp.name} </span>
                                                                    {/* <Box flexGrow={1} /> */}
                                                                    {sp.image_url &&
                                                                        <ImagePopover image_url={sp.image_url}>
                                                                            <Box height='20px' width='20px' ml={2} >
                                                                                <img src={sp.image_url} />
                                                                            </Box>
                                                                        </ImagePopover>
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    )

                                })}
                                <Box textAlign='right'>
                                    <Button variant='contained' color='primary' type='submit' disabled={formik.isSubmitting}>{t('save')}</Button>
                                </Box>
                            </form>
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <Card className={classes.card} style={{ marginBottom: 24, padding: '16px 16px 8px 16px' }}>
                                <Box>
                                    <Typography className='title'> {t('device_specification_name')}</Typography>
                                    <Typography className='content'>{selected?.device_specification?.name}</Typography>
                                </Box>
                                <Box mb={2}>
                                    <Typography className='title'>{t('device_track_no')} </Typography>
                                    <Typography className='content'>{selected?.device_specification?.total_tracks}</Typography>
                                </Box>
                                <img src={selected?.device_specification?.image_url} style={{ minHeight: '200px', maxHeight: '1000px' }} />
                            </Card>
                        </Grid>
                    </Grid>

                </Box>
                <AddConfigDialog open={open} setOpen={setOpen} setConfigs={setConfigs} setSelected={setSelected} />
            </Container >
        </>

    )
}


export default ProductDeviceConfigure
