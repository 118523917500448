
import { Box, isWidthDown, makeStyles, Paper, Typography, useTheme, withWidth } from '@material-ui/core'
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import GridListTile from '@material-ui/core/GridListTile'
import GridList from '@material-ui/core/GridList'
import clsx from 'clsx'
import AddIcon from '@material-ui/icons/Add'
import { withRouter } from 'react-router-dom'
import { useLocale } from 'provider/LocaleProvider'
import { useFormikContext } from 'formik'


const useStyles = makeStyles(theme => ({
    gridList: {
        // width: 'min-content',
        // minWidth: '100%',
        boxSizing: 'border-box',
        alignContent: 'baseline',
        // padding: theme.spacing(2, 2),
        // margin: '-20px'
    },
    gridTile: {
        height: '200px',
        minWidth: '150px',
        minHeight: '50px',
        overflow: 'visible',
        padding: theme.spacing(0)
    },
    paper_add: {
        borderRadius: theme.spacing(0.5),
    },
    paper: {
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(2),
        paddingTop: theme.spacing(5),
        alignContent: 'flex-start'
    }
}))





const ChooseGenerateMode = () => {


    const classes = useStyles()
    const { t } = useLocale()
    const [cols, setCols] = useState(3)
    const ref = useRef(null)

    const formik = useFormikContext()
    function handleResize() {
        if (ref.current == null) return
        const rect = ref.current.getBoundingClientRect()
        if (rect.width > 600) {
            setCols(3)
        }
        else {
            setCols(1)
        }

    }
    const list = [{ name: t('mode_auto'), value: 'auto' }, { name: t('mode_upload'), value: 'upload' }, { name: t('mode_custom'), value: 'custom' }]

    useLayoutEffect(() => {
        handleResize()
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener('keydown', handleResize)
        }
    }, [])

    function handleClick(tile) {
        formik.setFieldValue('mode', tile.value)
        formik.setFieldValue('step', 1)
    }

    return (
        <div className='flexCol' ref={ref} style={{ visibility: cols == null ? 'hidden' : 'visible' }}>
            <Typography noWrap variant='h5' color='primary' paragraph>{t('choose_generate_mode')}</Typography>
            <GridList className={classes.gridList} cols={cols} cellHeight='auto' spacing={24}>
                {list.map((tile, index) => {
                    return (
                        <GridListTile key={`tile_${index}`} cols={1} classes={{ tile: classes.gridTile }}  >
                            <Paper className={clsx('flexCol-center', 'fullSize', classes.paper_add)} onClick={() => handleClick(tile)} elevation={1}>
                                <AddIcon color='primary' style={{ width: '100px', height: '100px' }} />
                                <Typography variant='h6' color='primary'> {tile.name}</Typography>
                            </Paper>
                        </GridListTile>
                    )
                })}
            </GridList >
        </div >
    )
}

ChooseGenerateMode.propTypes = {
    // t: PropTypes.func.isRequired,
}

export default ChooseGenerateMode