import { DomainDisabled } from '@material-ui/icons'
import Axios from 'axios'
import { useBackDrop } from 'provider/BackdropProvider'
import { useHistory, useParams } from 'react-router-dom'
import { useSnack } from 'provider/SnackbarProvider'
import { useLocale } from 'provider/LocaleProvider'
import { useAuthData } from 'provider/AuthDataProvider'
import { useEffect } from 'react'
import apiLink from 'api/apiLink'

const moment = require('moment-timezone')
const baseURL = apiLink.server_url + '/api/v2'

Axios.defaults.baseURL = baseURL

const _ = require('lodash')
const debug = false

export function jsonToQuery(data) {
    if (typeof data == 'object') {
        var query = ''
        var entries = Object.entries(data)
        entries.map(([key, value], index) => {
            if (index > 0) {
                query = query + '&' + `${key}=${value}`
            }
            else {
                query = '?' + `${key}=${value}`
            }
        })
        return query
    }
    return ''
}

function saveFile(blob, fileName, fileType) {
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.href = url
    a.download = `${fileName}_${moment().format('YYYY_MM_DD')}.${fileType}`
    a.click()
    window.URL.revokeObjectURL(url)
}




const useApi = () => {
    const backdrop = useBackDrop()
    const history = useHistory()
    const snack = useSnack()
    const { t } = useLocale()


    const { campaignId, deviceId, themeId } = useParams()
    const { token, headers, organization } = useAuthData()


    function thenCloseBackDrop(res) {
        backdrop.close()
        return Promise.resolve(res)
    }


    const axios = Axios.create({
        baseURL: baseURL,
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`,
            'organization_id': organization?.id,
        },
    })

    axios.interceptors.request.use(
        (config) => {
            return config
        },
        (error) => {
            console.log(error)
            return error
        }
    )

    axios.interceptors.response.use(
        (response) => {
            const result = response && response.data
            if (result && result.result !== 'fail') {
                return response
            }
            else throw result
        },
        (error) => {
            backdrop.close()
            return Promise.reject(error)
        }
    )

    const getToken = (headers) => {
        return axios.get(`/token`, { headers: headers })
    }
    var campaignApi = {}

    campaignApi.addCampaign = ({ name, identifier, is_active }) => {
        const newCampaign = {
            name,
            identifier,
            is_active
        }
        return axios.post(`/campaigns`, newCampaign)
    }

    campaignApi.getCampaigns = (setCampaigns = new Function(), json = {}) => {
        var query = jsonToQuery(json)
        return (
            axios.get(`/campaigns${query}`).then(res => {
                const result = res.data
                setCampaigns(result.campaigns)
                return Promise.resolve(res)
            })
                .catch(err => history.push('/home'))
        )
    }

    campaignApi.getCampaignDetails = (setCampaign) => {
        return axios.get(`/campaigns/${campaignId}`).then(res => {
            const result = res.data
            setCampaign(result)
        }).catch(err => history.push('/campaigns'))
    }

    campaignApi.getCampaignData = (setCampaignData = new Function()) => {
        backdrop.open()
        return axios.get(`/campaigns/${campaignId}/data`).then(res => {
            backdrop.close()
            const result = res.data
            setCampaignData(result)
        })
    }

    campaignApi.updateCampaign = (updates, setCampaign = new Function()) => {
        if (!campaignId) return Promise.resolve({ data: { result: 'fail' } })
        return axios.post(`/campaigns/${campaignId}`, updates)
            .then(res => {
                const result = res.data
                setCampaign(result)
            })
    }


    campaignApi.getTokens = (json) => {
        if (!campaignId) return Promise.resolve({ data: { result: 'fail' } })
        backdrop.open()
        var query = jsonToQuery(json)
        return axios.get(`/campaigns/${campaignId}/redeem_tokens${query}`).then(thenCloseBackDrop)
    }

    campaignApi.getRedeemRecords = (json) => {
        if (!campaignId) return Promise.resolve({ data: { result: 'fail' } })
        backdrop.open()
        var query = jsonToQuery(json)
        return axios.get(`/campaigns/${campaignId}/redeem_records${query}`).then(thenCloseBackDrop)
    }

    campaignApi.editTokenRules = (tokenArray, tokenRule) => {
        if (!campaignId) return Promise.resolve({ data: { result: 'fail' } })
        return axios.post(`/campaigns/${campaignId}/redeem_tokens`, { redeem_tokens: tokenArray, redeem_token_rule: tokenRule })
    }


    campaignApi.exportSelecedTokensToCSV = (tokenArray) => {
        if (!campaignId) return Promise.resolve({ data: { result: 'fail' } })
        var query = jsonToQuery({ export: 'csv' })
        return axios.post(`/campaigns/${campaignId}/redeem_tokens${query}`, { redeem_tokens: tokenArray }, { responseType: 'blob', headers: headers }).then(res => {
            if (res.data) {
                saveFile(res.data, 'exported_tokens', 'csv')
            }
        })
    }



    campaignApi.exportSelecedTokensToPDF = (tokenArray) => {
        if (!campaignId) return Promise.resolve({ data: { result: 'fail' } })
        var query = jsonToQuery({ export: 'pdf' })
        backdrop.open()
        return axios.post(`/campaigns/${campaignId}/redeem_tokens${query}`, { redeem_tokens: tokenArray }, { responseType: 'blob', headers: headers }).then(res => {
            if (res.data) {
                saveFile(res.data, 'exported_tokens', 'pdf')
                backdrop.close()
            }
            // window.location.assign(url)
        })
    }


    campaignApi.generateToken = ({ product_id, campaign_id, redeem_token_rule, metadata, tokens_count, redeem_tokens }) => {
        var data = _.omitBy({
            use_default: false,
            redeem_token_rule: {
                metadata,
                ...redeem_token_rule
            },
            campaign_id,
            metadata,
            redeem_tokens,
            tokens_count,
            product_id
        }, _.isNil)
        return axios.post(`/redeem_tokens`, data)
    }


    campaignApi.redeemToken = (token) => {
        if (!token?.redeem_token) return null
        const data = {
            campaign_id: campaignId,
            redeem_pin: token?.redeem_pin,
            confirm: true
        }
        return axios.post(`/redeem/${token?.redeem_token}`, data)
    }


    var deviceApi = {}
    function getDevices(setDevices = new Function(), query = {}) {
        backdrop.open()
        var _query = jsonToQuery(query)
        return axios.get(`/devices${_query}`)
            .then(({ data }) => {
                backdrop.close()
                const result = data || {}
                setDevices(result.devices || null)
            })
            .catch(err => { setDevices(null) })
    }

    function getDeviceDetails(setDeviceDetails = new Function()) {
        if (!deviceId) return Promise.resolve({ data: { result: 'fail' } })
        return axios.get(`/devices/${deviceId}`)
            .then(res => {
                const result = res.data
                setDeviceDetails(result || null)
                return Promise.resolve(res)
            })
            .catch(err => {
                setDeviceDetails(null)
                history.push('/devices')
            })
    }

    deviceApi.checkDeviceStatus = (device_id) => {
        return axios.get(`/devices/${device_id}/actions/status?token=${token}`)
    }

    function updateDeviceDetails(data, setDeviceDetails = new Function()) {
        if (!deviceId) return Promise.resolve({ data: { result: 'fail' } })
        return axios.post(`/devices/${deviceId}`, data)
            .then(res => {
                const result = res.data
                if (result.result !== 'fail') {
                    setDeviceDetails(result)
                    snack.open(t('saved'))
                    return Promise.resolve(res)
                }
                else throw 'error'

            })
            .catch(err => {
                snack.error(t('failed'))
                return Promise.reject(err)
            })
    }


    function attachDevice(mode = 'attach/campaign', data = { campaign_id: 'campaign_id' }, setDeviceDetails) {
        return axios.post(`/devices/${deviceId}/${mode}`, data)
            .then(res => {
                const result = res.data
                if (result.result !== 'fail') {
                    setDeviceDetails(result)
                    snack.open(t('saved'))
                }
                else throw 'error'

            })
            .catch(err => {
                snack.error(t('failed'))
                return Promise.reject(err)
            })
    }


    function addTheme(data, setThemes) {
        return axios.post(`/themes`, data)
            .then(res => {
                const result = res.data || {}
                if (result.result != 'fail' && setThemes) {
                    getThemes(setThemes)
                    snack.open(t('theme_added'))
                }
                else {
                    snack.error(t('failed'))
                    throw 'failed'
                }
            })
    }

    function getThemes(setTheme = new Function()) {
        backdrop.open()
        return axios.get(`/themes`)
            .then(res => {
                backdrop.close()
                const result = res.data || {}
                setTheme(result.themes || null)
            })
            .catch(err => { setTheme(null) })
    }

    function getThemeDetails(setThemeDetails = new Function()) {
        if (!themeId) history.push('/themes')
        return axios.get(`/themes/${themeId}`)
            .then(res => {
                const result = res.data
                setThemeDetails(result)
            })
            .catch(err => {
                setThemeDetails(null)
                history.push('/themes')
            })
    }

    function updateThemeDetails(data, setThemeDetails = new Function()) {
        if (!themeId) history.push('/themes')
        return axios.post(`/themes/${themeId}`, data)
            .then(res => {
                const result = res.data
                if (result.result !== 'fail') {
                    setThemeDetails(result)
                    snack.open(t('saved'))
                    return Promise.resolve(res)
                }
                else throw 'error'

            })
            .catch(err => {
                snack.error(t('failed'))
                return Promise.reject(err)
            })
    }

    function deleteTheme(theme_id) {
        if (theme_id === undefined) return Promise.reject('fail')
        return axios.delete(`/themes/${theme_id}`)
            .then(res => {
                const result = res.data
                if (result && result.result !== 'fail') {
                    snack.open(t('deleted'))
                    history.push('/themes')
                }
                else throw 'error'

            })
            .catch(err => {
                snack.error(t('failed'))
                return Promise.reject(err)
            })
    }


    function uploadMedia(type, formdata) {
        return axios.post(`/upload/${type}`, formdata)
            .then(res => {
                const result = res.data
                if (result && result.result !== 'fail') {
                    return Promise.resolve(result)
                }
                else throw 'error'

            })
            .catch(err => {
                snack.error(t('failed'))
                return Promise.reject(err)
            })
    }


    return {
        baseURL,
        getToken,
        ...campaignApi,
        ...deviceApi,
        getDevices,
        getDeviceDetails,
        updateDeviceDetails,
        attachDevice,
        addTheme,
        getThemes,
        getThemeDetails,
        updateThemeDetails,
        deleteTheme,
        uploadMedia,
        downloadQrCode: (token) => {
            return axios.get(`/token_qr/${token}`)
        },
        oneTimeRedeem: (body) => {
            return axios.post(`/one-time-redeem`, body)
                .catch(err => {
                    console.log('err: ', err)
                })
        },
        getProducts: (params) => {
            return axios.get(`/campaigns/${campaignId}/products`, {
                params: params
            })
        },
        addProduct: async ({ name, image }) => {
            var data = { name }
            if (image) {
                const formData = new FormData()
                formData.append('image_url', image)
                const files = await uploadMedia('images', formData)
                for (const file of files) {
                    _.set(data, file.fieldname, file.url)
                }

            }
            return axios.post(`/campaigns/${campaignId}/products`, data)
        },
        updateProduct: async (product_id, { name, image, image_url, is_active }) => {
            var data = { name, image_url, is_active }
            if (image) {
                const formData = new FormData()
                formData.append('image_url', image)
                const files = await uploadMedia('images', formData)
                for (const file of files) {
                    _.set(data, file.fieldname, file.url)
                }
            }
            return await axios.post(`/campaigns/${campaignId}/products/${product_id}`, data)
        },
        getCampaignDeviceConfigs: (id = campaignId) => {
            return axios.get(`/campaigns/${id}/campaign_device_configs`)
        },
        addCampaignDeviceConfig: ({ device_specification_id, configs, name }) => {
            return axios.post(`/campaigns/${campaignId}/campaign_device_configs`, { device_specification_id, configs, name })
        },
        updateCampaignDeviceConfig: (config_id, { configs, name }) => {
            return axios.post(`/campaigns/${campaignId}/campaign_device_configs/${config_id}`, { configs, name })
        },
        getDeviceSpecifications: () => {
            return axios.get(`/device_specifications`)
        },
    }
}


export default useApi