import { Box, Container, Divider, InputAdornment, makeStyles, Paper, TextField, Typography, useTheme } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import clsx from 'clsx'
import { default as React, Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useApi from 'api/useApi'
import { useCampaign } from 'provider/CampaignProvider'
import { useLocale } from 'provider/LocaleProvider'
import { useSnack } from 'provider/SnackbarProvider'
import DetailsView from '../TokenDetails/components/DetailsView'

const moment = require('moment-timezone')

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    grid_container: {
        padding: '12px 24px',
        overflow: 'hidden'
    },
    text_name: {
        alignItems: 'start',
        minWidth: 'min(150px,100%)',
        maxWidth: '200px',
        paddingRight: '16px'
    },
    text_body: {
        // minWidth: 'min(200px,calc(100% - 32px))',
        alignItems: 'center',
        lineHeight: '24px',
        maxHeight: '96px',
        overflow: 'hidden',
        overflowWrap: 'break-word'
    },
    text_body_2: {
        // minWidth: 'min(200px,calc(100% - 32px))',
        // alignItems: 'center',
        lineHeight: '40px',
        maxHeight: '120px',
        overflow: 'hidden',
        overflowWrap: 'break-word'
    },

    divider: {
        width: 'calc(100% + 48px)',
        marginLeft: -theme.spacing(3),
        marginRight: -theme.spacing(3),
        marginTop: '16px',
        // marginBottom: '-16px'
    },
    formControlLabel: {
        margin: 0
    },
    button: {
        width: '80px',
    },
    statusButton: {
        margin: '24px 0 0 0',
        width: '100%',
        maxWidth: '1000px'
    },
    title: {
        padding: '4px 24px 12px 24px'
    },
    paper: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        overflow: 'hidden'
    }
}))


const TokenSearch = (props) => {
    const userRequest = useApi()
    const theme = useTheme()
    const classes = useStyles()
    const snack = useSnack()
    const { campaign, getRecordMetaField } = useCampaign()
    const [tokenDetails, setTokenDetails] = useState()
    const [records, setRecords] = useState([])
    const [metaField, setMetaField] = useState([])
    const [input, setInput] = useState()
    const [flag, setFlag] = useState(false)
    const [loading, setLoading] = useState(false)
    const { t } = useLocale()

    useEffect(() => {
        handleSearch()
        setMetaField(getRecordMetaField())
    }, [campaign])





    function handleChange(event) {
        setInput(event.target.value)
    }

    function handleSearch() {
        setLoading(true)
        if (input == '' || input == null) {
            setLoading(false)
            setTokenDetails(null)
            return
        }
        setFlag(true)
        getTokenDetails()
        getRedeemRecords()
    }

    function getTokenDetails() {
        userRequest.getTokens({ redeem_token: input })
            .then(res => {
                const result = res.data
                if (result && result.result != 'fail' && result.redeem_tokens && result.redeem_tokens.length > 0) {
                    setTokenDetails(result.redeem_tokens[0])
                }
                else {
                    setTokenDetails(null)
                }
                setLoading(false)
            })
    };

    function getRedeemRecords() {
        userRequest.getRedeemRecords({ redeem_token: input })
            .then(res => {
                const result = res.data
                if (result && result.result != 'fail') {
                    setRecords(result.redeem_records)
                }
                else {
                    setRecords([])
                }
            })
    };

    const handleKeyPress = (e) => {
        if (e.keyCode == 13 || e.charCode == 13) {
            handleSearch()
        }
    }

    return (
        <Container>
            <Typography variant='h4' className='bold' paragraph>
                {t('search_token')}
            </Typography>
            {/* <Typography noWrap variant='h5' color='primary' paragraph>Search by Token</Typography> */}
            <div className={clsx('flexCol-center', classes.root)}>
                <Box className='flexCol' width='100%' >
                    <Paper className='flexCol' >
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                            variant="outlined"
                            className={classes.textField}
                            name='input'
                            // disabled={type == null || type == ''}
                            placeholder={t('search')}
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                            value={input}
                        />
                    </Paper>
                </Box>
                <Divider className={classes.divider} />
                <Box className='flexCol' width='100%' mt={1} >
                    {flag &&
                        (loading
                            ? <Typography variant='h6' >{t('loading')}...</Typography>
                            : tokenDetails != null
                                ? <DetailsView details={tokenDetails || {}} records={records} metaField={metaField} />
                                : <Typography variant='h6' >{t('no_result')}</Typography>
                        )
                    }
                </Box>
            </div >
        </Container>

    )
}




TokenSearch.propTypes = {
    // t: PropTypes.func.isRequired,
}

export default TokenSearch