import React, { useContext, useEffect, useRef, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Box, CircularProgress, makeStyles, TextField, Typography, useTheme } from '@material-ui/core'
import PropTypes, { object } from 'prop-types'
import DataTextField from './DataTextField'
import { useLocale } from '../provider/LocaleProvider'
import { Autocomplete } from '@material-ui/lab'
import { Fragment } from 'react'
const useStyles = makeStyles((theme) => ({
    dialog: {
        padding: '16px',
        width: 'min(800px, 100%)',
        overflow: 'hidden',
        // backgroundColor: 'rgba(0,0,0,0.5)'
    },
    button: {
        '&:hover': {
            // backgroundColor: 'red',
            cursor: 'pointer'
        },
        '&:active': {
            // backgroundColor: 'red',
            cursor: 'auto'
        }
    },
}))

const ImageDialog = ({ children, image_url, ...rest }) => {

    const { t } = useLocale()

    const classes = useStyles()
    const [open, setOpen] = useState(false)


    const handleOpen = () => {
        setOpen(true)
        console.log('handlOpen => true', true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Fragment>
            <Box className={image_url ? classes.button : ''} onClick={handleOpen} >
                {children}
            </Box>
            {image_url &&
                <Dialog
                    // PaperProps={{
                    //     elevation: 0
                    // }}
                    classes={{ paper: classes.dialog }}
                    maxWidth='md'
                    open={open}
                    onClose={handleClose}
                    {...rest}
                >
                    <img src={image_url} style={{ maxHeight: 'calc(100vh - 96px)' }} />
                </Dialog>
            }
        </Fragment >
    )
}



export default ImageDialog