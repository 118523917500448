import Hidden from '@material-ui/core/Hidden'
import Link from '@material-ui/core/Link'
import { createMuiTheme, makeStyles, ThemeProvider, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import DnsRoundedIcon from '@material-ui/icons/DnsRounded'
import HomeIcon from '@material-ui/icons/Home'
import PeopleIcon from '@material-ui/icons/People'
import PermMediaOutlinedIcon from '@material-ui/icons/PhotoSizeSelectActual'
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent'
import PropTypes from 'prop-types'
import { useLocale } from 'provider/LocaleProvider'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Container, TitleContainer } from './Container'
import Navigator from './Navigator'
import AirplayIcon from '@material-ui/icons/Airplay'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import BuildIcon from '@material-ui/icons/Build'
import { Fragment } from 'react'
function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            {new Date().getFullYear()}
            {' '}
            <Link color="inherit" href="https://www.animaetech.com/">
                <span style={{ display: 'inline-block' }}> {'Animae Technologies Limited.'}</span>
            </Link>
            <span style={{ display: 'inline-block' }}>All Rights Reserved.</span>
        </Typography>
    )
}

const drawerWidth = 256

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        overflowY: 'hidden',
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
        "& .MuiDrawer-paper": {
            backgroundColor: '#18202c',
        },
        "& .MuiTabs-root": {
            marginLeft: theme.spacing(0.5),
        },
        "& .MuiTabs-indicator": {
            height: 3,
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
            backgroundColor: theme.palette.common.white,
        },
        "& .MuiTab-root": {
            textTransform: 'none',
            margin: '0 16px',
            minWidth: 0,
            padding: 0,
            [theme.breakpoints.down('md')]: {
                padding: 0,
                minWidth: 0,
            },
        },
        "& .MuiIconButton-root": {
            padding: theme.spacing(0.5),
        },
        "& .MuiTooltip-tooltip": {
            borderRadius: 4,
        },
        "& .MuiDivider-root": {
            backgroundColor: '#404854',
        },
        "& .MuiListItemText-primary": {
            fontWeight: theme.typography.fontWeightMedium,
        },
        "& .MuiListItemIcon-root": {
            color: 'inherit',
            // marginRight: 0,
            '& svg': {
                fontSize: 20,
            },
        },
        "& .MuiAvatar-root": {
            width: 32,
            height: 32,
        },
        "& .MuiInput-underline": {
            '&:not($disabled):not($focused):not($error):before': {
                border: 0
            },
            '&:not($disabled):not($focused):not($error):after': {
                border: 0
            },
            '&:hover:not($disabled):not($focused):not($error):after': {
                border: 0
            },
            '&:hover:not($disabled):not($focused):not($error):before': {
                border: 0
            }
        },
        "& .MuiInputBase-root": {
            color: 'red'
        },
        "& .MuiInputBase-input": {
            "&:focus": {
                backgroundColor: 'rgba(0,0,0,0)',
            }
        },

    },
    app: {
        paddingTop: '48px',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        background: '#eaeff1',
        overflowX: 'hidden',
        // height: '100%',
        minHeight: '100vh', /* Fallback for browsers that do not support Custom Properties */
        minHeight: 'calc(var(--vh, 1vh) * 100)',
    },

    main: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        boxSizing: 'border-box',
        margin: 'auto',
        padding: theme.spacing(3),
        maxWidth: '1240px'
        // paddingTop: theme.spacing(0),
    },

    footer: {
        padding: theme.spacing(1),
        background: '#eaeff1',
        // paddingBottom: '64px'
    },

}))

export function HomeLayout(props) {
    return <MainLayout {...props} />
}


export function DeviceLayout(props) {
    const theme = useTheme()
    const classes = useStyles(theme)
    const { t } = useLocale()
    const { deviceId } = useParams()


    const categories = [
        { id: t('devices'), icon: <AirplayIcon />, active: true, href: `/devices` },
        {
            id: t('device'),
            children: [
                { id: t('device_info'), icon: <VpnKeyIcon />, active: true, href: `/devices/${deviceId}/info` },
                { id: t('device_panel'), icon: <BuildIcon />, active: true, href: `/devices/${deviceId}/panel` },
                // { id: t('api'), icon: <VpnKeyIcon />, active: true, href: `/devices/${deviceId}/api_key` },
                // { id: t('device_track'), icon: <VpnKeyIcon />, active: true, href: `/devices/${deviceId}/track` },
            ],
        },
    ]
    return <MainLayout sidebar categories={categories} {...props} />
}




function MainLayout({ children, title, header: Header, sidebar, categories }) {
    // const { children, title, header: Header, sidebar = true } = props
    const theme = useTheme()
    const classes = useStyles(theme)
    const [mobileOpen, setMobileOpen] = React.useState(false)


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    useEffect(() => {
        document.getElementById("app").scroll(0, 0)
    }, [children])

    return (
        <Fragment>
            <div className={classes.root}>
                {sidebar &&
                    <nav className={classes.drawer}>
                        <Hidden mdUp implementation="css">
                            <Navigator
                                className={classes.drawer}
                                categories={categories}
                                PaperProps={{ style: { width: drawerWidth } }}
                                variant="temporary"
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                            />
                        </Hidden>
                        <Hidden smDown implementation="css">
                            <Navigator categories={categories} PaperProps={{ style: { width: drawerWidth } }} />
                        </Hidden>
                    </nav>
                }
                <div id='app' className={classes.app}>
                    <Header className={classes.header} title={title} onDrawerToggle={handleDrawerToggle} />
                    <Container>
                        {
                            title &&
                            <TitleContainer>
                                <Typography color="inherit" variant="h4" component="h1" className='bold'>
                                    {title}
                                </Typography>
                            </TitleContainer>
                        }
                        {children}
                    </Container>
                    <div className={classes.footer}>
                        <Copyright />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}


MainLayout.propTypes = {
    classes: PropTypes.object,
}

export default MainLayout
