import { Box, Button, Grid, InputAdornment, Link, makeStyles, Paper, TextField, Toolbar } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { Autocomplete } from '@material-ui/lab'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocale } from '../../../provider/LocaleProvider'
import MetaSelecter from './MetaSelecter'


const _ = require('lodash')
const moment = require('moment-timezone')
const useStyles = makeStyles(theme => ({
    typeField: {
        // maxWidth: '300px',
        width: '100%',
        minWidth: 'min(200px,100%)'
    },
    textField: {
        // maxWidth: '300px',
        width: '100%',
        minWidth: 'min(200px,100%)'
    },
    metaField: {
        width: '100%',
        minWidth: 'min(400px,100%)'
    },
    spacing: {
        padding: '4px'
    },
    button: {
        margin: '4px 0'
    },
    paper: {
        width: '100%',
    },
    autocomplete_inputRoot: {
        padding: '0px !important'
    },
    autocomplete_input: {
        padding: '14px 12px !important'
    }


}))




const TableToolbar = (props) => {

    const { table_name = 'table', setSearch, searchOptions } = props
    const { t } = useLocale()

    const classes = useStyles()
    const [type, setType] = useState(searchOptions[0])
    const [typeInput, setTypeInput] = useState('')

    const [input, setInput] = useState('')

    // useEffect(() => {
    //     if (searchOptions === undefined) return
    //     if (type === null || type === undefined) {
    //         var searchType = JSON.parse(localStorage.getItem(`${table_name}_search_type`))
    //         if (searchType) {
    //             var _type = searchOptions.find(op => op.id === searchType)
    //             setType(_type)
    //             return
    //         }
    //         setType(searchOptions[0])
    //     }
    //     else {
    //         var _type = searchOptions.find(op => op.id === type.id)
    //         setType(_type)
    //     }
    // }, [searchOptions])

    useEffect(() => {
        if (type != null && type != '' && input != '') {

            const filter = (row) => {
                try {
                    var regex = new RegExp(input, 'ig')
                    if (type.content && _.isString(type.content(row))) {
                        return regex.test(type.content(row))
                    }
                    else {
                        return regex.test(_.get(row, type.value))
                    }
                } catch (error) {
                    return false
                }
            }
            setSearch({ filter: filter })
        }
        else {
            setSearch({ filter: () => true })
        }
    }, [type, input])

    const handleChange = (event, value) => {
        setInput(event.target.value)
    }

    const handleTypeChange = (event, value) => {
        setType(value)
        localStorage.setItem(`${table_name}_search_type`, JSON.stringify(value.id))
    }

    return (
        <div >
            <Toolbar style={{ justifyContent: 'flex-end' }}>
                <Grid container alignItems="center" spacing={1} style={{ marginBottom: '4px' }}>
                    <Grid item xs={12} sm={5} style={{ minWidth: 'min(150px,100%)' }}>
                        <Paper className={classes.paper} elevation={0}>
                            <Autocomplete
                                className={classes.typeField}
                                classes={{
                                    inputRoot: classes.autocomplete_inputRoot,
                                    input: classes.autocomplete_input
                                }}
                                disableClearable
                                options={searchOptions}
                                groupBy={(option) => t(option.group)}
                                getOptionLabel={(option) => t(option.label || '')}
                                getOptionSelected={(option, value) => option && value && option.id === value.id && option.group === value.group}
                                onChange={handleTypeChange}
                                inputValue={typeInput}
                                onInputChange={(e, value) => {
                                    setTypeInput(value)
                                }}
                                value={type}
                                renderInput={(params) =>
                                    <TextField {...params} label={t('searching_fields')} variant="outlined" InputLabelProps={{

                                        shrink: true,
                                    }} />}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={7} style={{ minWidth: 'min(250px,100%)' }} >
                        <Paper className={classes.paper} elevation={0}>
                            <TextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }}
                                variant="outlined"
                                className={classes.textField}
                                name='input'
                                disabled={type == null || type == ''}
                                placeholder={t('search')}
                                onChange={handleChange}
                                value={input}
                            />
                        </Paper>
                    </Grid>


                </Grid>
            </Toolbar>
        </div >

    )
}

export default TableToolbar
